// top bottom

.percentage-topbottom {

  h3 {
    float: right;
    width: 30%;
    text-align: left;
    font-size: 11px;
    font-weight: bold;
    color: #969dab;
  }

  .ui-progressbar,
  .p-progressbar {
    height: 7px;
    margin-top: 3px;
    float: left;
    width: 65%;
  }
}


.carousel--top-botom {

  .p-carousel-item a {
    padding: 8px 10px;

    img {
      display: block
    }
  }

  .p-carousel-item a.active {
    background: #f1f1f1;
    color: black;
  }

  .p-carousel-indicators,
  .p-carousel-items-container {
    align-items: center;
  }

}