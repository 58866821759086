//main KPI

.main-kpi {



  .kpi {
    @include span(3);

    @include susy-breakpoint($md, $susy, ".no-mq") {
      @include span(12);
    }
  }

  &.kpi--threecol {
    .kpi {
      @include span(4);

      @include susy-breakpoint($md, $susy, ".no-mq") {
        @include span(12);
      }
    }
  }

  &.kpi--twocol {
    .kpi {
      @include span(6);

      @include susy-breakpoint($md, $susy, ".no-mq") {
        @include span(12);
      }
    }
  }

  &.kpi--fivecol {
    @include nested(15) {
      .kpi {
        @include span(3 no-gutters);
        padding: 0 2px;

        @include susy-breakpoint($md, $susy, ".no-mq") {
          @include span(12 no-gutters);
        }
      }
    }
  }
}


//.kpi {
//  .kpi__inner {
//    text-align: center;
//  }
//
//  .kpi__meter {
//    text-align: center;
//    width:192px;
////    display: inline-block;
//    height:192px;
//    text-align: center;
//    position: relative;
//    margin: 0 auto;
//    > div {
//      width:192px;
//      height:192px;
//    }
//  }
//}


$widget-title: #909090;
$kpi-heading: #39d3c6;
$progress-bar: #efefef;
$meter-bg: #212121;
$meter-border: #e6e6e6;
$kpi-link: #d2d2d2;
$location-border: #e2eaf2;

.widget--kpi {
  min-height: 100px;
}

.kpi {

  .kpi__inner {
    text-align: center;
  }

  .kpi__location {

    text-align: left;
    padding: 40px 2px 0;
    vertical-align: top;
    display: inline-block;
    max-width: 120px;
    word-break: break-word;

    @include susy-breakpoint(1400px, $susy, ".no-mq") {
      max-width: 65px;
    }

    li {
      display: block;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 2px solid $location-border;

      .icon {
        margin-right: 3px;
        position: relative;
        top: 1px;
      }

      .location__label {
        display: block;
        color: #34495e;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 5px;

        :lang(fr) {
          text-transform: none;
        }
      }

      .location__score {
        display: block;
        font-size: 12px;
        color: $clr-green;
        font-weight: 700;
      }

      &.location--positive {
        .location__score {
          color: $clr-green;
        }
      }

      &.location--negative {
        .location__score {
          color: $clr-red;
        }
      }
    }
  }

  .kpi__meter {
    width: 170px;
    display: inline-block;
    height: 200px;
    text-align: center;
    position: relative;
    margin: 0 auto;

    div {
      &:first-child {
        width: 170px;
        height: 200px;
      }

    }
  }
}

.kpi__footer {
  // margin-top: 10px;
  height: auto;
  font-size: 14px;
  color: #212229;

  span {
    cursor: pointer;
    color: $clr-pri;
  }
}


.kpi--hours {
  font-size: 18px;
}


.custom-tooltip {

  .p-tooltip-text {
    background: #dc3545 !important;
  }

  .p-tooltip-arrow {
    border-right-color: #dc3545 !important;
  }

}

.red-tooltip {

  .p-tooltip-text {
    background: #dc3545 !important;
  }

  .p-tooltip-arrow {
    border-right-color: #dc3545 !important;
  }

}

.green-tooltip {

  .p-tooltip-text {
    background: #3bb001 !important;
  }

  .p-tooltip-arrow {
    border-right-color: #3bb001 !important;
  }

}