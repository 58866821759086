.ui-progressbar, .p-progressbar {
  border: $progressBarBorder;
  height: $progressBarHeight;
  background-color: $progressBarBgColor;
  border-radius: 3px;

  .ui-progressbar-value, .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $clr-pri;
    border-radius: 3px;
    .progress--yellow & {
      background: #ffc107;
    }
  }

  .ui-progressbar-label, .p-progressbar-label {
    margin-top: 0;
    color: $textColor;
    line-height: $progressBarHeight;
  }
}

.ui-galleria {
  .ui-galleria-nav-prev,
  .ui-galleria-nav-next {
    @include transition(all $transitionDuration);
    position: absolute;
    color: $textSecondaryColor;

    &:hover {
      @include scale(1.2);
    }
  }

  .ui-galleria-nav-prev {
    font-size: $fontSize + 2;
  }

  .ui-galleria-nav-next {
    font-size: $fontSize + 2;
  }
}

.ui-terminal {
  background-color: $panelContentBgColor;
  color: $panelContentTextColor;
  border: $panelContentBorder;
  padding: $panelContentPadding;

  .ui-terminal-input {
    font-size: $fontSize;
    font-family: $fontFamily;
    height: $fontSize + 2;
  }

  .ui-terminal-command {
    height: $fontSize + 2;
  }
}

.ui-inplace {
  min-height: 2.357em;

  .ui-inplace-display {
    padding: $inplacePadding;
    @include border-radius($borderRadius);
    @include transition($inplaceTransition);

    &:not(.ui-state-disabled):hover {
      background-color: $inplaceHoverBgColor;
      color: $inplaceHoverTextColor;
    }
  }
}

.ui-fluid {
  .ui-inplace {
    &.ui-inplace-closable {
      .ui-inplace-content > :first-child {
        display: inline-block;
        width: calc(100% - #{$buttonIconOnlyWidth});
      }
    }
  }
}
