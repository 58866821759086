.breadcrumbs {
  .breadcrumbs_list {
    display: inline-block;
    li {
      display: inline;
      vertical-align: middle;
      white-space: normal;
      font-size: 12px;
      font-weight: 500;
      color: #969dab;

      &:before {
        color: #73879d;
        position: relative;
        top: 1px;
        font-size: 14px;
        margin: 0 10px;
        @include opacity(0.5);
        content: "\f105";
        font-family: "Font Awesome 5 Pro";
      }
      &:first-child {
        &:before {
          display: none;
        }
      }

      &.active {
        a {
          color: #969dab;
        }
      }
    }
  }

  &.page-breadcrumbs {
    .breadcrumbs_list {
      li {
        &:before {
          color: #73879d;
          position: relative;
          top: 1px;
          font-size: 14px;
          margin: 0 5px;
          @include opacity(0.5);
          content: "/";
        }
      }
    }
  }
}

.voc-filter-breadcrumbs {
  border-bottom: 1px solid #ebebeb;
  padding: 11px;
  background: #fcfcfc;
  float: none;
  line-height: normal;
  .breadcrumbs_list {
    display: inline-block;
    li {
      display: inline;
      vertical-align: middle;
      white-space: normal;
      font-size: 12px;
      font-weight: 500;
      color: #969dab;
      word-break: break-all;

      &:before {
        color: #73879d;
        position: relative;
        top: 1px;
        font-size: 14px;
        margin: 0 10px;
        @include opacity(0.5);
        content: "\f105";
        font-family: "Font Awesome 5 Pro";
      }
      &:first-child {
        &:before {
          display: none;
        }
      }

      &.active {
        a {
          color: #969dab;
        }
      }
    }
  }
}

//tags breadcrumbs

.breadcrumbs-tags {
  float: left;
  .breadcrumbs_list {

    li {
      float: left;
      vertical-align: middle;
      white-space: normal;
      font-size: 10px;
      font-weight: 500;
      margin-right: 10px;
      margin-bottom: 5px;
      a{
        background: $clr-pri;
        display:inline-block;
        padding: 3px 8px;
        text-transform: uppercase;
        position: relative;
        color:$white;
        border-radius: 3px;
        @include arrow("right", "center", 4px, #4fade3);
        :lang(fr) {
          text-transform: none;
        }
      }
    }
  }

}