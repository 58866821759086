//page-reportgenerator

.rg__filters {
  display: flex;
  border-bottom: 1px solid #ebebeb;

  .filter-item {
    padding: 10px;
    border-right: 1px solid #ebebeb;

    &.filter-item--settings {
      .btn {
        margin: 0 5px;
        padding: 9px;
      }
    }

    &.filter-item--action {
      border-right: none;
      margin-left: auto;

      .actions {
        float: left;
        margin: 0 5px;

        .btn {
          padding: 9px;
        }
      }
    }

    .item__label {
      font-size: 12px;
      color: #34495e;
      margin-right: 30px;
    }

  }

  .filter-by {
    display: inline-block;

    .list__item {
      display: inline-block;
      margin: 0 5px;

      .btn {
        padding: 9px;
      }

      &.list__item--filter {
        .btn {
          padding-left: 9px;
          padding-right: 25px;

          &:after {
            content: "\f078";
            font-size: 12px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            color: #bdc2c6;
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -7px;
          }

          &:hover {
            border-color: darken(#e1e1e1, 20%);
          }

          &.btn--active {
            background: $clr-pri;
            color: $white;
            border-color: $clr-pri;

            &:after {
              color: $white;
            }

            &:hover {
              background-color: darken($clr-pri, 8%);
              color: $white !important;
              border-color: $clr-pri;
            }
          }
        }
      }

      //&.list__item--reset {
      //  .btn {
      //    padding-left: 10px;
      //    padding-right: 10px;
      //    border-color: #e1e1e1;
      //    &:after {
      //      display: none;
      //    }
      //    &:hover {
      //      background: $clr-pri;
      //      border-color: #e1e1e1;
      //    }
      //  }
      //}
    }
  }
}

.report-generator {
  @include container();
  padding: 15px;
  min-height: 550px;

  &.sticky {
    .rg-drag-column {
      position: fixed;
      top: 79px;
      max-width: calc(25% - 48px);
      padding: 0;
      background: $white;
    }

    .rg-drop-column {
      margin-left: 25%;
    }
  }
}

.rg-drag-column {
  @include span(3 no-gutters);
  min-height: 400px;
  padding: 0 15px 0 0;

  .rg-drag-column-inner {
    border: 1px solid #ebebeb;

    >ul {
      max-height: 365px;
      overflow: auto;
    }
  }

  .tabs-container {
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .search-field-container {
    padding: 10px;

    .filter-search {
      border: 1px solid #ebebeb;
    }
  }

  .drag__list {
    .list__item {
      a {
        display: block;
        font-size: 12px;
        padding: 12px 35px 12px 15px;
        text-transform: capitalize;
        color: #34495e;
        border-top: 1px solid #ebebeb;
        text-decoration: none;
        position: relative;
        background: #f5f6f7;

        :lang(fr) {
          text-transform: none;
        }

        &:hover {
          cursor: move;
        }

        &:before,
        .action {
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          content: "\f105";
          color: #bdc2c6;
          font-size: 14px;
          line-height: 38px;
          text-align: center;
          width: 38px;
          height: 38px;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }

      &.active {
        >a {
          background: #edeef1;
          // color: $white;

          &:before {
            content: "\f107";
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.5;
        }
      }

      ul {
        li {
          a {
            background: $white;

            &:before {
              &:hover {
                cursor: pointer;
              }
            }

            &:hover {
              background: $clr-pri;
              color: $white;
            }

            &.p-state-disabled {
              &:hover {
                color: $black;
                opacity: 1;
              }
            }

            &.active {
              span {
                display: none;
              }
            }
          }

          &:first-child {
            a {
              border-radius: 0;
            }
          }

          &.selected {
            a {
              padding-right: 55px;

              i {
                position: absolute;
                right: 37px;
                top: 12px;
                font-size: 13px;
                color: $clr-red;
                cursor: pointer;
              }

              &:before {
                content: "\f00c";
                color: $clr-pri;
                font-size: 12px;
                right: 17px;
                top: 12px;
              }

              &:hover {
                background: none;
                cursor: default;
                color: inherit;

                &:before {}
              }
            }
          }
        }
      }
    }
  }
}

.rg-drop-column {
  @include span(9);
  position: relative;
  min-height: 400px;
  padding: 0;


  &.my--report--drop {
    @include span(12 no-gutters);

    .ui-table-bordered {
      margin-top: 40px;
    }

    .actions-next {
      text-align: right;
      right: 0;
      //  position: static;
      //   margin: 10px 0;


    }

    .topbar--piechart {
      padding: 10px 0 0;
      height: 55px;

    }
  }

  .btn--danger {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -41px;
    text-align: center;
  }

  .drag-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f9f8f8;
    border: 2px dashed #ebebeb;
    z-index: 5;
    opacity: 0.9;
    top: 0;
    left: 0;
  }

  .filter-item {
    padding: 10px;
    border: 1px solid #ebebeb;
  }

  .cdk-drag-placeholder {
    display: none;
  }

  .tab-view {
    border: 1px solid #ebebeb;
    border-width: 0 1px;
  }

  .filter-charts {
    padding: 10px;
    background: #f4f5f9;
    border: 1px solid #ebebeb;
    border-width: 1px 0;

    .chart-list {
      list-style-type: none;

      li {
        display: inline-block;
        padding: 0 6px 0 0;
      }
    }
  }

  .topbar--piechart {
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .drag-content {
    border: 2px dashed #ebebeb;
    padding: 15px;
    margin: 15px 0 0 0;
    text-align: center;
    border-radius: 3px;
    clear: both;
    float: left;
    width: 100%;
    max-height: 500px;
    min-height: 90px;
    overflow-y: auto;

    &.cdk-drop-list-receiving,
    &.cdk-drop-list-dragging {
      border-color: darken(#ebebeb, 20%);
    }

    .drag__msg {
      padding: 30px;

      i {
        display: inline-block;
        font-size: 25px;
        color: #d5d7dd;
      }
    }

    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .drop-list {
    .list__item {
      display: inline-block;
      width: calc(25% - 10px);
      margin: 5px;
      position: relative;
      font-size: 12px;
      @include transition();

      a {
        color: #34495e;
        border: 1px solid #ebebeb;
        padding: 15px;
        border-radius: 3px;
        display: block;
        cursor: move;

        &.input-holder {
          padding: 14px 15px;

          input {
            min-width: 70px;
            width: 100%;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            border: none;
            text-align: center;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      i {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 10px;
        cursor: pointer;
        //opacity: 0;
        z-index: 1;
        background: #ef207d;
        @include transition();
        text-align: center;
        width: 18px;
        color: $white;
        height: 18px;
        line-height: 19px;
        border-radius: 100%;
      }

      &:hover {
        a {
          border-color: #b7b7b7;
        }

        i {
          //opacity: 1;
          //transform: scale(1.1);
        }
      }
    }
  }

  .actions-next {
    position: absolute;
    right: 10px;
    top: 10px;

    .btn {
      margin-left: 5px;
    }
  }

  .p-table-bordered {
    padding: 10px;

    .p-table {
      text-align: left;
    }

    .short-column {
      width: 20%;
      text-align: center;

      .p-dropdown {
        text-align: left;
      }
    }

    p-checkbox {
      display: block;
      position: relative;
      padding: 0 0 0 22px;

      .p-checkbox {
        position: absolute;
        left: 0;
        top: 0;
      }

      .p-checkbox-label {
        margin: 0;
      }
    }
  }
}

.rg-overlay {
  //border: none;
  //box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.21);
}

.p-overlaypanel-content {
  .filter-dropdown {
    position: relative;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    display: block;
    transform: translateY(0);
    box-shadow: none;
    padding: 0;
    min-width: 250px;
    min-height: auto;

    .filter-search-wrapper {
      padding: 10px;
      background: #fcfcfc;
      border-bottom: 1px solid #ebebeb;
    }
  }

  .select-deselect {
    padding: 10px;
    text-align: right;
    font-size: 13px;
    color: #666666;
    border-bottom: 1px solid #ebebeb;

    a {
      color: #b3b3b3;

      &:hover {
        color: #666666;
      }
    }
  }

  .filter__list {
    max-height: 158px;
    overflow-y: auto;

    ul {
      li {
        a {
          &:before {
            display: none;
            content: "\f105";
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            right: 10px;
            color: #b8b8b8;
            font-size: 15px;
            top: 50%;
            margin: -7px 0 0 0;
          }

          &:hover {
            color: #666666;
            background: none;

            &:before {
              color: #666666;
            }
          }
        }

        &.has-child {
          a {
            &:before {
              display: block;
            }
          }
        }

        &.active {
          a {
            color: #666666;
            background: #f3f6f9;
          }
        }
      }
    }
  }

  .model-column {
    min-height: 303px;

    .model__title {
      border-bottom: 1px solid #ebebeb;
    }

    &.filter-column {
      width: 100%;
      min-height: 0;
      max-height: 303px;
      border-right: 0;
    }
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

body {
  >.list__item--rg-drag {
    &.cdk-drag-preview {
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      transition: none;
    }

    a {
      display: block;
      font-size: 12px;
      padding: 12px 35px 12px 15px;
      text-transform: capitalize;
      color: #34495e;
      border-top: 1px solid #ebebeb;
      text-decoration: none;
      position: relative;
      background: #f5f6f7;

      :lang(fr) {
        text-transform: none;
      }

      &:hover {
        cursor: move;
      }

      &:before,
      .action {
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        content: "\f105";
        color: #bdc2c6;
        font-size: 14px;
        right: 20px;
        cursor: pointer;
      }
    }

    &:not(.active) {
      a {
        border: 1px solid #ebebeb;
      }
    }

    &.active {
      border: 1px solid #ebebeb;

      >a {
        background: #edeef1;
        // color: $white;

        &:before {
          content: "\f107";
        }
      }

      ul {
        border-bottom: 1px solid #ebebeb;
      }
    }

    &.disabled {
      a {
        opacity: 0.5;
      }
    }

    ul {
      li {
        a {
          background: $white;

          &:before {
            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            background: $clr-pri;
            color: $white;
          }

          &.p-state-disabled {
            &:hover {
              color: $black;
              opacity: 1;
            }
          }

          &.active {
            span {
              display: none;
            }
          }
        }

        &:first-child {
          a {
            border-radius: 0;
          }
        }

        &.selected {
          a {
            padding-right: 55px;

            i {
              position: absolute;
              right: 37px;
              top: 12px;
              font-size: 13px;
              color: $clr-red;
              cursor: pointer;
            }

            &:before {
              content: "\f00c";
              color: $clr-pri;
              font-size: 12px;
              right: 17px;
              top: 12px;
            }

            &:hover {
              background: none;
              cursor: default;
              color: inherit;

              &:before {}
            }
          }
        }
      }
    }
  }
}

body {
  >.list__item--rg-drop {
    float: left;
    margin: 0 10px 0 0;
    position: relative;
    font-size: 12px;

    //@include transition();
    &.cdk-drag-preview {
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    a {
      color: #34495e;
      border: 1px solid #ebebeb;
      padding: 15px;
      border-radius: 3px;
      display: block;
      cursor: move;

      &.input-holder {
        padding: 14px 15px;

        input {
          min-width: 70px;
          color: inherit;
          font-size: inherit;
          font-family: inherit;
          border: none;
          text-align: center;
        }
      }
    }

    i {
      position: absolute;
      top: -7px;
      right: -7px;
      font-size: 10px;
      cursor: pointer;
      //opacity: 0;
      z-index: 1;
      background: #ef207d;
      @include transition();
      text-align: center;
      width: 18px;
      color: $white;
      height: 18px;
      line-height: 19px;
      border-radius: 100%;
    }

    &:hover {
      a {
        border-color: #b7b7b7;
      }

      i {
        //opacity: 1;
        //transform: scale(1.1);
      }
    }
  }
}

//page-reportgenerator

.rg__filters {
  display: flex;
  border-bottom: 1px solid #ebebeb;

  .filter-item {
    padding: 10px;
    border-right: 1px solid #ebebeb;

    &.filter-item--settings {
      .btn {
        margin: 0 5px;
        padding: 9px;
      }
    }

    &.filter-item--action {
      border-right: none;
      margin-left: auto;

      .actions {
        float: left;
        margin: 0 5px;

        .btn {
          padding: 9px;
        }
      }
    }

    .item__label {
      font-size: 12px;
      color: #34495e;
      margin-right: 30px;
    }

  }

  .filter-by {
    display: inline-block;

    .list__item {
      display: inline-block;
      margin: 0 5px;

      .btn {
        padding: 9px;
      }

      &.list__item--filter {
        .btn {
          padding-left: 9px;
          padding-right: 25px;

          &:after {
            content: "\f078";
            font-size: 12px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            color: #bdc2c6;
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -7px;
          }

          &:hover {
            border-color: darken(#e1e1e1, 20%);
          }

          &.btn--active {
            background: $clr-pri;
            color: $white;
            border-color: $clr-pri;

            &:after {
              color: $white;
            }

            &:hover {
              background-color: darken($clr-pri, 8%);
              color: $white !important;
              border-color: $clr-pri;
            }
          }
        }
      }

      //&.list__item--reset {
      //  .btn {
      //    padding-left: 10px;
      //    padding-right: 10px;
      //    border-color: #e1e1e1;
      //    &:after {
      //      display: none;
      //    }
      //    &:hover {
      //      background: $clr-pri;
      //      border-color: #e1e1e1;
      //    }
      //  }
      //}
    }
  }
}

.report-generator {
  @include container();
  padding: 15px;
  min-height: 550px;

  &.sticky {
    .rg-drag-column {
      position: fixed;
      top: 79px;
      max-width: calc(25% - 48px);
      padding: 0;
      background: $white;
    }

    .rg-drop-column {
      margin-left: 25%;
    }
  }
}

.rg-drag-column {
  @include span(3 no-gutters);
  min-height: 400px;
  padding: 0 15px 0 0;

  .rg-drag-column-inner {
    border: 1px solid #ebebeb;

    >ul {
      max-height: 365px;
      overflow: auto;
    }
  }

  .tabs-container {
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .search-field-container {
    padding: 10px;

    .filter-search {
      border: 1px solid #ebebeb;
    }
  }

  .drag__list {
    .list__item {
      a {
        display: block;
        font-size: 12px;
        padding: 12px 35px 12px 15px;
        text-transform: capitalize;
        color: #34495e;
        border-top: 1px solid #ebebeb;
        text-decoration: none;
        position: relative;
        background: #f5f6f7;

        :lang(fr) {
          text-transform: none;
        }

        &:hover {
          cursor: move;
        }

        &:before,
        .action {
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          content: "\f105";
          color: #bdc2c6;
          font-size: 14px;
          line-height: 38px;
          text-align: center;
          width: 38px;
          height: 38px;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }

      &.active {
        >a {
          background: #edeef1;
          // color: $white;

          &:before {
            content: "\f107";
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.5;
        }
      }

      ul {
        li {
          a {
            background: $white;

            &:before {
              &:hover {
                cursor: pointer;
              }
            }

            &:hover {
              background: $clr-pri;
              color: $white;
            }

            &.ui-state-disabled {
              &:hover {
                color: $black;
                opacity: 1;
              }
            }

            &.active {
              span {
                display: none;
              }
            }
          }

          &:first-child {
            a {
              border-radius: 0;
            }
          }

          &.selected {
            a {
              padding-right: 55px;

              i {
                position: absolute;
                right: 37px;
                top: 12px;
                font-size: 13px;
                color: $clr-red;
                cursor: pointer;
              }

              &:before {
                content: "\f00c";
                color: $clr-pri;
                font-size: 12px;
                right: 17px;
                top: 12px;
              }

              &:hover {
                background: none;
                cursor: default;
                color: inherit;

                &:before {}
              }
            }
          }
        }
      }
    }
  }
}

.rg-drop-column {
  @include span(9);
  position: relative;
  min-height: 400px;
  padding: 0;

  .btn--danger {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -41px;
    text-align: center;
  }

  .drag-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f9f8f8;
    border: 2px dashed #ebebeb;
    z-index: 5;
    opacity: 0.9;
    top: 0;
    left: 0;
  }

  .filter-item {
    padding: 10px;
    border: 1px solid #ebebeb;
  }

  .cdk-drag-placeholder {
    display: none;
  }

  .tab-view {
    border: 1px solid #ebebeb;
    border-width: 0 1px;
  }

  .filter-charts {
    padding: 10px;
    background: #f4f5f9;
    border: 1px solid #ebebeb;
    border-width: 1px 0;

    .chart-list {
      list-style-type: none;

      li {
        display: inline-block;
        padding: 0 6px 0 0;
      }
    }
  }

  .topbar--piechart {
    padding: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .drag-content {
    border: 2px dashed #ebebeb;
    padding: 15px;
    margin: 15px 0 0 0;
    text-align: center;
    border-radius: 3px;
    clear: both;
    float: left;
    width: 100%;
    max-height: 500px;
    min-height: 90px;
    overflow-y: auto;

    &.cdk-drop-list-receiving,
    &.cdk-drop-list-dragging {
      border-color: darken(#ebebeb, 20%);
    }

    .drag__msg {
      padding: 30px;

      i {
        display: inline-block;
        font-size: 25px;
        color: #d5d7dd;
      }
    }

    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .drop-list {
    .list__item {
      display: inline-block;
      width: calc(25% - 10px);
      margin: 5px;
      position: relative;
      font-size: 12px;
      @include transition();

      a {
        color: #34495e;
        border: 1px solid #ebebeb;
        padding: 15px;
        border-radius: 3px;
        display: block;
        cursor: move;

        &.input-holder {
          padding: 14px 15px;

          input {
            min-width: 70px;
            width: 100%;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            border: none;
            text-align: center;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      i {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 10px;
        cursor: pointer;
        //opacity: 0;
        z-index: 1;
        background: #ef207d;
        @include transition();
        text-align: center;
        width: 18px;
        color: $white;
        height: 18px;
        line-height: 19px;
        border-radius: 100%;
      }

      &:hover {
        a {
          border-color: #b7b7b7;
        }

        i {
          //opacity: 1;
          //transform: scale(1.1);
        }
      }
    }
  }

  .actions-next {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .ui-table-bordered {
    padding: 10px;

    .ui-table {
      text-align: left;
    }

    .short-column {
      width: 20%;
      text-align: center;

      .p-dropdown {
        text-align: left;
      }
    }

    p-checkbox {
      display: block;
      position: relative;
      padding: 0 0 0 22px;

      .p-checkbox {
        position: absolute;
        left: 0;
        top: 0;
      }

      .p-checkbox-label {
        margin: 0;
      }
    }
  }
}

.rg-overlay {
  //border: none;
  //box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.21);
}

.ui-overlaypanel-content {
  .filter-dropdown {
    position: relative;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    display: block;
    transform: translateY(0);
    box-shadow: none;
    padding: 0;
    min-width: 250px;
    min-height: auto;

    .filter-search-wrapper {
      padding: 10px;
      background: #fcfcfc;
      border-bottom: 1px solid #ebebeb;
    }
  }

  .select-deselect {
    padding: 10px;
    text-align: right;
    font-size: 13px;
    color: #666666;
    border-bottom: 1px solid #ebebeb;

    a {
      color: #b3b3b3;

      &:hover {
        color: #666666;
      }
    }
  }

  .filter__list {
    max-height: 158px;
    overflow-y: auto;

    ul {
      li {
        a {
          &:before {
            display: none;
            content: "\f105";
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            right: 10px;
            color: #b8b8b8;
            font-size: 15px;
            top: 50%;
            margin: -7px 0 0 0;
          }

          &:hover {
            color: #666666;
            background: none;

            &:before {
              color: #666666;
            }
          }
        }

        &.has-child {
          a {
            &:before {
              display: block;
            }
          }
        }

        &.active {
          a {
            color: #666666;
            background: #f3f6f9;
          }
        }
      }
    }
  }

  .model-column {
    min-height: 303px;

    .model__title {
      border-bottom: 1px solid #ebebeb;
    }

    &.filter-column {
      width: 100%;
      min-height: 0;
      max-height: 303px;
      border-right: 0;
    }
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

body {
  >.list__item--rg-drag {
    &.cdk-drag-preview {
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      transition: none;
    }

    a {
      display: block;
      font-size: 12px;
      padding: 12px 35px 12px 15px;
      text-transform: capitalize;
      color: #34495e;
      border-top: 1px solid #ebebeb;
      text-decoration: none;
      position: relative;
      background: #f5f6f7;

      :lang(fr) {
        text-transform: none;
      }

      &:hover {
        cursor: move;
      }

      &:before,
      .action {
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        content: "\f105";
        color: #bdc2c6;
        font-size: 14px;
        right: 20px;
        cursor: pointer;
      }
    }

    &:not(.active) {
      a {
        border: 1px solid #ebebeb;
      }
    }

    &.active {
      border: 1px solid #ebebeb;

      >a {
        background: #edeef1;
        // color: $white;

        &:before {
          content: "\f107";
        }
      }

      ul {
        border-bottom: 1px solid #ebebeb;
      }
    }

    &.disabled {
      a {
        opacity: 0.5;
      }
    }

    ul {
      li {
        a {
          background: $white;

          &:before {
            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            background: $clr-pri;
            color: $white;
          }

          &.ui-state-disabled {
            &:hover {
              color: $black;
              opacity: 1;
            }
          }

          &.active {
            span {
              display: none;
            }
          }
        }

        &:first-child {
          a {
            border-radius: 0;
          }
        }

        &.selected {
          a {
            padding-right: 55px;

            i {
              position: absolute;
              right: 37px;
              top: 12px;
              font-size: 13px;
              color: $clr-red;
              cursor: pointer;
            }

            &:before {
              content: "\f00c";
              color: $clr-pri;
              font-size: 12px;
              right: 17px;
              top: 12px;
            }

            &:hover {
              background: none;
              cursor: default;
              color: inherit;

              &:before {}
            }
          }
        }
      }
    }
  }
}

body {
  >.list__item--rg-drop {
    float: left;
    margin: 0 10px 0 0;
    position: relative;
    font-size: 12px;

    //@include transition();
    &.cdk-drag-preview {
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    a {
      color: #34495e;
      border: 1px solid #ebebeb;
      padding: 15px;
      border-radius: 3px;
      display: block;
      cursor: move;

      &.input-holder {
        padding: 14px 15px;

        input {
          min-width: 70px;
          color: inherit;
          font-size: inherit;
          font-family: inherit;
          border: none;
          text-align: center;
        }
      }
    }

    i {
      position: absolute;
      top: -7px;
      right: -7px;
      font-size: 10px;
      cursor: pointer;
      //opacity: 0;
      z-index: 1;
      background: #ef207d;
      @include transition();
      text-align: center;
      width: 18px;
      color: $white;
      height: 18px;
      line-height: 19px;
      border-radius: 100%;
    }

    &:hover {
      a {
        border-color: #b7b7b7;
      }

      i {
        //opacity: 1;
        //transform: scale(1.1);
      }
    }
  }
}

//models list
.model-column {
  @include span(4 no-gutters);
  border-right: 1px solid #ebebeb;

  .model-search {
    width: 100%;
    height: 60px;
    padding: 15px;
    background: #fcfcfc;
    position: relative;
    border-bottom: 1px solid #ebebeb;

    .search__icon {
      position: absolute;
      right: 25px;
      top: 50%;
      margin-top: -8px;

      &:before {
        color: #b3b3b3;
        font-size: 16px;
      }
    }

    .search__field {
      width: 100%;
      border: 1px solid #d0d7dd;
      background: $white;
      padding: 7px 30px 7px 10px;
      border-radius: 4px;
    }

    @include placeholder {
      font-size: 13px;
      font-weight: 500;
      color: #b3b3b3;
    }
  }

  .model__title {
    font-size: 13px;
    display: block;
    font-weight: 400;
    padding: 15px;
    color: #666666;

    span {
      color: #969dab;
    }
  }

  .model__list {
    height: 164px;
    padding-bottom: 8px;
    overflow-x: auto;

    li {
      display: block;
      position: relative;

      &:first-child {
        border: 0;
      }

      a {
        display: block;
        padding: 12px 15px;
        font-size: 13px;
        font-weight: 500;
        position: relative;
        border-bottom: 1px solid $border-clr;
        color: #b3b3b3;
        cursor: pointer;

        &:hover {
          // color: $clr-blue;
          color: #666666;
          // background: #f3f6f9;
        }
      }

      &.active {
        a {
          background-color: #f3f6f9;
          color: #666666;
          //color: ;
        }
      }

      &:before {
        display: none;
        content: "\f105";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        right: 10px;
        font-size: 16px;
        z-index: 1;
        color: #9aa6b2;
        top: 50%;
        margin-top: -8px;
      }

      &.has-child {
        &:before {
          display: block;
        }
      }
    }

    &.all__models {
      li {
        &:before {
          display: none;
        }
      }
    }
  }
}


.page-my-reports {
  .my-repor-chart {
    float: left;
    width: 100%;
    margin-top: 50px;
  }
}






.myreport-rg-drop-column {
  width: 100%;
}

.top-bar-filters-myreport {
  display: flex;
  gap: 10px;
  justify-content: start;
  margin-bottom: 10px;
}