.rg-overlay > .ui-overlaypanel-content {
    padding: 0 !important;
}

.rg-overlay .ui-scrollpanel-bar.ui-scrollpanel-bar-x {
    display: none !important;
}

.highcharts-credits {
    display: none !important;
}

.rg-drag-column .drag__list .list__item ul li.selected a.drag-item-a:before {
    right: 0px !important;
    top: 0px !important;
}