// layout.scss
// contains layout styles

*,
*:before,
*:after {
  @include box-sizing;
}

*:before,
*:after {
  font-feature-settings: "liga";
}

html {
  margin: 0;
  padding: 0;
  //height: 100%;
}

body {
  //height: 100%;
}

button {
  outline: none;

  &:focus {
    outline: none;
  }
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: none;
  outline: none;
}

body {
  background: #f4f5f9;
  color: $clr-copy;
  //font-family: "Open Sans";
  background-size: cover;
}

a {
  color: $clr-copy;

  &:hover {
    color: $clr-pri;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.test {
  position: relative;
  background: #ffff00;
}

.wrapper {
  max-width: 667px;
  margin: 0 auto;
  position: relative;
  padding: 0 25px;
  box-shadow: 5px 5px #82bc00;
  border-radius: 50px;
  transform: scale(2);
  background: #ffff00;

  &.circle {
    background: none;
    max-width: none;
    padding: 0;
    transform: initial;
    box-shadow: none;
  }
}

.icon--12 {
  font-size: 12px !important;
}

.icon--14 {
  font-size: 14px !important;
}

.icon--16 {
  font-size: 16px !important;
}

.icon--20 {
  font-size: 20px !important;
}

.icon--22 {
  font-size: 22px !important;
}

.icon--24 {
  font-size: 24px !important;
}

.icon--26 {
  font-size: 26px !important;
}

.icon--reversed {
  transform: rotate(180deg);
}

.icon--rotating {
  -webkit-animation: rotation 1s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.border-top-none {
  border-top: 0 !important;
}

.align-center {
  text-align: center;
}

.error-text {
  color: #fe596b !important;

  .form--editcustomer & {
    font-size: 14px;
    padding: 3px 0 0 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pad-right-50 {
  padding-right: 50px;
}

.pad-right-30 {
  padding-right: 30px;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}

.pad-bot-0 {
  padding-bottom: 0 !important;
}

.pad-top-0 {
  padding-top: 0 !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}

.border--none {
  border: none !important;
}

hr {
  padding: 0;
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ebebeb;
}

.filter-bar {
  display: inline-block;
  background: $white;
  border: 1px solid #e1e1e1;
  //border-radius: 3px;
  border-radius: 0 3px 3px 0;
  font-size: 12px;
  line-height: 32px;
  color: #34495e;
  border-left: 0;

  i {
    font-size: 13px;
    line-height: 14px;
    vertical-align: middle;
  }

  a {
    color: $clr-pri;

    &:hover {
      color: $clr-sec;
    }
  }

  .filter-dropdown {
    position: absolute;
    right: -6px;
    left: auto;
    top: 100%;
    z-index: 9;
    width: 150px;
    min-height: auto;
    padding: 7px 13px;
    margin: 11px 0 0 0;
    font-size: 12px;
    color: #34495e;
    background: $white;
    border: 1px solid $clr-pri;
    border-radius: 5px;
    box-shadow: 0px 0px 6.48px 1.52px rgba(146, 146, 146, 0.32);

    transform: translateY(45px);
    @include transition();
    visibility: hidden;
    @include opacity(0);

    &:before {
      content: "";
      position: absolute;
      right: 12px;
      top: -5px;
      width: 9px;
      height: 9px;
      background: $white;
      border: 1px solid $clr-pri;
      border-width: 1px 0 0 1px;
      transform: rotate(45deg);
    }

    ul {
      float: left;
      width: 100%;

      li {
        float: left;
        width: 100%;

        a {
          color: #34495e;
          display: block;

          &:hover,
          &.active {
            color: $clr-pri;
          }
        }

        ul {
          clear: both;

          li {
            float: left;
            width: 25%;
            padding-right: 10px;
          }
        }
      }
    }

    >ul {
      >li {
        border-top: 1px solid #e5e5e5;
        padding: 5px 2px;

        &:first-child {
          border-top: none;
          color: #a9b4bd;
        }
      }
    }

    &.active {
      transform: translateY(0);
      @include opacity(1);
      visibility: visible;
    }

    &.filter-options {
      width: 450px;
      right: -1px;
    }
  }

  .filter-name {
    //float: left;
    text-align: left;
    display: inline-block;
    min-width: 160px;
    padding: 0 19px 0 14px;
    border-right: 1px solid #e1e1e1;
    position: relative;

    .filter__icon {
      position: absolute;
      right: 10px;
      // float: right;
    }
  }

  .action-btns {
    //float: left;
    display: inline-block;
    padding: 0 10px 0;
    border-right: 1px solid #e1e1e1;

    a {
      margin: 0 5px;
    }
  }

  .filter-dropdown-node {
    // float: left;
    display: inline-block;
    position: relative;

    >a {
      display: block;
      border-radius: 0 3px 3px 0;
      background-color: #ffffff;
      width: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: #848484;
    }
  }
}

.tabs-block {
  .tabs__header {
    border-bottom: 2px solid #e1e1e1;
    margin: 0 0 10px 0;

    .tab {
      display: inline-block;
      border-bottom: 2px solid #e1e1e1;
      border-right: 1px solid $white;
      background: #f4f5f9;
      padding: 10px 24px;
      margin: 0 0 -2px 0;
      font-size: 14px;
      // font-weight: 600;
      color: #4d565f;
      cursor: pointer;

      &:hover,
      &.active {
        background: #edeef2;
      }

      &.active {
        border-bottom-color: $clr-pri;
      }
    }
  }

  .tabs__content__group {
    .tab__content {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.campaign-theme-wrapper {
  max-width: 1000px;
  margin: 0 auto;

  .theme-info {
    padding: 0 0 20px;
    text-align: center;

    strong {
      font-weight: 600;
    }

    h4 {
      font-size: 13px;
      font-weight: 600;
      padding: 0 0 10px;
      text-transform: uppercase;

      :lang(fr) {
        text-transform: none;
      }
    }

    .info-container {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      p {
        font-size: 13px;
        display: table-cell;
        text-align: center;
        padding: 10px;
        border: 1px solid #d5d6d8;
      }
    }
  }
}

.color-blue {
  color: $clr-pri !important;
}


.action-menu-node {
  float: left;
  position: relative;

  .date-range-field & {
    float: none;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;

    >i {
      font-size: 18px;
    }
  }

  //.action-menu-btn {
  //  color: #bdc2c6;
  //  padding: 0 9px 0 0;
  //  i {
  //    font-size: 16px;
  //    line-height: 32px;
  //  }
  //  &:hover {
  //    color: #aab0b3;
  //  }
  //}

  .action-menu-btn {
    display: block;
    width: 26px;
    height: 26px;
    z-index: 1;
    color: #96a2b6;
    position: relative;
    border-radius: 50%;
    text-align: center;
    line-height: 26px;

    i {
      font-size: 16px;
    }

    &:hover {
      background: lighten(#96a2b6, 40%);
    }
  }

  .action-menu {
    position: absolute;
    right: -10px;
    top: 100%;
    z-index: 9;
    width: 140px;
    padding: 10px 13px;
    margin: 6px 0 0 0;
    font-size: 12px;
    color: #34495e;
    background: $white;
    border: 1px solid $clr-pri;
    border-radius: 5px;
    box-shadow: 0px 0px 6.48px 1.52px rgba(146, 146, 146, 0.32);

    transform: translateY(20px);
    @include transition();
    visibility: hidden;
    @include opacity(0);

    &:before {
      content: "";
      position: absolute;
      right: 12px;
      top: -5px;
      width: 9px;
      height: 9px;
      background: $white;
      border: 1px solid $clr-pri;
      border-width: 1px 0 0 1px;
      transform: rotate(45deg);
    }

    ul {
      li {
        border-top: 1px solid #e5e5e5;
        padding: 2px;

        &:first-child {
          border-top: none;
          // a {
          //   color: #34495e;
          //   &:hover {
          //     color: $clr-pri;
          //   }
          // }
        }

        label {
          float: left;
          line-height: 16px;
        }

        &.admin-user-switch {
          padding-top: 4px;
          padding-bottom: 4px;
          @include clearfix();

          .toggle {
            float: right;
            padding: 0 2px 0 0;
          }
        }

        &.welcome-note {
          position: relative;
          line-height: 16px;
          padding-left: 36px;
          padding-bottom: 4px;

          .user-initial {
            position: absolute;
            left: 0;
            top: 3px;
            width: 29px;
            height: 29px;
            font-size: 16px;
            line-height: 29px;
            background: $clr-pri;
            color: $white;
            text-align: center;
            border-radius: 50%;
          }

          strong {
            font-weight: 600;
          }
        }

        &.active {
          background: $clr-pri;
          color: $white;

          a {
            color: $white;
          }
        }
      }
    }

    .date-range-field & {
      right: -13px;
    }

    &.active {
      transform: translateY(0);
      @include opacity(1);
      visibility: visible;
    }

    &.topnav--actionmenu {
      width: 180px;
    }
  }

  &.question-settings {
    float: right;
    padding: 0 0 0 10px;

    .action-menu-btn {
      i {
        font-size: 20px;
        line-height: 20px;
      }

      &:hover,
      &.active {
        color: $clr-pri;
      }
    }

    .action-menu {
      right: -8px;
      width: 130px;
      padding: 5px 10px;
      margin: 6px 0 0 0;

      ul {
        li {
          padding: 1px 2px 3px;

          a {
            color: #34495e;
            display: block;

            i {
              font-size: 16px;
              line-height: 18px;
              margin: 0 5px 0 0;
              position: relative;
              top: 3px;
              left: 2px;
              color: #b0bdc5;
            }

            &:hover {
              color: $clr-pri;

              i {
                color: $clr-pri;
              }
            }
          }
        }
      }
    }
  }

  &.question-translation {
    float: right;
    padding: 0 5px 0 10px;

    //border-right: 1px solid #d1dce2;
    .action-menu-btn {
      text-transform: uppercase;
      width: auto;
      font-size: 12px;
      line-height: 20px;

      :lang(fr) {
        text-transform: none;
      }

      span {
        float: left;
        padding: 0 5px 0 0;
      }

      i {
        font-size: 20px;
        line-height: 20px;
      }

      &:hover,
      &.active {
        color: $clr-pri;
      }
    }

    .action-menu {
      right: 6px;
      width: 230px;
      max-height: 175px;
      padding: 5px 10px;
      margin: 6px 0 0 0;

      ul {
        li {
          padding: 3px;

          a {
            color: #34495e;

            i {
              font-size: 16px;
              line-height: 18px;
              margin: 0 5px 0 0;
              position: relative;
              top: 3px;
              left: 2px;
              color: #b0bdc5;
            }

            &:hover {
              color: $clr-pri;

              i {
                color: $clr-pri;
              }
            }
          }

          &.heading {
            font-weight: bold;
            text-transform: uppercase;

            :lang(fr) {
              text-transform: none;
            }
          }

          &.translated {
            &:before {
              font-family: "Material Icons";
              content: "check";
              color: #0f9d46;
              text-transform: lowercase;
              padding: 0 3px 0 0;
              font-size: 14px;
              line-height: 14px;
              position: relative;
              top: 2px;
            }
          }
        }
      }

      &.secondary--theme {
        .mCSB_scrollTools {
          bottom: 5px;
          top: 5px;
          right: -5px;
        }
      }
    }
  }

  &.card--action {
    position: absolute;
    right: 20px;
    top: 15px;

    //z-index: 5;
    .action-menu-btn {
      display: inline-block;

      i {
        font-size: 16px;
        line-height: 16px;
      }

      &:hover,
      &.active {
        color: $clr-pri;
      }
    }

    .action-menu {
      right: -8px;
      width: 110px;
      padding: 5px 10px;
      margin: 6px 0 0 0;

      ul {
        li {
          padding: 1px 2px 3px;

          a {
            color: #34495e;

            i {
              font-size: 16px;
              line-height: 18px;
              margin: 0 5px 0 0;
              position: relative;
              top: 3px;
              left: 2px;
              color: #b0bdc5;
            }

            &:hover {
              color: $clr-pri;

              i {
                color: $clr-pri;
              }
            }
          }
        }
      }
    }

    .dropdown-wrapper {
      display: inline-block;
      padding: 0 5px 0 0;
      position: relative;
      top: -5px;
    }
  }

  &.filter-settings {
    float: right;
    padding: 3px 0 0 10px;

    //.action-menu-btn {
    //  i {
    //    font-size: 24px;
    //    line-height: 24px;
    //    color: #86919c;
    //  }
    //  &:hover,
    //  &.active {
    //    color: $clr-pri;
    //    i {
    //      color: $clr-pri;
    //    }
    //  }
    //}
    .action-menu {
      right: -4px;
      width: 180px;
      padding: 5px 10px;
      margin: 6px 0 0 0;

      ul {
        li {
          padding: 1px 2px 3px;

          a {
            color: #34495e;

            i {
              font-size: 16px;
              line-height: 18px;
              margin: 0 5px 0 0;
              position: relative;
              top: 3px;
              left: 2px;
              color: #b0bdc5;
            }

            &:hover {
              color: $clr-pri;

              i {
                color: $clr-pri;
              }
            }
          }
        }
      }
    }
  }
}



.ui-overlaypanel--filter-dropdown {

  //position: absolute;
  //right: -6px;
  //left: auto;
  //top: 100%;
  //z-index: 9;
  .ui-overlaypanel-content {
    padding: 0 !important;
    @include clearfix();
  }

  .filter-dropdown-panel {
    width: 450px;
    padding: 7px 13px;
    font-size: 12px;
    color: #34495e;
    background: $white;
    @include clearfix();

    ul {
      float: left;
      width: 100%;

      li {
        float: left;
        width: 100%;

        a {
          color: #34495e;
          display: block;

          &:hover,
          &.active {
            color: $clr-pri;
          }
        }

        ul {
          clear: both;

          li {
            float: left;
            width: 25%;
            padding-right: 10px;

            &:last-child {
              word-break: break-word;
            }
          }
        }
      }
    }

    >ul {
      >li {
        border-top: 1px solid #e5e5e5;
        padding: 5px 2px;

        &:first-child {
          border-top: none;
          color: #a9b4bd;
        }
      }
    }
  }
}

/* Layout helpers
----------------------------------*/
.p-helper-hidden,
.ui-helper-hidden {
  display: none;
}

.p-helper-hidden-accessible,
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset,
.p-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after,
.p-helper-clearfix:before,
.p-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after,
.p-helper-clearfix:after {
  clear: both;
}

.p-helper-fix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */
}

.ui-front {
  z-index: 100;
}


.media-flex {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.color-flex {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}


.filter-select-dropdown {
  max-width: 18p0px;
}

.table-data-source {
  table-layout: fixed;
}
