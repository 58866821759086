.my-surveys {
  .survey-block {
    background: $white;
    border: 1px solid #e0e8ec;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    padding: 10px 20px;
    display: table;
    width: 100%;
    table-layout: fixed;
    @include transition();

    &:hover,
    &.active {
      border-color: #c9d0d4;
      box-shadow: -1.259px 1.554px 6.48px 1.52px rgba(146, 146, 146, 0.11);
    }

    &.pin--row {
      background: #edf5ff;
    }

    p {
      color: #a9b4bd;
      padding: 3px 0;

      &.copy__desc {
        color: #34495e;
      }
    }

    a {
      color: $clr-pri;

      &:hover {
        color: $clr-sec;
      }
    }

    .col-left {
      display: table-cell;
      width: 30%;
      vertical-align: middle;
    }

    .col-right {
      display: table-cell;
      width: 70%;
      text-align: right;
      vertical-align: middle;
      font-weight: 400;
    }

    .col-full {
      display: table;
      width: 100%;
      vertical-align: middle;
      font-weight: 400;
    }

    .name {
      position: relative;
      padding: 0 0 0 40px;

      .number {
        position: absolute;
        left: 0;
        top: 50%;
        margin: -13px 0 0 0;
        color: $clr-pri;
        font-size: 14px;
        line-height: 24px;
        //font-weight: 600;
        text-align: center;
        min-width: 26px;
        padding: 2px 3px;
        //height: 26px;
        border-radius: 3px;
        border: 1px solid $clr-pri;

        &.invalid {
          &:after {
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            right: -7px;
            top: -7px;
            background: #dd3546;
            border: 1px solid $white;
            border-radius: 50%;
          }
        }
      }

      .details {
        padding: 8px 0 0 0;
        word-break: break-all;

        h4 {
          font-size: 14px;
          color: #34495e;
          font-weight: 400;
        }
      }
    }

    .inline-block {
      display: inline-block;
      text-align: center;
      padding: 0 25px;
      vertical-align: middle;
      min-width: 120px;
      max-width: 200px;
    }

    .table-cell {
      display: table-cell;
      text-align: center;
      padding: 0 10px;
      vertical-align: middle;
      // min-width: 120px;
      // max-width: 200px;

      &.name {
        // position: relative;
        padding: 0 0 0 40px;
        text-align: left;
        width: 20%;

      }
    }

    .created-by {}

    .date-created {}

    .end-date {}

    .responses {}

    .status {
      min-width: 130px;
      max-width: 150px;
    }

    .actions {
      padding: 1px 0 0 0;
      width: 170px;
      cursor: pointer;

      i {
        font-size: 14px;
        line-height: 30px;
      }

      a {
        display: inline-block;
        margin: 0 0 0 10px;
        color: #cdd4d8;
        width: 32px;
        height: 32px;
        border: 1px solid #cdd4d8;
        border-radius: 5px;

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          color: $clr-pri;
          border-color: $clr-pri;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: auto;

          .tooltiptext {
            display: none;
          }

          &:hover {
            color: #cdd4d8;
            border-color: #cdd4d8;
          }
        }
      }
    }
  }

  .contact-list-header {
    padding-left: 70px;
    position: relative;

    .toggle {
      position: absolute;
      left: 24px;
      top: 30px;
      width: 20px;
      height: 20px;
      border: 2px solid $clr-pri;
      border-radius: 2px;
      cursor: pointer;

      &:before {
        content: "add";
        position: absolute;
        left: -1px;
        top: -1px;
        font-size: 18px;
        line-height: 18px;
        font-family: "Material Icons";
        color: $clr-pri;
      }
    }

    &.active {
      .toggle {
        &:before {
          content: "remove";
        }
      }
    }
  }

  .contact-list-expandable {
    padding: 0 0 0 75px;
    display: none;
  }
}

.norecords-block {
  background: $white;
  border: 1px solid #e0e8ec;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 18px;
  padding: 15px 30px;
  text-align: center;
  float: left;
  width: 100%;

  p {
    padding: 2px 0;
  }
}

.survey-type {
  // @include container;

  @include clearfix();
  margin-right: 0 - gutter();
  margin-left: 0 - gutter();
  display: flex;
  justify-content: center;

  .type__item {
    //@include span(4);
    width: span(4);
    text-align: center;
    padding-right: gutter();
    padding-left: gutter();

  }

  .type__header {
    padding: 10px;
    background: #f4f5f9;
    border-bottom: 1px solid #e1e9ed;

    h2 {
      font-size: 12px;
      color: #4d565f;
    }

    i {
      color: $clr-pri;
      font-size: 30px;
      margin: 15px 0;
    }

  }

  .type__content {
    padding: 10px;

    p {
      color: #4d565f;
      font-size: 12px;
    }
  }

  .rating__label {
    float: left;
    width: 100%;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    border: 1px solid #e1e9ed;
    text-align: center;

    /*&:after {
      position: absolute;
      right: -10px;
      top: -10px;
      content: 'check_circle';
      font-family: 'Material Icons';
      font-size: 24px;
      border: 3px solid $white;
      color: $clr-pri;
      background: $white;
      border-radius: 50%;

      transform: scale(0);
    }*/
    img {
      width: 100%;
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      padding: 5px;
      background: $white;
      margin: 0 0 8px 0;

    }

    &:hover,
    &:active {
      img {
        border-color: #c9c9c9;
      }

      p {
        span {
          border-color: #c9c9c9;
        }
      }
    }
  }

  .rating__field {
    position: absolute;
    left: -9999px;

    &:checked {
      &+label {
        border: 2px solid $clr-pri;

        /* &:after {
           transform: scale(1);
         }*/
        p {
          span {
            background: $clr-pri;
            color: $white;
            border-color: $clr-pri;
          }
        }
      }
    }
  }
}

.form-container {

  &.create--survey {

    .ui-table .ui-table-thead>tr>th,
    .p-datatable .p-datatable-thead>tr>th {
      border: 0;
      padding: 10px;
    }

    .ui-table .ui-table-tbody>tr>td,
    .p-datatable .p-datatable-tbody>tr>td {
      padding: 10px;
    }

  }

  .table-container {
    margin-bottom: 0;

    .form-label {
      color: #a9b4bd;
    }
  }

  &.create--page {
    padding: 20px 0;
    @include clearfix();
    margin-right: 0 - gutter();
    margin-left: 0 - gutter();

  }

}

.table-createsurvey {
  height: 250px;
}

.option-list {
  margin: 0;
  padding: 0;
  font-size: 12px;
  @include clearfix();

  li {
    text-transform: capitalize;
    display: block;
    width: 100%;
    padding: 15px 10px;

    :lang(fr) {
      text-transform: none;
    }

    strong {
      min-width: 170px;
      display: inline-block;
      font-weight: 500;
    }

    i {
      color: $clr-pri;
      margin: 0 5px 0 8px;
      text-align: center;
      width: 20px;
      vertical-align: middle;
      font-size: 17px;
    }

    &:nth-child(even) {
      background: #f4f5f9;
      border-bottom: 1px solid #e1e9ed;
    }

    label {
      vertical-align: middle;
      cursor: pointer;
    }

    &.active {
      background: #dfeeff;
    }
  }
}

.number-box {
  position: absolute;
  left: 20px;
  top: 13px;
  color: #4ca1fe;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  min-width: 26px;
  padding: 2px 3px;
  border-radius: 3px;
  border: 1px solid #4ca1fe;
  display: inline-block;

  &.invalid {
    &:after {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      right: -7px;
      top: -7px;
      background: #dd3546;
      border: 1px solid $white;
      border-radius: 50%;
    }
  }
}

// pin styles

.pin-link {
  position: relative;
  margin: 0 0 0 5px;
  width: 20px;
  color: $clr-pri;
  font-size: 12px;
  height: 20px;

  i {
    visibility: hidden;
    transform: rotate(20deg);
  }

  &:hover {
    i {
      cursor: pointer;
      visibility: visible;
    }
  }

  &.active {
    i {
      visibility: visible;
      font-weight: 600;
    }
  }
}