.widget--keyperformance-indicators {
  .keyperformance-container {
    float: left;
    width: 100%;
    color: #212229;
    margin: 10px 0 25px;
    font-size: 14px;
    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      margin: 10px 0 10px;
    }

    .keyperformance__solidchart {
      position: absolute;
      left: 0;
      highcharts-chart {
        display: block;
        width: 52px;
        height: 52px;
      }
    }

    .keyperformance__header {
      float: left;
      width: 48%;
      position: relative;
      padding-left: 65px;
      @include susy-breakpoint(1024px, $susy, '.no-mq') {
        width: 100%;
      }

      .keyperformance__score {
        font-size: 25px;
        font-weight: 500;
      }
    }

    .keyperformance__linechart {
      float: right;
      width: 50%;
      @include susy-breakpoint(1024px, $susy, '.no-mq') {
        width: 100%;
      }
      highcharts-chart {
        display: block;
        width: 100%;
        height: 50px;
      }
    }

  }

}
