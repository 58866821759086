.question-weightage {
  @include clearfix();
  .weightage-summary {
    border-radius: 3px;
    .summary-block {
      padding: 0 0 20px;
      border: 1px solid #e1e9ed;
      background: $white;
      font-size: 14px;
      line-height: 18px;
      color: #4d565f;
      border-radius: 3px;
      .summary-heading {
        background: #ffffff;
        padding: 15px 15px 15px 80px;
        border-radius: 3px 3px 0 0;
        border-bottom: 1px solid #ebebeb;
        h2 {
          font-size: 16px;
          color: #34495e;
        }
      }
      h2 {
        font-size: 14px;
        color: #dc3545;
        font-weight: 500;
        &.positive {
          color: #17a2b8;
        }
      }
      h3 {
        font-size: 12px;
        font-weight: 500;
      }
      .summary__question {
        padding: 0 0 0 70px;
        position: relative;
        .question__no {
          position: absolute;
          left: 20px;
          top: 0;
          color: $clr-pri;
          font-weight: 500;
        }
      }
      .summary__answer {
        .percentage {
          font-weight: bold;
          color: $clr-pri;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}

.weight-table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}
.weight-table {
  font-size: 12px;
  color: #4d565f;
  min-width: 100%;
  tr {
    border-bottom: 1px solid #ebebeb;
    background: #ffffff;
    //&:nth-child(even) {
    //  background: #ffffff;
    //}
    &:first-child {
      .summary__question {
        .question__no {
          color: #4d565f;
        }
      }
    }
    td {
      white-space: nowrap;
      min-width: 150px;
      padding: 10px;
      text-align: center;
      &:first-child {
        width: 50%;
        min-width: 500px;
        text-align: left;
      }
    }
    &.first--level {
      background: #e9ecef;
    }
    &.second--level {
      background: #f0f2f4;
      .summary__question {
        padding-left: 90px;
      }
    }
    &.third--level {
      background: #f8f9fa;
      .summary__question {
        padding-left: 110px;
      }
    }
  }
}

.weight-kpi-bar {
  padding: 10px 0 0 0;
  form, div, button {
    display: inline-block;
  }
  form {
    margin: 0 10px 0 0;
  }
  .kpi-edit-mode {
    display: inline-block;
    font-size: 12px;
    padding: 0.75em;
    border: 1px solid #ddd;
    cursor: pointer;
    color: #212229;
    .kpi-percentage {
      margin: 0 0 0 5px;
      color: #969dab;
    }
  }
  .active-kpi {
    border-color: $clr-pri;
    background: $clr-pri;
    color: $white;
    border-radius: 3px;
    .kpi-percentage {
      color: $white;
    }
  }
  .kpi-container {
    margin: 0 10px 0 0;
  }
  .icon {
    margin: 0 0 0 10px;
  }
  .btn {
    padding: 9px;
  }
}