// Roboto

@font-face {
  font-family: "Roboto";
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.eot");
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.woff2")
      format("woff2"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.woff")
      format("woff"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.ttf")
      format("truetype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.eot");
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.eot?#iefix")
      format("embedded-opentype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.woff2")
      format("woff2"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.woff")
      format("woff"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.ttf")
      format("truetype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Light.svg#Roboto-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.eot");
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.woff2")
      format("woff2"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.woff")
      format("woff"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.ttf")
      format("truetype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.eot");
  src: url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.woff2")
      format("woff2"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.woff")
      format("woff"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.ttf")
      format("truetype"),
    url("^assets/styles/portal/build/assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}
