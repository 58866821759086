/** TabMenu **/

.p-tabmenu {
  .p-tabmenu-nav {
    margin: 0;
    border-radius: 0;
    padding: 0.25em 0.5em 0 0.25em;
    font-size:14px;
    .p-tabmenuitem {
      list-style: none;
      float: left;
      position: relative;
      margin: 0 3em 1px 0;
      padding: 0;
      white-space: nowrap;
      display: block;
      border-bottom: 0;
      top: 1px;
      @include susy-breakpoint($md, $susy, ".no-mq") {
        margin: 0 20px 1px 0;
      }
      a {
        float: left;
        padding: 0 0 0.8em 0;
        text-decoration: none;
        outline: none;
      
        @include susy-breakpoint($md, $susy, ".no-mq") {
          padding: 0 0 5px 0;
        }
      }
    }
    a {
      padding: 0.5em 1em;
    }
  }
  .ui-menuitem-icon {
    //margin-right: .25em;
    vertical-align: middle;
  }
  .ui-menuitem-text {
    vertical-align: middle;
  }
  .p-tabmenu-nav .p-tabmenuitem.ui-state-disabled a {
    cursor: default;
  }
}

.p-tabmenu {
  border: 0 none;
  @include clearfix();

  .p-tabmenu-nav {
    padding: 0;
    background: $tabsNavBgColor;
    border-bottom: 3px solid #e1e4e8;

    .p-tabmenuitem {
      position: static;
      //  border: $panelHeaderBorder;
      //  background-color: $panelHeaderBgColor;
      // margin-right: $tabHeaderSpacing;
      margin-bottom: -3px;
      @include transition($panelHeaderTransition);

      .ui-menuitem-link {
        color: $panelHeaderTextColor;
        // padding: $panelHeaderPadding;

        .ui-menuitem-text {
          //color: $panelHeaderTextColor;
          color: #212229;

          // margin-right: $inlineSpacing;
        }

        .ui-menuitem-icon {
          color: $panelHeaderIconColor;
          margin-right: $inlineSpacing;
        }
      }

      &:not(.ui-state-active):not(.ui-state-disabled):hover {
        //  background-color: $panelHeaderHoverBgColor;
        //  border: $panelHeaderHoverBorder;

        .ui-menuitem-link {
          .ui-menuitem-text {
            // color: $panelHeaderHoverTextColor;
            color: $clr-pri;
          }

          .ui-menuitem-icon {
            color: $panelHeaderIconHoverColor;
          }
        }
      }

      &.ui-state-active, &.p-highlight {
        //   background-color: $panelHeaderActiveBgColor;
        border-bottom: 3px solid $clr-pri;

        .ui-menuitem-link, .p-menuitem-link {
          color: $clr-pri;
          .ui-menuitem-text, .p-menuitem-text {
            //  color: $panelHeaderActiveTextColor;
            color: $clr-pri;
          }

          .ui-menuitem-icon, .p-menuitem-icon {
            color: $panelHeaderActiveIconColor;
          }
        }
      }
    }
  }
}

.tabs-secondary {
  border: 0 none;
  font-size: 0.75em;

  .p-tabmenu-nav {
    padding: 0;
    background: $tabsNavBgColor;
    border-bottom: 0;
    font-size:12px;

    .p-tabmenuitem {
      position: static;
      //  border: $panelHeaderBorder;
      //background-color: $panelHeaderBgColor;
      // margin-right: $tabHeaderSpacing;
      margin: 0 5px 0 0;
      border-radius: 3px;

      a {
        // float: left;
        padding: 7px 15px;
        //  text-decoration: none;
        .tab__count{
          color:#a9b4bd;
        }
      }

      //margin-bottom: -3px;
      @include transition($panelHeaderTransition);

      .ui-menuitem-link {
        color: $panelHeaderTextColor;
        // padding: $panelHeaderPadding;

        .ui-menuitem-text {
          //color: $panelHeaderTextColor;
          color: #212229;

          // margin-right: $inlineSpacing;
        }

        .ui-menuitem-icon {
          color: $panelHeaderIconColor;
          margin-right: $inlineSpacing;
        }
      }

      &:not(.ui-state-active):not(.ui-state-disabled):hover {
        //  background-color: $panelHeaderHoverBgColor;
        //  border: $panelHeaderHoverBorder;

        .ui-menuitem-link {
          .ui-menuitem-text {
            // color: $panelHeaderHoverTextColor;
            color: $clr-pri;
          }

          .ui-menuitem-icon {
            color: $panelHeaderIconHoverColor;
          }
        }
      }

      &.ui-state-active {
        background-color: $panelHeaderActiveBgColor;
        color: $white;
        border: 0;
        // border-bottom:3px solid $clr-pri;

        .ui-menuitem-link {
          color: $white;
          .ui-menuitem-text {
            //  color: $panelHeaderActiveTextColor;
            color: $white;
          }

          .tab__count{
            color: $white;
          }

          .ui-menuitem-icon {
            color: $panelHeaderActiveIconColor;
          }
        }
      }
    }
  }
}


.tabs--right{
  float: right;
}

/** TabMenu **/

.p-tabmenu {
  .p-tabmenu-nav {
    margin: 0;
    border-radius: 0;
    padding: 0.25em 0.5em 0 0.25em;
    font-size:14px;
    .p-tabmenuitem {
      list-style: none;
      float: left;
      position: relative;
      margin: 0 3em 1px 0;
      padding: 0;
      white-space: nowrap;
      display: block;
      border-bottom: 0;
      top: 1px;
      @include susy-breakpoint($md, $susy, ".no-mq") {
        margin: 0 20px 1px 0;
      }
      a {
        float: left;
        padding: 0 0 0.8em 0;
        text-decoration: none;
        outline: none;
        @include susy-breakpoint($md, $susy, ".no-mq") {
          padding: 0 0 5px 0;
        }
      }
    }
    a {
      padding: 0.5em 1em;
    }
  }
  .p-menuitem-icon {
    //margin-right: .25em;
    vertical-align: middle;
  }
  .p-menuitem-text {
    vertical-align: middle;
  }
  .p-tabmenu-nav .p-tabmenuitem.p-state-disabled a {
    cursor: default;
  }
}

.p-tabmenu {
  border: 0 none;

  .p-tabmenu-nav {
    padding: 0;
    background: $tabsNavBgColor;
    border-bottom: 3px solid #e1e4e8;

    .p-tabmenuitem {
      position: static;
      //  border: $panelHeaderBorder;
      //  background-color: $panelHeaderBgColor;
      // margin-right: $tabHeaderSpacing;
      margin-bottom: -3px;
      @include transition($panelHeaderTransition);

      .p-menuitem-link {
        color: $panelHeaderTextColor;
        // padding: $panelHeaderPadding;

        .p-menuitem-text {
          //color: $panelHeaderTextColor;
          color: #212229;

          // margin-right: $inlineSpacing;
        }

        .p-menuitem-icon {
          color: $panelHeaderIconColor;
          margin-right: $inlineSpacing;
        }
      }

      &:not(.p-state-active):not(.p-state-disabled):hover {
        //  background-color: $panelHeaderHoverBgColor;
        //  border: $panelHeaderHoverBorder;

        .p-menuitem-link {
          .p-menuitem-text {
            // color: $panelHeaderHoverTextColor;
            color: $clr-pri;
          }

          .p-menuitem-icon {
            color: $panelHeaderIconHoverColor;
          }
        }
      }

      &.p-state-active {
        //   background-color: $panelHeaderActiveBgColor;
        border-bottom: 3px solid $clr-pri;

        .p-menuitem-link {
          .p-menuitem-text {
            //  color: $panelHeaderActiveTextColor;
            color: $clr-pri;
          }

          .p-menuitem-icon {
            color: $panelHeaderActiveIconColor;
          }
        }
      }
    }
  }
}

.tabs-secondary {
  border: 0 none;
  font-size: 0.75em;

  .p-tabmenu-nav {
    padding: 0;
    background: $tabsNavBgColor;
    border-bottom: 0;
    font-size:12px;

    .p-tabmenuitem {
      position: static;
      //  border: $panelHeaderBorder;
      //background-color: $panelHeaderBgColor;
      // margin-right: $tabHeaderSpacing;
      margin: 0 5px 0 0;
      border-radius: 3px;

      a {
        // float: left;
        padding: 7px 15px;
        //  text-decoration: none;
        .tab__count{
          color:#a9b4bd;
        }
      }

      //margin-bottom: -3px;
      @include transition($panelHeaderTransition);

      .p-menuitem-link {
        color: $panelHeaderTextColor;
        // padding: $panelHeaderPadding;

        .p-menuitem-text {
          //color: $panelHeaderTextColor;
          color: #212229;

          // margin-right: $inlineSpacing;
        }

        .p-menuitem-icon {
          color: $panelHeaderIconColor;
          margin-right: $inlineSpacing;
        }
      }

      &:not(.p-state-active):not(.p-state-disabled):hover {
        //  background-color: $panelHeaderHoverBgColor;
        //  border: $panelHeaderHoverBorder;

        .p-menuitem-link {
          .p-menuitem-text {
            // color: $panelHeaderHoverTextColor;
            color: $clr-pri;
          }

          .p-menuitem-icon {
            color: $panelHeaderIconHoverColor;
          }
        }
      }

      &.p-state-active {
        background-color: $panelHeaderActiveBgColor;
        color: $white;
        border: 0;
        // border-bottom:3px solid $clr-pri;

        .p-menuitem-link {
          color: $white;
          .p-menuitem-text {
            //  color: $panelHeaderActiveTextColor;
            color: $white;
          }

          .tab__count{
            color: $white;
          }

          .p-menuitem-icon {
            color: $panelHeaderActiveIconColor;
          }
        }
      }
    }
  }
}


.tabs--right{
  float: right;
}
