.upload-zone {
  display: table;
  width: 100%;
  height: 150px;
  border: 2px dashed #ebebeb;
  background: $white;
  font-size: 12px;
  color: #969dab;
  text-align: center;
  .upload-zone-content {
    display: table-cell;
    vertical-align: middle;
  }
  p {
    padding: 2px 0;
  }
}

.library-filters {
  @include clearfix();
  padding: 10px 0 0 0;
  .filter--left {
    float: left;
    line-height: 34px;
    .library-filter-item {
      margin-right: 8px;
    }
  }
  .filter--right {
    float: right;
    .library-filter-item {
      margin-left: 8px;
    }
  }
  .library-filter-item {
    display: inline-block;
  }
  .input-search {
    width: 320px;
  }
  .select-view {
    a {
      font-size: 20px;
      line-height: 34px;
      display: inline-block;
      color: #ced1d7;
      vertical-align: middle;
      padding: 0 7px;
      &:hover, &.active {
        color: $clr-pri;
      }
    }
  }
}

.library-grid {
  @include clearfix();
  margin-right: 0 - gutter();
  margin-left: 0 - gutter();
  @include nested(10) {
    .grid__item {
      @include span(2);
      //&:nth-child(5n) {
      //  @include last;
      //}
    }
  }
  .library-content & {
    @include susy-breakpoint(1440px, $susy, ".no-mq") {
      @include nested(12) {
        .grid__item {
          @include span(3);
          //&:nth-child(4n) {
          //  @include last;
          //}
        }
      }
    }
  }

  .grid__item {
    margin-bottom: gutter();
    margin-top: gutter();
    .grid__label {
      float: left;
      width: 100%;
      cursor: pointer;
      position: relative;
      margin: 0;
      &:after {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 15px;
        top: 15px;
        content: '';
        font-family: "Font Awesome 5 Pro";
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #cad0e8;
        color: $white;
        background: $white;
        border-radius: 3px;
      }
      &:before {
        display: none;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        border: 3px solid $clr-pri;
        border-radius: 3px;
        z-index: 5;
      }
      img {
        width: 100%;
        border-radius: 3px;
        padding: 5px;
        background: $white;
      }
      p {
        font-size: 14px;
        line-height: 16px;
        color: $clr-sec;
        padding: 5px 10px 15px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .preview-icon, .more-icon {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 24px;
        height: 20px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #cad0e8;
        color: #969dab;
        background: $white;
        border-radius: 3px;
        z-index: 8;
        i {
          vertical-align: middle;
        }
      }
      .more-icon {
        display: none;
        width: 24px;
        height: 20px;
        font-size: 12px;
        line-height: 18px;
        color: #969dab;
        i {
          vertical-align: middle;
        }
      }
      .thumb-icon {
        width: 100%;
        height: 225px;
        display: block;
        text-align: center;
        background: white;
        color: $clr-pri;
        font-size: 48px;
        line-height: 225px;
        border-radius: 3px;
      }
      .library-image-tooltip {
        @include opacity(0);
        visibility: hidden;
        transform: translateY(45px);
        transition: all 0.1s ease-in-out;
      }
      &:hover,
      &:active {
        .more-icon {
          display: block;
        }
        img {
          border-color: #c9c9c9;
        }
        .library-image-tooltip {
          @include opacity(1);
          visibility: visible;
          transform: translateY(0);
        }
      }
      &.ui-label-active {
        background: $white;
        &:after {
          content: '\f00c';
          border-color: $clr-pri;
          background: $clr-pri;
        }
        &:before {
          display: block;
        }
        img {
          border-color: $clr-pri;
        }
        p {
          background: $white;
        }
        &:hover,
        &:active {
          .more-icon {
            display: none;
          }
          .library-image-tooltip {
            @include opacity(0);
            visibility: hidden;
            transform: translateY(0);
          }
        }
      }
    }
    .grid__field {
      position: absolute;
      left: -9999px;
      &:checked {
        & + label {
          background: $white;
          &:after {
            content: '\f00c';
            border-color: $clr-pri;
            background: $clr-pri;
          }
          &:before {
            display: block;
          }
          img {
            border-color: $clr-pri;
          }
          p {
            background: $white;
          }
        }
      }
    }
  }
}

.radio-image {
  position: relative;
  width: 100%;
  li {
    float: left;
    padding: 0 12px 12px;
    width: 25%;
    &:first-child, &:nth-child(4n+1) {
      margin-left: -12px;
      clear: left;
    }
    @include breakpoint ($sm) {
      width: 50%;
      &:nth-child(4n+1) {
        margin-left: 0;
        clear: none;
      }
      &:first-child, &:nth-child(2n+1) {
        margin-left: -12px;
        clear: left;
      }
    }
  }
}

.library-image-tooltip {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 50px;
  background: $white;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  padding: 10px;

  .tooltip__content {
    display: table;
    width: 100%;
  }
  .tooltip-row {
    display: table-row;
    border-top: 1px solid #e1e9ed;
    &:first-child {
      border-top: none;
    }
    .label, .info {
      // display: inline-block;
      display: table-cell;
      padding: 3px 0;
    }
    .label {
     // width: 30%;
      font-weight: 500;
    }
    .info {
    //  width: 68%;
    }
  }
}