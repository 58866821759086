
.i-container{
  max-width: 1200px;
  margin: 0 auto;
}

.top-bar{
  display: flex;
  justify-content: space-between;
  margin: 25px 0 15px;
  align-items: center;
  h2{
font-size:20px;
  }
}

.identity-content{
  padding: 20px;
}

.fields-group{
margin-bottom: 10px;
position: relative;

.form-field {
  input{
    padding-right: 20px;
  }
}


&:last-child{
  margin: 0;
}
}


.delete-scope {
  position: absolute;
  background: #e9ecef;
  padding: 8px 10px;
  border: 1px solid #d0d7dd;
  @include border-radius(0 3px);
  font-size: 12px;
  right: 0;
  color: $clr-red;
  cursor: pointer;
  @extend %transition;
  &:hover {
    color: $clr-red;
    background: #e2e3e5;
  }
 
}


.add-scope {
  position: absolute;
  background: #e9ecef;
  padding: 8px 10px;
  border: 1px solid #d0d7dd;
  @include border-radius(0 3px);
  font-size: 12px;
  right: 0;
  color: $clr-pri;
  cursor: pointer;
  @extend %transition;
  &:hover {
    color: $clr-pri;
    background: #e2e3e5;
  }
 
}


.indentity-pagination{
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center; 
  font-size: 12px;
  button{
    margin: 0 5px;
    border-radius: 100%;
    width:34px;
    height:34px;
  }
}