.ui-datagrid {
  .ui-datagrid-header {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
  }

  .ui-datagrid-content {
    padding: $panelContentPadding;
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .ui-datagrid-footer {
    background-color: $panelFooterBgColor;
    color: $panelFooterTextColor;
    border: $panelFooterBorder;
    padding: $panelFooterPadding;
    border-top: 0 none;
  }
}

.ui-datalist {
  .ui-datalist-header {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
  }

  .ui-datalist-content {
    padding: $panelContentPadding;
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .ui-datalist-footer {
    background-color: $panelFooterBgColor;
    color: $panelFooterTextColor;
    border: $panelFooterBorder;
    padding: $panelFooterPadding;
    border-top: 0 none;
  }
}

.ui-datascroller {
  .ui-datascroller-header {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
  }

  .ui-datascroller-content {
    padding: $panelContentPadding;
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .ui-datascroller-footer {
    background-color: $panelFooterBgColor;
    color: $panelFooterTextColor;
    border: $panelFooterBorder;
    padding: $panelFooterPadding;
    border-top: 0 none;
  }
}

.ui-virtualscroller {
  .ui-virtualscroller-header {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
  }

  .ui-virtualscroller-content {
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    .ui-virtualscroller-list {
      li {
        border-bottom: $panelHeaderBorder;
      }
    }
  }

  .ui-virtualscroller-footer {
    background-color: $panelFooterBgColor;
    color: $panelFooterTextColor;
    border: $panelFooterBorder;
    padding: $panelFooterPadding;
    border-top: 0 none;
  }
}

.ui-dataview {
  .ui-dataview-header {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
  }

  .ui-dataview-content {
    padding: $panelContentPadding;
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .ui-dataview-footer {
    background-color: $panelFooterBgColor;
    color: $panelFooterTextColor;
    border: $panelFooterBorder;
    padding: $panelFooterPadding;
    border-top: 0 none;
  }
}

.fc {
  th {
    background-color: $panelHeaderBgColor;
    border: $panelHeaderBorder;
    color: $panelHeaderTextColor;
    padding: $panelHeaderPadding;
  }

  td {
    &.p-widget-content {
      background-color: $panelContentBgColor;
      border: $panelContentBorder;
      color: $panelContentTextColor;
    }

    &.fc-head-container {
      border: $panelHeaderBorder;
    }
  }

  .fc-event {
    background-color: $scheduleEventBgColor;
    border: $scheduleEventBorder;
    color: $scheduleEventTextColor;
  }

  .fc-toolbar {
    .ui-button {
      .ui-icon-circle-triangle-w {
        font-family: "PrimeIcons";
        text-indent: 0;
        @include icon_override("\e900");
      }

      .ui-icon-circle-triangle-e {
        font-family: "PrimeIcons";
        text-indent: 0;
        @include icon_override("\e901");
      }

      &:focus {
        outline: 0 none;
      }
    }
  }

  .fc-divider {
    background-color: $panelHeaderBgColor;
    border: $panelHeaderBorder;
  }
}

.ui-fluid {
  .fc {
    .fc-toolbar {
      .ui-button {
        width: auto;
      }
    }
  }
}

.ui-picklist {
  .ui-picklist-buttons {
    button {
      font-size: $fontSize + 2;
    }
  }

  .ui-picklist-caption {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
    @include border-radius-top($borderRadius);
  }

  .ui-picklist-filter-container {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border: $panelHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
    margin: $inputListHeaderMargin;
    border-bottom: 0 none;

    input.ui-picklist-filter {
      width: 100%;
      padding-right: 2em;
      text-indent: 0;
    }

    .ui-picklist-filter-icon {
      top: 50%;
      margin-top: -0.5em;
      left: auto;
      right: $inputListHeaderPaddingRight + 0.5em;
      color: $inputListHeaderSearchIconColor;
    }
  }

  .ui-picklist-buttons {
    padding: $panelContentPadding;
  }

  .ui-picklist-list {
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $inputListPadding;

    .ui-picklist-item {
      padding: $inputListItemPadding;
      margin: $inputListItemMargin;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;

      &:not(.ui-state-highlight):hover {
        background-color: $inputListItemHoverBgColor;
        color: $inputListItemHoverTextColor;
      }

      &.ui-state-highlight {
        background-color: $inputListItemHighlightBgColor;
        color: $inputListItemHighlightTextColor;
      }
    }

    .ui-picklist-droppoint-highlight {
      background-color: $highlightBgColor;
    }
  }
}

@media (max-width: 40em) {
  .ui-picklist.ui-picklist-responsive {
    .ui-picklist-buttons {
      padding: $panelContentPadding;
    }
  }
}

.ui-orderlist {
  .ui-orderlist-controls {
    padding: $panelContentPadding;

    button {
      font-size: $fontSize + 2;
    }
  }

  .ui-orderlist-caption {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    font-weight: $panelHeaderFontWeight;
    border-bottom: 0 none;
    @include border-radius-top($borderRadius);
  }

  .ui-orderlist-filter-container {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border: $panelHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
    margin: $inputListHeaderMargin;
    border-bottom: 0 none;

    input.p-inputtext {
      width: 100%;
      padding-right: 2em;
      text-indent: 0;
    }

    .ui-orderlist-filter-icon {
      top: 50%;
      margin-top: -0.5em;
      left: auto;
      right: $inputListHeaderPaddingRight + 0.5em;
      color: $inputListHeaderSearchIconColor;
    }
  }

  .ui-orderlist-list {
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $inputListPadding;

    .ui-orderlist-item {
      padding: $inputListItemPadding;
      margin: $inputListItemMargin;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;

      &:not(.ui-state-highlight):hover {
        background-color: $inputListItemHoverBgColor;
        color: $inputListItemHoverTextColor;
      }

      &.ui-state-highlight {
        background-color: $inputListItemHighlightBgColor;
        color: $inputListItemHighlightTextColor;
      }
    }

    .ui-orderlist-droppoint-highlight {
      background-color: $highlightBgColor;
    }
  }
}

.ui-organizationchart {
  .ui-organizationchart-node-content {
    &.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
      background-color: $inputListItemHoverBgColor;
      color: $inputListItemHoverTextColor;
    }

    &.ui-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .ui-node-toggler {
        i {
          color: darken($highlightBgColor, 25%);
        }
      }
    }
  }

  .ui-organizationchart-line-down {
    background-color: $organizationChartConnectorColor;
  }

  .ui-organizationchart-line-left {
    border-right: $panelContentBorder;
    border-color: $organizationChartConnectorColor;
  }

  .ui-organizationchart-line-top {
    border-top: $panelContentBorder;
    border-color: $organizationChartConnectorColor;
  }

  .ui-organizationchart-node-content {
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .ui-organizationchart-node-content .ui-node-toggler {
    bottom: -0.7em;
    margin-left: -0.46em;
    color: $dataIconColor;
  }
}


