.notification-card {
  padding: 10px 10px 10px 50px;
  position: relative;

  .icon {
    position: absolute;
    left: 12px;
    top: 15px;
    width: 28px;
    height: 28px;
    text-align: center;
    border-radius: 50%;
    background: $clr-pri;
    color: $white;
    box-shadow: 0px 0px 20px 0px rgba(115, 115, 115, 0.2);

    i {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .details {
    color: #34495e;
    font-size: 13px;

    h3 {
      font-size: 26px;
      font-weight: normal;
    }
  }
}

.notification-listing {
  font-size: 12px;
  color: #34495e;

  .notification__item {
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 0;
    @include clearfix();

    h4 {
      font-size: 13px;
      font-weight: bold;
      padding: 0 0 5px 0;
    }
  }

  .notification__info {
    float: left;

    span {
      margin: 0 10px 0 0;

      &.imported {
        i {
          color: $clr-pri;
        }
      }

      &.successful {
        i {
          color: #88be58;
        }
      }

      &.failed {
        i {
          color: #ec638d;
        }
      }
    }

    i {
      font-size: 14px;
      vertical-align: bottom;
    }
  }

  .notification__date {
    float: right;
    color: #72818f;

    span {
      margin: 0 10px 0 10px;
    }

    i {
      font-size: 14px;
      vertical-align: bottom;
      color: #99a4ae;
    }
  }

  .notification__globe {
    float: right;

    span {
      margin: 0 5px 0 10px;
    }

    i {
      color: $clr-pri;
      font-size: 14px;
      vertical-align: bottom;
    }
  }

  .notification__status {
    float: right;

    span {
      margin: 0 20px 0 10px;
    }

    i {
      font-size: 14px;
      vertical-align: bottom;
    }
  }
}

.notification-tabs {
  float: right;
  color: #4d565f;
  font-size: 15px;

  a {
    color: #4d565f;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 25px;
    margin: 0 5px;

    &:hover {
      color: $clr-pri;
    }

    &.active {
      color: $white;
      background: $clr-pri;
      box-shadow: 0px 10px 20px 0px rgba(76, 161, 254, 0.3);
    }
  }
}


.profile-notification {
  position: absolute;
  right: 20px;
  background: white;
  font-size: 12px;
  padding: 7px 10px;
  border-radius: 2px;
  color: #666666;
  border: 1px solid #dc4352;
  top: 19px;

  i {
    font-size: 14px;
    color: #dc4352;
    margin-right: 5px;

    &.close {
      margin-left: 10px;
      color: #c0c0c0;
      cursor: pointer;
    }
  }

  span {
    color: #dc4352;
  }
}

.floating-notification {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background: white;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 2px;
  color: #666666;
  border: 1px solid #dc4352;
  z-index: 10;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

  i {
    font-size: 14px;
    color: #dc4352;
    margin-right: 5px;

    &.close {
      margin-left: 10px;
      color: #c0c0c0;
      cursor: pointer;
    }
  }

  span {
    color: #dc4352;
  }
}