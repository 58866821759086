//.p-steps {
//  ul {
//    list-style-type: none;
//    padding: 0;
//    margin: 0;
//  }
//  .p-steps-item {
//    float: left;
//    box-sizing: border-box;
//    cursor: pointer;
//  }
//  &.p-steps-readonly .p-steps-item {
//    cursor: auto;
//  }
//  .p-steps-item {
//    .p-menuitem-link {
//      text-decoration: none;
//      display: block;
//      padding: 1em;
//      position: relative;
//      text-align: center;
//    }
//    &.p-state-highlight .p-menuitem-link, &.p-state-disabled .p-menuitem-link {
//      cursor: default;
//    }
//  }
//  .p-steps-number {
//    font-size: 2em;
//    display: block;
//  }
//  .p-steps-title {
//    display: block;
//    white-space: nowrap;
//  }
//}

.p-steps {
  ul {
    list-style-type: none;
    margin: 0 auto !important;
    padding: 30px 0 !important;
    max-width: 1100px;
    text-align: center;
    @include clearfix();
  }
  .p-steps-item {
    float: left;
    width: calc(100% / 5);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: calc(-50% + 13px);
      bottom: -2px;
      z-index: 1;
      width: 100%;
      height: 3px;
      background: #cdd4d8;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    &.p-steps-current,
    &.p-steps-complete {
      &:before {
        background: $clr-pri;
      }
      .p-menuitem-link {
        &:before {
          border-color: $clr-pri;
          box-shadow: 0px 8px 16px 0px rgba(76, 161, 254, 0.25);
        }
      }
      .p-steps-number {
        color: #7c8288;
      }
      .p-steps-title {
        font-weight: 600;
        color: #34495e;
      }
    }
    &.p-steps-complete {
      .p-steps-number {
        color: #4d565f;
      }
    }
    &.p-steps-current {
      .p-menuitem-link {
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -6px;
          margin: 0 0 0 -6px;
          width: 12px;
          height: 12px;
          background: $clr-pri;
          border-radius: 50%;
        }
      }
    }
    &.p-steps-complete {
      .p-menuitem-link {
        &:after {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          left: 50%;
          bottom: -11px;
          margin: 0 0 0 -11px;
          width: 22px;
          font-size: 14px;
          line-height: 18px;
          border: 2px solid $clr-pri;
          color: $white;
          background: $clr-pri;
          border-radius: 50%;
        }
      }
    }
    &.p-state-disabled {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
  &.p-steps-readonly .p-steps-item {
    cursor: auto;
  }
  .p-steps-item {
    //.p-menuitem-link {
    //  text-decoration: none;
    //  display: block;
    //  padding: 1em;
    //  position: relative;
    //  text-align: center;
    //}
    //&.p-state-highlight .p-menuitem-link, &.p-state-disabled .p-menuitem-link {
    //  cursor: default;
    //}
    .p-menuitem-link {
      overflow: visible;
    }
  }
  .p-steps-number {
    display: block;
    font-size: 32px;
    font-weight: bold;
    color: #cdd4d8;
  }
  .p-steps-title {
    display: block;
    white-space: nowrap;
    font-size: 15px;
    color: #4d565f;
  }
  .p-menuitem-link {
    display: block;
    position: relative;
    padding: 0 0 25px 0;
    cursor: default;
    outline: none;
    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      bottom: -13px;
      left: 50%;
      margin: 0 0 0 -13px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: $white;
      border: 3px solid #cdd4d8;
      box-shadow: 0px 8px 16px 0px rgba(112, 124, 131, 0.25);
    }
    &:after {
      z-index: 3;
    }
  }
}

.timeline {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1100px;
  text-align: center;
  @include clearfix();
  .timeline__step {
    float: left;
    width: calc(100% / 4);
    position: relative;
    padding: 0 0 25px 0;
    &:before {
      content: "";
      position: absolute;
      left: -50%;
      bottom: -2px;
      z-index: 1;
      width: 100%;
      height: 3px;
      background: #cdd4d8;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    .step__status {
      position: absolute;
      z-index: 2;
      bottom: -13px;
      left: 50%;
      margin: 0 0 0 -13px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: $white;
      border: 3px solid #cdd4d8;
      box-shadow: 0px 8px 16px 0px rgba(112, 124, 131, 0.25);
    }
    .step__no {
      display: block;
      font-size: 32px;
      font-weight: bold;
      color: #cdd4d8;
    }
    .step__name {
      font-size: 15px;
      color: #4d565f;
    }
    &.current,
    &.done {
      &:before {
        background: $clr-pri;
      }
      .step__status {
        border-color: $clr-pri;
        box-shadow: 0px 8px 16px 0px rgba(76, 161, 254, 0.25);
      }
      .step__no {
        color: #7c8288;
      }
      .step__name {
        font-weight: 600;
        color: #34495e;
      }
    }
    &.done {
      .step__no {
        color: #4d565f;
      }
    }
    &.current {
      .step__status {
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -6px 0 0 -6px;
          width: 12px;
          height: 12px;
          background: $clr-pri;
          border-radius: 50%;
        }
      }
    }
    &.done {
      .step__status {
        &:after {
          content: "check";
          font-family: "Material Icons";
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -11px 0 0 -11px;
          font-size: 18px;
          line-height: 18px;
          border: 2px solid $clr-pri;
          color: $white;
          background: $clr-pri;
          border-radius: 50%;
        }
      }
    }
  }
}
