.p-toast {
  position: fixed;
  z-index:1100;
  width: 400px;
  .p-toast-message {
    position: relative;
    display: block;
    margin-bottom: 0.75em;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
  }
  .p-toast-message-content {
    display: block !important;
    padding: 20px 20px 20px 70px;
    position: relative;
    font-size: 13px;
    h3 {
      display: block;
      font-size: 18px;
      margin: 0 0 7px;
    }
  }
  .p-toast-icon-close {
    position: absolute;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    right: 10px;
    top: 6px;
  }
  .p-toast-summary {
    font-weight: bold;
    padding: 0 0 0.5em 0;
    display: block;
  }
  .ui-toast-icon, .p-toast-icon {
    position: absolute;
    display: inline-block;
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    font-size: 40px;
    left: 15px;
    line-height: 40px;
    margin: -20px 0 0;
    top: 50%;
  }
  .p-toast-message-text-content {
    padding: 0 0 0.25em 0;
    margin-left: 2.5em;
  }
}

/* Positions */

.p-toast-top-right {
  top: 20px;
  right: 20px;
}

.p-toast-top-left {
  top: 20px;
  left: 20px;
}

.p-toast-bottom-right,
.p-toast-bottom-left {
  bottom: 20px;
  right: 20px;
}

.p-toast-top-center {
  top: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-bottom-center {
  bottom: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}

.p-toast {
  .p-toast-message {
    @include shadow($toastShadow);
    margin: $toastMessageMargin;

    &.p-toast-message-info {
      background-color: $infoMessageBgColor;
      border: $infoMessageBorder;
      color: $infoMessageTextColor;

      .p-toast-icon-close {
        color: $infoMessageTextColor;
      }
    }

    &.p-toast-message-success {
      background-color: $successMessageBgColor;
      border: $successMessageBorder;
      color: $successMessageTextColor;

      .p-toast-icon-close {
        color: $successMessageTextColor;
      }
    }

    &.p-toast-message-warn {
      background-color: $warnMessageBgColor;
      border: $warnMessageBorder;
      color: $warnMessageTextColor;

      .p-toast-icon-close {
        color: $warnMessageTextColor;
      }
    }

    &.p-toast-message-error {
      background-color: $errorMessageBgColor;
      border: $errorMessageBorder;
      color: $errorMessageTextColor;

      .p-toast-icon-close {
        color: $errorMessageTextColor;
      }
    }
  }
  &.sticky--notification {
    .p-toast-icon {
      display: none;
    }
    .p-toast-message-content {
      padding: 15px 20px;

    }
    .p-toast-message-content {
      p {
        margin: 0 0 10px 0;
      }
    }
    .btn--small {
      padding: 3px 10px;
    }
  }
}