.ui-scrollpanel {
  @include clearfix();
}

.ui-scrollpanel-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
  &:not(.ui-scrollpanel-wrapper-hidden) {
    border-right: 6px solid #f4f4f4;
    .ui-scrollpanel-content {
      padding: 0 0 18px 0;
    }
  }
}

.ui-scrollpanel-content {
  height: calc(100% + 18px);
  width: calc(100% + 18px);
  padding: 0 18px 18px 0;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .filter__list & {
    padding-bottom: 0;
  }
}

.ui-scrollpanel-bar {
  position: relative;
  // background: #c1c1c1;
  border-radius: 3px;
  z-index: 2;
  cursor: pointer;
  // opacity: 0;
  transition: opacity 0.25s linear;
  background-color: #d3d5d6;
  opacity: 1;
  // transition: background-color .3s;
  &:hover {
    background-color: $clr-pri;
  }
}

.ui-scrollpanel-bar-y {
  width: 6px;
  top: 0;
}

.ui-scrollpanel-bar-x {
  height: 6px;
  bottom: 0;
}

.ui-scrollpanel-hidden {
  visibility: hidden;
}

.ui-scrollpanel {
  &:hover .ui-scrollpanel-bar,
  &:active .ui-scrollpanel-bar {
    opacity: 1;
  }
}

.ui-scrollpanel-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hierarchy__list {
  .ui-scrollpanel-wrapper {
    &:not(.ui-scrollpanel-wrapper-hidden) {
      .ui-scrollpanel-content {
        padding: 0;
      }
    }
  }

  .ui-scrollpanel-content {
    height: 100%;
  }
}
