//.widget__filters {
//  float: right;
//  .filter-item {
//    float: left;
//    border-left: 1px solid #ebebeb;
//    padding: 15px 20px;
//    min-width: 200px;
//    max-width: 280px;
//    min-height: 57px;
//    &:first-child {
//      border-left: none;
//    }
//  }
//}
.widget__filters {
  display: flex;
  justify-content: flex-end;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    flex-direction: column;
  }

  &.filter--grey {
    background: #f9fafc;
  }

  .page-category-configuration & {
    background: #f9fafc;
    border-bottom: 1px solid #ebebeb;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      flex-direction: row;
    }

    .toggle {
      padding: 4px 0 0 0;
    }
  }

  .filter-item {
    //flex-basis: 200px;
    padding: 15px;
    border-left: 1px solid #ebebeb;
    min-height: 60px;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      min-height: 0;
      border-top: 1px solid #ebebeb;
      flex-basis: auto !important;
      padding: 10px;

      .p-dropdown {
        width: 100% !important;
      }

      p-button {
        display: block;

        .btn {
          width: 100%;
          padding: 4px;
        }
      }

      .btn {
        width: 100%;
      }
    }

    &:first-child {
      border-left: none;
      border-top: none;
    }

    &.filter-item--btntranslation {
      flex: 1;
    }

    &.filter-item--tabs {
      flex: 1;

      .tabs-secondary .ui-tabmenu-nav .ui-tabmenuitem a {
        padding: 7px 10px;
      }
    }

    &.filter-item--tags {
      flex: 1;
    }

    &.filter-item--search {
      flex-basis: 280px;
    }

    &.filter-item--dropdown {
      //flex-basis: 300px;
    }
  }

  &.filters--medium {
    .filter-item {
      min-height: 10px;
      padding: 0 5px 15px;
    }
  }

  &.filters--commentlist {
    .filter-item {
      padding: 15px 10px;

      &.filter-item--search {
        flex-basis: 170px;
        padding-top: 17px;
      }

      &.filter-item--searchbtn {
        padding-top: 19px;
      }

      .tags-list {
        li {
          padding: 5px;

          &.with-info {
            padding-right: 32px;
          }
        }
      }
    }
  }
}

.filter-percentage {
  min-width: 160px;

  h3 {
    font-size: 11px;
    font-weight: bold;
    color: #969dab;
    padding: 0 0 7px 0;

    span {
      color: $primaryColor;
    }
  }

  .ui-progressbar,
  .p-progressbar {
    height: 7px;
  }
}

.filter-search {
  position: relative;

  i {
    position: absolute;
    left: 7px;
    top: 7px;
    color: #969dab;
    opacity: 0.5;
  }

  input {
    width: 100% !important;
    border: none;
    width: auto;
    box-shadow: none;
    color: #969dab;
    font-size: 12px;
    padding: 8px 20px 8px 35px;
  }
}

.filter-calendar {
  position: relative;

  i {
    position: absolute;
    left: 7px;
    top: 7px;
    z-index: 1;
    color: #969dab;
    opacity: 0.5;
  }

  input {
    width: 100% !important;
    border: none;
    width: auto;
    box-shadow: none;
    color: #969dab;
    font-size: 12px;
    padding: 8px 20px 8px 35px;
  }
}

.widget-header-filter-dropdown {
  >label {
    display: inline-block;
    font-size: 12px;
    padding: 0 10px 0 0;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      padding: 0 0 10px 0;
    }
  }

  >.dropdown-container {
    display: inline-block;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      display: block;
    }
  }

  &.inline--dropdown {
    display: inline-block;
    margin-right: 10px;
  }
}

.filter-slider {
  position: relative;
  padding: 12px 0 0 0;

  .p-slider {
    margin: 0;
    position: relative;
    z-index: 14;
  }

  .survey-score-heading {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: -10px;
    font-size: 14px;
    color: #666666;
    text-transform: uppercase;
    text-align: center;

    :lang(fr) {
      text-transform: none;
    }

    span {
      font-weight: bold;
    }
  }

  .ui-slider-legends,
  .p-slider-legends {
    position: absolute;
    z-index: 12;
    left: -4px;
    right: -5px;
    bottom: -28px;
    font-size: 14px;
    color: #666666;
    text-transform: uppercase;

    :lang(fr) {
      text-transform: none;
    }

    span {
      float: right;

      &:first-child {
        float: left;
      }
    }
  }
}