.customer-comment-row {
  overflow: auto;
  clear: both;
  border-bottom: 1px solid #e9ecef;
  padding: 20px 0;
  margin-right: 15px;
}

.customer-comment-container {
  overflow: auto;
  font-size: 12px;
  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    height: auto;
  }
  .customer-title-col {
    float: left;
    width: 100%;
    position: relative;
    padding: 0 0 0 50px;
  }

  .customer-title-label {
    position: absolute;
    left: 0;
    top: 0;
    .label-prefix {
      background-color: #058DC7;
      color: #fff;
      font-size: 14px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50%;
      text-align: center;
      vertical-align: bottom;
    }
  }

  .customer-title {
    // font-weight: bold;
    color: #333;
    padding-bottom: 5px;
    font-size: 14px;
  }

  .customer-title-kpi {
    color: #333;
    font-weight: bold;
  }

  .customer-comments {
    padding: 8px 0;
  }
  .label-question {
    float: left;
    width: 50%;
    text-align: left;

    .radius {
      float: left;
      margin-right: 4px;
      background-color: #ef207d;
      color: #fff;
      font-size: 11px;
      width: 25px;
      height: 15px;
      line-height: 15px;
      border-radius: 20px;
      text-align: center;
      vertical-align: bottom;
    }

    .radius2 {
      float: left;
      width: 25px;
      line-height: 17px;
      color: #969dab;
    }
  }

  .label-date {
    float: left;
    width: 50%;
    color: #969dab;
    text-align: right;
    padding: 0 5px 0 0;
  }
  .customer-bottom-row {
    clear: both;
    overflow: auto;
  }
}