.p-accordion {
  .ui-accordion-item, .p-accordion-item {
    float: left;
    width: 100%;
    clear: both;
    padding: 0;
    margin: 0 0 15px 0;
    border-radius: 5px !important;
    background: $white;
    box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.25);
    @include clearfix();
  }
  .p-accordion-header {
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;

    .knowledge-accordion &{
      border: 1px solid #e5e5e5;
      margin-top: 5px;
      background: white;

    }
    > a {
      position: relative;
      padding: 15px 40px 15px 20px;
      display: block;
      outline: none;
      .knowledge-accordion &{
        padding:12px 40px 12px 12px;
  
      }
    }
    .heading {
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      color: #4d565f;
      font-weight: 500;
      padding: 0;
      i {
        font-size: 15px;
        color: $clr-pri;
        margin: 0 10px 0 0;
        vertical-align: top;
      }
    }
  }
  .p-accordion-toggle-icon {
    position: absolute;
    right: 20px;
    top: 16px;
    cursor: pointer;
    font-size: 14px;

    .knowledge-accordion &{
   margin-top: -7px;
   top: 50%;

    }
  }
  .p-accordion-content {
    padding: 20px 40px;
    .knowledge-accordion &{
      border: 1px solid #e5e5e5;
      border-top: 0;
      padding: 20px;

    }
  }
}

.p-accordion-content-wrapper-overflown {
  overflow: hidden;
}

.ui-rtl .p-accordion {
  .p-accordion-header a {
    padding: 20px 30px 15px 50px !important;
  }
  .p-accordion-toggle-icon {
    left: 20px;
    right: auto;
  }
}

.sidebar--accordion {
  .p-accordion {
    .ui-accordion-item, .p-accordion-item {
      border-radius: 3px !important;
      margin-bottom: 9px;
    }
    .p-accordion-header {
      > a {
        padding: 7px;
        border-radius: 3px 3px 0 0;
      }
      .heading {
        font-size: 12px;
        color: #34495e;
        .initials {
          display: inline-block;
          width: 28px;
          height: 26px;
          font-size: 12px;
          line-height: 18px;
          color: $clr-pri;
          text-align: center;
          padding: 4px;
          border: 1px solid $clr-pri;
          border-radius: 3px;
          margin: 0 10px 0 0;
        }
        .count {
          color: #969dab;
        }
      }
      &.ui-state-active {
        > a {
          background: $clr-pri;
        }
        .heading {
          color: $white;
          .count {
            color: $white;
          }
          .initials {
            color: $white;
            border-color: $white;
          }
        }
        .p-accordion-toggle-icon {
          color: $white;
        }
      }
    }
    .p-accordion-toggle-icon {
      color: #bdc2c6;
      right: 15px;
      top: 14px;
    }
    .p-accordion-content {
      padding: 10px 50px;
      ul {
        li {
          padding: 5px 0;
          a {
            color: #34495e;
            .count {
              color: #969dab;
            }
            &:hover {
              color: $clr-pri;
            }
          }

          &.active{
            a{
              color: $clr-pri;
            }
          }
        }
      }
    }
  }
}
