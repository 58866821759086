// config.scss
// contains all the required configurations

%transition {
  transition: all 0.5s ease;
}
// -----------------------------------------------------------------------------------
// colors



// Grays
$white:#fff !default;
$whitelight: #f1f1f1 !default;
$black: #000 !default;
$grayLighter: #ccd3e4 !default;
$grayLight: #999999 !default;
$grayDark: #0d0d0f !default;
$clr-red: #ef207d;
$gray: #303030;
$grayDarker: #0a0a0c;
$clr-unassigned: #c2cbdc;


// Theme colors
$pink: #82bc00;
$blue: #002e6d !default;
$clr-gray: #eeeeee !default;
$clr-gray-d: #aaaaaa;
$blueDarker: #1d315f !default;
$blueDark: #333366 !default;
$blueLight: #f9fbff !default;
$blueLighter: #f0f4f8 !default;
$blueLightest: #e3e9f0 !default;
$marron: #441c6d;
$social: #204399;
$clr-amber: #f67812;
$border-clr: #f3f3f3 !default;
$clr-blue: #59b9ef;
$clr-darkgreen: #70c1b3 !default;
$bluishGray: #666699 !default;
$notificationBg: #fffbcb !default;
$notification: #f83925;
$color-legend-orange: #fca52b;
$color-legend-blue: #00a3c4;
$clr-iconred: #e35092;
$clr-icongreen: #11c97e;
// Sub colors
$borderLight: #e2e2e2 !default;
$border: #cccccc !default;
$bg_progress: #b7bcce !default;
//$bg_progress_hover		: $blue;

// Colors
$clr_copy: #4d565f;
$color_question: #303030;

$color_btnPrimary: $bluishGray;
$bg_btnPrimary: $blueLighter;
$color_btnPrimary_hover: $white;
$bg_btnPrimary_hover: $blueDarker;

$color_btn: $gray;
$bg_btn: $blueLight;
$color_btn_hover: $white;
$bg_btn_hover: $blueDarker;

$color_list: $grayLight;

$secondary: $white;

$clr-pri: #4ca1fe !default;
$clr-sec: #1a1a1a !default;
$clr-green: #9bc559;
$primary: $clr-pri;

$clr-positive: #88be58 !default;
$clr-negative: #fe596b !default;
$clr-none: #808080 !default;

// -----------------------------------------------------------------------------------
// Paths
$path_images: "^assets/styles/portal/build/assets/images" !default;
$path_fonts: "^assets/styles/portal/build/assets/fonts" !default;
$font-pri: "Roboto";

// -----------------------------------------------------------------------------------
// Images

// -----------------------------------------------------------------------------------
// Responsive
$iphone4s: "iphone4s";
$iphone5s: "iphone5s";
$iphone6: "iphone6";
$iphone6plus: "iphone6plus";
$xs: 480px; // Default Mobile-first approach
$sm: 768px; // Respond Mixin's Default Tablet size
$md: 1024px; // Medium Desktops and Laptops
$lg: 1200px; // Large Screens
$mobile_first_approach: true !default; // if set false, the min-width will change to max-width
$most_used_device: $iphone4s; // Values : iphone4s, iphone5s, iphone6, iphone plus

// ------------------------------------------------------------------------------------
// Containers
// overriding bootstrap defaults as per design
// $container-sm 			: 950px;
$container-sm: 750px;
$container-md: 980px;
$container-lg: 992px;

// .container {
//   @include container-fixed;

//   @media (min-width: $screen-sm-min) {
//     width: $container-sm;
//   }
//   @media (min-width: $screen-md-min) {
//     width: $container-md;
//   }
//   @media (min-width: $screen-lg-min) {
//     width: $container-lg;
//   }
// }


$zindex: (
  modal: 35,
  modalcontent: 10,
  overlay: 31,
  modalDropdown: 1032,
  dropdown: 18,
  widgetoverlay: 20,
  sidebar: 31,
  sidebaroverlay: 30,
  header: 29,
  filter: 10,
  qlmention: 25
);
