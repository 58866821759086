// notification template
.notification-template {
  // padding: 20px;

  .template__editable,
  .template__original {
    @include span(6);
    padding: 20px;
  }

  h4 {
    font-size: 12px;
    margin-bottom: 10px;
    color: #a9b4bd;
  }

  .template__subject {
    margin-bottom: 15px;
    input[type="text"] {
      width: 100%;
      padding: 10px;
      @include border-radius(3px);
      border: 1px solid #ebebeb;
    }
  }

  .invite__content {
    background: $white;
    padding: 30px;
    @include border-radius(3px);
    border: 1px solid #ebebeb;

    table {
      margin-bottom: 20px;
      border-collapse: separate;
      border-spacing: 15px;
      width: 100%;
      tr {
        border: 0;
      }
      td {
        text-align: left;
        font-size: 12px;
        color: #a9b4bd;
        font-weight: normal;
        border-bottom: 2px solid #ebebeb;

        &:first-child {
          color: #34495e;
        }
      }
    }

    h2 {
      margin-bottom: 10px;
      font-size: 12px;
      color: #34495e;
    }

    p {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .template__original {
    .invite__content {
      border: 0;
      padding: 0 0 0;
      background: transparent;
    }

    .template__subject {
      font-size: 12px;
      margin: 20px 0 52px;
      color: #34495e;
      span {
        display: inline-block;
        margin-right: 10px;
        color: #a9b4bd;
      }
    }
  }

  .template__actions {
    margin: 15px 0;
    //text-align: right;
    .btn {
      margin-right:10px;
    }

    p {
      margin: 20px 0;
      font-size: 12px;
      color: #34495e;
      span {
        display: inline-block;
        margin-right: 10px;
        color: #a9b4bd;
      }
    }
  }
}

.ui-table {
}
