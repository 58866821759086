.translation__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  @include transition();
  display: none;
  .btn {
    @include center();
  }
}

.edit--translation {
  border: 1px dashed #969dab;
  position: relative;

  &:hover {
    .translation__overlay {
      display: block;
    }
  }
}
