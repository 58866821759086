.ui-chkbox, .p-checkbox  {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: $checkboxWidth;
  height: $checkboxHeight;
  cursor: pointer;

  .ui-chkbox-box, .p-checkbox-box {
    border: $inputBorder;
    background-color: $inputBgColor;
    width: $checkboxWidth;
    height: $checkboxHeight;
    line-height: 1.5em;
    text-align: center;
    @include border-radius($borderRadius);
    @include transition($checkboxTransition);

    &:not(.ui-state-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    &.ui-state-focus {
      border-color: $checkboxFocusBorderColor;
      background-color: $checkboxFocusBgColor;
      color: $checkboxFocusTextColor;
      @include shadow($checkboxFocusShadow);
    }

    &.ui-state-active, &.p-highlight {
      border-color: darken($primaryColor, 20%);
      background-color: $checkboxActiveBgColor;
      color: $checkboxActiveTextColor;

      &:not(.ui-state-disabled):hover {
        border-color: $checkboxActiveHoverBorderColor;
        background-color: $checkboxActiveHoverBgColor;
        color: $checkboxActiveHoverTextColor;
      }

      &.ui-state-focus {
        border-color: $checkboxActiveFocusBorderColor;
        background-color: $checkboxActiveFocusBgColor;
        color: $checkboxActiveFocusTextColor;
      }
    }

    .ui-chkbox-icon, .p-checkbox-icon {
      overflow: hidden;
      position: relative;
      font-size: $fontSize;

      .ui-table & {
        font-weight: 400;
        font-family: "Font Awesome 5 Pro";
      }
    }
  }

  &.secondary-checkbox {
    position: relative;
    .ui-chkbox-box, .p-checkbox-box {
      @include border-radius($borderRadius);
      @include transition($checkboxTransition);

      &:not(.ui-state-disabled):hover {
        border-color: $checkboxActiveHoverBorderColor;
        .ui-chkbox-icon, .p-checkbox-icon {
          background: $checkboxActiveHoverBorderColor;
        }
      }

      &.ui-state-focus {
        background-color: $white;
      }

      &.ui-state-active, &.p-highlight {
        background-color: $white;

        &:not(.ui-state-disabled):hover {
          background-color: $white;
        }

        &.ui-state-focus {
          background-color: $white;
        }
        .ui-chkbox-icon, .p-checkbox-icon {
          position: absolute;
          left: 3px;
          top: 3px;
          width: 10px;
          height: 10px;
          background: $checkboxActiveBgColor;
          @include transition($checkboxTransition);
        }
      }

      .ui-chkbox-icon, .p-checkbox-icon {
        &:before {
          content: '' !important;
        }
      }
    }
  }
}

.ui-checkbox-label, .p-checkbox-label {
  margin: 0 0 0 $inlineSpacing;
  vertical-align: middle;
  cursor: pointer;
}

.ui-chkbox-absolute, .ui-checkbox-absolute, .p-checkbox-absolute {
  ui-chkbox, p-checkbox {
    display: block;
    position: relative;
    padding: 0 0 0 23px;
  }
  .ui-chkbox, .p-checkbox {
    position: absolute;
    left: 0;
    right: 0;
  }
  .ui-chkbox-label, .p-checkbox-label {
    margin: 0;
  }
}
