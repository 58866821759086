.ui-inputswitch, .p-inputswitch {
  font-size: 10px;
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.75em;
  vertical-align: middle;

  &.ui-inputswitch-with-label, &.p-inputswitch-with-label{
    width: auto;
    height: auto;

    label{
      float: left;
      line-height: 20px;
      color: #969dab;
      cursor: pointer;
      font-size: 12px;
      &.active{
        color: #212229;
      }
    }

    .switch{
      float: left;
      margin:0 10px;
      width:30px;
      height:18px;
      position: relative;
    }
  }
}

.ui-inputswitch-slider, .p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
  &:before {
    position: absolute;
    content: "";
    height: 1.25em;
    width: 1.25em;
    left: 0.25em;
    bottom: 0.25em;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}

.ui-inputswitch-checked .ui-inputswitch-slider:before, .p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}

.ui-inputswitch.ui-state-disabled .ui-inputswitch-slider,
.ui-inputswitch-readonly .ui-inputswitch-slider,
.p-inputswitch.p-state-disabled .p-inputswitch-slider,
.p-inputswitch-readonly .p-inputswitch-slider {
  cursor: default;
}

.ui-inputswitch, .p-inputswitch {
  width: $inputSwitchWidth;
  height: $inputSwitchHeight;

  .ui-inputswitch-slider, .p-inputswitch-slider {
    @include transition($inputSwitchTransition);
    border-radius: $inputSwitchBorderRadius;
    background: $inputSwitchSliderOffBgColor;

    &:before {
      background-color: $inputSwitchHandleOffBgColor;
      height: 1.25em;
      width: 1.25em;
      left: 0.25em;
      top: auto;
      bottom: 0.25em;
      border-radius: 50%;
      -webkit-transition: $transitionDuration;
      transition: $transitionDuration;
    }
  }

  &.ui-inputswitch-checked, &.p-inputswitch-checked {
    .ui-inputswitch-slider:before, .p-inputswitch-slider:before {
      -webkit-transform: translateX(1.25em);
      -ms-transform: translateX(1.25em);
      transform: translateX(1.25em);
    }
  }

  &.ui-inputswitch-focus, &.p-inputswitch-focus {
    .ui-inputswitch-slider, .p-inputswitch-slider {
      background: $inputSwitchSliderOffFocusBgColor;
    }
  }

  &:not(.ui-state-disabled):hover, &:not(.p-state-disabled):hover {
    .ui-inputswitch-slider, .p-inputswitch-slider {
      background-color: $inputSwitchSliderOffHoverBgColor;
    }
  }

  &.ui-inputswitch-checked, &.p-inputswitch-checked {
    .ui-inputswitch-slider, .p-inputswitch-slider {
      background-color: $inputSwitchSliderOnBgColor;
    }

    &:not(.ui-state-disabled):hover, &:not(.p-state-disabled):hover {
      .ui-inputswitch-slider, .p-inputswitch-slider {
        background-color: $inputSwitchSliderOnHoverBgColor;
      }
    }

    &.ui-inputswitch-focus, &.p-inputswitch-focus {
      .ui-inputswitch-slider, .p-inputswitch-slider {
        background: $inputSwitchSliderOnFocusBgColor;
      }
    }
  }
}

// Primeng Updated classes
// .p-inputswitch {
//   width: 3rem;
//   height: 1.75rem;
// }
// .p-inputswitch .p-inputswitch-slider {
//   background: #ffffff;
//   transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
//   border-radius: 30px;
// }
// .p-inputswitch .p-inputswitch-slider:before {
//   background: #605e5c;
//   width: 1.25rem;
//   height: 1.25rem;
//   left: 0.25rem;
//   margin-top: -0.625rem;
//   border-radius: 50%;
//   transition-duration: 0.2s;
// }
// .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
//   transform: translateX(1.25rem);
// }
// .p-inputswitch.p-focus .p-inputswitch-slider {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 1px #605e5c;
// }
// .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
//   background: #ffffff;
// }
// .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
//   background: #0078d4;
// }
// .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
//   background: #ffffff;
// }
// .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
//   background: #005a9e;
// }

// p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
//   border-color: #a4252c;
// }
