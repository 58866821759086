// .page-commentlist {
//   .widget--commentlist {
//     min-height: 350px;
//   }
//   .table {
//     table {
//       td,
//       th {
//         &:last-child {
//           // width:300px !important;
//         }
//       }
//     }
//     tbody {
//       tr {
//         &.expanded {
//           td {
//             padding: 0;
//             position: relative;
//             font-weight: normal;
//             &:before {
//               content: "";
//               width: 4px;
//               height: 100%;
//               background: lighten($clr-pri, 50%);
//               position: absolute;
//               left: 0;
//               top: 0;
//             }
//           }
//         }
//       }
//     }
//   }
//   #GTPanelItem,
//   #GTPanelItemContent {
//     overflow: visible !important;
//   }
// }

// .breadcrumbs-commentlist {
//   float: left;
//   width: 100%;
//   margin-bottom: 6px;
//   ul{
//     float: left;
//     margin-top: 3px;
//     width: 80%;
//   }
//   .icon {
//     padding-left: 20px;
//     position: relative;
//     &:after {
//       position: absolute;
//       left: 0;
//       font-family: "Font Awesome 5 Pro";
//       content: "=";
//       font-size: 12px;
//       top: 50%;
//       margin-top: -7px;
//     }
//     &.neutral {
//       &:after {
//         color: $clr-amber;
//         content: "\f52c";
//       }
//     }
//     &.positive {
//       &:after {
//         content: "\f164";
//         color: $clr-green;
//       }
//     }
//     &.negative {
//       &:after {
//         content: "\f165";
//         color: $clr-red;
//       }
//     }
//     &.unassigned {
//       &:after {
//         content: "\f05e";
//         color: $clr-unassigned;
//       }
//     }
//   }
//   li {
//     display: inline;
//     vertical-align: middle;
//     white-space: normal;
//     font-size: 12px;
//     font-weight: 400;
//     color: #34495e;
//     @include susy-breakpoint($xs, $susy, ".no-mq") {
//       font-size: 12px;
//       // font-weight: normal;
//     }
//     &:before {
//       color: #969dab;
//       position: relative;
//       top: 0;
//       font-size: 12px;
//       margin: 0 5px;
//       content: "\f105";
//       font-family: "Font Awesome 5 Pro";
//     }
//     &:first-child {
//       &:before {
//         display: none;
//       }
//     }
//     &.active {
//       a {
//         color: #969dab;
//       }
//     }
//   }
// }

// //$bg-clr:#f9f9f9;
// $border-clr: #e1e1e1;
// $notification: #5b6c7c;
// .expanded__content {
//   @include clearfix();
//   float: left;
//   width: 100%;
//   padding: 15px;
//   max-width: calc(100vw - 150px);
//   .content__left {
//     @include span(6 no-gutters);
//     padding: 0 30px 0 0;
//   }
//   .content__right {
//     @include span(6 no-gutters);
//     padding: 0 0 200px 30px;
//     border-left: 2px solid #ebebeb;
//   }
//   &.is--admin {
//     .content__left {
//       width: 100%;
//     }
//     .testimonial {
//       min-height: auto;
//     }
//     .translation {
//       .translation__content {
//         width: 100%;
//       }
//     }
//   }
// }

// .testimonial {
//   float: left;
//   width: 100%;
//   margin: 15px 0;
//   padding: 15px 0;
//   @include clearfix();
//   position: relative;
//   border-top: 2px solid #ebebeb;
//   border-bottom: 2px solid #ebebeb;
//   min-height: 90px;
//   h3 {
//     font-weight: 400;
//     font-size: 14px;
//     color: #34495e;
//     padding: 0 0 0 30px;
//     margin-bottom: 10px;
//     small {
//       display: block;
//       font-size: 12px;
//       color: #a9b4bd;
//       margin-top: 3px;
//     }
//   }
//   p {
//     //font-style: italic;
//     color: #34495e;
//     line-height: 18px;
//     position: relative;
//     padding: 5px 0 0 30px;
//     &:before {
//       position: absolute;
//       left: 0;
//       color: #a9b4bd;
//       top: 0;
//       font-family: "Font Awesome 5 Pro";
//       font-size: 16px;
//       content: "\f10d";
//     }
//   }
//   .testimonial__footer {
//     padding: 15px 0 0 30px;
//   }
// }

// .translation {
//   float: left;
//   padding: 0;
//   @include clearfix();
//   width: 100%;
//   position: relative;
//   h2 {
//     margin-bottom: 10px;
//     color: #34495e;
//     font-size: 12px;
//     font-weight: normal;
//   }
//   p {
//     position: relative;
//     color: #34495e;
//     font-size: 12px;
//     line-height: 18px;
//     white-space: pre-wrap;
//     padding: 10px 22px 10px 0;
//     margin-bottom: 10px;
//     &.translation__notification {
//       color: $notification;
//     }
//   }
//   .translation__content {
//     float: left;
//     width: 50%;
//     padding: 0;
//   }
//   .translation__form {
//     float: right;
//     width: 50%;
//     .form__label {
//       color: #a9b4bd;
//       display: block;
//       font-size: 11px;
//       margin-bottom: 10px;
//     }
//     textarea {
//       width: 100%;
//       padding: 10px;
//       font-family: $font-pri;
//       @include border-radius(3px);
//       border: 1px solid #d0d7dd;
//       font-size: 12px;
//       height: 105px;
//       color: #34495e;
//       line-height: 18px;
//       &:focus {
//         -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
//         -moz-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
//         box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
//       }
//     }
//   }
// }

// $cat-border: #e7e7e7;
// $media-clr: #d5d5d5;
// //
// .category {
//   float: left;
//   @include clearfix();
//   width: 100%;
//   position: relative;

//   .category-top{
//     display: flex;
//     justify-content: space-between;
//     .intent__content,
//     .sentiment__content,
//     .emotion__content{
//       flex-grow: 1;
//       gap: 10px;
//       flex-basis: 0;
//     }
//   }

//   .category__col {
//     // @include span(2 no-gutter);
//     // &:first-child {
//     //   @include span(5 no-gutter);
//     //   padding-right: 20px;
//     //   // background: red;
//     // }
//     // &:nth-child(2) {
//     //   @include span(4 no-gutter);
//     //   // background: #000;
//     // }
//     // &:last-child {
//     //   @include span(1 no-gutter);
//     // }
//     h2 {
//       font-size: 11px;
//       text-transform: uppercase;
//       color: #a9b4bd;
//       font-weight: 500;
//       margin-bottom: 8px;
//       :lang(fr) {
//         text-transform: none;
//       }
//     }

//     .icon-col {
//       font-size: 14px;
//       color: #666;
//     }
//     > i {
//       cursor: pointer;
//     }
//   }
//   .category__content {
//     .category__field {
//       position: relative;
//       word-break: break-word;
//       .category_link {
//         display: block;
//         border: 1px solid #d6dade;
//         padding: 8px 18px 8px 8px;
//         font-size: 12px;
//         color: #34495e;
//         white-space: nowrap;
//         width: 150px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         max-width: 100%;
//         &:after {
//           font-size: 12px;
//           color: #b3b3b3;
//           font-family: "Font Awesome 5 Pro";
//           content: "\f078";
//           position: absolute;
//           top: 9px;
//           right: 10px;
//         }
//         &:hover,
//         &:active {
//           color: #808690;
//           border-color: #808690;
//           &:after {
//             color: #808690;
//           }
//         }
//         &.active {
//           color: #808690;
//           border-color: #808690;
//           &:after {
//             content: "\f00d";
//             color: #808690;
//           }
//         }
//       }
//     }
//   }
//   .remove-category {
//     padding-top: 22px;
//     a {
//       color: $clr-red;
//       font-size: 16px;
//       &.disabled {
//         color: $media-clr;
//         opacity: 0.9;
//         cursor: default;
//       }
//     }
//   }
// }

// .sentiment__media,
// .intent__content,
// .emotion__content   {
//   li {
//     display: flex;
//     align-items: center;
//     text-transform: capitalize;
//     .rounded-icon {
//       opacity: 0.25;
//       margin-right: 5px;
//       &.active {
//         opacity: 1;
//       }
//     }
//     &:hover {
//       cursor: pointer;
//       .rounded-icon {
//         opacity: 0.75;

//       }
//     }
//   }
// }



// .add-category {
//   float: right;
//   width: 100%;
//   text-align: right;
//   border-top: 2px solid #ebebeb;
//   padding: 10px 0 0;
//   .disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// }

// .expanded__control {
//   float: left;
//   @include clearfix();
//   width: 100%;
//   text-align: right;
//   background: $white;
//   padding: 12px 20px;
//   max-width: calc(100vw - 150px);
//   border-top: 2px solid #ebebeb;
//   .btn {
//     margin-left: 5px;
//   }
// }

// .last-updated-msg {
//   float: left;
//   display: inline-block;
//   padding: 5px;
//   margin: 4px 0 0 0;
//   text-align: left;
//   font-size: 12px;
//   color: #34495e;
//   i {
//     margin-right: 5px;
//     color: $clr-blue;
//   }
//   .modal__header & {
//     float: right;
//     padding: 0 8px;
//     margin: 8px 8px 5px;
//     color: #969dab;
//     border-right: 1px solid #969dab;
//   }
// }

// .category__overlay {
//   position: absolute;
//   background: $white;
//   width: 250px;
//   //  box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.21);
//   box-shadow: 0px 0px 6.48px 1.52px rgba(146, 146, 146, 0.32);
//   // top: 37px;
//   padding: 0;
//   text-align: left;
//   left: 0;
//   min-height: 200px;
//   z-index: map-get($zindex, dropdown);
//   z-index: 2;
//   opacity: 0;
//   visibility: hidden;
//   transform: translateY(20px);
//   @include transition();
//   border-radius: 0 0 3px 3px;
//   &.active {
//     opacity: 1;
//     visibility: visible;
//     transform: translateY(0);
//   }
//   .overlay__header {
//     background: #eff0f2;
//     border-bottom: 1px solid #e5e5e5;
//     padding: 10px 15px;
//     li {
//       display: inline;
//       vertical-align: middle;
//       font-size: 11px;
//       font-weight: 500;
//       white-space: normal;
//       &:before {
//         content: "/";
//         display: inline-block;
//         margin: 0 3px 0 3px;
//         color: #a9b4bd;
//         position: relative;
//         top: 0;
//       }
//       &:first-child {
//         &:before {
//           display: none;
//         }
//       }
//       a {
//         color: #a9b4bd;
//         &:hover {
//           color: $clr-pri;
//         }
//       }
//       &.active {
//         a {
//           color: $clr-pri;
//         }
//       }
//     }
//   }
//   .overlay__content {
//     padding: 10px;
//     .mCSB_scrollTools {
//       top: 5px;
//       padding-bottom: 5px;
//       right: 0;
//     }
//     ul {
//       max-height: 165px;
//       overflow-y: auto;
//       li {
//         display: block;
//         a {
//           display: block;
//           padding: 12px 15px;
//           font-size: 13px;
//           border-bottom: 1px solid #ebebeb;
//           position: relative;
//           cursor: pointer;
//           color: #b3b3b3;
//           &:before {
//             content: "\f105";
//             font-family: "Font Awesome 5 Pro";
//             position: absolute;
//             right: 8px;
//             color: #b8b8b8;
//             font-size: 16px;
//             top: 10px;
//           }
//           &:hover {
//             color: #666666;
//             &:before {
//               color: #666666;
//             }
//           }
//         }
//         /* &.active {

//           a {
//             color: #666;
//             background: #f3f6f9;
//             &:before {
//               color: #666;
//             }
//           }

//         }*/
//         &.selected {
//           a {
//             color: #666;
//             //  background: #f3f6f9;
//             &:before {
//               color: #666;
//             }
//           }
//         }
//         &.no--child {
//           a {
//             &:before {
//               display: none;
//             }
//           }
//         }
//       }
//     }
//   }
//   .overlay__footer {
//     float: left;
//     width: 100%;
//     //  text-align: right;
//     clear: both;
//     padding: 10px;
//     //  background: #f4f5f9;
//     //  border-top: 1px solid #ebebeb;
//     border-radius: 0 0 3px 3px;
//     a {
//       display: block;
//     }
//   }
// }

// .comment-portal {
//   float: right;
//   width: 150px;
//   max-width: 20%;
// }

// .segment {
//   float: left;
//   width: 100%;
//   margin: 20px 0;
//   h3 {
//     font-size: 11px;
//     text-transform: uppercase;
//     color: #a9b4bd;
//     font-weight: 500;
//     margin-bottom: 8px;
//     :lang(fr) {
//       text-transform: none;
//     }
//   }
//   .segment__area {
//     width: 100%;
//     cursor: pointer;
//     border: 1px solid #e1e1e1;
//     border-radius: 2px;
//     min-height: 50px;
//     position: relative;
//     padding: 12px 60px 12px 12px;
//     p {
//       font-size: 12px;
//       line-height: 18px;
//       color: #a9b4bd;
//       white-space: pre-wrap;
//     }
//     textarea {
//       font-size: inherit;
//       color: inherit;
//       font-family: inherit;
//       border: none;
//       outline: none;
//       resize: none;
//     }
//   }
//   .segment__control {
//     position: absolute;
//     top: 5px;
//     text-align: right;
//     right: 5px;
//     a {
//       display: none;
//       margin: 0 5px;
//       font-size: 14px;
//       &.edit {
//         display: inline-block;
//       }
//     }
//   }
//   &.active {
//     p {
//       color: #34495e;
//     }
//     .segment__control {
//       a {
//         display: inline-block;
//         &.edit {
//           display: none;
//         }
//         &.close {
//           color: $clr-red;
//         }
//         &.confirm {
//           color: $clr-green;
//         }
//       }
//     }
//     .segment__area {
//       background: $white;
//       cursor: default;
//       border: 2px dashed #e1e1e1;
//     }
//   }
// }

// .segment-error {
//   float: left;
//   clear: both;
//   width: 100%;
//   color: $clr-red;
//   padding-top: 10px;
//   font-size: 12px;
// }

// .confidence {
//   float: left;
//   padding: 10px 5px 15px;
//   width: 100%;
//   border-bottom: 1px solid $cat-border;
//   li {
//     margin-right: 10px;
//     float: left;
//     label {
//       display: block;
//       color: $notification;
//       margin-bottom: 5px;
//     }
//   }
// }

// ///rounded-icons
// .rounded-icon {
//   width: 30px;
//   line-height: 30px;
//   font-size: 14px;
//   border-radius: 100%;
//   display: inline-block;
//   text-align: center;
//   height: 30px;
//   position: relative;
//   i {
//     vertical-align: middle;
//     @include center();
//   }
//   &.active {
//   }
//   &.negative {
//     border: 1px solid $clr-red;
//     color: $clr-red;
//     background: lighten($clr-red, 40%);
//   }
//   &.positive {
//     border: 1px solid $clr-green;
//     color: $clr-green;
//     background: lighten($clr-green, 62%);
//   }
//   &.neutral {
//     border: 1px solid $clr-amber;
//     color: $clr-amber;
//     background: lighten($clr-amber, 40%);
//   }
//   &.primary {
//     border: 1px solid $clr-blue;
//     color: $clr-blue;
//     background: lighten($clr-blue, 30%);
//   }
//   &.unassigned {
//     border: 1px solid $clr-unassigned;
//     color: $clr-unassigned;
//     background: lighten($clr-unassigned, 15%);
//   }
// }

// .emotion-icon {
//   font-size: 22px;
//   display: inline-block;
//   text-align: center;
//   position: relative;
//   &.surprise {
//     color: #27d4d4;
//   }
//   &.negative {
//     color: $clr-red;
//   }
//   &.positive {
//     color: $clr-green;
//   }
//   &.neutral {
//     color: $clr-amber;
//   }
//   &.primary {
//     color: $clr-blue;
//   }
//   &.unassigned {
//     color: $clr-unassigned;
//   }
// }

// .tags-list {
//   margin-top: 3px;
//   li {
//     display: inline-block;
//     padding: 5px 8px;
//     margin-right: 5px;
//     border-radius: 3px;
//     background: $white;
//     position: relative;
//     border: 1px solid #d6dade;
//     span {
//       color: #969dab;
//       margin-right: 3px;
//       display: inline-block;
//     }
//     &.with-info {
//       padding-right: 35px;
//     }
//     .tooltip {
//       position: absolute;
//       top: 0;
//       border-left: 1px solid #d6dade;
//       right: 0;
//       padding: 5px 7px;
//       color: #666;
//       margin-right: 0;
//       background: #e9ecef;
//       bottom: 0;
//     }
//   }
// }

// //sentiment emojis
// .sentiment-emojis {
//   float: left;
//   width: 100%;
//   margin: 0 0 20px;
//   .emojis__list {
//     padding: 0;
//     text-align: center;
//     li {
//       display: inline-block;
//       width: 20%;
//       padding: 10px;
//       border: 1px solid #ebebeb;
//       border-left: 0;
//       &:first-child {
//         border-left: 1px solid #ebebeb;
//       }
//       div {
//         opacity: 0.3;
//       }
//       &.active {
//         background: #f9f6f6;
//         div {
//           opacity: 1;
//         }
//       }
//       i {
//         font-size: 25px;
//       }
//       h2 {
//         margin: 5px 0;
//         font-weight: 500;
//       }
//     }
//   }
//   h3 {
//     font-size: 11px;
//     text-transform: uppercase;
//     color: #a9b4bd;
//     font-weight: 500;
//     margin-bottom: 8px;
//   }
// }

// .clr-happy {
//   color: #64cb64;
// }

// .clr-angry {
//   color: #fe575f;
// }

// .clr-sad {
//   color: red;
// }

// .clr-bored {
//   color: #939393;
// }

// .clr-frustration {
//   color: #8a1016;
// }

// .clr-fear {
//   color: #9932cc;
// }

// .clr-trust {
//   color: #0000ff;
// }

// .clr-joy {
//   color: #007bff;
// }

// .clr-surprise {
//   color: #27d4d4;
// }

// .clr-digust {
//   color: #32cd32;
// }

// .audio-sentiments {
//   float: left;
//   padding: 0;
//   margin-top: 15px;
//   @include clearfix();
//   width: 100%;
//   padding-top: 15px;
//   border-top: 2px solid #ebebeb;
//   position: relative;
//   .page-speech-classification & {
//     padding: 10px 30px;
//     border: 0;
//   }
//   h2 {
//     margin-bottom: 25px;
//     color: #34495e;
//     font-size: 12px;
//     font-weight: normal;
//   }
//   .sentiment__bar {
//     padding: 0;
//     text-align: center;
//     li {
//       display: inline-block;
//       width: 11.11111111111111%;
//       position: relative;
//       padding-bottom: 25px;
//       &:first-child {
//         &:before {
//           content: "";
//           height: 18px;
//           top: -4px;
//           width: 1px;
//           position: absolute;
//           left: 0;
//           background: #b2b2b2;
//         }
//       }
//       &:after {
//         content: "";
//         height: 18px;
//         top: -4px;
//         width: 1px;
//         position: absolute;
//         right: 0;
//         background: #b2b2b2;
//       }
//       .time-first {
//         position: absolute;
//         text-align: center;
//         bottom: 0;
//         left: -3px;
//         color: #a1a1a1;
//       }
//       .time {
//         position: absolute;
//         text-align: center;
//         bottom: 0;
//         right: -10px;
//         color: #a1a1a1;
//       }
//       &.alternate {
//         .time {
//           bottom: auto;
//           top: -20px;
//         }
//         // background: red;
//       }
//       .bar {
//         width: 100%;
//         height: 10px;
//       }
//     }
//   }
//   .sentiment__emojis {
//     padding: 5px 0 0;
//     text-align: center;
//     li {
//       display: inline-block;
//       width: 11.11111111111111%;
//       i {
//         font-size: 20px;
//       }
//       h3 {
//         margin: 5px 0 0;
//         font-size: 12px;
//         color: #a1a1a1;
//         font-weight: 400;
//       }
//       &:last-child {
//         h3 {
//           margin-left: -18px;
//         }
//       }
//       &:first-child {
//         h3 {
//           margin-left: 0;
//         }
//       }
//     }
//   }
// }

// //bg
// .clr-happy-background {
//   background: #64cb64;
// }

// .clr-angry-background {
//   background: #fe575f;
// }

// .clr-anger-background {
//   background: #fe575f;
// }

// .clr-sadness-background {
//   background: red;
// }

// .clr-sad-background {
//   background: red;
// }

// .clr-joy-background {
//   background: #007bff;
// }

// .clr-happy-background {
//   background: #64cb64;
// }

// .clr-pleasant-background {
//   background: #64cb64;
// }

// .clr-fear-background {
//   background: #9932cc;
// }

// .clr-surprise-background {
//   background: #27d4d4;
// }

// .clr-excited-background {
//   background: #27d4d4;
// }

// .clr-neutral-background {
//   background: $clr-amber;
// }

// .clr-trust-background {
//   background: #0000ff;
// }

// .clr-digust-background {
//   background: #32cd32;
// }

// .clr-frustration-background {
//   background: #8a1016;
// }

// .clr-default-background {
//   background: #939393;
// }

// .play-back {
//   float: left;
//   width: 100%;
//   margin-top: 10px;
//   h2 {
//     margin-bottom: 20px;
//     color: #34495e;
//     font-size: 12px;
//     font-weight: normal;
//   }
// }

// .icon-sent {
//   font-size: 20px;
// }



// .comments-sentiment {
//   float: left;
//   width: 100%;
//   margin: 0;
//   padding: 0 0 20px;
//   .sentiment__box {
//     float: left;
//     text-align: left;
//     padding: 5px 8px;
//     margin-right: 10px;
//     line-height: 15px;
//     border-radius: 3px;
//     border: 1px solid $clr-pri;
//     color: #2e2e2e;
//     background: lighten($clr-pri, 75%);
//     h4 {
//       font-size: 12px;
//       font-weight: normal;
//       color: black;
//       line-height: 18px;
//     }
//     i {
//       font-size: 16px;
//       color: $clr-pri;
//       margin-left: 5px;
//     }

//     &.negative,
//     &.sad,
//     &.angry {
//       border: 1px solid $clr-red;
//       background: lighten($clr-red, 44%);
//       h5 {
//         color: $clr-red;
//       }
//       i {
//         color: $clr-red;
//       }
//     }
//     &.positive,
//     &.praise,
//     &.happy {
//       border: 1px solid $clr-green;
//       background: lighten($clr-green, 60%);
//       h5 {
//         color: $clr-green;
//       }
//       i {
//         color: $clr-green;
//       }
//     }
//     &.unassigned,
//     &.neutral {
//       border: 1px solid #fd7e14;
//       background: lighten(#fff1e5, 60%);
//       h5 {
//         color: #fd7e14;
//       }
//       i {
//         color: #fd7e14;
//       }
//     }
//     &.complain {
//       border: 1px solid $clr-amber;
//       background: lighten($clr-amber, 44%);
//       h5 {
//         color: $clr-amber;
//       }
//       i {
//         color: $clr-amber;
//       }
//     }

//     &.risk,
//     &.acceptance {
//       border: 1px solid #fd7e14;
//       background: lighten(#fd7e14, 44%);
//       h5 {
//         color: #fd7e14;
//       }
//       i {
//         color: #fd7e14;
//       }
//     }
//   }
// }



// .overall-sentiments-icon{
//   float:right;

//   span{
//     font-size: 16px;
//     padding: 0 5px;

//     i{
//       &.negative,
//   &.sad,
//   &.complain,
//   &.churnrisk,
//   &.risk,
//   &.suggestion,
//   &.recommendation
//   &.angry {
//    // border: 1px solid $clr-red;
//     color: $clr-red;
//    // background: lighten($clr-red, 45%);
//   }
//   &.acceptance,
//   &.positive,
//         &.praise,
//         &.acceptance, &.anticipation
//         &.happy {
//    // border: 1px solid $clr-green; 
//     color: $clr-green;
//    // background: lighten($clr-green, 62%);
//   }

//   &.neutral {
//    // border: 1px solid $clr-amber;
//     color: $clr-amber;
//    // background: lighten($clr-amber, 40%);
//   }

//   &.primary {
//   //  border: 1px solid $clr-blue;
//     color: $clr-blue;
//   //  background: lighten($clr-blue, 30%);
//   }

//   &.unassigned {
//   //  border: 1px solid $clr-unassigned;
//     color: $clr-unassigned;
//   //  background: lighten($clr-unassigned, 15%);
//   }
//   &.happy {
//   //  border: 1px solid #64cb64;
//     color: #64cb64;
//   //  background: lighten(#64cb64, 40%);
//   }
//   &.excited {
//     //border: 1px solid #9932cc;
//     color: #9932cc;
//   //  background: lighten(#9932cc, 40%);
//   }
//   &.angry {
//  //   border: 1px solid #fe575f;
//     color: #fe575f;
//   //  background: lighten(#fe575f, 30%);
//   }
//   &.sad {
//   //  border: 1px solid red;
//     color: red;
//   //  background: lighten(red, 45%);
//   }

//   &.questions {
//   //  border: 1px solid #fd7e14;
//     color: #fd7e14;
//   //  background: lighten(#fd7e14, 40%);
//   }
//   &.complain {
//  //   border: 1px solid #a25016;
//     color: #a25016;
//   //  background: lighten(#a25016, 55%);
//   }
//   &.suggestion {
//    // border: 1px solid #17a2b8;
//     color: #17a2b8;
//   //  background: lighten(#17a2b8, 55%);
//   }
//   &.recommendation {
//  //   border: 1px solid #4ca1fe;
//     color: #4ca1fe;
//  //   background: lighten(#4ca1fe, 30%);
//   }
//   &.appreciation {
//   //  border: 1px solid #3bb001;
//     color: #3bb001;
//  //   background: lighten(#3bb001, 60%);
//   }
//   &.leaving {
//   //  border: 1px solid $clr-red;
//     color: $clr-red;
//    // background: lighten($clr-red, 40%);
//   }
//     }
//   }
// }




// .sentiment__media__icon {
//   li {
//     display: inline-block;
//     margin: 0 5px;
//     @extend %transition;
//     .rounded-icon {
//      opacity: 0.2;
//       @extend %transition;
//       &.active {
//         opacity: 1;
//       }
//     }

//     &:hover {
//       cursor: pointer;
//       .rounded-icon {
//         opacity: 1;
//       }

//     }

//   }
// }


.raw-detail-page {
  padding: 10px;
  font-size: 12px;
}

.raw-seperator {
  @include clearfix();
  float: left;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
 // border-bottom: 2px solid #ebebeb;
}

.raw-with-border{
  border-top: 2px solid #ebebeb;
}


.review-detail-kpi {
  display: flex;
  gap: 10px;

  .item {
    padding: 14px;
    background: #f6f6f6;
    flex: 1;
    border-radius: 5px;
    border: 1px solid #ebebeb;


    .kpi-icon {
      color: $clr-pri;
    }

    .item__inner {
      display: flex;
      gap: 10px;
     

      // .comment-icon{
      //   flex-grow: 1;
      // }

      // .comment-detail{
      //   flex-grow: 1;
      // }
    
    }

    p {
      font-size: 14px;
      color: #404040;
      padding: 3px 0 8px;
      font-weight: 500;

      .source-icon {
        max-height: 15px;

      }


      &.title {
        color: #9ba2aa;
        font-size: 12px;

      }
    }


  }
}




.comment-review-detail {
  padding: 14px;


  .comment-icon {
    color: $clr-pri;
  }

  .item__inner {
    float: left;
    width: 100%;

    .comment-icon{
      float: left;
      margin-right: 10px;
    }
  }

  p {
    // font-size: 12px;
    // line-height: 18px;
    // color: #4f545a;
    // padding: 0 0 10px;


    &.title {
      color: #9ba2aa;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 0;
    }
  }


}



.raw-detail-title {
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  margin-bottom: 10px;
  text-align: left;
}


.spinner-hand {
  cursor: pointer;
}



.testimonial-review-detail {
  float: left;
  width: 100%;
  margin: 5px 0 0;
  padding: 15px 0;
  @include clearfix();
  position: relative;
  //  border-top: 2px solid #ebebeb;
 // border-bottom: 2px solid #ebebeb;
  min-height: 90px;

  h3 {
    font-weight: 400;
    font-size: 14px;
    color: #34495e;
    padding: 0 0 0 30px;
    margin-bottom: 10px;

    small {
      display: block;
      font-size: 12px;
      color: #a9b4bd;
      margin-top: 3px;
    }
  }

  p {
    //font-style: italic;
    color: #34495e;
    line-height: 18px;
    position: relative;
    padding: 5px 0 0 30px;

    &:before {
      position: absolute;
      left: 0;
      color: #a9b4bd;
      top: 0;
      font-family: "Font Awesome 5 Pro";
      font-size: 16px;
      content: "\f10d";
    }
  }

  .testimonial__footer {
    padding: 15px 0 0 30px;
  }

  &.no--content{
    p {
      font-style: italic;
      color: #856404;
      line-height: 18px;
      position: relative;
      padding: 3px 0 0 20px;
  
      &:before {
        position: absolute;
        left: 0;
        color: #856404;
        font-style: normal;
        top: 0;
        font-weight: 300;
        font-family: "Font Awesome 5 Pro";
        font-size: 16px;
        content: "\f129";
      }
    }
  }
}