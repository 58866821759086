//table styles
.table-reporting {
    width: 100%;
    max-width: 100%;

    th,
    td {
        padding: 10px;
        font-size: 13px;
        text-align: center;
        font-weight: 400;

        &:first-child {
            text-align: left;
        }
    }

    tr {
        border-bottom: 1px solid #e9ecef;

        &:last-child {
            border-bottom: none;
        }
    }

    &.table--border {

        th,
        td {
            border: 1px solid #e9ecef;
        }
    }
}


/*
.ui-table table {
  table-layout: auto !important;
}

.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tbody > tr > td {
  white-space: nowrap;
}
*/

.sales-question {
    width: 100%;
    max-width: 100%;

    th,
    td {
        padding: 15px 5px 15px;

        div {
            margin: 0 auto;
        }
    }

    tr {
        // border-bottom: 1px solid #e9ecef;
    }
}

//table styles for google map tooltip for VOC
.table-tooltip {
    .dealerTitle_Tooltip {
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
    }

    table {
        width: 100%;
        max-width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                background: #f4f5f9;
            }
        }

        th,
        td {
            padding: 10px;
            font-size: 13px;
            text-align: center;
            font-weight: 400;
            border: 1px solid #e9ecef;
        }

        th {
            font-weight: 500;
        }
    }
}

.table-scroll {
    position: sticky;
    z-index: 10;
    width: 100%;
    top: 50%;

    .btn {
        position: absolute;
        height: 50px;
        line-height: 30px;
        left: 30px;
        font-size: 20px;

        &.btn--right {
            left: auto;
            right: 30px;
        }
    }
}

.sticky-table table tr.sticky-row th,
.sticky-table table tr.sticky-row td {
    position: relative !important;
    z-index: 5;
}

.sticky-table table td.sticky-cell,
.sticky-table table th.sticky-cell {
    position: relative !important;
    z-index: 10;
}

.sticky-table table tr.sticky-row td.sticky-cell,
.sticky-table table tr.sticky-row th.sticky-cell {
    z-index: 15;
}

.bluefixedtable td,
.bluefixedtable th {
    width: auto;
    white-space: nowrap;
}

.status-icon {
    i {
        font-size: 16px;
    }

    .clr-green {
        color: #28b266;
    }

    .clr-red {
        color: #f16363;
    }

    .clr-yellow {
        color: #fcc014;
    }
}

.nowrap-table {
    white-space: nowrap;
}

$column-border: #cccccc;
$row-gray: #f4f5f9;
$row-border: #ebebeb;

.table {
    width: 100%;
    max-width: 100%;
    clear: both;
    font-size: 12px;
    text-align: left;

    //height: auto;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
        overflow-x: auto;
    }

    &.table--scroll {
        overflow-x: auto;
    }

    &.table--fixedheight {
        td {
            height: 50px;
        }
    }

    &.table--fixed {
        table {
            table-layout: fixed;
        }
    }

    &.table--scroll {
        height: 200px;
        overflow-y: auto;
        width: 100%;
        display: block;

        @include susy-breakpoint(768px, $susy, ".no-mq") {
            height: auto;
        }

        .mCSB_container {
            margin: 0;
        }
    }

    &.table--bordered {
        tr {
            border: 0;
        }

        td,
        th {
            border: 1px solid $row-border;
        }
    }

    &.table--fixedheight {
        td {
            height: 50px;
        }
    }

    &.table--striped {
        tr {
            &:nth-child(even) {
                background: #f4f5f9;
            }
        }
    }

    &.table--nowrap {

        td,
        th {
            white-space: nowrap;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;

        &.table--striped {
            tr {
                &:nth-child(even) {
                    background: #f4f5f9;
                }
            }
        }
    }

    th {
        padding: 15px;
        //text-align: center;
        color: #a9b4bd;
        position: relative;
        //color: #a9b4bd;
        text-transform: uppercase;
        font-weight: 500;

        @include susy-breakpoint(1024px, $susy, ".no-mq") {
            white-space: nowrap;
        }

        &.expanded--col {
            .GridCellDiv {
                width: 400px;
            }
        }

        &:first-child {
            //border-left: 4px solid $white;
        }

        &.header--grey {
            background: #f8f9fa;
        }

        &.column--border {
            border-right: 1px solid #e4e9ed;
        }

        &.nw {
            // white-space: nowrap;
        }

        &.col--blue {
            // background: $clr-lightblue;
        }

        &.sorting {
            // padding-right: emify(15, 10);
            position: relative;

            //  cursor: pointer;
            //@include arrow('top', 'top', 5px, $clr-gray);
            a {
                &:after {
                    position: relative;
                    top: 2px;
                    display: inline-block;
                    margin-left: 10px;
                    font-family: "Font Awesome 5 Pro";
                    content: "\f0dc";
                    color: #a9b4bd;
                    font-size: 14px;
                    @include opacity(0.3);
                }
            }

            &.sorting--asc {
                a {
                    &:after {
                        content: "\f0de";
                        color: $clr-pri;
                        @include opacity(1);
                    }
                }
            }

            &.sorting--desc {
                a {
                    &:after {
                        content: "\f0dd";
                        color: $clr-pri;
                        @include opacity(1);
                    }
                }
            }
        }

        &.column--border-gray {
            border-left: 1px solid $column-border;
        }
    }

    td {
        padding: 15px;
        //text-align: center;
        font-weight: normal;
        color: #34495e;
        font-family: $font-pri;

        @include susy-breakpoint(1024px, $susy, ".no-mq") {
            white-space: nowrap;
        }

        &.active {
            background: #dbf7ff;
        }

        .indent--one {
            text-indent: 30px;
            display: inline-block;
        }

        .indent--two {
            text-indent: 60px;
            display: inline-block;
        }

        .indent--three {
            text-indent: 90px;
            display: inline-block;
        }

        .indent--four {
            text-indent: 120px;
            display: inline-block;
        }

        .indent--five {
            text-indent: 150px;
            display: inline-block;
        }

        .indent--six {
            text-indent: 180px;
            display: inline-block;
        }

        .indent--seven {
            text-indent: 210px;
            display: inline-block;
        }

        .indent--eight {
            text-indent: 240px;
            display: inline-block;
        }

        .indent--nine {
            text-indent: 270px;
            display: inline-block;
        }

        .indent--ten {
            text-indent: 300px;
            display: inline-block;
        }

        &.expanded--col {
            .GridCellDiv {
                width: 400px;
            }
        }

        &.column--border {
            border-right: 1px solid #e4e9ed;
        }

        &.bolt {
            a {
                border: 1px solid $clr-amber;
                color: $clr-amber;
                display: inline-block;
                width: 30px;
                line-height: 30px;
                height: 30px;
                cursor: pointer;
                border-radius: 100%;
                background: lighten($clr-amber, 38%);

                &:hover {
                    background: lighten($clr-amber, 30%);
                }
            }
        }

        &.pop-icon {
            i {
                font-size: 20px;
            }
        }

        &.tags {
            ul {
                li {
                    display: inline-block;
                    margin: 0 3px 5px;

                    a {
                        display: block;
                        padding: 2px 10px;
                        font-size: 10px;
                        text-transform: uppercase;
                        border-radius: 3px;
                        font-weight: 500;
                        // border: 1px solid $clr-pri;
                        // color: $clr-pri;
                        // background: lighten($clr-pri, 34%);
                        border: 1px solid $clr-amber;
                        color: $clr-amber;
                        background: lighten($clr-amber, 38%);

                        &.dirty {
                            border: 1px solid $clr-red;
                            color: $clr-red;
                            background: lighten($clr-red, 40%);
                        }

                        &.unhappy {
                            border: 1px solid $clr-red;
                            color: $clr-red;
                            background: lighten($clr-red, 40%);
                        }

                        &.cleanliness {
                            border: 1px solid $clr-red;
                            color: $clr-red;
                            background: lighten($clr-red, 40%);
                        }

                        &.locks {
                            border: 1px solid $clr-red;
                            color: $clr-red;
                            background: lighten($clr-red, 40%);
                        }

                        &.repair {
                            border: 1px solid $clr-red;
                            color: $clr-red;
                            background: lighten($clr-red, 40%);
                        }

                        &.clean {
                            border: 1px solid $clr-green;
                            color: $clr-green;
                            background: lighten($clr-green, 60%);
                        }

                        &.delivery {
                            border: 1px solid $clr-green;
                            color: $clr-green;
                            background: lighten($clr-green, 60%);
                        }

                        &.service {
                            border: 1px solid $clr-amber;
                            color: $clr-amber;
                            background: lighten($clr-amber, 38%);
                        }

                        &.appointment {
                            border: 1px solid $clr-amber;
                            color: $clr-amber;
                            background: lighten($clr-amber, 38%);
                        }
                    }
                }
            }
        }

        &.red-bg {
            background-color: #fef3f5;
            //color: #e86f87;
        }

        &.qts {
            span {
                border-radius: 3px;
                font-weight: 500;
                font-size: 10px;
                text-transform: uppercase;
                padding: 2px 6px;
                border: 1px solid #34495e;
                color: #34495e;
                background: lighten(#34495e, 68%);

                &.default {
                    border: 1px solid #34495e;
                    color: #34495e;
                    background: lighten(#34495e, 68%);
                }

                &.green {
                    border: 1px solid $clr-green;
                    color: $clr-green;
                    background: lighten($clr-green, 60%);
                }

                &.blue {
                    border: 1px solid $clr-pri;
                    color: $clr-pri;
                    background: lighten($clr-pri, 60%);
                }

                &.red {
                    border: 1px solid $clr-red;
                    color: $clr-red;
                    background: lighten($clr-red, 60%);
                }

                &.yellow {
                    border: 1px solid $clr-amber;
                    color: $clr-amber;
                    background: lighten($clr-amber, 60%);
                }
            }
        }

        &.nw {
            //   white-space: nowrap;
        }

        &.file-type {
            .icon {
                font-size: 18px;

                &.clr--green {
                    color: $clr-green;
                }

                &.clr--red {
                    color: $clr-red;
                }

                &.clr--blue {
                    color: $clr-pri;
                }

                &.clr--yellow {
                    color: $clr-amber;
                }
            }
        }

        &.col--blue {
            //background: $clr-lightblue;
        }

        &.column--yellow {
            background: $clr-yellow;
        }

        &.column--border-gray {
            border-left: 1px solid $column-border;
        }

        .type {
            border-radius: 50%;
            border: 1px solid $clr-pri;
            width: 30px;
            background: #dcf1f4;
            background: lighten($clr-pri, 34%);
            height: 30px;
            line-height: 30px;
            display: inline-block;
            text-align: center;
            color: $clr-pri;
            vertical-align: middle;
            font-size: 16px;

            i {
                //vertical-align: middle;
            }
        }

        &.bounceback {
            span {
                position: relative;
                display: inline-block;
                padding: 0 0 0 35px;

                i {
                    font-size: 24px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                }

                &.user-nocontact {
                    i {
                        top: 2px;
                    }
                }

                &.email-undeliv {
                    i {
                        top: -10px;
                    }
                }
            }
        }

        &.case {
            text-align: center;

            i {
                border-radius: 50%;
                border: 1px solid $clr-amber;
                width: 29px;
                height: 29px;
                background: $white;
                text-align: center;
                display: inline-block;
                //color: $clr-blue-tab;
                line-height: 26px;

                &:before {
                    font-size: emify(16, 10);
                    vertical-align: middle;
                }

                &.icon-mobile {
                    &:before {
                        // font-size: emify(18, 10);
                    }

                    //line-height: 1.7;
                }
            }
        }

        &.follow {
            a {
                border-radius: 3px;
                border: 1px solid $clr-pri;
                padding: 3px 10px;
                font-weight: 500;
                font-size: 10px;
                text-transform: uppercase;
                background-color: lighten($clr-pri, 34%);

                &:hover,
                &.active {
                    background-color: $clr-pri;
                    color: white;
                }
            }
        }

        &.status {
            i {
                font-size: 18px;
                display: inline-block;
                text-align: center;
            }

            .clr-green {
                color: #28b266 !important;
            }

            .clr-red {
                color: #f16363 !important;
            }

            .clr-amber {
                color: #fcc014 !important;
            }
        }

        &.remove {

            // background-color: #fcf3d4;
            a {
                color: $clr-blue;

                i {
                    color: $clr-pri;
                }
            }

            i {
                font-size: emify(10, 10);
                margin-right: 5px;
            }
        }

        &.actions {
            font-size: 16px;

            a {
                display: inline-block;
                margin: 0 5px;
            }
        }

        &.disable {
            opacity: 0.3;
        }

        &.edit,
        &.preview {
            a {
                font-size: 16px;
            }
        }

        &.view {
            a {
                color: #929090;

                i {
                    &:before {
                        font-size: 18px;
                    }
                }

                &:hover {
                    color: $clr-pri;
                }
            }
        }
    }

    .thead,
    thead {
        tr {
            border-bottom: 1px solid $row-border;

            &:first-child {

                // border-bottom: 1px solid $white;
                th {
                    border-top: 1px solid $row-border;
                    text-transform: uppercase;

                    &:first-child {
                        //border-left:4px solid transparent;
                    }

                    &.column--border-gray {
                        border-left: 1px solid $column-border;
                    }

                    a {
                        color: #a9b4bd;
                    }
                }
            }

            &.with--border {
                th {
                    text-transform: uppercase;
                    border-bottom: 2px solid $clr-pri;
                }
            }

            &.no--border {
                th {
                    border-top: 0;
                }
            }

            th {
                //background: $clr-lightblue;
                //text-transform: capitalize;
            }
        }
    }

    //tbody {
    tr {
        border-bottom: 1px solid $row-border;

        &.row--highlighted {
            background: #eeeff4 !important;
        }

        &.row--total {
            td {
                font-weight: 600;
                color: $black;
            }
        }

        &.row--blue {
            // background: $clr-lightblue;
        }

        &.active {
            background: $row-gray;
        }

        &.thead {
            border-bottom: 1px solid $row-border;
            background: none;

            &:first-child {

                // border-bottom: 1px solid $white;
                th {
                    border-top: 1px solid $row-border;
                    text-transform: uppercase;

                    &:first-child {
                        //   border-left: 0;
                    }

                    &.column--border-gray {
                        border-left: 1px solid $column-border;
                    }

                    a {
                        color: #a9b4bd;
                    }
                }
            }

            &.no--border {
                th {
                    border-top: 0;
                }
            }

            th {
                //background: $clr-lightblue;
                //text-transform: capitalize;
            }
        }
    }

    .bar {
        >td:nth-child(1) {
            position: relative;

            &:before {
                content: "";
                width: 4px;
                height: 100%;
                background: lighten($clr-pri, 25%);
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &.active {
            >td:nth-child(1) {
                &:before {
                    background: $clr-pri;
                }
            }
        }
    }

    .bar-single {
        >td:first-child {
            position: relative;

            &:before {
                content: "";
                width: 4px;
                height: 100%;
                background: lighten($clr-pri, 50%);
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        &.active {
            >td:first-child {
                &:before {
                    background: $clr-pri;
                }
            }
        }
    }
}

.accordion {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    display: block;

    &:before {
        position: absolute;
        font-family: "Font Awesome 5 Pro";
        content: "\f054" !important;
        color: $clr-pri;
        font-size: 14px;
        left: 0;
        top: -1px !important;
    }

    &.indent--one {
        text-indent: 30px;
    }

    &.indent--two {
        text-indent: 60px;
    }

    &.indent--three {
        text-indent: 80px;
    }

    &.indent--four {
        text-indent: 120px;
    }

    &.indent--five {
        text-indent: 150px;
    }

    &.indent--six {
        text-indent: 180px;
    }

    &.indent--seven {
        text-indent: 210px;
    }

    &.indent--eight {
        text-indent: 240px;
    }

    &.indent--nine {
        text-indent: 270px;
    }

    &.indent--ten {
        text-indent: 300px;
    }

    &.open {
        &:before {
            content: "\f078" !important;
        }
    }
}


// new colors for stats alerts

.status-clr-green {
    color: #28b266;
    font-size: 16px;
}

.status-clr-red {
    color: #f16363;
    font-size: 16px;
}

.status-clr-yellow {
    color: #fcc014;
    font-size: 16px;
}

// Comparision Report CSS
.table-comparison {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;

    td {

        font-size: 13px;
        text-align: center;
        font-weight: 400;
        padding: 15px;

        &:first-child {
            text-align: left;
        }
    }

    th {
        padding: 15px;
        font-size: 13px;
        text-align: center;
        position: relative;
        color: #8996a0;
        text-transform: uppercase;
        font-weight: 500;
        border-bottom: 2px solid #59b9ef;

        &:first-child {
            text-align: left;
        }
    }

    tr {
        border-bottom: 1px solid #e9ecef;

        &:last-child {
            border-bottom: none;
        }
    }


}

.comparison-graph {
    padding: 10px;
}



.comparison-switch {
    margin-top: 8px;
    font-size: 12px;

    .toggle {
        margin-left: 10px;
    }
}