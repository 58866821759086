// Mixins and examples

/* Clearfix
  ========================================================================== */

@mixin clearfix() {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* Usage */

/* .article {
    @include clearfix();
} */

/* Box Model
  ========================================================================== */

@mixin box-sizing($type: border-box) {
  box-sizing: #{$type};
}

/* Usage */

/* *,
*:after,
*:before {
    @include box-sizing(border-box);
} */

/* Border Radius
  ========================================================================== */

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

// Single side border-radius
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

/* Usage */

/* .button {
    @include border-radius(5px);
}

.submit-button {
    @include border-top-radius(10px);
} */

/* Opacity
  ========================================================================== */

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/* Usage */

/* .article-heading {
    @include opacity(0.8);
}
 */

/* Text overflow
  ========================================================================== */

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Usage */

/* .text-truncate {
    @include text-truncate;
} */

/* Placeholder
  ========================================================================== */

@mixin placeholder-color($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

/* Usage */
/*
input {
    @include placeholder-color(#FA4A4A)
}
 */

$default-tr-property: all;
$default-tr-duration: 0.3s;
$default-tr-function: ease-in-out;

// Transition:
@mixin transition(
  $property: $default-tr-property,
  $duration: $default-tr-duration,
  $function: $default-tr-function,
  $delay: 0s
) {
  transition: $property $duration $function;
  transition-delay: $delay;
}
/* Usage */
// @include transition();



// mixins.scss
// contains all the mixins used
// ----------------------------------------------------------------------------------------

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// `Helpers
// remove unit
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// add unit
@function add-unit($num, $unit: px) {
  @return ($num + $unit);
}

// clean-unit
@function handle-unit($num) {
  @if unitless($num) {
    @return add-unit($num);
  } @else {
    @return $num;
  }
}

/// Mixin helper to output vendor-prefixed CSS. Not required if using prefixr API
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }
  #{$property}: $value;
}

// -----------------------------------------------------------
// `Conversions:
// px to em
@function emify($target, $context) {
  @if $target==0 {
    @return 0;
  }
  @return $target / $context + 0em;
}

@function em($target, $context) {
  @if $target==0 {
    @return 0;
  }
  @return $target / $context + 0em;
}

// Make px to ems using base-font-size
// echo as: font-size: em-font(#px);
@function em-font($target, $context: $base-font-size) {
  @return emify($target, $context);
}

// Make px to ems using base-line-height
// echo as: font-size: em-base(#px);
@function em-base($target, $context: $base-line-height) {
  @return emify($target, $context);
}

// px to rem
@function remify($pxSize) {
  $remSize: $pxSize / $base-font-size;
  @return #{$remSize}rem;
}

// HEX to RGBA
@mixin rgba($color, $value) {
  background-color: $color;
  background-color: rgba($color, $value);
}

// Rem fallback approach by Karl Merkli edited by Arsal Khan. It now takes values in px convert in rems and put a pixel fallback.
// @include rf(margin, 10, 20, 30, 40); // property-value pair
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rf($property, $values...) {
  $max: length($values);
  $pxValues: "";
  $remValues: "";
  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value}px;
    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }
  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value/10}rem;
    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }
  #{$property}: $pxValues;
  #{$property}: $remValues;
}

@function remify($pxSize) {
  $remSize: $pxSize / 10;
  @return #{$remSize}rem;
}

// ----------------------------------------------------------
// `Media
// Arrows
@mixin createArrow($size: 5px, $direction: up, $color: $primary-color) {
  border-width: $size;
  border-color: transparent;
  border-style: solid;
  display: inline-block;
  height: 0px;
  width: 0px;
  @if $direction== "up" {
    border-bottom-color: $color;
    border-top-width: 0px;
  } @else if $direction== "right" {
    border-left-color: $color;
    border-right-width: 0px;
  } @else if $direction== "down" {
    border-top-color: $color;
    border-bottom-width: 0px;
  } @else if $direction== "left" {
    border-right-color: $color;
    border-left-width: 0px;
  }
}

// png sprite(the old sschool way)
@mixin createIcon($width, $height, $background-pos: "0 0", $pseudo: "") {
  // when the icon is used in a separate tag, ie, i.icon or span.icon
  @if $pseudo== "" {
    background: transparent url("#{$mainSprite}") no-repeat;
    background-position: $background-pos;
    width: $width;
    height: $height;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    /* Fix for IE7 */
    *display: inline;
    /* Fix for IE7 */
  }
  // when used with pseudo element :before or :after
  @if $pseudo != "" {
    &:#{$pseudo} {
      background: transparent url("#{$mainSprite}") no-repeat;
      background-position: $background-pos;
      width: $width;
      height: $height;
      display: inline-block;
      vertical-align: top;
      zoom: 1;
      /* Fix for IE7 */
      *display: inline;
      /* Fix for IE7 */
      content: "";
      padding-left: $width + 5px;
      /* adding space from left */
    }
  }
}

// Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
}

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation== "vertical" {
    background: -webkit-linear-gradient(to bottom, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
    background: -moz-linear-gradient(to bottom, $start-color, $end-color);
    background: -o-linear-gradient(to bottom, $start-color, $end-color);
    background: -ms-linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation== "horizontal" {
    background: -webkit-linear-gradient(to right, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
    background: -moz-linear-gradient(to right, $start-color, $end-color);
    background: -o-linear-gradient(to right, $start-color, $end-color);
    background: -ms-linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      $start-color,
      $end-color
    );
    background: radial-gradient(
      center,
      ellipse cover,
      $start-color,
      $end-color
    );
    background: -moz-gradient(center, ellipse cover, $start-color, $end-color);
    background: -o-gradient(center, ellipse cover, $start-color, $end-color);
    background: -ms-gradient(center, ellipse cover, $start-color, $end-color);
    background: -webkit-radial-gradient(
      ellipse at center,
      $start-color,
      $end-color
    );
    background: radial-gradient(ellipse at center, $start-color, $end-color);
    background: -moz-gradient(ellipse at center, $start-color, $end-color);
    background: -o-gradient(ellipse at center, $start-color, $end-color);
    background: -ms-gradient(ellipse at center, $start-color, $end-color);
  }
}

//-------------------------------------------------------------
// `Structure
// Box sizing
@mixin box-sizing($type: border-box) {
  box-sizing: #{$type};
}

// Box-shadow
@mixin box-shadow(
  $horiz: 0.5em,
  $vert: 0.5em,
  $blur: 0px,
  $spread: 0px,
  $color: #000000
) {
  // -webkit-box-shadow: $horiz $vert $blur $spread $color;
  // -moz-box-shadow: $horiz $vert $blur $spread $color;
  // box-shadow: $horiz $vert $blur $spread $color;
  @include prefix(
    "box-shadow",
    $horiz $vert $blur $spread $color,
    "webkit" "moz"
  );
}

// ----------------------------------------------------------
// `Alignment
// place element in center of the container;
@mixin old-school-center($width, $height) {
  width: $width;
  height: $height;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -($height/2);
  margin-left: -($width/2);
}

// Center align anything
@mixin center($direction: both, $dim1: null, $dim2: $dim1) {
  // if no direction is set, center align from both horizontal and vertical
  @if $direction==both {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    @if $dim1 and $dim2 {
      $width: $dim1;
      $height: $dim2;
      width: $width;
      height: $height;
      @include ie {
        /* fallback for ie that doesn't support translate */
        margin-left: -($width/2);
        margin-top: -($height/2);
      }
    }
  } @else {
    // check the direction
    @if $direction== "vertical" {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      @if $dim1 {
        $height: $dim1;
        height: $height;
        @include ie {
          /* fallback for ie that doesn't support translate */
          margin-top: -($height/2);
        }
      }
    }
    // for horizontal
    @else if $direction== "horizontal" {
      @if $dim1 {
        width: $dim1;
        display: block;
        margin-right: auto;
        margin-left: auto;
        float: none;
      } @else {
        display: table;
        margin-right: auto;
        margin-left: auto;
        float: none;
      }
    }
  }
}

// @center
// ----------------------------------------------------------
// `Borders
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin border-radius-separate(
  $top-left-radius,
  $top-right-radius,
  $bottom-right-radius,
  $bottom-left-radius
) {
  border-top-left-radius: #{$top-left-radius};
  border-top-right-radius: #{$top-right-radius};
  border-bottom-left-radius: #{$bottom-left-radius};
  border-bottom-right-radius: #{$bottom-right-radius};
}

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

// Simple fallback for font-size for older browsers
@mixin font-size($pxSize) {
  $num: strip-unit($pxSize);
  font-size: $num + px; //Fallback in px
  font-size: remify($num);
}

/// Embossing text shadow
/// @access public
/// @param {Float} $value - Opacity value
/// @example scss - Usage
///   .foo {
///     @include text-shadow(0.5);
///   }
/// @example css - Result
///   .foo {
///     text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
///   }
@mixin text-shadow($value) {
  text-shadow: rgba(255, 255, 255, $value) 0 1px 0;
}

// ----------------------------------------------------------
// `Responsive
@function mobile-first() {
  @if ($mobile_first_approach) {
    @return "min-width";
  } @else {
    @return "max-width";
  }
}

@mixin breakpoint($arg1: null, $arg2: null) {
  $width_break: mobile-first();
  // Change min-width to max-width if not using mobile-first approach, the default is tablet
  @if ($arg1) {
    // if the argument is set
    @if type-of($arg1) == "number" {
      // if the argument is a number
      @if not($arg2) {
        @media only screen and (#{$width_break}: $arg1) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $min) and (max-width: $max) {
          @content;
        }
      }
    } @else if type-of($arg1) == "string" {
      // if the argument is string, we are talking about device based breakpoints
      $model: $arg1;
      $orientation: $arg2;
      // iphone4s
      @if $model== "iphone4s" {
        // portrait
        @if $orientation== "portrait" {
          @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            @content;
          }
        }
        // iphone 4/4s landscape
        @else if $orientation== "landscape" {
          @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
            @content;
          }
        }
        // both
        @else {
          @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
          }
        }
      } // /iphone4s
      @else if $model== "iphone5s" {
        // iphone 5/5s portrait
        @if $orientation== "portrait" {
          @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            @content;
          }
        }
        // iphone 5/5s landscape
        @else if $orientation== "landscape" {
          @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
            @content;
          }
        }
        // both landscape and portrait by default
        @else {
          @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
          }
        }
      } // /iphone5s
      @else if $model== "iphone6" {
        // iphone 6 portrait
        @if $orientation== "portrait" {
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            @content;
          }
        }
        // iphone 6 landscape
        @else if $orientation== "landscape" {
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
            @content;
          }
        }
        // both landscape and portrait by default for iphone 6
        @else {
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
            /* iphone6 */
            @content;
          }
        }
      } // /iphone6
      // both landscape and portrait by default for iphone 6 plus
      @else if $model=='iphone6plus' {
        // iphone 6 plus portrait
        @if $orientation== "portrait" {
          @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
            @content;
          }
        }
        // iphone 6 plus landscape
        @else if $orientation== "landscape" {
          @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
            @content;
          }
        } @else {
          @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
            @content;
          }
        }
      } // /iphone6plus
      // both landscape and portrait by default for iphone 6 plus
      @else if $model=='xs-only' {
        @media only screen and (max-width: #{$xs}) {
          @content;
        }
      } // /xs-only
      @else if $model=='xs-sm' {
        @media only screen and (max-width: #{$md}) {
          @content;
        }
      } // /xs and sm
      @else if $model=='sm-only' {
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          @content;
        }
      } // /sm-only
      @else if $model=='sm-md' {
        @media only screen and (min-width: #{$sm}) and (max-width: #{$lg}) {
          @content;
        }
      } // /sm and medium
      @else if $model=='md-only' {
        @media only screen and (min-width: #{$sm}) and (max-width: #{$md}) {
          @content;
        }
      } // /sm-only
      @else if $model=='lg-only' {
        @media only screen and (min-width: #{$lg}) {
          @content;
        }
      } // /xs-only
    }
  } @else {
    // if nothing passed, check mobilefirst or desktop first and do accordingly
    @if ($mobile_first_approach) {
      @media only screen and (#{$width_break}: $sm) {
        @content;
      }
    } @else {
      @media only screen and (#{$width_break}: $md) {
        @content;
      }
    }
  }
}

// --------------------------------------
// User Interaction
// keyframes
@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }
  @keyframes $animation-name {
    @content;
  }
}

/// Given two opacity values, animates an element between those opacity values.
/// @access public
/// @param {Number} $fade-from [0] - The beginning opacity value.
/// @param {Number} $fade-to [1] - The final opacity value.
/// @param {List} $animation-properties [1s ease] - The animation properties to apply.
/// @example scss - Usage
///   .foo {
///     @include fade(.8, .2, 3s linear);
///   }
/// @example css - Result
///   .foo {
///     -webkit-animation: fade-0-8-0-2 3s linear;
///     animation: fade-0-8-0-2 3s linear;
///   }
/// // (the @keyframes are also generated with a -webkit- vendor prefix)
///   @keyframes fade-0-8-0-2 {
///     from {
///       opacity: 0.8;
///       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
///     }
///     to {
///       opacity: 0.2;
///       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
///     }
///   }
@mixin fade($fade-from: 0, $fade-to: 1, $animation-properties: 1s ease) {
  $alias: fade- + str-replace($fade-from + "", ".", "-") + "-" +
    str-replace($fade-to + "", ".", "-");
  @include keyframes($alias) {
    from {
      @include opacity($fade-from);
    }
    to {
      @include opacity($fade-to);
    }
  }
  @include prefix(animation, $alias $animation-properties, "webkit");
}

// // transition
// @mixin transition( $property: all, $duration: 1s, $func: linear ) {
//     @include transition-property($property);
//     @include transition-duration($duration);
//     @include transition-timing-function($func);
// }
@mixin transition($property: all, $time: 0.5s, $timing-function: ease-out) {
  @include prefix(transition, $property $time $timing-function, "webkit");
}

@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

/*.item {
    @include transition(padding, 1s, ease-in-out);
}*/

// mixin for ie versions
@mixin ie($version: "ie8") {
  .lt-ie9 &,
  .ie-8 & {
    @content;
  }
}

// Responsive utilities
//
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent} {
    display: table;
  }
  tr#{$parent} {
    display: table-row !important;
  }
  th#{$parent},
  td#{$parent} {
    display: table-cell !important;
  }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

// use in ul
// disc (• • •)
// circle (○ ○ ○)
// square (▪ ▪ ▪)
// decimal (1 2 3)
// decimal-leading-zero (01, 02, 03)
// lower-roman (i ii iii)
// upper-roman (I II III)
// lower-greek (α β γ)
// lower-latin (a b c)
// upper-latin (A B C)
// armenian (Ա Բ Գ)
// georgian (ა ბ გ)
// lower-alpha (a b c)
// upper-alpha (A B C)

@mixin list-counter($counter-style: lower-alpha) {
  list-style: none;
  counter-reset: my-counter;
  > li:before {
    content: counter(my-counter, $counter-style);
    counter-increment: my-counter;
  }
}

// App specific mixins (if any)
//bleed for negative gutters
@mixin bleed($padding: $grid-padding, $sides: left right) {
  @if $sides== "all" {
    margin: -$padding;
    padding: $padding;
  } @else {
    @each $side in $sides {
      margin-#{$side}: -$padding;
      padding-#{$side}: $padding;
    }
  }
}

//box shadow
%box-shadow {
  box-shadow: 0 1px 5px #ccc;
}

//box shadow topnone
%box-shadowTN {
  box-shadow: 0 2px 2px rgba(1, 1, 1, 0.35);
}

$default-tr-property: all;
$default-tr-duration: 0.3s;
$default-tr-function: ease-in-out;

// Transition:
@mixin transition(
  $property: $default-tr-property,
  $duration: $default-tr-duration,
  $function: $default-tr-function,
  $delay: 0s
) {
  transition: $property $duration $function;
  transition-delay: $delay;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
