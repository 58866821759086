.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-step-header {
  pointer-events: none !important;
}

.mat-horizontal-stepper-header-container {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1100px;
  text-align: center;
  @include clearfix();

  .mat-step-header {
    float: left;
    width: calc(100% / 4);
    display: block !important;
    padding: 0 !important;

    .mat-step-header-inner {
      display: block;
      width: 100%;
      position: relative;
      padding: 0 0 25px 0;

      &:before {
        content: "";
        position: absolute;
        left: -50%;
        bottom: -2px;
        z-index: 1;
        width: 100%;
        height: 3px;
        background: #cdd4d8;
      }

      //&:first-child {
      //  &:before {
      //    display: none;
      //  }
      //}
      .step__status {
        position: absolute;
        z-index: 2;
        bottom: -13px;
        left: 50%;
        margin: 0 0 0 -13px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $white;
        border: 3px solid #cdd4d8;
        box-shadow: 0px 8px 16px 0px rgba(112, 124, 131, 0.25);
      }

      .step__no {
        display: block;
        font-size: 32px;
        font-weight: bold;
        color: #cdd4d8;
      }

      .step__name {
        font-size: 15px;
        color: #4d565f;
      }

      &.current,
      &.done {
        &:before {
          background: $clr-pri;
        }

        .step__status {
          border-color: $clr-pri;
          box-shadow: 0px 8px 16px 0px rgba(76, 161, 254, 0.25);
        }

        .step__no {
          color: #7c8288;
        }

        .step__name {
          font-weight: 600;
          color: #34495e;
        }
      }

      &.done {
        .step__no {
          color: #4d565f;
        }
      }

      &.current {
        .step__status {
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -6px 0 0 -6px;
            width: 12px;
            height: 12px;
            background: $clr-pri;
            border-radius: 50%;
          }
        }
      }

      &.done {
        .step__status {
          &:after {
            content: "check";
            font-family: "Material Icons";
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -11px 0 0 -11px;
            font-size: 18px;
            line-height: 18px;
            border: 2px solid $clr-pri;
            color: $white;
            background: $clr-pri;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .mat-horizontal-stepper-header {
    &:first-child {
      .mat-step-header-inner {
        &:before {
          display: none;
        }
      }
    }
  }
}

.hide {
  display: none !important;
}

.hide-collapse {
  display: none;
}

.alphabetic-filters {
  float: right;

  >ul {
    text-transform: uppercase;
    font-size: 0;

    :lang(fr) {
      text-transform: none;
    }

    >li {
      display: inline-block;
      border: 1px solid #e1e1e1;
      border-left: none;

      >a {
        display: inline-block;
        font-size: 12px;
        background: #f4f5f9;
        padding: 0.75em;
        cursor: pointer;
        color: #34495e;

        >i {
          font-size: 14px;
          line-height: 14px;
          vertical-align: bottom;
        }

        &:hover,
        &.active {
          background: $clr-pri;
          color: $white;
        }
      }

      &:first-child {
        border-left: 1px solid #e1e1e1;
        border-radius: 3px 0 0 3px;

        a {
          border-radius: 3px 0 0 3px;
        }
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
        vertical-align: top;

        a {
          border-radius: 0 3px 3px 0;
        }
      }
    }
  }

  .dropdown-menu {
    font-size: 12px;
    text-transform: none;
  }

  .btn.dropdown-toggle {
    border: none !important;
    padding-bottom: 4px;
  }

  &.secondary--theme {
    >ul {
      >li {
        >a {
          background: $white;

          &:hover,
          &.active {
            background: $clr-pri;
          }
        }
      }
    }
  }
}

.crop-image {
  display: table;
  width: 100%;
  min-height: 300px;
  vertical-align: middle;
  text-align: center;

  .image-wrapper {
    display: table-cell;
    width: 50%;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    position: relative;

    .image {
      font-size: 0;
      display: inline-block;
      padding: 10px;
      border: 2px solid $clr-pri;
      background: #f4f5f9;
      border-radius: 3px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      color: #34495e;
      text-transform: uppercase;
      padding: 5px 5px 0 5px;

      :lang(fr) {
        text-transform: none;
      }

      &.dimensions {
        font-size: 11px;
        font-weight: 400;
        text-transform: none;
        padding-top: 0;
      }
    }

    &.original--image {
      .image {
        border-style: dotted;
      }
    }

    &:first-child {
      &:after {
        content: "\f178";
        position: absolute;
        top: 50%;
        right: 0;
        margin: -12px -12px 0 0;
        font-family: "Font Awesome 5 Pro";
        text-transform: lowercase;
        color: #99a4ae;
        font-size: 24px;
      }
    }
  }
}

.icon-outline {
  display: inline-block;
  width: 26px;
  height: 26px;
  padding: 2px;
  border: 2px solid $clr-copy;
  border-radius: 50%;
  margin: 5px 0 0 0;

  i {
    font-size: 14px;
    line-height: 20px;
  }

  &.positive {
    border-color: #88be58;
    color: #88be58;
    box-shadow: 0px 8px 16px 0px rgba(140, 192, 93, 0.25);
  }

  &.negative {
    border-color: #ec638d;
    color: #ec638d;
    box-shadow: 0px 8px 16px 0px rgba(236, 99, 141, 0.25);
  }
}

.reportrange {
  border-radius: 3px;
  margin: 0 10px 0 0;
  padding: 1px 0 1px 35px;
  border: 1px solid #e1e1e1;
  cursor: pointer;
  background: #fff;

  >i {
    display: none;
  }

  .caret {
    font-weight: normal;

    &:before {
      font-size: 21px;
      margin: 0 2px 0 0;
      position: relative;
      top: 2px;
      -webkit-text-stroke: 1px white;
      color: black;
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    min-width: 50px;
    background-color: #4c5e70;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 0 3px 3px 0;
    font-size: 11px;
    line-height: 20px;
    font-weight: normal;
    text-transform: uppercase;
    white-space: nowrap;

    position: absolute;
    z-index: 1;
    left: 64px;
    top: 50%;
    margin: -15px 0 0 0;
    box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.25);

    :lang(fr) {
      text-transform: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #4c5e70 transparent transparent;
    }
  }

  &.tooltip--top {
    .tooltiptext {
      font-size: 11px;
      font-weight: normal;
      line-height: 16px;
      border-radius: 3px;
      left: 50%;
      top: -22px;
      z-index: 20;
      min-width: 66px;
      margin: -10px 0 0 0;
      padding: 3px 5px;
      transform: translateX(-50%);

      &:before {
        top: 100%;
        right: auto;
        left: 50%;
        margin: 0 0 0 -5px;
        border-color: #4c5e70 transparent transparent transparent;
      }
    }
  }

  &.tooltip--bottom {
    .tooltiptext {
      font-size: 11px;
      font-weight: normal;
      line-height: 16px;
      border-radius: 3px;
      left: 50%;
      top: auto;
      bottom: -25px;
      z-index: 20;
      min-width: 66px;
      margin: -10px 0 0 0;
      padding: 3px 5px;
      transform: translateX(-50%);

      &:before {
        top: -10px;
        right: auto;
        left: 50%;
        margin: 0 0 0 -5px;
        border-color: transparent transparent #4c5e70 transparent;
      }
    }
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

.info-strip {
  display: inline-block;
  border: 1px solid $clr-pri;
  text-transform: uppercase;
  color: $clr-pri !important;
  font-size: 10px;
  font-weight: 500;
  line-height: 110%;
  padding: 2px 8px !important;
  border-radius: 3px;
  //max-width: 100px;
  text-align: center;
  background: #e7f6f8;

  :lang(fr) {
    text-transform: none;
  }

  &.inprogress {
    color: #f2c200 !important;
    border-color: #f2c200;
    background: #fbf2ce;
  }

  &.pending {
    color: #f2c200 !important;
    border-color: #f2c200;
    background: #fbf2ce;
  }

  &.draft {
    color: #f2c200 !important;
    border-color: #f2c200;
    text-transform: capitalize;
    background: #fbf2ce;

    :lang(fr) {
      text-transform: none;
    }
  }

  &.completed {
    color: #0c9c44 !important;
    border-color: #0c9c44;
    background: #e7f6f8;
  }

  &.active {
    color: #0c9c44 !important;
    border-color: #0c9c44;
    background: #e7f6f8;
  }

  &.open {
    color: $clr-pri !important;
    border-color: $clr-pri;
    background: #e7f6f8;
  }

  &.viewed {
    color: #ff6c00 !important;
    border-color: #ff6c00;
    background: #fcb988;
  }

  &.failed,
  &.close {
    color: #ff2f2f !important;
    background: #fbeaec;
    border-color: #ff2f2f;
  }

  &.graytag {
    color: #979797 !important;
    background: #e9ecef;
    border-color: #e9ecef;
    border-radius: 20px;
    max-width: 100%;
    display: block;
    margin: 3px 0;
  }
}

.info-strip-long {
  display: inline-block;
  border: 1px solid $clr-pri;
  text-transform: uppercase;
  color: $clr-pri !important;
  font-size: 10px;
  font-weight: 500;
  line-height: 110%;
  padding: 2px 8px !important;
  border-radius: 3px;
  text-align: center;
  background: #e7f6f8;

  &.strap--with-ellipsis {
    white-space: nowrap;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :lang(fr) {
    text-transform: none;
  }

  &.green {
    color: #0c9c44 !important;
    border-color: #0c9c44;
    background: #e7f6f8;
  }

  &.red {
    color: #ff2f2f !important;
    background: #fbeaec;
    border-color: #ff2f2f;
  }

  &.black {
    color: #979797 !important;
    background: #e9ecef;
    border-color: #979797;
  }
}

.plus-minus-action {
  font-size: 14px;
  font-weight: 600;
  color: #4d565f;

  .setting-field & {
    font-weight: 400;
  }

  p {
    padding: 5px 0;
  }

  .options {
    line-height: 20px;

    span {
      padding: 0 5px;
    }

    i {
      font-size: 12px;
      line-height: 20px;
      color: $clr-pri;
      vertical-align: top;
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed;

      i {
        color: #d7d7d7;
      }
    }
  }
}

.modal__outer {
  position: relative;
}

.image-block {
  float: left;
  width: 90px;
  padding: 10px;
  text-align: center;
  position: relative;

  .summary__answer & {
    width: 80px;
    padding-left: 0;
    padding-top: 0;
  }

  .image {
    width: 70px;
    height: 60px;
    border: 1px solid #d1dce2;
    display: table;
    border-radius: 3px;

    i {
      color: #d1dce2;
      font-size: 48px;
      vertical-align: middle;
      display: table-cell;
    }

    img {
      max-width: 50px;
      max-height: 50px;
      margin: 5px;
      border-radius: 3px;
    }
  }

  .image-title {
    font-size: 13px;
    color: #a6aaaf;
    padding: 3px;
  }

  .delete {
    position: absolute;
    right: 3px;
    top: -3px;

    a {
      display: table;
      width: 18px;
      height: 18px;
      background: $white;
      border-radius: 50%;

      i {
        display: table-cell;
        font-size: 16px;
        line-height: 17px;
        color: #ec638d;
        vertical-align: middle;
      }

      &:hover {
        i {
          color: #ad4967;
        }
      }
    }
  }
}

.disabled-content {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

.clear {
  clear: both;
}

.brand-logo {
  max-width: 48px;
}


//compatible
.not-compatible {
  text-align: center;
  font-size: 1rem;
  width: 100%;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 55px;
  margin: 0 0 0 -50%;

  .compatible__icons {
    position: relative;
    width: 75px;
    height: 75px;
    margin: 0 auto;
  }

  .icon--mobile {
    @include opacity(0.5);


    &:before {
      font-size: 72px;
      color: #5b6c7c;
      vertical-align: middle;

    }
  }

  .icon--close {
    position: absolute;
    background: #e9ecef;
    right: 11px;
    bottom: 10px;

    &:before {
      font-size: 20px;
      color: $clr-red;
      vertical-align: middle;

    }
  }

  .compatible__msg {
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 600;

  }
}

//Password hint

.password-hint {
  display: block;
  width: 95%;
  padding: 7px 10px;
  font-size: 12px;
  border: 1px solid #d7d7d7;

  .login-form & {
    width: 100%;
  }

  p {
    font-weight: bold;
    padding: 0 0 5px 0;
  }

  ul {
    li {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      padding: 0 0 5px 10px;
    }
  }
}

//Password Strength

.password-strength {
  position: relative;

  .password-strength-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 0;

    .login-form & {
      bottom: -1px;
    }

    .password-strength-block {
      width: calc(100%/3);
      display: inline-block;
      height: 3px;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;

      &.weak {
        background: $clr-red;
      }

      &.moderate {
        background: $color-legend-orange;
      }

      &.strong {
        background: $clr-green;
      }
    }
  }
}

.editable-block {
  position: relative;
  clear: both;
  padding: 5px 0 0 0;

  p {
    float: left;
    width: 100%;
    font-weight: 500;

    &[contenteditable="true"],
    &[contenteditable="true"]:active,
    &[contenteditable="true"]:focus {
      border: 1px dashed #d8dbdf;
      padding: 5px;
      margin: 5px 0 0 0;
    }
  }

  input {
    width: 100%;
    font-size: 12px;
    color: #333333;
    background: #ffffff;
    padding: 5px 23px 5px 5px;
    border: 1px dashed $clr-pri;
    max-width: 100%;
    transition: border-color 0.2s 0.3s ease-in-out;
    transition-delay: 0s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
      border-color: $clr-pri;
    }

    &:focus {
      border: 1px solid $clr-pri;
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
      background: #d7d7d7 !important;
      border-color: transparent !important;
      color: #4d565f !important;
    }
  }

  .edit-actions {
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 14px;
  }
}