.ui-datepicker {
  padding: $calendarPadding;
  min-width: $calendarWidth;
  background-color: $inputContentPanelBgColor;
  color: $inputContentPanelTextColor;
  border: $inputListBorder;

  &:not(.ui-datepicker-inline) {
    border: $inputOverlayBorder;
    @include shadow($inputOverlayShadow);
  }

  &:not(.ui-state-disabled) {
    .ui-datepicker-header {
      .ui-datepicker-prev,
      .ui-datepicker-next {
        &:hover {
          color: $calendarNavIconHoverColor;
        }

        &:focus {
          outline: 0 none;
          color: $calendarNavIconHoverColor;
        }
      }
    }

    table {
      td {
        a:not(.ui-state-active):not(.ui-state-highlight):hover {
          background-color: $calendarCellDateHoverBgColor;
        }
      }
    }

    .ui-monthpicker {
      a.ui-monthpicker-month:not(.ui-state-active):hover {
        background-color: $calendarCellDateHoverBgColor;
      }
    }
  }

  .ui-datepicker-header {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    background-color: $inputListHeaderBgColor;
    color: $inputListHeaderTextColor;
    @include border-radius(0);

    .ui-datepicker-prev,
    .ui-datepicker-next {
      cursor: pointer;
      top: 0;
      color: $calendarNavIconColor;
      @include transition($calendarNavIconTransition);
    }

    .ui-datepicker-title {
      margin: 0;
      padding: 0;
      line-height: 1;

      select {
        margin-top: -0.35em;
        margin-bottom: 0;
      }
    }
  }

  table {
    font-size: $fontSize;
    margin: $calendarTableMargin;

    th {
      padding: $calendarHeaderCellPadding;
    }

    td {
      padding: $calendarCellDatePadding;
      vertical-align: top;

      > a,
      > span {
        display: block;
        text-align: center;
        color: $textColor;
        padding: $calendarCellDatePadding;
        @include border-radius($calendarCellDateBorderRadius);
      }

      > a {
        cursor: pointer;

        &.ui-state-active {
          color: $calendarCellDateSelectedTextColor;
          background-color: $calendarCellDateSelectedBgColor;
        }
      }

      &.ui-datepicker-today {
        a {
          background-color: $calendarCellDateTodayBgColor;
          color: $calendarCellDateTodayTextColor;

          &.ui-state-active {
            color: $calendarCellDateSelectedTextColor;
            background-color: $calendarCellDateSelectedBgColor;
          }
        }
      }
    }
  }

  .ui-datepicker-buttonbar {
    border-top: $calendarButtonBarDivider;
  }

  .ui-timepicker {
    border: 0 none;
    border-top: $calendarTimePickerDivider;
    padding: $calendarTimePickerPadding;

    a {
      color: $calendarTimePickerIconColor;
      font-size: $calendarTimePickerIconFontSize;

      &:hover {
        color: $calendarTimePickerIconHoverColor;
      }
    }

    span {
      font-size: $calendarTimePickerTimeFontSize;
    }
  }

  .ui-monthpicker {
    .ui-monthpicker-month {
      color: $textColor;

      &.ui-state-active {
        color: $calendarCellDateSelectedTextColor;
        background-color: $calendarCellDateSelectedBgColor;
      }
    }
  }

  &.ui-datepicker-timeonly {
    padding: 0;

    .ui-timepicker {
      border-top: 0 none;
    }
  }

  &.ui-datepicker-multiple-month {
    .ui-datepicker-group {
      //border-right: $calendarMultipleMonthDivider;
      padding: 5px;
      vertical-align: top;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: 0 none;
      }
    }
  }
}

.ui-calendar.ui-calendar-w-btn {
  .p-inputtext {
    @include border-radius-right(0);
    border-right: 0 none;
    //padding-bottom: 5px;
    width: calc(100% - #{$buttonIconOnlyWidth});

    &:enabled:hover:not(.ui-state-error),
    &:enabled:focus:not(.ui-state-error) {
      border-right: 0 none;
    }
  }

  .ui-datepicker-trigger.ui-button {
    width: $buttonIconOnlyWidth;
    @include border-radius-left(0);
    font-size: 12px;
    position: absolute;
  }
}

.ui-fluid {
  .ui-calendar.ui-calendar-w-btn {
    input.p-inputtext {
      width: calc(100% - #{$buttonIconOnlyWidth});
    }
  }
}

.ui-calendar {
  position: relative;
  display: inline-block;
  width: 100%;
  .ui-calendar-button {
    height: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 2em;
    border-left: 0 none;
    &:enabled:hover,
    &:focus {
      border-left: 0 none;
    }
  }
  .ui-datepicker {
    min-width: 100%;
    font-size: 13px;
  }
  .date-range-field {
    border-radius: 3px;
    margin: 0 10px 0 0;
    padding:9px 30px 9px 35px;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    background: #fff;
    font-size: 12px;
    position: relative;
    > i {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -8px;
    }
    .date-range-label {
      display: inline-block;
    }
  }
  .date-range-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
    select {
      width: 50px;
      margin: 0 auto;
      background: #eee;
      border: 1px solid #eee;
      padding: 2px;
      outline: 0;
      font-size: 12px;
      &.disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
  &.insight-calendar {
    width: auto;
  }

}

/* Fluid */

.ui-fluid {
  .ui-calendar {
    width: 100%;
  }
  .ui-calendar-button {
    width: 2em;
  }
  .ui-datepicker-buttonbar button {
    width: auto;
  }
  .ui-calendar.ui-calendar-w-btn .p-inputtext {
    width: calc(100% - 2em);
  }
}

/* Datepicker */

.ui-datepicker {
  width: auto;
  padding: 0.2em;
  position: absolute;
  .dashboard-filters & {
    padding: 0;
    margin-top: 5px !important;
    left: -140px;
  }
  &.ui-datepicker-inline {
    display: inline-block;
    position: static;
  }
  .ui-datepicker-range {
    display: table-cell;
    min-width: 140px;
    border-right: 1px solid #dddddd;
    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      li {
        font-size: 12px;
        padding: 8px 12px;
        cursor: pointer;
        a {
          color: #333333;
        }
        &:hover {
          background-color: #eee;
        }
        &.active {
          background-color: $clr-pri;
          color: #fff;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .ui-datepicker-footer {
    text-align: right;
    border-top: 1px solid #dddddd;
    padding: 8px;
    .btn {
    //  margin-left: 8px;
    }
  }
  .ui-datepicker-group {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    margin-bottom: 10px;
  }
  .ui-datepicker-header {
    position: relative;
    padding: 0.5em 0;
  }
  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
    top: 0.5em;
    width: 1.8em;
    height: 1.8em;
  }
  .ui-datepicker-prev {
    left: 0.125em;
  }
  .ui-datepicker-next {
    right: 0.125em;
  }
  .ui-datepicker-prev span,
  .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }
  .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    select {
      font-size: 1em;
      margin: 0.125em 0;
      vertical-align: middle;
    }
  }
  select.ui-datepicker-month {
    margin-right: 0.25em;
  }
  span.ui-datepicker-year {
    margin-left: 0.25em;
  }
}

/* Header */

/* Multiple Month DatePicker */

.ui-datepicker-multiple-month .ui-datepicker-group {
  display: table-cell;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 1px;
}

/* DatePicker Table */

.ui-datepicker {
  table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
  }
  th {
    padding: 0.5em;
    text-align: center;
    font-weight: bold;
    border: 0;
  }
  td {
    border: 0;
    padding: 0;
    > {
      span,
      a {
        display: block;
        padding: 0.5em;
        text-decoration: none;
        border: 0 none;
        text-align: center;
      }
    }
  }
  .ui-datepicker-buttonbar {
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    padding: 0.5em;
    > .ui-g > div {
      &:last-child {
        text-align: right;
      }
      padding: 0;
    }
  }
}

.ui-calendar.ui-calendar-w-btn input {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* Month Picker */
.ui-monthpicker {
  text-align: center;
}
.ui-monthpicker .ui-monthpicker-month {
  width: 54px;
  display: inline-block;
  text-align: center;
  padding: 0;
  height: 54px;
  line-height: 54px;
  margin: 2px;
  border-radius: 3px;
}

.ui-datepicker-monthpicker select.ui-datepicker-year {
  width: auto;
}

/* Time Picker */

.ui-timepicker {
  text-align: center;
  padding: 0.5em 0;
  > {
    div {
      display: inline-block;
      margin-left: 0.5em;
      min-width: 1.5em;
    }
    .ui-minute-picker,
    .ui-second-picker {
      margin-left: 0;
    }
    .ui-separator {
      margin-left: 0px;
      min-width: 0.75em;
      a {
        visibility: hidden;
      }
    }
    div a {
      display: block;
      opacity: 0.7;
      filter: Alpha(Opacity = 70);
      cursor: pointer;
      &:last-child {
        margin-top: 0.3em;
      }
      &:hover {
        display: block;
        opacity: 1;
        filter: Alpha(Opacity = 100);
      }
    }
  }
}

input[type="text"]::-ms-clear {
  display: none;
}

/* Touch UI */

.ui-datepicker-touch-ui,
.ui-calendar .ui-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
}

.ui-datepicker-touch-ui {
  &.ui-datepicker {
    th {
      padding: 2em 0;
    }
    td {
      padding: 0;
      > {
        span,
        a {
          padding: 2em 0;
        }
      }
    }
  }
  .ui-timepicker {
    padding: 1em 0;
    > div a {
      font-size: 2em;
    }
  }
}

.ui-datepicker-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 40em) {
  .ui-datepicker-multiple-month {
    width: 17em;
    overflow: auto;
  }
  .ui-datepicker-touch-ui.ui-datepicker {
    th {
      padding: 1em 0;
    }
    td {
      span,
      a {
        padding: 1em 0;
      }
    }
  }
}

// New Primeng calendar styles

.p-calendar {
  display: block !important;
  &.p-calendar-w-btn {
    .p-inputtext {
      width: 100%;
      padding: 8px 10px;
      border-radius: 3px;
      font-family: "Roboto";
      font-size: 12px;
      color: #666;
    }
    .p-datepicker-trigger {
      position: absolute;
      top: 0;
      right: 0;
      background: $clr-pri;
      color: #fff;
      width: 35px;
      padding: 8px;
      border-radius: 0 3px 3px 0;
      .p-button-label {
        display: none;
      }
    }
  }
}

.p-datepicker {
  padding: 0.75rem;
  background: #ffffff;
  color: #323130;
  border: 1px solid #605e5c;
  border-radius: 2px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  color: #323130;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 0 none;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #605e5c;
  border: 0 none;
  background: transparent;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #605e5c;
  border-color: transparent;
  background: #f3f2f1;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 12px;
  font-family: inherit;
  padding: 0.25em;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
  border-color: $clr-pri;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 12px;
  margin: 0;
}
.p-datepicker table th {
  padding: 5px;
}
.p-datepicker table th > span {
  width: 1.75rem;
  height: 1.75rem;
}
.p-datepicker table td {
  padding: 1px;
}
.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  transition: box-shadow 0.2s;
  border: 0 none;
}
.p-datepicker table td > span.p-highlight {
  color: #323130;
  background: #edebe9;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}
.p-datepicker table td.p-datepicker-today > span {
  background: $clr-pri !important;
  color: #ffffff;
  border-color: transparent;
}
// .p-datepicker table td.p-datepicker-today > span.p-highlight {
//   color: #323130;
//   background: #edebe9;
// }
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #edebe9;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #edebe9;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #605e5c;
  border: 0 none;
  background: transparent;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #605e5c;
  border-color: transparent;
  background: #f3f2f1;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 1px;
  transition: box-shadow 0.2s;
  border-radius: 2px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #323130;
  background: #edebe9;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #edebe9;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #f3f2f1;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #f3f2f1;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}