// @import "~@swimlane/ngx-datatable/release/index.css";
// @import "~@swimlane/ngx-datatable/release/themes/material.css";
// @import "~@swimlane/ngx-datatable/release/assets/icons.css";

/* Survey Builder */

//@import "material-icons"; // material icons
@import "config"; // variables declarations

//@import "engine/foundation";

// structure
@import "layout"; // defines the whole layout
@import "buttons"; // button

// content

@import "vendor/quill/quill";

@import "grid";
@import "modules";
@import "login";
@import "forms";
@import "content";
@import "sidebar";
@import "surveys";
@import "questions";
@import "fivestar";
@import "settings";
@import "modal";
@import "translation";
@import "table";
@import "pagination";
@import "logic";
@import "administration";
@import "flags";
@import "library";
@import "campaign-configuration";
@import "pagination";
@import "distribution";
@import "data-source-wizard";
@import "responses";
@import "weightage";
@import "insight";
@import "notification";
@import "ng-datatables";
@import "mentions";
