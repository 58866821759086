.widget--categorytime {
  .widget__content {
    @include clearfix();
    padding: 15px 0 0;
    @include susy-breakpoint($md, $susy, '.no-mq') {
      padding:0;
    }
  }

  .col-left {
    @include span(4);
    padding-right: 1%;
    @include susy-breakpoint($md, $susy, '.no-mq') {
      @include span(12 no-gutter);
      padding-top: 0;
      padding-right: 0;
    }
  }

  .col-right {
    @include span(8);
    @include susy-breakpoint($md, $susy, '.no-mq') {
      @include span(12 no-gutter);
     // padding-top: 0;
     // padding-right: 0;
    }

  }
  .col-full {
    @include span(12 no-gutter);
    padding-top: 20px;
  }

}

.cattime-chart {
  padding-left: 60px;
}

.table--cattime {
  width: 100%;
  max-width: 100%;
  clear: both;
  font-size: 12px;
  height: auto;
  @include susy-breakpoint($md, $susy, '.no-mq') {
    overflow-x: auto;
  }
  table {
    width: 100%;
    tr {
      border: 1px solid $white;
      &.positive {
        background: #f3f8fb;
      }

      &.negative {
        background: #fff7f7;
      }

      &.neutral {
        background: #fff9f6;
      }

      &.positive {
        // background: #f3f8fb;
      }

    }
    td, th {
      padding: 7px 10px;
      min-width: 50px;
      white-space: nowrap;
      font-size: 12px;
      &:first-child {
        white-space: normal;
      }
    }
  }

}

.category-table-legends {
  position: relative;
  padding-left: 20px;
  word-break: break-word;

  .rounded-bullet {
    width: 10px;
    height: 10px;
    background: $clr-pri;
    display: inline-block;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    left: 0;
    top:2px;
    &.blue {
      background: #007bff;
    }
    &.green {
      background: #3bb001;
    }

    &.yellow {
      background: #ffc107;
    }

    &.red {
      background: #dc3545;
    }
    &.voc-color0 {
      background: #007bff;
    }
    &.voc-color1 {
      background: #ffc107;
    }
    &.voc-color2 {
      background: #3bb000;
    }
    &.voc-color3 {
      background: #dc3545;
    }
    &.voc-color4 {
      background: #6f42c1;
    }
    &.voc-color5 {
      background: #17a2b8;
    }
    &.voc-color6 {
      background: #27d4d4;
    }
    &.voc-color7 {
      background: #fd7e14;
    }
    &.voc-color8 {
      background: #4ca1fe;
    }
    &.voc-color9 {
      background: #34495e;
    }
    &.voc-color10 {
      background: #cad0e8;
    }
    &.voc-color11 {
      background: #d4c99e;
    }
    &.voc-color12 {
      background: #a25016;
    }
    &.voc-color13 {
      background: #566127;
    }
    &.voc-color14 {
      background: #b62b6e;
    }

  }
}