.ui-breadcrumb {
  background-color: $panelContentBgColor;
  border: $panelContentBorder;
  padding: $panelContentPadding;

  ul {
    li {
      .ui-menuitem-link {
        color: $panelContentTextColor;
        margin: 0;
      }

      &.ui-breadcrumb-chevron {
        margin: 0 $inlineSpacing 0 $inlineSpacing;
        color: $textSecondaryColor;
      }

      &:first-child {
        a {
          color: $textSecondaryColor;
          margin: 0;
        }
      }

      .ui-menuitem-icon {
        color: $textSecondaryColor;
      }
    }
  }
}

//.p-steps {
//    position: relative;
//
//    .p-steps-item {
//        background-color: transparent;
//        text-align: center;
//
//        .ui-menuitem-link {
//            display: inline-block;
//            text-align: center;
//            background-color: transparent;
//            overflow: hidden;
//
//            .p-steps-number {
//                @include border-radius(50%);
//                display: inline-block;
//                color: $stepsItemNumberColor;
//                background-color: $stepsItemBgColor;
//                border: $stepsItemBorder;
//                position: relative;
//                top: $fontSize + 2;
//                margin-bottom: $fontSize;
//                width: $stepsItemWidth;
//                height: $stepsItemHeight;
//                font-size: $fontSize + 2;
//                line-height: $stepsItemHeight - 4;
//                text-align: center;
//            }
//
//            .p-steps-title {
//                display: block;
//                margin-top: 6px;
//                color: $stepsItemTextColor;
//            }
//        }
//
//        &.ui-state-highlight {
//            .p-steps-number {
//                background: $highlightBgColor;
//                color: $highlightColorText;
//            }
//
//            .p-steps-title {
//                font-weight: 700;
//                color: $textColor;
//            }
//        }
//
//        &:last-child {
//            .ui-menuitem-link {
//                display: block;
//            }
//        }
//    }
//
//    &:before {
//        content:' ';
//        border-top: $panelContentBorder;
//        width: 100%;
//        top: 45%;
//        left: 0;
//        display: block;
//        position: absolute;
//    }
//}

.ui-menu .ui-menuitem-link,
.ui-menubar .ui-menuitem-link,
.ui-tieredmenu .ui-menuitem-link,
.ui-contextmenu .ui-menuitem-link,
.ui-megamenu .ui-menuitem-link,
.ui-slidemenu .ui-menuitem-link {
  padding: $menuitemPadding;
  color: $menuitemTextColor;
  font-weight: normal;
  @include border-radius(0);

  .ui-menuitem-text {
    color: $menuitemTextColor;
  }

  .ui-menuitem-icon {
    color: $menuitemIconColor;
    margin-right: $inlineSpacing;
    width: 1em;
    text-align: center;
  }

  &:hover {
    background-color: $menuitemHoverBgColor;

    .ui-menuitem-text {
      color: $menuitemHoverTextColor;
    }

    .ui-menuitem-icon {
      color: $menuitemHoverIconColor;
    }
  }
}

.ui-menubar {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $menuBorder;

  .ui-menubar-root-list {
    > .ui-menuitem {
      > .ui-menuitem-link {
        padding: $menuitemPadding;
      }
    }

    > .ui-menu-separator {
      border: $menuBorder;
      border-width: 0 0 0 1px;
    }
  }

  .ui-submenu-list {
    padding: $submenuPadding;
    background-color: $menuBgColor;
    border: $overlayMenuBorder;
    @include shadow($overlayMenuShadow);

    .ui-menuitem {
      margin: $menuitemMargin;
    }

    .ui-menu-separator {
      border: $menuBorder;
      border-width: 1px 0 0 0;
    }
  }

  .ui-menuitem {
    margin: $menuitemMargin;

    &.ui-menuitem-active {
      > .ui-menuitem-link {
        background-color: $menuitemActiveBgColor;

        .ui-menuitem-text {
          color: $menuitemActiveTextColor;
        }

        .ui-menuitem-icon,
        .ui-submenu-icon {
          color: $menuitemActiveIconColor;
        }
      }
    }
  }
}

.ui-contextmenu {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $overlayMenuBorder;
  @include shadow($overlayMenuShadow);

  .ui-submenu-list {
    padding: $submenuPadding;
    background-color: $menuBgColor;
    border: $overlayMenuBorder;
    @include shadow($overlayMenuShadow);
  }

  .ui-menuitem {
    margin: $menuitemMargin;

    .ui-menuitem-link {
      .ui-submenu-icon {
        right: 0.429em;
      }
    }

    &.ui-menuitem-active {
      > .ui-menuitem-link {
        background-color: $menuitemActiveBgColor;

        .ui-menuitem-text {
          color: $menuitemActiveTextColor;
        }

        .ui-menuitem-icon,
        .ui-submenu-icon {
          color: $menuitemActiveIconColor;
        }
      }
    }
  }

  .ui-menu-separator {
    border: $menuBorder;
    border-width: 1px 0 0 0;
  }
}

.ui-tieredmenu {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $menuBorder;

  .ui-submenu-list {
    padding: $submenuPadding;
    background-color: $menuBgColor;
    border: $overlayMenuBorder;
    @include shadow($overlayMenuShadow);
  }

  .ui-menuitem {
    margin: $menuitemMargin;

    .ui-menuitem-link {
      .ui-submenu-icon {
        right: 0.429em;
      }
    }

    &.ui-menuitem-active {
      > .ui-menuitem-link {
        background-color: $menuitemActiveBgColor;

        .ui-menuitem-text {
          color: $menuitemActiveTextColor;
        }

        .ui-menuitem-icon,
        .ui-submenu-icon {
          color: $menuitemActiveIconColor;
        }
      }
    }
  }

  .ui-menu-separator {
    border: $menuBorder;
    border-width: 1px 0 0 0;
  }
}

.ui-slidemenu {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $menuBorder;

  .ui-submenu-list {
    padding: $submenuPadding;
    background-color: $menuBgColor;
    border: 0 none;
  }

  .ui-menuitem {
    margin: $menuitemMargin;

    .ui-menuitem-link {
      .ui-submenu-icon {
        right: 0.429em;
      }
    }

    &.ui-menuitem-active {
      > .ui-menuitem-link {
        background-color: $menuitemActiveBgColor;

        .ui-menuitem-text {
          color: $menuitemActiveTextColor;
        }

        .ui-menuitem-icon,
        .ui-submenu-icon {
          color: $menuitemActiveIconColor;
        }
      }
    }
  }

  .ui-slidemenu-backward {
    margin: $submenuHeaderMargin;
    padding: $panelHeaderPadding;
    color: $panelHeaderTextColor;
    background-color: $panelHeaderBgColor;
    font-weight: $panelHeaderFontWeight;
    border: 0 none;

    &:hover {
      background-color: $panelHeaderHoverBgColor;
      color: $panelHeaderTextColor;
    }
  }

  .ui-menu-separator {
    border: $menuBorder;
    border-width: 1px 0 0 0;
  }
}

.ui-megamenu {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $menuBorder;

  .ui-megamenu-submenu-header {
    margin: $submenuHeaderMargin;
    padding: $panelHeaderPadding;
    color: $panelHeaderTextColor;
    background-color: $panelHeaderBgColor;
    font-weight: $panelHeaderFontWeight;
    border: 0 none;
  }

  .ui-megamenu-panel {
    padding: $submenuPadding;
    background-color: $menuBgColor;
    border: $overlayMenuBorder;
    @include shadow($overlayMenuShadow);
  }

  .ui-menuitem {
    margin: $menuitemMargin;

    .ui-menuitem-link {
      .ui-submenu-icon {
        right: 0.429em;
      }
    }

    &.ui-menuitem-active {
      > .ui-menuitem-link {
        background-color: $menuitemActiveBgColor;

        .ui-menuitem-text {
          color: $menuitemActiveTextColor;
        }

        .ui-menuitem-icon,
        .ui-submenu-icon {
          color: $menuitemActiveIconColor;
        }
      }
    }
  }

  &.ui-megamenu-vertical {
    .ui-megamenu-root-list {
      > .ui-menuitem {
        > .ui-menuitem-link {
          > .ui-submenu-icon {
            right: 0.429em;
          }
        }
      }
    }
  }
}
