//performance trend

.page-performancetrend {

  .widget__graph {
    padding: 30px 0;
  }
}

.page__filters {
  clear: both;
  display: flex;
  position: relative;
  background: #f5f6f7;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  &.pt--filters {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-top: 0;
    justify-content: start;
    border-top: 0;
    padding: 0 10px;
  }

  &.margin-top-none {
    margin-top: 0;
  }

  @include susy-breakpoint($md, $susy, ".no-mq") {
    flex-flow: row wrap;
  }

  .filter-item {
    padding: 10px;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      flex-basis: 100%;
    }

    &.filter-item--toggle {

      // flex: 1;
      @include susy-breakpoint($md, $susy, ".no-mq") {
        padding-top: 0;
      }
    }

    &.filter-item--hierarchy {
      //   flex:2;
      //   margin-left: auto;
      //align-items:center;
    }

  }
}

.filter-hierarchy {
  label {
    font-size: 12px;
    float: left;
    line-height: 37px;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      width: 100%;
    }
  }

  .filter-dropdown {

    top: 55px;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      top: 85px;
    }

  }

  .filter-field {
    background: $white;
    padding: 0;
    margin: 0 10px;
    width: 650px;
    position: static;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      width: calc(100% - 58px);
      margin-left: 0;
    }

    .filter__trigger {
      padding: 8px 10px 3px;
      border: 1px solid#ebebeb;
      min-height: 37px;
      border-radius: 3px;
      position: relative;
    }

    .field__icons {
      top: 8px;
      right: 15px;
    }
  }

  .btn {
    float: left;
    padding: 11px 15px;
  }

}