.toggle {
  ul {
    li {
      display: table-cell;
      vertical-align: middle;
      label {
        font-size: 12px;
        font-weight: 400;
        color: #969dab;
        cursor: pointer;
        text-transform: capitalize;
        margin: 0;
        :lang(fr) {
          text-transform: none;
        }
        :active {
          color: Black;
        }

        &.toggle__switch {
          margin: 0 10px;
        }
      }
    }
  }

  &.toggle--right {
    float: right;
  }

  input {
    position: absolute;
    left: -9999px;
  }
  .toggle__switch {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    border-radius: 20px;
    transition: 350ms;
    border: 1px solid #d0d7dd;
    &::after {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      top: -4px;
      left: 15px;
      border-radius: 50%;
      transition: 250ms ease-in-out;
      background: $white;
      border: 1px solid #d0d7dd;
    }
  }

  input:checked + .toggle__switch {
    &::after {
      left: -2px;
    }
  }
}
.toggle_label {
  padding-right: 5px;
  vertical-align: middle;
}
