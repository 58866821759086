
.p-chips {
  display: block !important;
  border-radius: 3px;
  border: 1px solid #d0d7dd;
  padding: 2px 10px 0px;
  > ul.p-inputtext {
    width: 100%;
    min-height: 25px;
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 !important;
    border: none !important;
  }
  > .ui-state-disabled {
    .p-chips-token-label {
      margin-right: 0;
    }
  }
}

.p-chips-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  border: 0 none;
  font-size: 0.9em;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 3px 20px 2px 7px;
  :lang(fr) {
    text-transform: none;
  }
  .p-chips-token-label {
    display: block;
  }
  .p-chips-token-icon {
    margin-top: -0.5em;
    position: absolute;
    right: 5px;
    top: 50%;
    cursor: pointer;
    .p-chips--without-close & {
      display: none;
    }
  }
  .p-chips--without-close & {
    padding-right: 7px;
  }
}

.p-chips-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  input {
    border: 0 none;
    width: 10em;
    outline: medium none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    min-height: 25px;
  }
}


.p-chips {
  > ul.p-inputtext {
    //padding: $inputPadding / 2 $inputPadding;
    display: inline-block;

    &:not(.ui-state-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    &:not(.ui-state-disabled).ui-state-focus {
      //@include focused-input();
    }

    .p-chips-input-token {
      //padding: $inputPadding / 2 0;

      input {
        font-family: $fontFamily;
        font-size: $fontSize;
        padding: 0;
        margin: 0;
        color: $textColor;
        border: 0 none;

        &:hover {
          border: 0 none;
        }

        &:focus {
          border: 0 none;
          box-shadow: none;
        }
      }
    }

    .p-chips-token {
      font-size: $fontSize;
      //padding: $inputPadding / 2 $inputPadding;
      margin: $chipsItemMargin;
      background: #4da1ff;
      color: $white;
    }
  }
}
