//areas for improvements
.widget--areasforimprovements, .widget--keyperformance {
  overflow: hidden;
  // background: #f8f9fa;

  .highcharts-container {
    overflow: visible !important;
  }
  .half-column {
    @include span(6);
    .widget__footer {
      margin:0 -10px -10px;

    }
  }

  .widget__footer {
    border-top: 1px solid #ebebeb;
    padding: 20px;
    margin:0 -20px -20px;
    position: relative;
    background: #f4f5f7;
    @include arrow('top', 'center', 10px, #f4f5f7, #ebebeb, 1px);

  }

  .table {
    tr {
      &:last-child {
        border-bottom: 0;
      }
    }
    th {
      //text-transform: uppercase;
      padding:10px;
      font-weight:400;
      font-size:12px;
      vertical-align: top;
      :lang(fr) {
        text-transform: none;
      }

    }
    td {
      vertical-align: middle;
      font-size:12px;
      padding:10px;
      font-weight: normal;
      &:first-child {
        text-transform: uppercase;
        :lang(fr) {
          text-transform: none;
        }
      }

    }
  }
  .lowest__score {
    border-top: 1px solid #eee;
  }
  .highest__score {
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
  }
  .highest__score, .lowest__score {
    float: left;
    width: 50%;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      width: 100%;
    }
  }
  .highest__score {
    //border-right: 1px solid #d6dbdf;
  }
}

.widget--keyperformance {
  .widget__content {
    padding: 0;
    background: #f4f5f7;
    @include clearfix();
  }
  .widget__graph {
    padding: 0 20px;
  }
  .table {
    padding: 0 20px;
    width: 100%;
    th{
      text-transform: inherit;
    }
    tr {
      border-bottom: 1px solid #e7ebef;
    }
  }
  .widget__footer {
    padding: 20px;
    margin: 0;
  }
  .highest__score, .lowest__score {
    background: #ffffff;
  }
}
