// grid

$width: 100%;
//$gutter: 2%;

.row-grid {
  margin: 0 -10px;
}

.row-grid .col {
  float: left;
  padding: 10px;
}

.row-grid::after {
  content: "";
  display: table;
  clear: both;
}

.col-1 {
  width: calc($width / 12);
}
.col-2 {
  width: calc($width / 6);
}
.col-3 {
  width: calc($width / 4);
}
.col-4 {
  width: calc($width / 3);
}
.col-5 {
  width: calc($width / (12 / 5));
}
.col-6 {
  width: calc($width / 2);
}
.col-7 {
  width: calc($width / (12 / 7));
}
.col-8 {
  width: calc($width / (12 / 8));
}
.col-9 {
  width: calc($width / (12 / 9));
}
.col-10 {
  width: calc($width / (12 / 10));
}
.col-11 {
  width: calc($width / (12 / 11));
}
.col-12 {
  width: $width;
}

.col-three-dashboard {
  float: left;
  width: calc($width / 4);
}
