// Variables:
$color--text: #546a79;
$color--border: #e1e1e1;
$radius: 2px;
$field-border: #E6E6E6;
$clr-error: #e74c3c;

.login-page {
  display: flex;
  width: 100%;
  padding: 30px;
  color: #212529;
  height: 100vh;
  // table-layout: fixed;
  background: $white;
  display: inline-flex;
  flex-direction: row;

  @include susy-breakpoint($md, $susy, ".no-mq") {
    display: flex;
    padding: 20px;
    flex-direction: column;

  }

  .col-right {
    //  display: table-cell;
    width: 50%;
    height: 100%;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      width: 100%;
    }
  }

  .col-left {
    //   display: table-cell;
    width: 50%;
    // background: url("#{$path_images}/login-screen.jpg") no-repeat left top;
    // background-size: cover;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      // display: none; 
      width: 100%;
    }
  }


  .col-right-mbc {
    display: table-cell;
    width: 50%;
    background: url("#{$path_images}/login-screen-mbc.jpg") no-repeat center center;
    background-size: cover;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      display: none;
    }
  }

  .col-right-reflecx {
    display: table-cell;
    width: 50%;
    background: url("#{$path_images}/login-screen-reflecx.jpg") no-repeat center center;
    background-size: cover;

    @include susy-breakpoint($md, $susy, ".no-mq") {
      display: none;
    }
  }
}

.login-form {
  height: 100%;
  display: table;
  table-layout: fixed;
  width: 100%;

  .form {
    display: table-cell;
    vertical-align: middle;
    max-width: 100%;

    .form__inner {
      padding: 0 15%;
      width: 100%;

      @include susy-breakpoint($md, $susy, ".no-mq") {
        padding: 0 5%;
      }
    }

    .login-logo {
      margin-bottom: 20px;

      img {
        max-width: 140px;
      }

    }

    &__header {
      padding: 0 0 30px;

      .logo-login {
        text-align: left;
        display: none;
        margin-bottom: 20px;

        img {
          max-width: 220px;
        }

        @include susy-breakpoint($md, $susy, ".no-mq") {
          display: block;
        }
      }

      h3 {
        font-size: 30px;
        font-weight: normal;
        padding-bottom: 5px;
        letter-spacing: -1.5px;
        line-height: 1.2;
      }

      p {
        font-size: 18px;
      }
    }

    .form__group {
      padding: 0 0 25px;
      position: relative;
      @include transition();

      &.login--actions {
        padding-bottom: 0;
      }
    }

    .form__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $clr-pri;
      font-size: 18px;
    }

    .form__label {
      font-size: 14px;
      text-transform: uppercase;
    }

    .form__input {
      width: 100%;
      max-width: 100%;
      font-size: 18px;
      color: #666;
      padding: 10px 0 10px 0;
      border: 0;
      border-bottom: 1px solid $field-border;
      background: $white;
      transition: all 0.3s;
      line-height: 1.2;
      @include placeholder-color(#a6b1b9);

      &:focus {
        border-color: #bfbfbf;
        outline: none;
        box-shadow: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
    }

    .validation-message {
      color: $clr-error;
      font-size: 12px;
    }

    .field__error {
      color: $clr-error;
      font-size: 13px;
      margin-top: 3px;
      background: #F4F4F4;
      border: 1px solid #E6E6E6;
      display: inline-block;
      font-family: "GiloryLight";

      p {
        padding: 5px;
      }
    }

    .actions {
      font-size: 13px;

      .form-link {
        float: right;
        padding: 10px 0 0 0;
      }

      .btn {
        float: left;
      }
    }
  }
}


.login--actions {
  text-align: right;
}



.login-btn {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 25px;
  background: rgb(247, 147, 30);
  background: linear-gradient(90deg, rgba(247, 147, 30, 1) 5%, rgba(241, 90, 36, 1) 60%);
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  font-weight: 500;

  &:hover {
    background: rgb(241, 90, 36);
    color: white;
    background: linear-gradient(90deg, rgba(241, 90, 36, 1) 10%, rgba(247, 147, 30, 1) 70%);

  }
}



.password-hints {
  background: #F5F5F5;
  padding: 10px;

  li {
    display: block;
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 3px;
    position: relative;
    padding-left: 20px;

    &::before {
      content: "\2014";
      color: #BBBBBB;
      position: absolute;
      top: 0;
      left: 0;
    }
  }


}

.password-hints-title {
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
}


.error--field {
  .form__input {
    border-color: #DE7A7A !important;
  }

  .form__label {
    color: #DE7A7A;
  }

}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-vertical>.swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;

  &.swiper-vertical {
    touch-action: pan-x;
  }
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-autoheight {

  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

/* 3D Effects */
.swiper-3d {

  &,
  &.swiper-css-mode .swiper-wrapper {
    perspective: 1200px;
  }

  .swiper-wrapper,
  .swiper-slide,
  .swiper-slide-shadow,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-cube-shadow {
    transform-style: preserve-3d;
  }

  .swiper-slide-shadow,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }

  .swiper-slide-shadow {
    background: rgba(0, 0, 0, 0.15);
  }

  .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }

  .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
}

/* CSS Mode */
.swiper-css-mode {
  >.swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;

    /* For Internet Explorer and Edge */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  >.swiper-wrapper>.swiper-slide {
    scroll-snap-align: start start;
  }
}

.swiper-horizontal.swiper-css-mode {
  >.swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
}

.swiper-vertical.swiper-css-mode {
  >.swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
}

.swiper-centered {
  >.swiper-wrapper::before {
    content: '';
    flex-shrink: 0;
    order: 9999;
  }

  &.swiper-horizontal {
    >.swiper-wrapper>.swiper-slide:first-child {
      margin-inline-start: var(--swiper-centered-offset-before);
    }

    >.swiper-wrapper::before {
      height: 100%;
      width: var(--swiper-centered-offset-after);
    }
  }

  &.swiper-vertical {
    >.swiper-wrapper>.swiper-slide:first-child {
      margin-block-start: var(--swiper-centered-offset-before);
    }

    >.swiper-wrapper::before {
      width: 100%;
      height: var(--swiper-centered-offset-after);
    }
  }

  >.swiper-wrapper>.swiper-slide {
    scroll-snap-align: center center;
  }
}


.swiper-fade {
  &.swiper-free-mode {
    .swiper-slide {
      transition-timing-function: ease-out;
    }
  }

  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;

    .swiper-slide {
      pointer-events: none;
    }
  }

  .swiper-slide-active {

    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}




.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 40px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;

  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }

  .swiper-pagination-bullet-active {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }

  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  //  background: var(--swiper-pagination-bullet-inactive-color, #fff);
  //  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  border: 1px solid #fff;

  // @at-root button#{&} {
  //   border: none;
  //   margin: 0;
  //   padding: 0;
  //   box-shadow: none;
  //   appearance: none;
  // }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: white;
  // background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-vertical>.swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);

  .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
    display: block;
  }

  &.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;

    .swiper-pagination-bullet {
      display: inline-block;
      transition: 200ms transform, 200ms top;
    }
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  }

  &.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    .swiper-pagination-bullet {
      transition: 200ms transform, 200ms left;
    }
  }
}

.swiper-horizontal.swiper-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;

  .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }

  .swiper-rtl & .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }

  .swiper-horizontal>&,
  &.swiper-pagination-horizontal,
  .swiper-vertical>&.swiper-pagination-progressbar-opposite,
  &.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }

  .swiper-vertical>&,
  &.swiper-pagination-vertical,
  .swiper-horizontal>&.swiper-pagination-progressbar-opposite,
  &.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.swiper-pagination-lock {
  display: none;
}


.slider-caption {
  position: absolute;
  right: 10%;
  bottom: 60px;
  left: 10%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;

  //   @include respondTo($lg) {
  //  left: 5%;
  //  right: 5%;
  //    }   

  p {
    line-height: 1.3;
    font-size: 26px;
    // @include respondTo($lg) {
    //  font-size: 20px;
    //  line-height: 1.5;
    //  }   
  }
}