.ui-inputtext, .p-inputtext {
  font-size: $fontSize;
  color: $textColor;
  background: $inputBgColor;
  padding: $inputPadding;
  border: $inputBorder;
  max-width: 100%;
  @include transition($inputTransition);
  @include appearance(none);

  &:enabled:hover:not(.ui-state-error) {
    border-color: $inputHoverBorderColor;
  }

  &:enabled:focus:not(.ui-state-error) {
    @include focused-input();
  }
  &.fluid {
    width: 100%;
  }
  &.two-digit {
    max-width: 70px;
  }
  &.count {
    max-width: 100px;
  }
  &.editable-text {
    width: 100%;
    font-size: 14px;
    border: 2px dashed #e9ecef !important;
    padding: 10px;
    min-height: auto !important;
    font-family: inherit;
    &:focus {
      box-shadow: none !important;
      border-color: #ccc !important;
    }
  }
}
.input-search {
  position: relative;
  display: inline-block;
  max-width: 100%;
  .header-actions & {
    width: 220px;

    .ui-inputtext, .p-inputtext{
      border-radius:3px 0 0 3px;
    }

  }
  &:before {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -7px;
    content: '\f002';
    font-size: 14px;
    line-height: 14px;
    font-family: "Font Awesome 5 Pro";
    color: #969dab;
  }
  .ui-inputtext, .p-inputtext {
    width: 100%;
    padding-left: 30px;
  }
}





.ui-autocomplete {
  .ui-autocomplete-input {
    padding: $inputPadding;
  }

  &.ui-autocomplete-multiple {
    .ui-autocomplete-multiple-container {
      padding: $inputPadding / 2 $inputPadding;

      &:not(.ui-state-disabled):hover {
        border-color: $inputHoverBorderColor;
      }

      &:not(.ui-state-disabled).ui-state-focus {
        @include focused-input();
      }

      .ui-autocomplete-input-token {
        margin: 0;
        padding: $inputPadding / 2 0;
        color: $textColor;

        input {
          font-family: $fontFamily;
          font-size: $fontSize;
          padding: 0;
          margin: 0;
        }
      }

      .ui-autocomplete-token {
        font-size: $fontSize;
        padding: $inputPadding / 2 $inputPadding;
        margin: $chipsItemMargin;
        background: $highlightBgColor;
        color: $highlightColorText;
      }
    }
  }
}

.p-autocomplete-panel {
  padding: 0;
  border: $inputOverlayBorder;
  background-color: $inputListBgColor;
  @include shadow($inputOverlayShadow);

  .p-autocomplete-items {
    padding: $inputListPadding;

    .p-autocomplete-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;
      @include border-radius(0);

      &.p-highlight  {
        color: $inputListItemHighlightTextColor;
        background-color: $inputListItemHighlightBgColor;
      }
    }

    .p-autocomplete-group {
      padding: $inputListItemPadding;
      background-color: $inputListItemDividerColor;
      color: $textColor;
    }
  }
}

.ui-fluid {
  .ui-autocomplete {
    .ui-autocomplete-dropdown.ui-button {
      width: $buttonIconOnlyWidth;
    }

    &.ui-autocomplete-multiple.ui-autocomplete-dd {
      .ui-autocomplete-multiple-container {
        border-right: 0 none;
        width: calc(100% - #{$buttonIconOnlyWidth});
      }
    }

    &.ui-autocomplete-dd {
      .p-inputtext {
        border-right: 0 none;
        width: calc(100% - #{$buttonIconOnlyWidth});
      }
    }
  }
}

.ui-multiselect, .p-multiselect {
  background: $inputBgColor;
  border: $inputBorder;
  @include transition($inputTransition);

  &:not(.ui-state-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.ui-state-disabled).ui-state-focus {
    @include focused-input();
  }

  .ui-multiselect-label, .p-multiselect-label {
    padding: $inputPadding;
    padding-right: 2em;
    color: $textColor;
    min-height: 30px;
  }

  .ui-multiselect-trigger, .p-multiselect-trigger {
    background-color: $inputBgColor;
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    color: $inputDropdownIconColor;
  }
}

.ui-multiselect-panel, .p-multiselect-panel {
  padding: 0;
  border: $inputOverlayBorder;
  background-color: $inputListBgColor;
  @include shadow($inputOverlayShadow);

  .ui-multiselect-header, .p-multiselect-header {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
    margin: $inputListHeaderMargin;

    .ui-multiselect-filter-container, .p-multiselect-filter-container {
      float: none;
      width: calc(100% - 45px);
      display: inline-block;
      vertical-align: middle;
      margin-left: 0;

      .ui-inputtext, .p-inputtext {
        padding: $inputPadding;
        padding-right: 2em;
      }

      .ui-multiselect-filter-icon, .p-multiselect-filter-icon {
        color: $inputListHeaderSearchIconColor;
        top: 50%;
        margin-top: -0.5em;
        right: 0.5em;
        left: auto;
      }
    }

    .ui-chkbox, .p-checkbox {
      margin-right: $inlineSpacing;
      float: none;
      vertical-align: middle;
    }

    .ui-multiselect-close, .p-multiselect-close {
      color: $inputListHeaderCloseIconColor;
      top: 50%;
      margin-top: -0.5em;
      @include transition($inputListHeaderCloseIconTransition);

      &:hover {
        color: $inputListHeaderCloseIconHoverColor;
      }
    }
  }

  .ui-multiselect-items, .p-multiselect-items {
    padding: $inputListPadding;

    .ui-multiselect-item, .p-multiselect-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;
      @include border-radius(0);

      &.ui-state-highlight {
        color: $inputListItemHighlightTextColor;
        background-color: $inputListItemHighlightBgColor;
      }

      &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        color: $inputListItemHoverTextColor;
        background-color: $inputListItemHoverBgColor;
      }

      &.ui-state-disabled {
        .ui-chkbox-box, .p-checkbox-box {
          cursor: auto;

          &:hover {
            border: $inputBorder;
          }

          &.ui-state-active:hover {
            border-color: $checkboxActiveBorderColor;
          }
        }
      }

      .ui-checkbox, .p-checkbox {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin: 0 $inlineSpacing 0 0;
      }

      label {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.ui-listbox {
  padding: 0;
  min-width: $inputListMinWidth;
  background: $inputListBgColor;
  border: $inputListBorder;

  .ui-chkbox, .p-checkbox {
    margin: 0 $inlineSpacing 0 0;
  }

  .ui-listbox-header {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
    margin: $inputListHeaderMargin;

    .ui-listbox-filter-container {
      width: calc(
        100% -
          (
            #{$inputListHeaderPaddingLeft} + #{$inputListHeaderPaddingLeft} + #{$inlineSpacing}
          )
      );

      input {
        padding: $inputPadding;
        padding-right: 2em;
      }

      .ui-listbox-filter-icon {
        top: 50%;
        left: auto;
        margin-top: -0.5em;
        right: 0.5em;
        color: $inputListHeaderSearchIconColor;
      }
    }
  }

  .ui-listbox-list {
    background-color: $inputListBgColor;

    .ui-listbox-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;
      @include border-radius(0);

      &.ui-state-highlight {
        color: $inputListItemHighlightTextColor;
        background-color: $inputListItemHighlightBgColor;
      }

      .ui-chkbox, .p-checkbox {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin: 0 $inlineSpacing 0 0;
      }

      label {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &:not(.ui-state-disabled) {
    .ui-listbox-item {
      &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        color: $inputListItemHoverTextColor;
        background-color: $inputListItemHoverBgColor;
      }
    }
  }

  &.ui-state-disabled {
    .ui-chkbox-box, .p-checkbox-box {
      &:not(.ui-state-disabled):not(.ui-state-active):hover {
        border: $inputBorder;
      }
    }
  }

  .ui-listbox-footer {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight
      $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border-top: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
  }
}

.ui-editor-container {
  .ui-editor-toolbar {
    border: $panelHeaderBorder;
    background-color: $panelHeaderBgColor;
  }

  .ui-editor-content {
    border: $panelContentBorder;

    .ql-editor {
      background-color: $inputBgColor;
      color: $textColor;
    }
  }

  .ql-picker.ql-expanded {
    .ql-picker-label {
      color: $panelHeaderTextColor;
    }
  }

  .ql-stroke {
    stroke: $panelHeaderTextColor;
  }

  .ql-picker-label {
    color: $panelHeaderTextColor;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $highlightBgColor;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $highlightBgColor;
  }
}

.ui-rating {
  a {
    text-align: center;
    display: inline-block;
    color: $ratingStarIconColor;
    @include transition($ratingTransition);

    &.ui-rating-cancel {
      color: $ratingCancelIconColor;
    }

    .ui-rating-icon {
      font-size: $ratingIconFontSize;
    }
  }

  &:not(.ui-state-disabled):not(.ui-rating-readonly) {
    a {
      &:hover {
        color: $ratingStarIconHoverColor;
      }

      &.ui-rating-cancel {
        &:hover {
          color: $ratingCancelHoverIconColor;
        }
      }
    }
  }
}

.ui-spinner {
  .ui-spinner-input {
    padding-right: $spinnerButtonWidth + $inputPadding;
  }

  .ui-spinner-button {
    width: $spinnerButtonWidth;

    &.ui-spinner-up {
      @include border-radius-top-right($borderRadius);
    }

    &.ui-spinner-down {
      @include border-radius-bottom-right($borderRadius);
    }

    .ui-spinner-button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      width: 1em;
    }
  }
}

.ui-fluid {
  .ui-spinner {
    .ui-spinner-input {
      padding-right: $spinnerButtonWidth + $inputPadding;
    }

    .ui-spinner-button {
      width: $spinnerButtonWidth;

      .ui-spinner-button-icon {
        left: 50%;
      }
    }
  }
}

.p-slider {
  background-color: $sliderBgColor;
  border: $sliderBorder;

  &.p-slider-horizontal {
    height: $sliderHeight;

    .p-slider-handle {
      top: 50%;
      margin-top: -1 * $sliderHandleHeight / 2;
    }
  }

  &.p-slider-vertical {
    width: $sliderWidth;

    .p-slider-handle {
      left: 50%;
      margin-left: -1 * $sliderHandleWidth / 2;
    }
  }

  .p-slider-handle {
    height: $sliderHandleHeight;
    width: $sliderHandleWidth;
    background-color: $sliderHandleBgColor;
    border: $sliderHandleBorder;
    @include border-radius($sliderHandleBorderRadius);
    @include transition($sliderHandleTransition);
  }

  .p-slider-range {
    background-color: $sliderRangeBgColor;
  }

  &:not(.p-disabled) {
    .p-slider-handle:hover {
      background-color: $sliderHandleHoverBgColor;
      border: $sliderHandleHoverBorder;
    }
  }
}

.p-slider {
  .dropdown--surveyscore &, .filter-slider & {
    margin: 15px 10px;

    &.p-slider-horizontal {
      height: 5px;

      .p-slider-handle {
        margin-top: -10px;
      }
    }

    &.p-slider-vertical {
      width: 5px;

      .p-slider-handle {
        left: 50%;
        margin-left: -10px;
      }
    }

    .p-slider-handle {
      height: 20px;
      width: 20px;
      border: 5px solid $clr-pri;
      cursor: pointer;
      outline: none;
    }
    &:not(.p-disabled) {
      .p-slider-handle:hover {
        border: 5px solid $clr-pri;
      }
    }
  }
}

.ui-fileupload {
  .ui-fileupload-buttonbar {
    background-color: $panelHeaderBgColor;
    padding: $panelHeaderPadding;
    border: $panelHeaderBorder;
    color: $panelHeaderTextColor;
    border-bottom: 0 none;

    .ui-button {
      margin-right: 8px;
    }
  }

  .ui-fileupload-content {
    background-color: $panelContentBgColor;
    padding: $panelContentPadding;
    border: $panelContentBorder;
    color: $panelContentTextColor;
  }

  .ui-progressbar {
    top: 0;
  }
}

.ui-fileupload-choose {
  &:not(.ui-state-disabled):hover {
    background-color: $buttonHoverBgColor;
    color: $buttonHoverTextColor;
    border-color: $buttonHoverBorderColor;
  }

  &:not(.ui-state-disabled):active {
    background-color: $buttonActiveBgColor;
    color: $buttonActiveTextColor;
    border-color: $buttonActiveBorderColor;
  }

  &.ui-state-focus {
    outline: $buttonFocusOutline;
    outline-offset: $buttonFocusOutlineOffset;
  }
}

.ui-password-panel {
  padding: $inputContentPanelPadding;
  background-color: $inputContentPanelBgColor;
  color: $inputContentPanelTextColor;
  border: $inputOverlayBorder;
  @include shadow($inputOverlayShadow);
}

.ui-inputgroup {
  .ui-inputgroup-addon {
    border-color: $inputGroupBorderColor;
    background-color: $inputGroupBgColor;
    color: $inputGroupTextColor;
    padding: $inputPadding;
    min-width: 2em;

    &:first-child {
      @include border-radius-left($borderRadius);
    }

    &:last-child {
      @include border-radius-right($borderRadius);
    }

    &.ui-inputgroup-addon-checkbox {
      position: relative;

      .p-checkbox {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1 * $checkboxWidth / 2;
        margin-top: -1 * $checkboxHeight / 2;
      }
    }

    &.ui-inputgroup-addon-radiobutton {
      position: relative;

      .ui-radiobutton {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1 * $radiobuttonWidth / 2;
        margin-top: -1 * $radiobuttonHeight / 2;
      }
    }
  }

  .ui-button {
    &:first-child {
      @include border-radius-left($borderRadius);
    }

    &:last-child {
      @include border-radius-right($borderRadius);
    }
  }
}

.ui-fluid {
  .ui-inputgroup {
    .ui-button {
      width: auto;

      &.ui-button-icon-only {
        width: $buttonIconOnlyWidth;
      }
    }
  }
}

::-webkit-input-placeholder {
  color: $inputPlaceholderTextColor;
}

:-moz-placeholder {
  color: $inputPlaceholderTextColor;
}

::-moz-placeholder {
  color: $inputPlaceholderTextColor;
}

:-ms-input-placeholder {
  color: $inputPlaceholderTextColor;
}

/* Validations */
.p-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .p-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-calendar > .p-inputtext,
p-chips.ng-dirty.ng-invalid > .p-inputtext,
p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
p-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .p-inputtext,
p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
p-spinner.ng-dirty.ng-invalid > .p-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border: $inputErrorBorder;
}
