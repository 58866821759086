
/*

.ui-radiobutton {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.ui-radiobutton-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  position: relative;
}

.ui-radiobutton-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.ui-radiobutton,
.ui-radiobutton-label {
  vertical-align: middle;
}*/


.ui-radiobutton, .p-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: $radiobuttonWidth;
  height: $radiobuttonHeight;
  cursor: pointer;

  .ui-radiobutton-box, .p-radiobutton-box {
    border: $inputBorder;
    background-color: $inputBgColor;
    width: $radiobuttonWidth;
    height: $radiobuttonHeight;
    text-align: center;
    position: relative;
    @include transition($radiobuttonTransition);
    @include border-radius(50%);

    &:not(.ui-state-disabled):not(.ui-state-active):hover, &:not(.p-state-disabled):not(.p-state-active):hover {
      border-color: $inputHoverBorderColor;
    }

    &.ui-state-focus, &.p-state-focus {
      border-color: $radiobuttonFocusBorderColor;
      background-color: $radiobuttonFocusBgColor;
      color: $radiobuttonFocusTextColor;
      @include shadow($checkboxFocusShadow);
    }

    &.ui-state-active, &.p-highlight {
      border-color: $radiobuttonActiveBorderColor;
      background-color: $radiobuttonActiveBgColor;
      color: $radiobuttonActiveTextColor;

      .ui-radiobutton-icon, .p-radiobutton-icon {
        background-color: $radiobuttonActiveTextColor;
      }

      &:not(.ui-state-disabled):hover, &:not(.p-state-disabled):hover {
        border-color: $radiobuttonActiveHoverBorderColor;
        background-color: $radiobuttonActiveHoverBgColor;
        color: $radiobuttonActiveHoverTextColor;
      }

      &.ui-state-focus, &.p-state-focus {
        border-color: $radiobuttonActiveFocusBorderColor;
        background-color: $radiobuttonActiveFocusBgColor;
        color: $radiobuttonActiveFocusTextColor;
      }
    }

    .ui-radiobutton-icon, .p-radiobutton-icon {
      background: transparent;
      width: $radiobuttonWidth / 2;
      height: $radiobuttonHeight / 2;
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -1 * $radiobuttonWidth / 4;
      margin-top: -1 * $radiobuttonWidth / 4;
      @include transition(background-color $transitionDuration);
      @include border-radius(50%);

      &:before {
        display: none;
      }
    }
  }
}


.ui-radiobutton-label, .p-radiobutton-label {
  margin: 0 0 0 0.5em;
  vertical-align: middle;
  cursor: pointer;
}
