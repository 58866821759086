.resources-list {
  ul {
    li {
      a {
        padding: 15px 15px 15px 35px;
        font-size: 12px;
        color: #34495e;
        display: block;
        border-top: 1px solid #ebebeb;
        cursor: pointer;
        position: relative;
        &:before {
          content: "\f1c1";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          font-size: 16px;
          top: 13px;
          color: $clr-pri;
          font-weight: 300;
          left: 10px;
        }

        &:after {
          content: "\f019";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          font-size: 12px;
          top: 15px;
          color: #969dab;
          font-weight: 300;
          right: 15px;
        }
        &:hover {
          background: #f4f5f9;
          &:after {
            color: $clr-pri;
          }
        }
      }

      &:first-child {
        a {
          border: 0;
        }
      }
    }
  }
}
