.widget--totalhotalerts {
  .widget__content {
    @include clearfix();

    .obw-bottom-area {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .highcharts-tooltip>span {
    padding: 8px;
    background-color: rgba(255, 255, 255, 1);
    background-color: white \9;
    /* < IE11 */
    //  border: 1px solid #a8a7a5;
    z-index: 9999 !important;
  }

  .widget__graph {
    @include span(6);

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
    }

    // div {
    //   margin: 0 auto;
    // }

    .alert__count {
      font-size: 10px;
      color: red;
    }

  }

  .widget__counts {
    @include span(6);
    height: 240px;
    position: relative;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
      height: auto;
    }

  }

  .counts__table {
    @include center(vertical);
    width: 100%;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      position: static;
      top: 0;
      transform: none;
    }

    table {
      width: 100%;

      td {
        color: #969dab;
        padding: 5px 0;
        font-size: 16px;
        vertical-align: middle;

        .count__percentage {
          margin: 0 10px;
          white-space: nowrap;
        }

        .series__name {
          font-size: 13px;
        }
      }
    }

    .count__symbol {
      width: 8px;
      background: $clr-gray;
      margin-right: 10px;
      height: 8px;
      border-radius: 100%;
      display: inline-block;
    }

  }

}

//pie chart

.pie-chart {
  text-align: center;

  span {
    display: block;
    padding-bottom: 2px;
  }

  .chart__title {
    font-weight: normal;
    //cursor: pointer;
  }

  .chart__count {
    font-size: 30px;

  }

  .chart__label {
    font-size: 11px;
    font-weight: normal;
  }

  .chart__link {
    font-size: 14px;
    color: $clr-pri;
    cursor: pointer;
    font-weight: normal;
  }

}

.hc-clr-new {
  background: #3BB001 !important;
}

.hc-clr-inprogress {
  background: #FFC107 !important;
}

.hc-clr-late {
  background: #FD7E14 !important;
}

.hc-clr-closedintarget {
  background: #007BFF !important;
}

.hc-clr-closedouttarget {
  background: #DC3545 !important;
}

.hc-clr-followupyes {
  background: #27D4D4 !important;
}

.hc-clr-followupno {
  background: #17A2B8 !important;
}


.clr-green-background {
  background: $clr-green !important;
}

.clr-red-background {
  background: $clr-red !important;
}

.clr-yellow-background {
  background: $clr-yellow !important;
}

.clr-orange-background {
  background: #fd7e14 !important;
}

.clr-blue-background {
  background: $clr-pri !important;
}

.clr-gray-background {
  background: #7d7d7d !important;
}


.clr-purple-background {
  background: #e53c3c !important;
}

.clr-magenta-background {
  background: #17A2B8 !important;
}

// alert resolution

.widget--alertresolution {
  .widget__content {
    @include clearfix();
  }



  .widget__graph {
    @include span(4);

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
    }

    div {
      margin: 0 auto;
    }

    .alert__count {
      font-size: 10px;
      color: red;
    }

  }

  .widget__counts {
    @include span(8);
    height: 240px;
    position: relative;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
      height: auto;
    }

  }

  .counts__table {
    @include center(vertical);
    width: 95%;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      position: static;
      width: 100%;
      top: 0;
      transform: none;
    }

    table {
      width: 100%;

      td {
        color: #3a3c2f;
        padding: 5px 2px;
        font-size: 12px;
        vertical-align: middle;

        .social-progress-bar {
          margin: 0;
          min-width: 100px;

          .social-progress {
            border-radius: 0;
            background: white;
          }

          .p-progressbar {
            border-radius: 0 !important;
            background: none !important;
          }

          .p-progressbar-value {
            border-radius: 0 !important;
          }
        }


      }
    }



  }

}




.topic-stats {
  position: relative;

  .widget__content {
    @include clearfix();
  }

  .highcharts-tooltip>span {
    padding: 8px;
    background-color: rgba(255, 255, 255, 1);
    background-color: white \9;
    /* < IE11 */
    //  border: 1px solid #a8a7a5;
    z-index: 9999 !important;
  }

  .widget__graph {
    @include span(6);

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
    }

    div {
      margin: 0 auto;
    }

    .alert__count {
      font-size: 10px;
      color: red;
    }

  }

  .widget__counts {
    @include span(6);
    min-height: 100px;
    position: relative;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      @include span(12);
      height: auto;
    }

  }

  .counts__table {
    @include center(vertical);
    width: 100%;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      position: static;
      top: 0;
      transform: none;
    }

    table {
      width: 100%;

      td {
        color: #969dab;
        padding: 5px 0;
        font-size: 12px;
        vertical-align: middle;

        .count__percentage {
          margin: 0 10px;
          white-space: nowrap;
        }

        .series__name {
          font-size: 12px;
        }
      }
    }

    .count__symbol {
      width: 8px;
      background: $clr-gray;
      margin-right: 10px;
      height: 8px;
      border-radius: 100%;
      display: inline-block;
    }

  }

}


.table--status {
  td {
    text-align: left;
    padding: 10px 20px;

    &:first-child {
      border-right: 1px solid #e4e9ed;
    }

    h2 {
      font-size: 24px;
      font-weight: normal;

      small {
        font-size: 14px;
      }

      &.positive {
        color: $clr-green;
      }

      &.negative {
        color: $clr-red;
      }
    }

    p {
      font-size: 12px;
      font-weight: normal;
    }
  }
}


.ticket-container {
  padding-bottom: 20px;
}

.table-ticket {
  width: 100%;
  padding-bottom: 20px;

  td,
  th {
    padding: 5px;
    font-size: 12px;
    color: #34495e;
    text-align: left;
    vertical-align: middle;

    .status {
      padding: 2px 10px;
      border-radius: 3px;
      color: white;
      display: inline-block;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 11px;
      margin-top: 2px;

      &.open {
        background: #c72a1c;
      }

      &.pending {
        background: #3091ec;
      }

      &.solved {
        background-color: #87929d;
      }
    }
  }

  th {
    color: #adb3b9;
    font-weight: 400;
  }
}