.ngx-datatable {
  display: block;
  // overflow-x: hidden;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  @include clearfix();
  .my-surveys {
    width: 100%;
  }
  .alphabetic-filters {
    margin: 15px;
  }
  .datatable-body-cell {
    //.actions {
    //  a {
    //    color: #cdd4d8;
    //    margin: 0 15px;
    //    cursor: pointer;
    //    &:hover {
    //      color: $clr-pri;
    //    }
    //  }
    //  i {
    //    font-size: 22px;
    //  }
    //}
    .actions {
      margin: -6px 0 0 0;
      min-width: auto;
      max-width: auto;
      cursor: pointer;
      i {
        font-size: 14px;
        line-height: 30px;
      }
      a {
        display: inline-block;
        margin: 0 10px 0 0;
        color: #cdd4d8;
        width: 32px;
        height: 32px;
        border: 1px solid #cdd4d8;
        border-radius: 5px;
        text-align: center;
        &:hover {
          color: $clr-pri;
          border-color: $clr-pri;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: auto;
          .tooltiptext {
            display: none;
          }
          &:hover {
            color: #cdd4d8;
            border-color: #cdd4d8;
          }
        }
      }
    }
  }
  .datatable-header-inner[_ngcontent-c11] {
    margin-top: 0 !important;
  }

  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
  [hidden] {
    display: none !important;
  }
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:before,
    &:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  &.scroll-vertical {
    .datatable-body {
      overflow-y: auto;
    }
    &.virtualized .datatable-body .datatable-row-wrapper {
      position: absolute;
    }
  }
  &.scroll-horz .datatable-body {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  }
  &.fixed-header .datatable-header .datatable-header-inner {
    //white-space: nowrap;
    .datatable-header-cell {
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
  }
  &.fixed-row .datatable-scroll {
    //white-space: nowrap;
    outline: none;
    .datatable-body-row {
      //white-space: nowrap;
      border-bottom: 1px solid #ebebeb;
      .datatable-body-cell,
      .datatable-body-group-cell {
        //overflow: hidden;
        //white-space: nowrap;
        //text-overflow: ellipsis;
      }
    }
  }
  .datatable-body-row,
  .datatable-row-center,
  .datatable-header-inner {
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: flex;
    //-webkit-box-orient: horizontal;
    //-webkit-box-direction: normal;
    //-ms-flex-direction: row;
    //flex-direction: row;
    //-ms-flex-flow: row;
    //-o-flex-flow: row;
    //flex-flow: row;
    display: table;
    table-layout: fixed;
    outline: none;
  }
  .datatable-body-cell,
  .datatable-header-cell {
    //overflow-x: hidden;
    vertical-align: top;
    display: table-cell;
    line-height: 1.625;
    white-space: nowrap;
  }
  .datatable-body-cell:focus,
  .datatable-header-cell:focus {
    outline: none;
  }
  .datatable-row-left,
  .datatable-row-right {
    z-index: 9;
  }
  .datatable-row-left,
  .datatable-row-center,
  .datatable-row-group,
  .datatable-row-right {
    position: relative;
  }
  .datatable-header {
    display: block;
    overflow: hidden;
    .datatable-header-inner {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-align-items: stretch;
    }
    .datatable-header-cell {
      position: relative;
      display: table-cell;
      &.sortable .datatable-header-cell-wrapper {
        cursor: pointer;
      }
      &.longpress .datatable-header-cell-wrapper {
        cursor: move;
      }
      .sort-btn {
        line-height: 100%;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        margin-left:10px;
        color: $clr-pri;
        font-size:17px;
      }
      .resize-handle,
      .resize-handle--not-resizable {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        padding: 0 4px;
        visibility: hidden;
      }
      .resize-handle {
        cursor: ew-resize;
      }
      &.resizeable:hover .resize-handle,
      &:hover .resize-handle--not-resizable {
        visibility: visible;
      }
      .targetMarker {
        position: absolute;
        top: 0;
        bottom: 0;
        &.dragFromLeft {
          right: 0;
        }
        &.dragFromRight {
          left: 0;
        }
      }
      .datatable-header-cell-template-wrap {
        height: inherit;
      }
    }
  }
  .datatable-body {
    position: relative;
    z-index: 10;
    display: block;
    .datatable-scroll {
      //display: inline-block;
    }
    .datatable-row-detail {
      overflow-y: hidden;
    }
    .datatable-row-wrapper {
      //display: -webkit-box;
      //display: -ms-flexbox;
      //display: flex;
      //-webkit-box-orient: vertical;
      //-webkit-box-direction: normal;
      //-ms-flex-direction: column;
      //flex-direction: column;
      display: table;
      table-layout: fixed;
    }
    .datatable-body-row {
      outline: none;
      > div {
        //display: -webkit-box;
        //display: -ms-flexbox;
        //display: flex;
        display: table;
        table-layout: fixed;
      }
    }
  }
  .datatable-footer {
    display: block;
    width: 100%;
    .datatable-footer-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
    }
    .selected-count {
      .page-count {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 40%;
        flex: 1 1 40%;
      }
      .datatable-pager {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 60%;
        flex: 1 1 60%;
      }
    }
    .page-count {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 20%;
      flex: 1 1 20%;
    }
    .datatable-pager {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 80%;
      flex: 1 1 80%;
      text-align: right;
      //.pager {
      //  padding: 0;
      //  margin: 0;
      //  display: inline-block;
      //  list-style: none;
      //  li {
      //    padding: 0;
      //    margin: 0;
      //    display: inline-block;
      //    list-style: none;
      //    outline: none;
      //    a {
      //      outline: none;
      //      cursor: pointer;
      //      display: inline-block;
      //    }
      //    &.disabled a {
      //      cursor: not-allowed;
      //    }
      //  }
      //}
    }
  }
}

.datatable-mainbody-wrapper {
  font-size: 12px;
  background: #fff;
  border: 1px solid #e0e8ec;
  border-bottom: none;
  overflow: hidden;
  clear: both;
  .records-found {
    float: left;
    margin: 15px 0 0 20px;
    line-height: 34px;
  }
}
//Material Skin

.ngx-datatable {
  &.material {
    /**
       * Shared Styles
       */
    /**
       * Global Row Styles
       */
    /**
       * Header Styles
       */
    /**
       * Body Styles
       */
    /**
       * Footer Styles
       */
    &.striped .datatable-row-odd {
      background: #f4f5f9;
    }
    &.single-selection .datatable-body-row.active,
    &.multi-selection .datatable-body-row.active,
    &.multi-click-selection .datatable-body-row.active {
      background-color: #304ffe;
      color: #fff;
      .datatable-row-group {
        background-color: #304ffe;
        color: #fff;
      }
    }
    &.single-selection .datatable-body-row.active:hover,
    &.multi-selection .datatable-body-row.active:hover,
    &.multi-click-selection .datatable-body-row.active:hover {
      background-color: #193ae4;
      color: #fff;
      .datatable-row-group {
        background-color: #193ae4;
        color: #fff;
      }
    }
    &.single-selection .datatable-body-row.active:focus,
    &.multi-selection .datatable-body-row.active:focus,
    &.multi-click-selection .datatable-body-row.active:focus {
      background-color: #2041ef;
      color: #fff;
      .datatable-row-group {
        background-color: #2041ef;
        color: #fff;
      }
    }
    &:not(.cell-selection) .datatable-body-row {
      &:hover {
        //background-color: #eee;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        .datatable-row-group {
          //background-color: #eee;
          transition-property: background;
          transition-duration: 0.3s;
          transition-timing-function: linear;
        }
      }
      &:focus {
        //background-color: #ddd;
        .datatable-row-group {
          //background-color: #ddd;
        }
      }
    }
    &.cell-selection .datatable-body-cell {
      &:hover {
        background-color: #eee;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        .datatable-row-group {
          background-color: #eee;
          transition-property: background;
          transition-duration: 0.3s;
          transition-timing-function: linear;
        }
      }
      &:focus {
        background-color: #ddd;
        .datatable-row-group {
          background-color: #ddd;
        }
      }
      &.active {
        background-color: #304ffe;
        color: #fff;
        .datatable-row-group {
          background-color: #304ffe;
          color: #fff;
        }
        &:hover {
          background-color: #193ae4;
          color: #fff;
          .datatable-row-group {
            background-color: #193ae4;
            color: #fff;
          }
        }
        &:focus {
          background-color: #2041ef;
          color: #fff;
          .datatable-row-group {
            background-color: #2041ef;
            color: #fff;
          }
        }
      }
    }
    .empty-row {
      height: 50px;
      text-align: left;
      padding: 0.5rem 1.2rem;
      vertical-align: top;
      border-top: 0;
    }
    .loading-row {
      text-align: left;
      padding: 0.5rem 1.2rem;
      vertical-align: top;
      border-top: 0;
    }
    .datatable-header .datatable-row-left,
    .datatable-body .datatable-row-left {
      background-color: #fff;
      background-position: 100% 0;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
    }
    .datatable-header .datatable-row-right,
    .datatable-body .datatable-row-right {
      background-position: 0 0;
      background-color: #fff;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
    }
    .datatable-header {
      border-bottom: 1px solid #ebebeb;
      border-top: 1px solid #ebebeb;
      .datatable-header-cell {
        text-align: left;
        padding: 15px 20px;
        font-weight: 400;
        color: #a9b4bd;
        vertical-align: bottom;
        //font-size: 12px;
        .datatable-header-cell-wrapper {
          position: relative;
        }
        &.longpress .draggable::after {
          transition: transform 400ms ease, opacity 400ms ease;
          opacity: 0.5;
          transform: scale(1);
        }
        .draggable::after {
          content: " ";
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -30px 0 0 -30px;
          height: 60px;
          width: 60px;
          background: #eee;
          border-radius: 100%;
          opacity: 1;
          filter: none;
          transform: scale(0);
          z-index: 9999;
          pointer-events: none;
        }
        &.dragging .resize-handle {
          border-right: none;
        }
      }
      .resize-handle {
        border-right: solid 1px #eee;
      }
    }
    .datatable-body {
      .datatable-row-detail {
        background: #f5f5f5;
        padding: 10px;
      }
      .datatable-group-header {
        background: #f5f5f5;
        border-bottom: solid 1px #d9d8d9;
        border-top: solid 1px #d9d8d9;
      }
      .datatable-body-row {
        .datatable-body-cell,
        .datatable-body-group-cell {
          text-align: left;
          padding: 15px 20px;
          vertical-align: top;
          border-top: 0;
          color: #34495e;
          transition: width 0.3s ease;
          //font-size: 14px;
          font-weight: 400;
        }
      }
      .progress-linear {
        display: block;
        position: relative;
        width: 100%;
        height: 5px;
        padding: 0;
        margin: 0;
        position: absolute;
        .container {
          display: block;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 5px;
          -webkit-transform: translate(0, 0) scale(1, 1);
          transform: translate(0, 0) scale(1, 1);
          background-color: #aad1f9;
          .bar {
            transition: all 0.2s linear;
            -webkit-animation: query 0.8s infinite
              cubic-bezier(0.39, 0.575, 0.565, 1);
            animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
            transition: -webkit-transform 0.2s linear;
            transition: transform 0.2s linear;
            background-color: #106cc8;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
          }
        }
      }
    }
    .datatable-footer {
      //border-top: 1px solid rgba(0, 0, 0, 0.12);
      //font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.54);
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
      }
      //.datatable-pager {
      //  //margin: 0 10px;
      //  li {
      //    vertical-align: middle;
      //    &.disabled a {
      //      color: rgba(0, 0, 0, 0.26) !important;
      //      background-color: transparent !important;
      //    }
      //    &.active a {
      //      background-color: rgba(158, 158, 158, 0.2);
      //      font-weight: bold;
      //    }
      //  }
      //  a {
      //    height: 22px;
      //    min-width: 24px;
      //    line-height: 22px;
      //    padding: 0 6px;
      //    border-radius: 3px;
      //    margin: 6px 3px;
      //    text-align: center;
      //    vertical-align: top;
      //    color: rgba(0, 0, 0, 0.54);
      //    text-decoration: none;
      //    vertical-align: bottom;
      //    &:hover {
      //      color: rgba(0, 0, 0, 0.75);
      //      background-color: rgba(158, 158, 158, 0.2);
      //    }
      //  }
      //  .datatable-icon-left,
      //  .datatable-icon-skip,
      //  .datatable-icon-right,
      //  .datatable-icon-prev {
      //    font-size: 20px;
      //    line-height: 20px;
      //    padding: 0 3px;
      //  }
      //}
    }
    .datatable-summary-row .datatable-body-row {
      background-color: #ddd;
      &:hover {
        background-color: #ddd;
      }
      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}

/**
 * Checkboxes
**/

.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  input[type="checkbox"] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;
    &:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2;
    }
    &:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 0.5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer;
    }
  }
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}



.pin--row {
  background: #edf5ff !important;
}
