// Base styles here
$bg-body: #e9ecef;
$clr-body: #666;
$clr-red: #dc3545;
$clr-green: #3bb001;
$clr-yellow: #ffc107;
$clr-amber: #fd7e14;

$lime-green: #89bf5d;
$clr-black: #222;
$clr-blue: #59b9ef;
$lime-red: #f35d58;
$lime-orange: #fd7e14;

body {
  background-color: $bg-body;
  color: $clr-body;
  font-family: $font-pri;
  margin: 0;

  &.admin--body {
    background: #f4f5f9;
  }
}

a {
  color: $clr-pri;
  cursor: pointer;
  @extend %transition;

  &:hover {
    color: $clr-pri;
  }

  &.link--gray {
    color: #a9b4bd;
  }
}

select {
  outline: none;
}

textarea {
  resize: none;
}

.clr-red {
  color: $clr-red !important;

  a {
    color: $clr-red;
  }
}

.clr-pink {
  color: $clr-red !important;

  a {
    color: $clr-red;
  }
}

.clr-green {
  color: $clr-green !important;

  a {
    color: $clr-green;
  }
}

.clr-amber {
  color: $clr-amber !important;

  a {
    color: $clr-amber;
  }
}

.clr-orange {
  color: $clr-amber !important;

  a {
    color: $clr-amber;
  }
}

.clr-yellow {
  color: #ffc107;
}

.clr-blue {
  color: $clr-blue !important;
}

.clr-gray {
  color: $clr-gray !important;

  a {
    color: $clr-gray;
  }
}

.clr-google {
  color: #ea4335 !important;
}

.clr-green-background {
  background: $clr-green !important;
}

.clr-red-background {
  background: $clr-red !important;
}

.clr-amber-background {
  background: $clr-amber !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.hidden-desktop {
  display: none;
}

@include susy-breakpoint($md, $susy, ".no-mq") {
  .hidden-desktop {
    display: block;
  }
}

.hide {
  display: none !important;
}

.right {
  float: right;
}

.showprint {
  display: none !important;
}

.icon-cell {
  i {
    font-size: 16px;
  }
}

.clr-blue {
  color: $clr-pri !important;
}

.icon-gray {
  i {
    color: #969dab;
  }

  a {
    i {
      color: #969dab;
    }

    &:hover {
      i {
        color: $clr-pri;
      }
    }
  }
}

//
.follow-btn {
  border: 1px solid $clr-pri;
  background: #ecf5ff;
  border-radius: 3px;
  padding: 2px 5px;
  font-weight: 500;
  text-transform: uppercase;
  color: $clr-pri;
  font-size: 10px;

  :lang(fr) {
    text-transform: none;
  }

  &.active {
    background: $clr-pri;
    color: $white;
  }
}

.tag-btn {
  background: #73c1b3;
  border-radius: 3px;
  padding: 2px 7px;
  color: $white;
  font-size: 12px;

  &:hover {
    color: $white;
    background-color: darken(#73c1b3, 8%);
  }
}

.icon--circular {
  i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    text-align: center;
    border: 1px solid $clr-pri;
  }
}

.progress--green {
  .ui-progressbar {
    .ui-progressbar-value {
      background: $clr-green;
    }
  }
}

.progress--orange {
  .p-progressbar {
    .p-progressbar-value {
      background: $lime-orange;
    }
  }
}

.progress--red {
  .ui-progressbar {
    .ui-progressbar-value {
      background: $clr-red;
    }
  }
}

.progress--blue {
  .ui-progressbar {
    .ui-progressbar-value {
      background: $clr-pri;
    }
  }
}

.progress--green {
  .p-progressbar {
    .p-progressbar-value {
      background: $clr-green;
    }
  }
}

.progress--lime-green {
  .p-progressbar {
    .p-progressbar-value {
      background: $lime-green;
    }
  }
}

.progress--yellow {
  .p-progressbar {
    .p-progressbar-value {
      background: $clr-yellow;
    }
  }
}

.progress--black {
  .p-progressbar {
    .p-progressbar-value {
      background: $clr-black;
    }
  }
}

.progress--red {
  .p-progressbar {
    .p-progressbar-value {
      background: $clr-red;
    }
  }
}

.progress--orange {
  .ui-progressbar {
    .ui-progressbar-value {
      background: $clr-amber;
    }
  }
}

.progress--blue {
  .p-progressbar {
    .p-progressbar-value {
      background: $clr-pri;
    }
  }
}

.whitespace-pre {
  white-space: pre;
}

#section-to-print {
  display: none !important;
}

@media print {
  .noprint {
    display: none;
  }

  .noprint~#section-to-print {
    display: block !important;
  }
}

.mat-expansion-panel-header,
.mat-expansion-panel-body {
  padding: 0 !important;
  height: auto !important;
}

.mat-expansion-indicator {
  display: none;
}

.mat-expansion-panel-header {
  display: inline-block !important;
}

.calendar-dropdown {
  float: right;
  width: 386px;
  border-left: 1px solid #dddddd;
  padding: 5px;
  margin: 0;

  select {
    padding: 5px;
  }
}


@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    visibility: hidden;
    width: 0;
  }
}

#r-frozen {
  .ui-treetable-scrollable-body {
    @include no-scroll-bar;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

  }
}


.no-radius--rtrb {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}


.ql-edited-text {
  color: #000;
}

.dialog--overflow {
  .ui-dialog-content {
    overflow: visible !important;
  }
}


/// calendar fix


.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-monthpicker {
  width: auto !important;
}

.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multiple-month {
  width: auto !important;
}


.ui-overflow-hidden,
.p-overflow-hidden {
  overflow: hidden;
}

.ql-tooltip-hidden {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}