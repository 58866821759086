.customer-profile {
  .customer-info {
    @include span(5 no-gutters);

    // padding: 20px;
    .customer-name {
      font-size: 20px;
      color: #34495e;
      line-height: 26px;

      .customer__email {
        display: block;
        font-size: 14px;
        text-transform: lowercase;
      }
    }

    .customer-detail {
      float: left;
      width: 100%;

      ul {
        list-style: none outside none;
        padding: 0;

        li {
          margin-top: 15px;
          line-height: 22px;

          .title {
            display: block;
            font-size: 12px;
            color: #a9b4bd;
          }

          .number {
            display: inline-block;
            vertical-align: top;
            position: relative;
            font-size: 12px;
            color: #34495e;
            font-weight: 600;
            padding-right: 40px;
          }

          .status {
            padding: 2px 10px;
            border-radius: 3px;
            color: white;
            display: inline-block;
            font-weight: 600;
            font-size: 12px;
            margin-top: 2px;

            &.open {
              background: #28b266;
            }

            &.in-progress {
              background: #fcc014;
            }

            &.late,
            &.close {
              background-color: #f16363;
            }
          }
        }
      }
    }
  }

  .customer-detailed-info {
    //border-left: 1px solid $bg-pagination;
    @include span(7 no-gutters);
    padding: 5px 10px;
    padding-left: 30px;

    .profile-details {
      width: 100%;

      tr {
        td {
          padding: 5px 5px;
          border-bottom: 1px solid #ededed;
          font-size: 12px;
          color: #666;
          width: 50%;

          &:first-child {
            padding-left: 0;
          }



          .title {
            font-size: 11px;
            color: #a9b4bd;
            font-weight: 500;
            margin-bottom: 3px;
            width: 45%;
          }

          &:last-child {
            font-size: 12px;
            color: #666;
          }
        }

        &:last-child {
          td {
            border: 0;
          }
        }
      }
    }
  }
}

.customer-life-cycle {
  padding: 0;
  position: relative;
  height: calc(100% - 40px);

  .timeline-details {
    clear: both;

    .timeline-block {
      padding: 0 0 15px 0;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        border-left: 1px solid #e7e7ea;
        left: 15px;
        top: 0;
      }

      .icon-box {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        text-align: center;
        margin-right: 15px;
        display: inline-block;
        line-height: 26px;
        color: white;
        padding: 0;
        font-size: 12px;
        border: 2px solid white;

        &.green {
          background-color: lighten($clr-darkgreen, 30%);
          color: $clr-darkgreen;
          border-color: $clr-darkgreen;
        }

        &.orange {
          background-color: lighten($color-legend-orange, 30%);
          color: $color-legend-orange;
          border-color: $color-legend-orange;
        }

        &.blue {
          background-color: lighten($color-legend-blue, 50%);
          color: $color-legend-blue;
          border-color: $color-legend-blue;
        }

        &.red {
          background-color: lighten($clr-red, 45%);
          color: $clr-red;
          border-color: $clr-red;
        }
      }

      .timeline__content {
        background: white;
        border: 1px solid #e2e7ec;
        display: inline-block;
        width: calc(100% - 60px);
        vertical-align: top;
        position: relative;
        @include arrow("left", "top", 8px, #fff, #d9dee3, 1px);

        .timeline__left {
          float: left;
          //  width:60%;
        }

        .timeline__right {
          float: right;
          display: flex;
          align-items: center;
          gap: 10px;
          //  width:35%;
        }

        .timeline__date {
          font-size: 11px;
          //  font-weight: 600;
          margin-bottom: 2px;
        }

        .timeline__status {
          border-radius: 3px;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          padding: 2px 3px;
          vertical-align: middle;
          color: #ffffff;
          display: inline-block;
          margin-top: 5px;

          :lang(fr) {
            text-transform: none;
          }

          &.green {
            background: $clr-darkgreen;
          }

          &.orange {
            background: $color-legend-orange;
          }

          &.blue {
            background: $color-legend-blue;
          }

          &.red {
            background: $clr-red;
          }
        }

        .timeline__rating {
          font-size: 12px;
          font-weight: 600;
          padding-top: 0;
          float: left;
          display: inline-block;
        }

        .timeline__count {
          border-radius: 3px;
          min-width: 40px;
          min-height: 20px;
          padding: 3px 5px;
          float: left;
          font-weight: 500;
          color: white;
          font-size: 11px;
          text-align: center;
          // line-height: 28px;
          margin: 0;
          border: 1px solid white;

          &.green {
            background-color: lighten($clr-darkgreen, 30%);
            color: $clr-darkgreen;
            border-color: $clr-darkgreen;
          }

          &.orange {
            background-color: lighten($color-legend-orange, 30%);
            color: $color-legend-orange;
            border-color: $color-legend-orange;
          }

          &.blue {
            background-color: lighten($color-legend-blue, 50%);
            color: $color-legend-blue;
            border-color: $color-legend-blue;
          }

          &.red {
            background-color: lighten($clr-red, 45%);
            color: $clr-red;
            border-color: $clr-red;
          }
        }

        .timeline__top {
          padding: 8px 12px;
          // float: left;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .timeline__left {
            //  width: 40%;
          }

          .timeline__right {
            // width: 55%;
          }
        }

        .timeline__bottom {
          // float: left;
          width: 100%;
          background: #f3f6f9;
          padding: 8px 15px;
          border-top: 1px solid #e2e7ec;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .timeline__right {
            margin: 0;
          }
        }

        .timeline__model {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;

          span {
            display: block;
            color: #989898;
            font-size: 12px;
          }
        }

        .timeline__source {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;

          span {
            display: block;
            color: #989898;
            font-size: 12px;
          }
        }
      }
    }

    &:first-child {
      .timeline-block {}
    }

    &:last-child,
    .last-child {
      .timeline-block {
        &:before {
          display: none;
        }
      }
    }
  }
}

.all-survey {
  max-width: 100%;

  table {
    width: 100%;

    tr {
      display: table-row;
      border-bottom: 1px solid #e5e5e5;

      &.last-child {
        border: 0;
      }




      &.header-row {
        border-top: 1px solid #ebebeb;

        td {
          background: #f3f6f9;
          padding: 10px !important;
          vertical-align: middle;
          text-align: left;
          font-size: 12px;
          color: #666666;

          &:first-child {
            //border-left: 4px solid #34a2e5;
            position: relative;

            &:before {
              content: '';
              width: 4px;
              height: 100%;
              background: $clr-pri;
              position: absolute;
              left: 0;
              top: 0;

            }
          }

          &:before {
            background: lighten($clr-pri, 55%);

          }

          &.center {
            text-align: center;
          }

          &:first-child {
            padding-left: 22px;
            font-size: 14px;
            text-transform: none;
          }
        }
      }

      td {
        font-size: 12px;
        //  border-bottom: 1px solid #f5f5f5;
        padding: 12px 10px !important;
        color: #989898;
        border: none !important;

        &:first-child {
          padding-left: 50px;
          text-transform: uppercase;

          :lang(fr) {
            text-transform: none;
          }
        }

        &.no--space {
          padding: 0 !important;
        }

        &.child-row {
          position: relative;
          // padding: 0;

          &:before {
            content: '';
            width: 4px;
            height: 100%;
            background: #c7e2ff;
            position: absolute;
            left: 0;
            top: 0;

          }
        }
      }

      &.highlighted-row {
        background-color: white;
        text-transform: uppercase;
        border-color: #e0e0e0;

        :lang(fr) {
          text-transform: none;
        }

        td {
          color: $clr-pri;
        }
      }
    }

    .th-text {
      display: inline-block;
      vertical-align: top;
      color: #989898;

      span {
        display: block;
        color: #666;
      }
    }

    .icon-col {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;

      &.blue {
        color: $color-legend-blue;
      }

      &.red {
        color: $clr-iconred;
      }

      &.green {
        color: $clr-icongreen;
      }

      &.orange {
        color: $clr-amber;
      }
    }

    .rating-box {
      width: 25px;
      height: 25px;
      line-height: 25px;
      border: 1px solid white;
      border-radius: 100%;
      font-size: 12px;
      font-weight: 600;
      background-color: white;
      display: inline-block;
      padding: 0 1px;
      text-align: center;

      //width: 23px;
      &.green {
        background-color: lighten($clr-darkgreen, 30%);
        color: $clr-darkgreen;
        border-color: $clr-darkgreen;
      }

      &.orange {
        background-color: lighten($color-legend-orange, 30%);
        color: $color-legend-orange;
        border-color: $color-legend-orange;
      }

      &.blue {
        background-color: lighten($color-legend-blue, 50%);
        color: $color-legend-blue;
        border-color: $color-legend-blue;
      }

      &.red {
        background-color: lighten($clr-red, 45%);
        color: $clr-red;
        border-color: $clr-red;
      }
    }
  }
}

.following-field {
  border-radius: 5px;
  border: 1px solid #d6dade;
  padding: 9px 10px 10px;
  height: 40px;
}

.following-tags {
  li {
    display: inline-block;
    margin-right: 5px;
    font-weight: 500;
    font-size: 11px;
    color: white;
    text-transform: uppercase;
    padding: 3px 7px;
    border-radius: 3px;
    background: #4da1ff;
    background-clip: padding-box;

    :lang(fr) {
      text-transform: none;
    }
  }
}

//timeline history

.timeline-history {
  position: relative;
  padding: 0 50px 0;
  margin-top: 40px;
  float: left;
  width: 100%;
  text-align: left;

  .status {
    padding: 3px 10px 2px;
    border-radius: 3px;
    color: white;
    display: inline-block;
    font-weight: 500;
    font-size: 11px;
    margin-top: 2px;
    text-transform: uppercase;

    :lang(fr) {
      text-transform: none;
    }

    &.open {
      background: $clr-red;
    }

    &.in-progress {
      background: $clr-amber;
    }

    &.late {
      background: $clr-red;
    }

    &.close {
      background-color: $clr-green;
    }

    &.comment {
      background-color: $clr-pri;
    }
  }

  .timeline__icon {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 20;
    background: white;
    margin-left: -22.5px;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 41px;
    border-radius: 50%;
    border: 2px solid #e5e5e5;
    font-size: 20px;
    color: #e5e5e5;

    &.open {
      color: $clr-red;
    }

    &.in-progress {
      color: $clr-amber;
    }

    &.late {
      color: $clr-red;
    }

    &.close {
      color: $clr-green;
    }

    &.comment {
      color: $clr-pri;
    }
  }
}

.timeline__block {
  position: relative;
  padding: 0 0 40px 0;
  float: left;
  width: 100%;

  &:first-child {}

  &:last-child,
  .last-child {
    &:after {
      display: none;
    }
  }

  &:after {
    /* this is the vertical line */
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    height: 100%;
    width: 2px;
    background: #e5e5e5;
    display: inline;
    z-index: 10;
  }

  .timeline__content {
    position: relative;
    border-radius: 3px;
    background: #f3f6f9;
    margin-left: 0;
    @include arrow("left", "top", 10px, #f3f6f9);
    float: right;
    padding: 20px;
    width: 45%;

    h2 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #333;

      :lang(fr) {
        text-transform: none;
      }

      .time {
        color: #999999;
      }
    }

    p {
      font-size: 12px;
      padding: 10px 0 0;
      word-break: break-word;
    }

    .timeline__date {
      padding: 0;
      line-height: 25px;
      text-align: right;
      color: #333333;
      position: absolute;
      width: 100%;
      right: 122%;
      top: 6px;

      h4 {
        font-size: 30px;
      }

      &.open {
        color: $clr-red;
      }

      &.in-progress {
        color: $clr-amber;
      }

      &.late {
        color: $clr-red;
      }

      &.close {
        color: $clr-green;
      }

      &.comment {
        color: $clr-pri;
      }

      h5 {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        color: #666;

        :lang(fr) {
          text-transform: none;
        }
      }
    }

    .timeline__user {
      font-size: 12px;
      text-transform: uppercase;
      padding: 10px 0 0 0;

      :lang(fr) {
        text-transform: none;
      }

      i {
        margin-right: 3px;
      }
    }
  }

  &.manual-comment {
    .timeline__content {
      float: left;
      text-align: right;
      @include arrow("right", "top", 10px, #f3f6f9);

      &:after {
        left: auto;
      }

      .timeline__date {
        right: auto;
        left: 122%;
        text-align: left;
      }
    }
  }
}

//category history

.category-history {
  position: relative;
  padding: 0;
  margin-bottom: 20px;
  float: left;
  border: 1px solid #ebebeb;
  width: 100%;
  text-align: left;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    padding: 0 20px 0;
  }

  .category__inner {
    padding: 15px 15px 0;
  }

  .category-heading {
    padding: 10px;
    background: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
  }

  h4 {
    color: $clr-pri;
    font-size: 12px;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      margin: 5px 0;
    }
  }

  .timeline__time {
    position: absolute;
    top: 0;
    left: 111px;
    z-index: 13;
    background: white;
    margin-left: -25px;
    width: 50px;
    text-align: center;
    height: 50px;
    padding: 8px 3px;
    border-radius: 50%;
    border: 2px solid #e5e5e5;
    font-size: 9px;
    color: #666;

    p {
      margin-top: 1px;
    }
  }
}

.category__block {
  position: relative;
  padding: 0 0 40px 150px;
  float: left;
  width: 100%;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    padding: 70px 0 20px;
  }

  &:after {
    /* this is the vertical line */
    content: "";
    position: absolute;
    top: 0;
    left: 111px;
    margin-left: -1px;
    height: 100%;
    width: 2px;
    background: #e5e5e5;
    display: inline;
    z-index: 10;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      z-index: 1;
    }
  }

  .timeline__date {
    padding: 0;
    line-height: 25px;
    text-align: right;
    color: #333333;
    position: absolute;
    font-size: 11px;
    left: 0;
    top: 6px;

    h4 {
      font-size: 25px;
      font-weight: 400;

      @include susy-breakpoint(1024px, $susy, ".no-mq") {
        font-size: 20px;
        margin: 0;
      }

      &.open {
        color: $clr-red;
      }

      &.in-progress {
        color: $clr-amber;
      }

      &.late {
        color: $clr-red;
      }

      &.close {
        color: $clr-green;
      }

      &.comment {
        color: $clr-pri;
      }
    }

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      right: 0;
      line-height: 20px;
      text-align: center;
    }

    h5 {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      color: #666;
    }
  }

  .timeline__content {
    position: relative;
    border-radius: 3px;
    background: #f3f6f9;
    border: 1px solid #e2e7ec;
    margin-left: 0;
    @include arrow("left", "top", 10px, #fff, #e2e7ec, 1px);
    float: left;
    padding: 0;
    width: 100%;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      width: 100%;
      text-align: center;
      padding-top: 50px;
      z-index: 2;

      &:before,
      &:after {
        display: none;
      }
    }

    .timeline__header {
      background: white;
      padding: 5px 10px;
      border-bottom: 1px solid #e2e7ec;

      ul {
        li {
          display: inline-block;
          margin-right: 8px;
          vertical-align: middle;

          .rounded-icon {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
          }
        }
      }

      .title {
        color: #989898;
      }
    }

    .timeline__inner {
      padding: 10px;

      h2 {
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 5px;
        font-weight: 600;
        color: #989898;
      }

      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.sentiment-tags {
  font-size: 10px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  padding: 2px 6px;
  text-transform: uppercase;
  position: relative;
  margin: 0 10px 0 0;

  &.negative {
    border: 1px solid $clr-red;
    color: $clr-red;
    background: lighten($clr-red, 40%);
  }

  &.positive {
    border: 1px solid $clr-green;
    color: $clr-green;
    background: lighten($clr-green, 62%);
  }

  &.neutral {
    border: 1px solid $clr-amber;
    color: $clr-amber;
    background: lighten($clr-amber, 40%);
  }

  &.primary {
    border: 1px solid $clr-blue;
    color: $clr-blue;
    background: lighten($clr-blue, 30%);
  }

  &.unassigned {
    border: 1px solid $clr-unassigned;
    color: $clr-unassigned;
    background: lighten($clr-unassigned, 15%);
  }
}

.close-histroy {
  position: absolute;
  padding: 5px;
  right: 5px;
  top: 5px;
}



.timeline__reason {
  float: left;
  width: 100%;
  font-size: 14px;
  margin-top: 15px;
  border-top: 1px solid #e9ecef;
  padding: 10px;
}



.client-description {
  display: flex;
  align-items: center;
  gap: 10px;
}


.customer-satisfication-star {
  color: #ffc107;
  letter-spacing: 2px;
  font-size: 17px;
}

.customer-thumbsup {
  color: #567f31;
  font-size: 17px;
}

.icon-size-17 {
  font-size: 17px;
  margin-right: 2px;
}


/// journey graph

$color_1: #aeb1b9;
$color_2: #666;
$color_3: #dc3545;
$color_4: #fff;
$color_5: #212229;
$background-color_1: #fff;
$border-color_1: #ffc107;
$border-color_2: #3bb001;
$border-color_3: #dc3545;

.c-j-charts {
  display: table;
  width: 100%;
  position: relative;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .cej_chart_container {
    display: table-cell;
    width: 50%;
    padding: 0 50px 30px;
  }

  .cej_header {
    width: 100%;
    text-align: center;
    padding: 0 0 30px;
  }

  .cej_dd_container {
    display: inline-block;
    padding: 0 5px;

    label {
      line-height: 28px;
    }

    .select-dropdown {
      float: none;
      display: inline-block;
      margin: 0 5px;
    }
  }

  .cej_chart {
    width: 100%;
    height: 268px;
    border: 1px dashed #aeb1b9;
    border-radius: 120px;
    position: relative;
    padding: 20px;
    background: #fcfcfc;

    .start-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $clr-pri;
      color: white;
      //border: 2px solid #aeb1b9;
      top: -40px;
      left: 50%;
      z-index: 3;
      text-align: center;
      font-size: 10px;
      line-height: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .cej_chart-inner {
      width: 100%;
      height: 100%;
      background: #fcfcfc;
      border-radius: 100px;
      border: 1px dashed #aeb1b9;
      padding: 20px;
      position: relative;
      z-index: 1;

      .exp-container {
        width: 100%;
        height: 100%;
        background-color: $background-color_1;
        border: 1px dashed #aeb1b9;
        border-radius: 90px;
        padding: 25px;

        .exp-box {
          width: 100%;
          height: 100%;
          border-radius: 100px;
          display: table;
          position: relative;

          .exp__inner {
            text-align: center;
            display: table-cell;
            vertical-align: middle;
          }

          h3 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: $color_2;

            i {
              font-size: 24px;
            }
          }
        }
      }

      .arrows {
        position: absolute;
        left: 81.5%;
        top: -12px;
        font-size: 21px;
        color: $color_3;

        &.neutral {
          color: $border-color_1;
        }

        i {
          margin: 0 1px;
        }
      }

      .step-dot {
        content: "";
        position: absolute;
        background: #f4f5f7;
        width: 19px;
        height: 19px;
        border: 2px solid #aeb1b9;
        border-radius: 50%;
      }

      .step-dot.neutral {
        border-color: $border-color_1;
      }

      .step-dot.positive {
        border-color: $border-color_2;
      }

      .step-dot.negative {
        border-color: $border-color_3;
      }

      .step-dot.step1 {
        top: -10px;
        left: 23%;
      }

      .step-dot.step2 {
        top: 0;
        right: 10%;
      }

      .step-dot.step3 {
        right: 5%;
        bottom: 16px;
      }

      .step-dot.step4 {
        bottom: -10px;
        left: 50%;
        margin-left: -10px;
      }

      .step-dot.step5 {
        bottom: 16px;
        left: 5%;
      }
    }

    .item {
      height: 76px;
      width: 76px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      border: 2px solid #ddd;
      z-index: 2;

      &:hover {
        background: #f3f6f9;
      }

      a {
        font-size: 10px;
        padding: 8px;
        line-height: 1;
        display: block;
        text-align: center;
        color: $color_4;
        font-weight: 600;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        &:focus {
          outline: 0;
          text-decoration: none;
        }

        span {
          margin-top: 2px;
          display: block;
          font-size: 20px;
          letter-spacing: -1px;

          i {
            font-size: 12px;
            position: relative;
            top: -12px;
          }
        }
      }
    }

    .item.item1 {
      top: -18px;
      left: 50%;
      margin-left: -39px;
    }

    .item.item2 {
      top: 50%;
      right: -18px;
      margin-top: -39px;
    }

    .item.item3 {
      bottom: -14px;
      right: 24%;
    }

    .item.item4 {
      bottom: -14px;
      left: 24%;
    }

    .item.item5 {
      left: -18px;
      top: 50%;
      margin-top: -39px;
    }

    .item.positive {
      border: 2px solid #3bb001;

      a {
        color: $color_5;
      }

      span {
        color: $color_5;
      }
    }

    .item.negative {
      border: 2px solid #dc3545;

      a {
        color: $color_5;
      }

      span {
        color: $color_5;
      }
    }

    .item.neutral {
      border: 2px solid #ffc107;

      a {
        color: $color_5;
      }

      span {
        color: $color_5;
      }
    }

    .item.normal {
      border: 2px solid #3bb001;
      color: $color_5;

      a {
        color: $color_5;
      }

      span {
        color: $color_5;
      }
    }
  }

  .cej_chart.left {
    float: left;
  }

  .cej_chart.right {
    float: right;
  }

  @media (max-width: 1024px) {
    .charts {
      .cej_chart_container {
        display: block;
        width: 100%;
        padding: 0 5px 30px;
      }
    }

    .cej_chart {
      border-radius: 0;

      .cej_chart-inner {
        .step-dot.step2 {
          top: 7%;
        }
      }

      .item.item3 {
        right: -10px;
      }

      .item.item4 {
        left: -10px;
      }
    }
  }
}




.sentiment-chips {
  align-items: center;
  display: flex;
  gap: 6px;

  li {
    border: 1px solid #c7e2ff;
    padding: 3px 5px;
    background: #f9fbfd;
    font-size: 11px;
    border-radius: 3px;
    text-transform: uppercase;
  }
}

.heading-cycle {
  font-size: 14px;
  text-transform: capitalize;
  color: #222;
  margin: 0 0 0;
}


.table-header {
  margin: 10px 0 5px;
  font-size: 12px;
}

.info {

  .ui-progressbar .ui-progressbar-value,
  .ui-progressbar .p-progressbar-value,
  .p-progressbar .ui-progressbar-value,
  .p-progressbar .p-progressbar-value {
    background: #f10075;
  }
}

.info {
  .p-progressbar-label {
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}

.pink {

  .ui-progressbar .ui-progressbar-value,
  .ui-progressbar .p-progressbar-value,
  .p-progressbar .ui-progressbar-value,
  .p-progressbar .p-progressbar-value {
    background: #dc3545;
  }
}


.lightgreen {

  .ui-progressbar .ui-progressbar-value,
  .ui-progressbar .p-progressbar-value,
  .p-progressbar .ui-progressbar-value,
  .p-progressbar .p-progressbar-value {
    background: #89bf5d;
  }
}

.green {

  .ui-progressbar .ui-progressbar-value,
  .ui-progressbar .p-progressbar-value,
  .p-progressbar .ui-progressbar-value,
  .p-progressbar .p-progressbar-value {
    background: #3bb001;
  }
}

.yellow {

  .ui-progressbar .ui-progressbar-value,
  .ui-progressbar .p-progressbar-value,
  .p-progressbar .ui-progressbar-value,
  .p-progressbar .p-progressbar-value {
    background: #ffc107;
  }
}




.customer-widget-component {
  .c-j-charts .cej_chart_container {
    display: table-cell;
    width: 100%;
    padding: 0 10px 10px;
    zoom: 0.8;
  }
}