.create-data-source-wizard {
  .wizard__step {
    &.step--01 {
      padding: 20px 0 0 0;
    }
    &.step--02 {
      padding: 20px 0 0 0;
    }
    &.step--03 {
      .row {
        .col-left {
          width: 25% !important;
        }
      }
    }
    &.step--04 {
      padding: 20px 0 0 0;
    }
    .upload-field {
      width: 100%;
    }
    .validation-progress {
      padding: 15px 0 0 0;
      position: relative;
      .close-progress {
        position: absolute;
        right: -30px;
        top: 12px;
        i {
          font-size: 16px;
          color: #fe596b;
          &:hover {
            color: #fe2e47;
          }
        }
      }
    }
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.modal--create-data-source {
  .timeline {
    padding: 0 0 40px 0;
  }
}
.file-types {
  background: #f4f5f9;
  border-radius: 3px;
  li {
    clear: both;
    padding: 10px 45px 10px 15px;
    border-bottom: 1px solid #d2dae4;
    font-size: 12px;
    line-height: 20px;
    @include clearfix();
    position: relative;
    cursor: pointer;
    .file-extension {
      display: inline-block;
      width: 80px;
      font-weight: 600;
      border-right: 1px solid #c0cfdc;
      &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        margin: -8px 0 0 0;
        content: "\f058";
        font-family: "Font Awesome 5 Pro" ;
        font-size: 16px;
        text-transform: lowercase;
        color: $clr-pri;
        display: none;
      }
      i {
        display: inline-block;
        font-size: 20px;
        color: #b8bbc0;
        margin: 0 10px 0 0;
        vertical-align: text-bottom;
      }
      span {
        display: inline-block;
      }
    }
    .file-description {
      display: inline-block;
      padding: 0 10px;
    }
    &:hover {
      background: #edeef2;
      border-bottom-color: #c6ced7;
      .file-extension {
        i {
          color: #a9abb0;
        }
      }
    }
    &.active,
    &.active:hover {
      background: #e3ecfa;
      border-bottom-color: $clr-pri;
      .file-extension {
        i {
          color: $clr-pri;
        }
        &:after {
          display: block;
        }
      }
    }
  }
}

.process-list {
  background: #f4f5f9;
  border-radius: 3px;
  li {
    clear: both;
    padding: 10px 45px 10px 15px;
    border-bottom: 1px solid #d2dae4;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    @include clearfix();
    position: relative;
    .process__description {
      position: relative;
      padding: 0 50px 0 0;
      .process__no {
        font-size: 16px;
        width: 25px;
        display: inline-block;
        text-align: right;
        margin: 0 10px 0 0;
      }
      .process__percentage {
        position: absolute;
        right: 0;
        top: 2px;
      }
      .process__more {
        margin: 0 0 0 20px;
        font-weight: normal;
        strong {
          font-weight: 600;
        }
      }
    }
    &:after {
      position: absolute;
      right: 15px;
      top: 50%;
      margin: -10px 0 0 0;
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
      font-size: 16px;
      text-transform: lowercase;
      color: $clr-pri;
    }
    &.error {
      color: #fe596b;
      background: #ffeded;
      &:after {
        content: "info";
        color: #fe596b;
      }
    }
  }
}




.import-model-list {
  max-width: 100%;
  table {
    width: 100%;
    tr {
      display: table-row;
    // border-bottom: 1px solid #ebebeb;
      th {
        background: #f3f6f9;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        color: #666666;
        border: 1px solid #ebebeb;

        &:first-child{
          text-align: left;
        }
     
      }

      td {
        font-size: 12px;
        padding:10px;
        text-align: center;
        color: #989898;
        border: 1px solid #ebebeb;

        i{
          margin-right:5px;
        }
        &:first-child{
          text-align: left;
        }

       
      }     
    }    
  }
}