.sidebar {
  position: fixed;
  left: 0px;
  top: $header-height;
  z-index: map-get($zindex, sidebar);
  width: $leftnav-width;
  height: 100%;
  background: $white;
 // border-right: 1px solid #e7eaed;
  border-top: 1px solid #e1e4e7;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.5, 0.32, 0.01, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.32, 0.01, 1);
  .sidebar-nav {
    ul {
      list-style-type: none;
      li {
        padding: 10px 0;
        width: 100%;
        text-align: center;
        color: #d1dce2;
        a {
          display: block;
          color: #d1dce2;
          &:hover {
            color: $clr-pri;
          }
        }
        i {
          font-size: 20px;
          line-height: 36px;
        }
        &.active {
          a {
            color: $clr-pri;
          }
        }
        &.disable {
          opacity: 0.5;
          i {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
