@import "components/accordion";
@import "components/steps";
@import "components/modal";
@import "components/dropdown";
@import "components/toastmessages";
@import "components/menu";
@import "components/tooltip";
@import "components/calendar";
@import "components/tabmenu";
@import "components/scrollpane";
@import "components/datatable";
@import "components/paginator";
@import "components/checkbox";
@import "components/radio";
@import "components/treetable";
@import "components/carousel";
@import "components/uiswitch";
@import "components/foldertree";
@import "components/panelmenu";
@import "components/uichips";
@import "components/overlay";


.ui-widget {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.p-hidden {
  display: none !important;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  input,
  select {
    transform: scale(0);
  }
}

.p-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-clearfix {
  &::before {
    content: "";
    display: table;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  zoom: 1;
}

.p-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.p-state-disabled {
  cursor: default !important;

  a {
    cursor: default !important;
  }
}

.p-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-overlay,
.p-widget-overlay {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-resizable {
  position: relative;
}

.p-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}

.p-resizable-disabled .p-resizable-handle,
.p-resizable-autohide .p-resizable-handle {
  display: none;
}

.p-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.p-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.p-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.p-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.p-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.p-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.p-shadow {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.p-unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.p-overflow-hidden {
  overflow: hidden;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #898989;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #898989;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #898989;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #898989;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #898989;
}

.p-placeholder {
  color: #898989;
}

input {

  &[type="button"],
  &[type="submit"],
  &[type="reset"],
  &[type="file"]::-webkit-file-upload-button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
}

button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-blockui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-blockui-document {
  position: fixed;
}

.p-autocomplete {
  width: auto;
  zoom: 1;
  cursor: pointer;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: inline-block;

  .p-autocomplete-dropdown {
    height: 100%;
    width: 2em;
    margin-right: 0;
    vertical-align: top;
  }

  .p-autocomplete-input {
    padding-right: 1.5em;
  }
}

.p-autocomplete-loader {
  position: absolute;
  right: 0.25em;
  top: 50%;
  margin-top: -0.5em;
}

.p-autocomplete-query {
  font-weight: bold;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;

  .p-autocomplete-list {
    padding: 0.4em;
    border: 0 none;
  }

  .p-autocomplete-list-item {
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: 0.186em 0.313em;
    text-align: left;
  }
}

.p-autocomplete .p-button-icon-only {
  border-left: 0 none;

  &:enabled {

    &:hover,
    &:focus,
    &:active {
      border-left: 0 none;
    }
  }
}

/* Multiple Selection */

.p-autocomplete-multiple-container {
  display: inline-block;
  vertical-align: middle;

  &.p-inputtext {
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5em 0 0.25em;
  }
}

.p-autocomplete-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-autocomplete-token-label {
  display: block;
  margin-right: 2em;
}

.p-autocomplete-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-autocomplete-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;

  input {
    border: 0 none;
    width: 10em;
    outline: medium none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
}

.p-autocomplete-dd {
  .p-autocomplete-loader {
    right: 2.25em;
  }

  input.p-corner-all,
  .p-autocomplete-multiple-container.p-corner-all {
    -moz-border-radius-topright: 0px;
    -webkit-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .p-autocomplete-dropdown.p-corner-all {
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

/** AutoComplete **/

.p-fluid {

  p-autocomplete,
  .p-autocomplete,
  .p-autocomplete-input {
    width: 100%;
  }

  .p-autocomplete {
    &.p-autocomplete-dd {

      .p-autocomplete-input,
      .p-autocomplete-multiple-container {
        width: calc(100% - 2em);
      }
    }

    .p-autocomplete-dropdown.p-button {
      width: 2em;
    }
  }
}

/* Button */

.p-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
  /* the overflow property removes extra width in IE */
}

p-button {
  display: inline-block;
}

/*button text element */

.p-button .p-button-text,
.p-button .p-button-label {
  display: block;
  line-height: normal;
  padding: .429em 1em;
}

.p-button-text-only .p-button-text {
  padding: 0.25em 1em;
}

.p-button-icon-only .p-button-text,
.p-button-text-empty .p-button-text {
  padding: 0.25em;
  text-indent: -9999999px;
}

.p-button-text-icon-left .p-button-text {
  padding: 0.25em 1em 0.25em 2.1em;
}

.p-button-text-icon-right .p-button-text {
  padding: 0.25em 2.1em 0.25em 1em;
}

/*button icon element(s) */

.p-button-icon-only {
  padding: 9px 15px;
  vertical-align: middle;
}

.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  height: 1em;
}

.p-button-icon-only .p-button-icon-left {
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
}

.p-button-icon-only .p-button-label {
  visibility: hidden;
  width: 0;
  flex: 0 0 auto;
  display: none;
}

.p-button-icon-left {
  left: 0.5em;
}

.p-button-icon-right {
  right: 0.5em;
}

/*button sets*/

.p-buttonset .p-button {
  margin-left: 0;
  margin-right: 0;
}

/* workarounds */

button.p-button::-moz-focus-inner {
  border: 0;
  padding: 0;
  /* reset extra padding in Firefox */
}

/** Fluid **/

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-text-icon-left .p-button-text,
  .p-button-text-icon-right .p-button-text {
    padding-left: 1em;
    padding-right: 1em;
  }

  .p-buttonset {
    width: 100%;

    &.p-buttonset-1 .p-button {
      width: 100%;
    }

    &.p-buttonset-2 .p-button {
      width: 50%;
    }

    &.p-buttonset-3 .p-button {
      width: 33.3%;
    }

    &.p-buttonset-4 .p-button {
      width: 25%;
    }

    &.p-buttonset-5 .p-button {
      width: 20%;
    }

    &.p-buttonset-6 .p-button {
      width: 16.6%;
    }
  }
}

/** ButtonSet **/

@media (max-width: 640px) {
  .p-fluid .p-buttonset {

    &.p-buttonset-1 .p-button,
    &.p-buttonset-2 .p-button,
    &.p-buttonset-3 .p-button,
    &.p-buttonset-4 .p-button,
    &.p-buttonset-5 .p-button,
    &.p-buttonset-6 .p-button {
      width: 100%;
    }
  }
}

/** Breadcrumb **/

.p-breadcrumb {
  margin: 0;
  padding: 0;
  padding: 0.5em;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 0.25em;
    }
  }
}

.p-breadcrumb-chevron,
.p-breadcrumb-home {
  vertical-align: middle;
}

.p-breadcrumb {
  ul li .p-menuitem-link {
    text-decoration: none;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }
}

.p-card-header img {
  width: 100%;
}

.p-card-body {
  padding: 1em;
}

.p-card-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.p-card-subtitle {
  opacity: 0.7;
  margin-bottom: 0.5em;
  margin-top: -0.25em;
  font-weight: bold;
}

.p-card-footer {
  padding-top: 1em;
}



/*.p-checkbox {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  .p-checkbox-box {
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
  }
  .p-checkbox-icon {
    display: block;
  }
}*/

/*.p-checkbox-label {
  vertical-align: middle;
}*/



.p-colorpicker {
  display: inline-block;
}

.p-colorpicker-dragging {
  cursor: pointer;
}

.p-colorpicker-overlay {
  position: relative;
}

.p-colorpicker-panel {
  position: relative;
  width: 193px;
  height: 166px;
  background-color: #323232;
  border-color: #191919;
}

.p-colorpicker-overlay-panel {
  position: absolute;
}

.p-colorpicker-preview {
  width: 2em;
  cursor: pointer;
}

.p-colorpicker-panel {
  .p-colorpicker-content {
    position: relative;
  }

  .p-colorpicker-color-selector {
    width: 150px;
    height: 150px;
    top: 8px;
    left: 8px;
    position: absolute;
  }

  .p-colorpicker-color {
    width: 150px;
    height: 150px;
    background: transparent url("/assets/styles/portal/source/styles/vendor/primeng/images/color.png") no-repeat left top;
  }

  .p-colorpicker-color-handle {
    position: absolute;
    top: 0px;
    left: 150px;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
    margin: -5px 0 0 -5px;
    cursor: pointer;
  }

  .p-colorpicker-hue {
    background: transparent url("/assets/styles/portal/source/styles/vendor/primeng/images/hue.png") no-repeat left top;
    width: 17px;
    height: 150px;
    top: 8px;
    left: 167px;
    position: absolute;
    opacity: 0.85;
  }

  .p-colorpicker-hue-handle {
    position: absolute;
    top: 150px;
    left: 0px;
    width: 21px;
    margin-left: -2px;
    margin-top: -5px;
    height: 10px;
    border: 2px solid #ffffff;
    opacity: 0.85;
    cursor: pointer;
  }

  &.p-state-disabled {

    .p-colorpicker-hue-handle,
    .p-colorpicker-color-handle {
      opacity: 0.5;
    }
  }
}

.p-contextmenu {
  width: 12.5em;
  padding: 0.25em;
  position: absolute;
  display: none;

  .p-menu-separator {
    border-width: 1px 0 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-submenu-list {
    display: none;
    position: absolute;
    width: 12.5em;
    padding: 0.25em;
  }

  .p-menuitem-link {
    padding: 0.25em;
    display: block;
    position: relative;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }

  .p-menuitem {
    position: relative;
  }

  .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -0.5em;
    right: 0;
    top: 50%;
  }

  .p-menuitem-active>.p-submenu>.p-submenu-list {
    display: block !important;
  }
}

.p-datagrid .p-paginator {
  text-align: center;
}

.p-datagrid-column {
  padding: 0.25em;
}

.p-datagrid-content-empty {
  padding: 0.25em 0.625em;
}

.p-datagrid {

  .p-datagrid-header,
  .p-datagrid-footer {
    text-align: center;
    padding: 0.5em 0.75em;
  }

  .p-datagrid-header {
    border-bottom: 0 none;
  }

  .p-datagrid-footer {
    border-top: 0 none;
  }

  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }
}

.p-datalist {

  .p-datalist-header,
  .p-datalist-footer {
    text-align: center;
    padding: 0.5em 0.75em;
  }

  .p-datalist-header {
    border-bottom: 0 none;
  }

  .p-datalist-footer {
    border-top: 0 none;
  }

  .p-datalist-data {
    margin: 0;
    padding: 0;

    >li {
      list-style-type: none;
    }
  }

  .p-datalist-emptymessage {
    padding: 0.5em 0.75em;
  }

  &.p-datalist-scrollable .p-datalist-content {
    overflow: auto;
  }

  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }
}

.p-datascroller {
  .p-datascroller-header {
    text-align: center;
    padding: 0.5em 0.75em;
    border-bottom: 0 none;
  }

  .p-datascroller-footer {
    text-align: center;
    padding: 0.25em 0.625em;
    border-top: 0px none;
  }

  .p-datascroller-content {
    padding: 0.25em 0.625em;
  }
}

.p-datascroller-inline .p-datascroller-content {
  overflow: auto;
}

.p-datascroller .p-datascroller-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-datatable {
  position: relative;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .p-datatable-header,
  .p-datatable-caption,
  .p-datatable-footer {
    text-align: center;
    padding: 0.5em 0.75em;
    box-sizing: border-box;
  }

  .p-datatable-caption,
  .p-datatable-header {
    border-bottom: 0 none;
  }

  .p-datatable-footer {
    border-top: 0 none;
  }

  thead th,
  tfoot td {
    text-align: center;
  }

  thead tr {
    border-width: 0;
  }

  // .p-datatable-thead > tr > th,
  // .p-datatable-tfoot > tr > td,
  // .p-datatable-data > tr > td {
  //   border-color: inherit;
  //   box-sizing: border-box;
  //   padding: 0.25em 0.5em;
  //   border-width: 1px;
  //   border-style: solid;
  // }
  &.p-datatable-resizable {

    .p-datatable-thead>tr>th,
    .p-datatable-tfoot>tr>td,
    .p-datatable-data>tr>td {
      overflow: hidden;
    }
  }

  // .p-datatable-thead>tr>th,
  // .p-datatable-tfoot>tr>td {
  //   font-weight: normal;
  // }

  tbody {
    outline: 0;
  }

  .p-sortable-column {
    cursor: pointer;
  }

  .p-sortable-column-icon {
    display: inline-block;
    margin-left: 0.125em;
  }

  tr.p-state-highlight {
    cursor: pointer;
  }
}

/* Scrollable */

.p-datatable-scrollable-body {
  overflow: auto;
  overflow-anchor: none;
  min-height: 0%;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
}

.p-datatable-scrollable {

  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    position: relative;
    border: 0 none;
  }

  .p-datatable-scrollable-header td {
    font-weight: normal;
  }
}

.p-datatable-scrollable-body .p-datatable-data {
  border-top-color: transparent;

  >tr:first-child {
    border-top-color: transparent;
  }
}

.p-datatable .p-datatable-data tr {

  &.p-state-hover,
  &.p-state-highlight {
    border-color: inherit;
    font-weight: inherit;
    cursor: pointer;
  }

  &.p-rowgroup-header td {

    a,
    span.p-rowgroup-header-name {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.p-datatable-scrollable-theadclone {
  height: 0;

  tr {
    height: 0;
  }

  th {
    &.p-state-default {
      height: 0;
      border-bottom-width: 0;
      border-top-width: 0;
      padding-top: 0;
      padding-bottom: 0;
      outline: 0 none;
    }

    span.p-column-title {
      display: block;
      height: 0;
    }
  }
}

.p-datatable {
  .p-paginator {
    padding: 0.125em;
  }

  .p-paginator-top {
    border-bottom-width: 0;
  }

  .p-paginator-bottom {
    border-top-width: 0;
  }
}

.p-datatable-rtl {
  direction: rtl;

  &.p-datatable {

    thead th,
    tfoot td {
      text-align: right;
    }
  }
}

/* Row Toggler */

.p-row-toggler {
  cursor: pointer;
}

/* Resizable */

.p-datatable {
  .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5em;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }

  .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
}

.p-datatable-resizable {
  padding-bottom: 1px;
  /*fix for webkit overlow*/
  overflow: auto;

  thead th,
  tbody td,
  tfoot td {
    white-space: nowrap;
  }

  th.p-resizable-column {
    background-clip: padding-box;
    position: relative;
  }
}

/** Reflow **/

.p-datatable-reflow .p-datatable-data td .p-column-title {
  display: none;
}

/* Filter */

.p-datatable {
  .p-column-filter {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.25em;
  }

  .p-editable-column input {
    width: 100%;
    outline: 0;
  }

  .p-datatable-data>tr>td.p-editable-column {
    padding: 0.5em;
  }

  .p-editable-column>.p-cell-editor {
    display: none;
  }

  .p-datatable-data>tr>td.p-editable-column.p-cell-editing {
    padding: 1px;
  }

  .p-editable-column.p-cell-editing> {
    .p-cell-editor {
      display: block;
    }

    .p-cell-data {
      display: none;
    }
  }
}

/* Editing */

.p-datatable-stacked {

  thead th,
  tfoot td {
    display: none !important;
  }
}

.p-datatable.p-datatable-stacked .p-datatable-data {
  >tr>td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  &.p-widget-content {
    border: 0 none;
  }
}

.p-datatable-stacked .p-datatable-data {
  tr.p-widget-content {
    border-left: 0 none;
    border-right: 0 none;
  }

  td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}

.p-datatable .p-selection-column {

  .p-checkbox,
  .p-radiobutton {
    margin: 0;
    display: block;
  }

  .p-checkbox-box,
  .p-radiobutton-box {
    display: block;
    box-sizing: border-box;
    margin: 0;
  }
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-scrollable-view {}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0px;
}

.p-datatable {
  .p-datatable-load-status {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .p-datatable-virtual-table {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .p-datatable-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
  }

  .p-datatable-loading-content {
    position: absolute;
    left: 50%;
    top: 25%;
    z-index: 2;
  }
}

@media (max-width: 35em) {
  .p-datatable-reflow {

    thead th,
    tfoot td {
      display: none !important;
    }

    .p-datatable-data {
      >tr>td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        clear: left;
      }

      &.p-widget-content {
        border: 0 none;
      }

      tr.p-widget-content {
        border-left: 0 none;
        border-right: 0 none;
      }

      td .p-column-title {
        padding: 0.4em;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4em;
        font-weight: bold;
      }
    }

    &.p-datatable-scrollable .p-datatable-scrollable-body colgroup {
      display: block;
    }
  }
}

.p-dataview {
  position: relative;

  .p-paginator {
    text-align: center;
  }
}

.p-dataview-column {
  padding: 0.25em;
}

.p-dataview-content-empty {
  padding: 0.25em 0.625em;
}

.p-dataview {

  .p-dataview-header,
  .p-dataview-footer {
    padding: 0.5em 0.75em;
  }

  .p-dataview-header {
    border-bottom: 0 none;
  }

  .p-dataview-footer {
    border-top: 0 none;
  }

  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }

  &.p-dataview-list>.p-dataview-content>div.p-g>div {
    width: 100%;
  }

  .p-dataview-emptymessage {
    padding: 0.5em 0.75em;
  }
}

/* Loader */

.p-dataview-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  opacity: 0.1;
  z-index: 1;
}

.p-dataview-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-dataview-loading-icon {
  font-size: 2em;
}

.p-fieldset {
  padding: 0.5em 1em;

  .p-fieldset-legend {
    padding: 0.5em 1em;
  }
}

.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;

  a {
    padding: 0.5em 1em;
    cursor: pointer;
    white-space: nowrap;
    display: block;
  }
}

.p-fieldset {
  .p-fieldset-toggler {
    margin-right: 0.1em;
    display: inline-block;
    vertical-align: middle;
  }

  .p-fieldset-legend-text {
    vertical-align: middle;
  }

  .p-fieldset-content-wrapper-overflown {
    overflow: hidden;
  }
}

/*
 * FileUpload
 */

.p-fileupload-buttonbar {
  .p-fileupload-choose.p-state-disabled input {
    cursor: default;
  }

  padding: 0.5em;
  border-bottom: 0 none;

  .p-button {
    vertical-align: middle;
    margin-right: 0.25em;
  }
}

.p-fileupload-content {
  padding: 1em;
  position: relative;
  transition: border-color 0.3s;

  &.p-fileupload-highlight {
    border-color: #156090;
  }
}

.p-fileupload-files {
  img {
    border: none;
  }

  display: table;
}

.p-fileupload-row {
  display: table-row;

  >div {
    display: table-cell;
    padding: 0.5em 1em;
    vertical-align: middle;
  }
}

.p-fileupload-content {
  .p-progressbar {
    width: 100%;
    position: absolute;
    top: 1px;
    left: 0;
    height: 0.25em;
    border: 0 none;
  }

  .p-progressbar-value {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
  }
}

/* Simple */

.p-fileupload-choose {
  position: relative;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    direction: ltr;
    cursor: pointer;
  }

  &.p-fileupload-choose-selected input[type="file"] {
    display: none;
  }
}

/* ui-fluid */

.p-fluid {
  .p-fileupload .p-button {
    width: auto;
  }

  .p-fileupload-content .p-button-icon-only {
    width: 2em;
  }

  .fc .p-button {
    width: auto;
  }
}

@media (max-width: 40em) {
  .p-fileupload-buttonbar .p-button {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.25em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .p-fileupload-row>div {
    display: block;
  }
}

.p-galleria {
  overflow: hidden;
  visibility: hidden;
  position: relative;

  .p-galleria-panel-wrapper {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .p-galleria-panel {
    filter: inherit;
    position: absolute;
    top: 0;
    left: 0;
    list-style-type: none;
  }

  .p-galleria-filmstrip-wrapper {
    overflow: hidden;
    margin: 0.25em auto;
    position: relative;
  }

  .p-galleria-filmstrip {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    width: 2340px;
    z-index: 900;
    position: absolute;
    top: 0;
    left: 0;
  }

  .p-galleria-frame {
    float: left;
    margin-right: 5px;
    opacity: 0.3;
    cursor: pointer;
  }

  .p-galleria-frame-active {
    opacity: 1;
  }

  .p-galleria-frame-content {
    overflow: hidden;
  }

  .p-galleria-nav-next {
    cursor: pointer;
    position: absolute;
    z-index: 910;
  }

  .p-galleria-nav-prev {
    cursor: pointer;
    position: absolute;
    z-index: 910;
    left: 4px;
  }

  .p-galleria-nav-next {
    right: 4px;
  }

  .p-galleria-caption {
    position: absolute;
    left: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    color: #ededed;
    padding: 0.2em 1em;

    h4 {
      color: #ededed;
    }
  }

  .p-galleria-panel-content {
    padding: 1em 1.4em;
  }
}

/* Deprecated Grid CSS */

.p-grid {
  clear: both;
  padding: 0;
  margin: 0;

  &::before {
    content: "";
    display: table;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .p-grid-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    clear: both;
  }
}

.p-grid-row::after {
  clear: both;
  content: "";
  display: table;
}

.p-grid-col-1,
.p-grid-col-2,
.p-grid-col-3,
.p-grid-col-4,
.p-grid-col-5,
.p-grid-col-6,
.p-grid-col-7,
.p-grid-col-8,
.p-grid-col-9,
.p-grid-col-10,
.p-grid-col-11,
.p-grid-col-12 {
  float: left;
  box-sizing: border-box;
}

.p-grid-col-1 {
  width: 8.33333%;
}

.p-grid-col-2 {
  width: 16.66666%;
}

.p-grid-col-3 {
  width: 25%;
}

.p-grid-col-4 {
  width: 33.33333%;
}

.p-grid-col-5 {
  width: 41.66666%;
}

.p-grid-col-6 {
  width: 50%;
}

.p-grid-col-7 {
  width: 58.33333%;
}

.p-grid-col-8 {
  width: 66.66666%;
}

.p-grid-col-9 {
  width: 75%;
}

.p-grid-col-10 {
  width: 83.33333%;
}

.p-grid-col-11 {
  width: 91.66666%;
}

.p-grid-col-12 {
  width: 100%;
}

@media (min-width: 480px) {
  .p-grid-fixed {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .p-grid-fixed {
    width: 768px;
  }
}

@media (min-width: 960px) {
  .p-grid-fixed {
    width: 960px;
  }
}

@media (min-width: 1024px) {
  .p-grid-fixed {
    width: 1024px;
  }
}

/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive {
    .p-grid-row {
      display: block;
    }

    .p-grid-col-1,
    .p-grid-col-2,
    .p-grid-col-3,
    .p-grid-col-4,
    .p-grid-col-5,
    .p-grid-col-6,
    .p-grid-col-7,
    .p-grid-col-8,
    .p-grid-col-9,
    .p-grid-col-10,
    .p-grid-col-11,
    .p-grid-col-12 {
      width: 100%;
      float: none;
    }
  }
}

.p-grid.p-grid-pad>.p-grid-row>div {
  padding: 0.25em 0.5em;
}

/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive {
    .p-grid-row {
      display: block;
    }

    .p-grid-col-1,
    .p-grid-col-2,
    .p-grid-col-3,
    .p-grid-col-4,
    .p-grid-col-5,
    .p-grid-col-6,
    .p-grid-col-7,
    .p-grid-col-8,
    .p-grid-col-9,
    .p-grid-col-10,
    .p-grid-col-11,
    .p-grid-col-12 {
      width: 100%;
      float: none;
    }
  }
}

/* New Grid CSS */

.p-g {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  &::after {
    clear: both;
    content: "";
    display: table;
    box-sizing: border-box;
  }
}

.p-g-1,
.p-g-2,
.p-g-3,
.p-g-4,
.p-g-5,
.p-g-6,
.p-g-7,
.p-g-8,
.p-g-9,
.p-g-10,
.p-g-11,
.p-g-12 {
  float: left;
  box-sizing: border-box;
  padding: 0.5em;
}

.p-g-1 {
  width: 8.3333%;
}

.p-g-2 {
  width: 16.6667%;
}

.p-g-3 {
  width: 25%;
}

.p-g-4 {
  width: 33.3333%;
}

.p-g-5 {
  width: 41.6667%;
}

.p-g-6 {
  width: 50%;
}

.p-g-7 {
  width: 58.3333%;
}

.p-g-8 {
  width: 66.6667%;
}

.p-g-9 {
  width: 75%;
}

.p-g-10 {
  width: 83.3333%;
}

.p-g-11 {
  width: 91.6667%;
}

.p-g-12 {
  width: 100%;
}

.p-g-offset-12 {
  margin-left: 100%;
}

.p-g-offset-11 {
  margin-left: 91.66666667%;
}

.p-g-offset-10 {
  margin-left: 83.33333333%;
}

.p-g-offset-9 {
  margin-left: 75%;
}

.p-g-offset-8 {
  margin-left: 66.66666667%;
}

.p-g-offset-7 {
  margin-left: 58.33333333%;
}

.p-g-offset-6 {
  margin-left: 50%;
}

.p-g-offset-5 {
  margin-left: 41.66666667%;
}

.p-g-offset-4 {
  margin-left: 33.33333333%;
}

.p-g-offset-3 {
  margin-left: 25%;
}

.p-g-offset-2 {
  margin-left: 16.66666667%;
}

.p-g-offset-1 {
  margin-left: 8.33333333%;
}

.p-g-offset-0 {
  margin-left: 0%;
}

@media screen and (max-width: 40em) {

  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
    padding: 0.5em;
  }

  .p-sm-1 {
    width: 8.3333%;
  }

  .p-sm-2 {
    width: 16.6667%;
  }

  .p-sm-3 {
    width: 25%;
  }

  .p-sm-4 {
    width: 33.3333%;
  }

  .p-sm-5 {
    width: 41.6667%;
  }

  .p-sm-6 {
    width: 50%;
  }

  .p-sm-7 {
    width: 58.3333%;
  }

  .p-sm-8 {
    width: 66.6667%;
  }

  .p-sm-9 {
    width: 75%;
  }

  .p-sm-10 {
    width: 83.3333%;
  }

  .p-sm-11 {
    width: 91.6667%;
  }

  .p-sm-12 {
    width: 100%;
  }

  .p-sm-offset-12 {
    margin-left: 100%;
  }

  .p-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-sm-offset-9 {
    margin-left: 75%;
  }

  .p-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-sm-offset-6 {
    margin-left: 50%;
  }

  .p-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-sm-offset-3 {
    margin-left: 25%;
  }

  .p-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-sm-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 40.063em) {

  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
    padding: 0.5em;
  }

  .p-md-1 {
    width: 8.3333%;
  }

  .p-md-2 {
    width: 16.6667%;
  }

  .p-md-3 {
    width: 25%;
  }

  .p-md-4 {
    width: 33.3333%;
  }

  .p-md-5 {
    width: 41.6667%;
  }

  .p-md-6 {
    width: 50%;
  }

  .p-md-7 {
    width: 58.3333%;
  }

  .p-md-8 {
    width: 66.6667%;
  }

  .p-md-9 {
    width: 75%;
  }

  .p-md-10 {
    width: 83.3333%;
  }

  .p-md-11 {
    width: 91.6667%;
  }

  .p-md-12 {
    width: 100%;
  }

  .p-md-offset-12 {
    margin-left: 100%;
  }

  .p-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-md-offset-9 {
    margin-left: 75%;
  }

  .p-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-md-offset-6 {
    margin-left: 50%;
  }

  .p-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-md-offset-3 {
    margin-left: 25%;
  }

  .p-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-md-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 64.063em) {

  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
    padding: 0.5em;
  }

  .p-lg-1 {
    width: 8.3333%;
  }

  .p-lg-2 {
    width: 16.6667%;
  }

  .p-lg-3 {
    width: 25%;
  }

  .p-lg-4 {
    width: 33.3333%;
  }

  .p-lg-5 {
    width: 41.6667%;
  }

  .p-lg-6 {
    width: 50%;
  }

  .p-lg-7 {
    width: 58.3333%;
  }

  .p-lg-8 {
    width: 66.6667%;
  }

  .p-lg-9 {
    width: 75%;
  }

  .p-lg-10 {
    width: 83.3333%;
  }

  .p-lg-11 {
    width: 91.6667%;
  }

  .p-lg-12 {
    width: 100%;
  }

  .p-lg-offset-12 {
    margin-left: 100%;
  }

  .p-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-lg-offset-9 {
    margin-left: 75%;
  }

  .p-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-lg-offset-6 {
    margin-left: 50%;
  }

  .p-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-lg-offset-3 {
    margin-left: 25%;
  }

  .p-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-lg-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 90.063em) {

  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    padding: 0.5em;
  }

  .p-xl-1 {
    width: 8.3333%;
  }

  .p-xl-2 {
    width: 16.6667%;
  }

  .p-xl-3 {
    width: 25%;
  }

  .p-xl-4 {
    width: 33.3333%;
  }

  .p-xl-5 {
    width: 41.6667%;
  }

  .p-xl-6 {
    width: 50%;
  }

  .p-xl-7 {
    width: 58.3333%;
  }

  .p-xl-8 {
    width: 66.6667%;
  }

  .p-xl-9 {
    width: 75%;
  }

  .p-xl-10 {
    width: 83.3333%;
  }

  .p-xl-11 {
    width: 91.6667%;
  }

  .p-xl-12 {
    width: 100%;
  }

  .p-xl-offset-12 {
    margin-left: 100%;
  }

  .p-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-xl-offset-9 {
    margin-left: 75%;
  }

  .p-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-xl-offset-6 {
    margin-left: 50%;
  }

  .p-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-xl-offset-3 {
    margin-left: 25%;
  }

  .p-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-xl-offset-0 {
    margin-left: 0%;
  }
}

.p-g-nopad {
  padding: 0;
}

.p-growl {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 20em;
}

.p-growl-item-container {
  position: relative;
  margin: 0 0 10px 0;
  opacity: 0.95;
  filter: alpha(opacity=95);
}

.p-growl-item {
  position: relative;
  display: block;
  padding: 0.5em 1em;

  p {
    padding: 0;
    margin: 0;
  }
}

.p-growl-icon-close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.p-growl-title {
  font-weight: bold;
  padding: 0 0 0.5em 0;
  display: block;
}

.p-growl-image {
  position: absolute;
  display: inline-block;
  left: 0.5em;
  top: 0.25em;
  padding: 0;
  font-size: 2em;
}

.p-growl-message {
  padding: 0 0 0.25em 0;
  margin-left: 2.5em;

  p {
    font-weight: normal;
  }
}

.p-inplace {
  .p-inplace-display {
    display: inline;
    cursor: pointer;
    border: 0 none;
    padding: 0.25em;
    font-weight: normal;
  }

  .p-inplace-content {
    display: inline;
  }
}

.p-fluid .p-inputgroup p-inputmask {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.p-inputgroup p-inputmask {
  &:not(:first-child) .p-inputtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
  }

  &:not(:last-child) .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
  }
}

.p-widget-header .p-inputtext,
.p-widget-content .p-inputtext {
  font-weight: normal;
}

.p-fluid .p-inputtext {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.p-inputgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  .p-inputgroup-addon {
    display: inline-block;
    text-align: center;
    min-width: 1.5em;
    padding: 0.25em;
    border-width: 1px;
    border-style: solid;

    +.p-inputgroup-addon {
      border-left: 0 none;
    }
  }

  .p-inputtext {
    padding-left: 0.5em;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0 none;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0 none;
    }
  }

  .p-button {
    margin-right: 0;
    border-radius: 0;
  }
}

.p-fluid .p-inputgroup {
  .p-button {
    width: auto;
  }

  .p-inputtext {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.p-inputgroup {

  .p-checkbox,
  .p-radiobutton {
    margin-right: 0;
    vertical-align: bottom;
  }
}

/* Floating Label */

.p-float-label {
  display: block;
  position: relative;

  > {
    label {
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 0.25em;
      top: 50%;
      margin-top: -0.5em;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all;
      color: #898989;
      line-height: 1;
    }

    input {

      &:-webkit-autofill~label,
      &:focus~label,
      &.p-state-filled~label {
        top: -0.75em;
        font-size: 12px;
      }
    }

    .p-inputwrapper-focus~label,
    .p-inputwrapper-filled~label {
      top: -0.75em;
      font-size: 12px;
    }
  }
}

/* breaks firefox so defined separately */
textarea.p-inputtext {
  width: 100%;
  min-height: 107px;
}

.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
}

.p-float-label {
  textarea {

    &:focus~label,
    &.p-state-filled~label,
    &:-webkit-autofill~label {
      top: -0.75em;
      font-size: 12px;
    }
  }

  >textarea~label {
    left: 0.25em;
    top: 0.75em;
  }
}

.p-lightbox {
  position: fixed;
  display: none;
}

.p-lightbox-content-wrapper {
  position: relative;
}

.p-lightbox-content {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #000000;
}

.p-lightbox-nav-right {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.p-lightbox-nav-left {
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 0;
}

.p-lightbox-nav-right {
  right: 0;
}

.p-lightbox-loading .p-lightbox-content {
  background: url("/assets/styles/portal/source/styles/vendor/primeng/images/loading.gif") #000000 center center no-repeat;
}

.p-lightbox-caption {
  padding: 0.2em 0.4em;
  display: none;
}

.p-lightbox-caption-text {
  margin: 0.3em 0 0.1em 0;
  float: left;
}

.p-lightbox-close {
  float: right;
  margin: 0;
  padding: 0.125em;

  &.p-state-hover {
    padding: 0;
  }
}

.p-lightbox-nav-left,
.p-lightbox-nav-right {
  opacity: 0.5;
}

.p-lightbox-nav-left:hover,
.p-lightbox-nav-right:hover {
  opacity: 1;
}

.p-listbox {
  // padding: 0.25em;
  // width: 10em;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;

  .p-listbox-list-wrapper {
    overflow: auto;
  }

  .p-listbox-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .p-listbox-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #495057;
    transition: box-shadow 0.2s;
    border-radius: 0;

    &.p-highlight {
      color: $clr-pri;
      background: #EFF6FF;
    }

    .p-checkbox {
      margin-right: 0.5rem;
    }

    >span {
      vertical-align: middle;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.p-state-disabled .p-listbox-item {
    cursor: default;
  }
}

.p-listbox-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;

  .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0.5rem;
  }

  .p-listbox-filter-container {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
  }

  &.p-listbox-header-w-checkbox .p-listbox-filter-container {
    width: calc(100% - 2em);
  }

  .p-listbox-filter-container .p-listbox-filter-icon {
    position: absolute;
    //    top: 0.25em;
    right: 0.25em;
  }

  .p-inputtext {
    // padding: 0.125em 0.125em 0.125em 1.25em;
    width: 100%;
  }
}

.p-listbox-footer {
  padding: 0.125em 0.2em;
}

.p-megamenu {
  padding: 0.25em;
}

.p-megamenu-root-list {
  margin: 0;
  padding: 0;
  list-style: none;

  >.p-menuitem {
    position: relative;
  }
}

.p-megamenu {
  .p-menuitem-link {
    padding: 0.25em;
    display: block;
    text-decoration: none;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }
}

.p-megamenu-panel {
  display: none;
  position: absolute;
  width: auto;
}

.p-megamenu-root-list>.p-menuitem-active>.p-megamenu-panel {
  display: block;
}

.p-megamenu-panel .p-menuitem {
  margin: 0.125em 0;
}

.p-megamenu-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 12.5em;
}

.p-megamenu-submenu-header {
  padding: 0.25em;
}

/* Horizontal */

.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem {
  display: inline-block;

  >.p-menuitem-link>.p-submenu-icon {
    vertical-align: middle;
    margin-left: 0.25em;
  }
}

/* Vertical */

.p-megamenu-vertical {
  width: 12.5em;

  .p-megamenu-root-list>.p-menuitem {
    display: block;

    >.p-menuitem-link {
      position: relative;

      >.p-submenu-icon {
        position: absolute;
        width: 1em;
        height: 1em;
        top: 50%;
        right: 0;
        margin-top: -0.5em;
      }
    }
  }
}

.p-megamenu .p-g {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.p-menubar {
  padding: 0.25em;

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .p-menuitem-link {
    display: block;
    padding: 0.25em;
    position: relative;
    text-decoration: none;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
  }

  .p-menubar-root-list {
    display: inline-block;

    > {
      .p-menuitem {
        display: inline-block;
        position: relative;

        >.p-menuitem-link {
          padding: 0.5em;

          >.p-submenu-icon {
            vertical-align: middle;
            margin-left: 0.25em;
          }
        }
      }

      li ul {
        display: none;
      }

      .p-menu-separator {
        display: inline-block;
        vertical-align: middle;
        height: 1.5em;
      }
    }
  }

  .p-submenu-list {
    display: none;
    position: absolute;
    min-width: 12.5em;
    padding: 0.25em;

    .p-menuitem {
      margin: 0.125em 0;
      position: relative;
    }
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }

  .p-submenu-list .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -0.5em;
    right: 0;
    top: 50%;
  }

  .p-menuitem-active>.p-submenu>.p-submenu-list {
    display: block;
  }

  .p-menubar-custom {
    float: right;
    padding: 0.25em;
  }
}

.p-message {
  border: 1px solid;
  margin: 0px 0.25em;
  padding: 0.25em 0.5em;
  display: inline-block;
  vertical-align: top;

  .p-message-icon,
  .p-message-text {
    vertical-align: middle;
  }
}

.p-fluid .p-message {
  display: block;
}

.p-messages {
  border: 1px solid;
  margin: 0.5em 0;
  padding: 1em 1em 1em 0.5em;
  position: relative;
  display: flex;

  .p-messages-icon {
    display: inline-block;
    padding: 0;
    vertical-align: middle;
    font-size: 2em;
  }

  .p-messages-summary {
    font-weight: bold;
    margin-left: 0.25em;
  }

  .p-messages-detail {
    margin-left: 0.25em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;
    vertical-align: middle;
    display: flex;
    align-items: center;

    >li {
      padding: 0.25em;
    }
  }

  &.p-messages-noicon ul {
    margin: 0 1.5em 0 0;
  }

  .p-messages-close {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.ui-multiselect,
.p-multiselect {
  display: inline-block;
  position: relative;
  width: 100%;
  cursor: pointer;
  border-color: #d0d7dd;
  border-radius: 3px;
  font-size: 12px;

  .ui-multiselect-trigger,
  .p-multiselect-trigger {
    border-right: none;
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    width: 2em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;

    .ui-multiselect-trigger-icon,
    .p-multiselect-trigger-icon {
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      position: absolute;
      font-size: 12px;
    }
  }

  .ui-multiselect-label-container,
  .p-multiselect-label-container {
    overflow: hidden;
  }

  .ui-multiselect-label,
  .p-multiselect-label {
    display: block;
    // padding: 2px 2.5em 3px 10px;
    width: auto;
    border: none;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal !important;

    .setting-field & {
      font-size: 11px;
      padding: 5px;
      padding-right: 2.5em;
      max-width: 70px;
    }
  }

  &.ui-state-disabled,
  &.p-state-disabled {

    .ui-multiselect-trigger,
    .p-multiselect-trigger,
    .ui-multiselect-label,
    .p-multiselect-label {
      cursor: auto;
    }
  }

  .p-multiselected-item-token,
  .p-multiselected-empty-token,
  .ui-multiselected-item-token,
  .ui-multiselected-empty-token {
    //padding: 2px 4px;
    //margin: 0 0.286em 0 0;
    //display: inline-block;
    //vertical-align:middle;
    //height: 1.857em;


    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border: 0 none;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 3px 7px 2px;
    margin: 3px 5px 3px 0;

    :lang(fr) {
      text-transform: none;
    }
  }

  .ui-multiselected-item-token,
  .p-multiselected-item-token {
    background: $clr-pri;
    color: #ffffff;
  }

  .ui-multiselected-empty-token,
  .p-multiselected-empty-token {
    background: $clr-pri;
    color: #ffffff;
    visibility: visible !important;
  }
}

.ui-multiselect-panel,
.p-multiselect-panel {
  // padding: 0.2em;
  position: absolute;
  min-width: 12em;
  font-size: 12px;
}

.ui-multiselect .ui-multiselect-panel,
.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.ui-multiselect-panel,
.p-multiselect-panel {

  .ui-multiselect-items-wrapper,
  .p-multiselect-items-wrapper {
    overflow: auto;
    position: relative;
    padding: 0.2em 0;
  }

  .ui-multiselect-list,
  .p-multiselect-list {
    border: 0 none;
  }

  .ui-multiselect-item,
  .p-multiselect-item {
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: 0.5em !important;
    text-align: left;
    white-space: nowrap;
    display: block;
    position: relative;
    outline: none;

    .p-checkbox,
    span {
      display: inline-block;
    }

    >span {
      padding: 3px 0 0 0;
      vertical-align: middle;
    }

    //added for form multiselect of customer history
    // padding: 7px 5px 7px 30px !important;
    clear: both;
    float: left;
    width: 100%;

    .p-checkbox {
      // position: absolute !important;
      // left: 6px;
      // top: 50%;
      // margin-top: -9px !important;

    }

    >div {
      float: none !important;
      font-size: 13px !important;
      margin-top: 0 !important;
      display: inline-block !important;
    }

    >.p-checkbox {
      margin-bottom: 3px !important;
    }

  }
}

.ui-multiselect-header,
.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
  min-height: 2em;
  display: block !important;

  .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  .ui-multiselect-filter-container,
  .p-multiselect-filter-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 65%;
  }

  &.ui-multiselect-header-no-toggleall .ui-multiselect-filter-container,
  &.p-multiselect-header-no-toggleall .p-multiselect-filter-container {
    width: 85%;
  }

  .ui-multiselect-filter-container .ui-multiselect-filter-icon,
  .p-multiselect-filter-container .p-multiselect-filter-icon {
    position: absolute;
    top: 0.25em;
    left: 0.125em;
  }

  .ui-inputtext,
  .p-inputtext {
    padding: 0.125em 0.125em 0.125em 1.25em;
    width: 100%;
  }

  .ui-multiselect-close,
  .p-multiselect-close {
    position: absolute;
    right: 0.5em;
    top: 0.375em;
    display: block;
    border: 0 none;
    cursor: pointer;
  }

  a {

    &.ui-multiselect-all,
    &.ui-multiselect-none,
    &.p-multiselect-all,
    &.p-multiselect-none {
      float: left;
      margin-right: 10px;
      display: block;
    }
  }
}

.ui-multiselect-footer,
.p-multiselect-footer {
  padding: 0.25em;
}

.ui-fluid .ui-multiselect,
.p-fluid .p-multiselect {
  width: 100%;
  box-sizing: border-box;
}

.p-orderlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-orderlist-controls-left {
  flex-direction: row;
}

.p-orderlist-controls-right {
  flex-direction: row-reverse;
}

.p-orderlist-controls,
.p-orderlist-list-container {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.p-orderlist-controls {
  padding: 0 0.25em;
  width: 16.66666%;
  align-self: center;

  .p-button.p-button-icon-only {
    display: block;
    margin-bottom: 0.25em;
    width: 100%;
  }
}

.p-orderlist-list-container {
  width: 83.33333%;
}

.p-orderlist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 12.5em;
}

.p-orderlist-caption {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-orderlist-item {
  margin: 1px;
  padding: 0.125em;
  cursor: pointer;
  border: 0 none;
  font-weight: inherit;
}

.p-orderlist-filter-container {
  position: relative;
  width: 100%;
  padding: 0.5em 0.6em;
  border-bottom: 0 none;

  .p-inputtext {
    text-indent: 1.1em;
    width: 100%;
  }

  .p-orderlist-filter-icon {
    position: absolute;
    top: 50%;
    left: 1em;
    margin-top: -0.6em;
  }
}

.p-orderlist {
  &.p-state-disabled {

    .p-orderlist-item,
    .p-button {
      cursor: default;
    }

    .p-orderlist-list {
      overflow: hidden;
    }
  }

  .p-orderlist-droppoint {
    height: 6px;
    list-style-type: none;
  }
}

@media (max-width: 767px) {
  .p-orderlist-controls {
    width: 100%;
    text-align: center;
  }

  .p-orderlist {
    .p-orderlist-list-container {
      width: 100%;
    }

    .p-orderlist-controls .p-button.p-button.p-button-icon-only {
      display: inline-block;
      width: 20%;
      margin-right: 0.25em;
    }
  }
}

.p-organizationchart {
  .p-organizationchart-table {
    border-spacing: 0;
    border-collapse: separate;
    margin: 0 auto;

    >tr>td {
      text-align: center;
      vertical-align: top;
      padding: 0;
      padding: 0 0.75em;
    }
  }

  .p-organizationchart-node-content {
    padding: 0.5em 0.75em;
    display: inline-block;
    position: relative;

    .p-node-toggler {
      position: absolute;
      bottom: -9px;
      margin-left: -8px;
      z-index: 2;
      left: 50%;
    }
  }

  .p-organizationchart-line-down {
    margin: 0 auto;
    height: 20px;
    width: 1px;
    float: none;
  }

  .p-organizationchart-line-right {
    float: none;
    border-radius: 0px;
  }

  .p-organizationchart-line-left {
    float: none;
    border-radius: 0;
  }

  .p-organizationchart-node-content.p-organizationchart-selectable-node {
    cursor: pointer;
  }
}

// .p-overlaypanel {
//   padding: 0;
//   margin: 0;
//   position: absolute;
//   margin-top: 10px;
// }

// .p-overlaypanel-flipped {
//   margin-top: 0;
//   margin-bottom: 10px;
// }

// .p-overlaypanel-content {
//   padding: 0.5em 1em;
// }

// .p-overlaypanel-close {
//   position: absolute;
//   top: -1em;
//   right: -1em;
//   width: 2em;
//   height: 2em;
//   line-height: 2em;
//   text-align: center;
//   -moz-border-radius: 100%;
//   -webkit-border-radius: 100%;
//   border-radius: 100%;
//   cursor: pointer;
// }

// .p-overlaypanel-close-icon {
//   line-height: inherit;
// }

// .p-overlaypanel {
//   &:after,
//   &:before {
//     bottom: 100%;
//     left: 1.25em;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//   }
//   &:after {
//     border-width: 8px;
//     margin-left: -8px;
//   }
//   &:before {
//     border-width: 10px;
//     margin-left: -10px;
//   }
// }

// .p-overlaypanel-flipped {
//   &:after,
//   &:before {
//     bottom: auto;
//     top: 100%;
//   }
// }

// .p-overlaypanel.p-overlaypanel-flipped {
//   &:after,
//   &:before {
//     border-bottom-color: transparent;
//   }
// }

.p-panel {
  padding: 0.2em;

  .p-panel-titlebar {
    padding: 0.5em 0.75em;

    &.p-panel-titlebar-clickable {
      cursor: pointer;
    }
  }

  .p-panel-titlebar-icon {
    float: right;
    cursor: pointer;
    margin-left: 0.2em;
    margin-top: -0.1em;
  }

  .p-panel-content {
    border: 0;
    background: none;
    padding: 0.5em 0.75em;
  }

  .p-panel-footer {
    border-width: 1px 0 0;
    padding: 0.25em 0.5em;
    text-align: left;
  }
}

.p-panel-content-wrapper-overflown {
  overflow: hidden;
}

.p-password-panel {
  padding: 0.25em 0.5em;
  position: absolute;

  .p-password-meter {
    height: 10px;
    background: transparent url("/assets/styles/portal/source/styles/vendor/primeng/images/password-meter.png") no-repeat left top;
    padding: 0;
    margin: 0;
  }
}

.p-password-info {
  margin-top: 0.25em;
}

/* Overlay Animations */

.p-password-panel {
  -webkit-transform: translateY(5%);
  -ms-transform: translateY(5%);
  transform: translateY(5%);
  opacity: 0;
  -webkit-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.p-password-panel-visible {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.p-password-panel-hidden {
  opacity: 0;
  -webkit-transform: translateY(5%);
  -ms-transform: translateY(5%);
  transform: translateY(5%);
  -webkit-transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s;
}

.p-picklist {
  >div {
    float: left;
  }

  .p-picklist-buttons {
    height: 12.5em;
    padding: 0 0.25em;
  }

  .p-picklist-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 12.5em;
    width: 12.5em;

    li {
      margin: 1px;
      padding: 0.125em;
    }
  }

  .p-button {
    display: block;
    margin-bottom: 0.25em;
  }

  .p-button-text-icon-left {
    width: 100%;
  }

  .p-picklist-item {
    cursor: pointer;
    border: 0 none;
    font-weight: inherit;
  }

  .p-picklist-caption {
    text-align: center;
    padding: 0.5em 0.75em;
    border-bottom: 0 none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .p-picklist-filter-container {
    position: relative;
    width: 100%;
    padding: 0.5em 0.6em;
    border-bottom: 0 none;

    .p-picklist-filter {
      text-indent: 1.1em;
      width: 100%;
    }

    .p-picklist-filter-icon {
      position: absolute;
      top: 50%;
      left: 1em;
      margin-top: -0.6em;
    }
  }

  display: table;

  >div {
    float: none;
    display: table-cell;
    vertical-align: top;
  }

  .p-picklist-buttons {
    vertical-align: middle;
  }

  &.p-picklist-vertical {
    display: table;

    >div {
      float: none;
      display: table-row;
      vertical-align: top;
    }

    .p-picklist-buttons {
      text-align: center;
      height: auto;

      .p-button {
        display: inline-block;
      }
    }

    .p-button {
      margin-top: 0.25em;
    }
  }
}

/* Vertical */

.p-picklist-outline {
  outline: 1px dotted black;
  z-index: 1;
}

.p-picklist {
  .p-picklist-droppoint {
    height: 6px;
    list-style-type: none;
  }

  .p-picklist-list .p-picklist-droppoint-empty {
    height: 100%;
    list-style-type: none;
  }
}

.p-picklist-list {

  &.p-picklist-source,
  &.p-picklist-target {
    outline: none;
  }
}

/* Responsive */

.p-picklist.p-picklist-responsive {
  * {
    box-sizing: border-box;
  }

  width: 100%;

  .p-picklist-listwrapper {
    width: 35%;

    &.p-picklist-listwrapper-nocontrols {
      width: 45%;
    }
  }

  .p-picklist-buttons {
    width: 10%;

    button {
      width: 100%;
    }
  }

  .p-picklist-list {
    width: auto;
  }
}

/* Responsive */
@media (max-width: 40em) {
  .p-picklist.p-picklist-responsive {
    display: block;

    >div {
      display: block;
      width: 100% !important;
    }

    .p-picklist-buttons {
      text-align: center;
      height: auto;
      padding: 0.4em 0;

      button {
        display: inline-block;
        width: 20%;
        margin-bottom: 0;
        margin-right: 0.25em;
      }
    }

    .p-picklist-source-controls.p-picklist-buttons {
      padding-bottom: 0.4em;
    }

    .p-picklist-target-controls.p-picklist-buttons {
      padding-top: 0.4em;
    }

    .p-picklist-buttons {
      .pi-angle-right::before {
        content: "\e930";
      }

      .pi-angle-double-right::before {
        content: "\e92c";
      }

      .pi-angle-left::before {
        content: "\e933";
      }

      .pi-angle-double-left::before {
        content: "\e92f";
      }
    }
  }
}

.p-progressbar {
  height: 1.2em;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.p-progressbar-determinate {
  .p-progressbar-value {
    height: 100%;
    width: 0%;
    position: absolute;
    display: none;
    border: 0 none;
  }

  .p-progressbar-value-animate {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  .p-progressbar-label {
    text-align: center;
    height: 100%;
    width: 100%;
    position: absolute;
    display: none;
    font-weight: bold;
  }
}

.p-progressbar-indeterminate {
  height: 3px;

  .p-progressbar-value {
    border: 0 none;

    &::before {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
      animation-delay: 1.15s;
    }
  }
}

@-webkit-keyframes ui-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes ui-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes ui-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes ui-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}


.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: inline-block;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.p-progress-spinner-svg {
  animation: ui-progress-spinner-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #d62d20;
  animation: ui-progress-spinner-dash 1.5s ease-in-out infinite,
    ui-progress-spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes ui-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes ui-progress-spinner-color {

  100%,
  0% {
    stroke: #d62d20;
  }

  40% {
    stroke: #0057e7;
  }

  66% {
    stroke: #008744;
  }

  80%,
  90% {
    stroke: #ffa700;
  }
}

.p-rating {
  font-size: 1.25em;
}

.p-fluid .fc .p-button {
  width: auto;
}

.p-selectbutton {
  display: inline-block;

  &.p-state-error {
    padding: 0;
  }

  .p-button.p-state-focus {
    outline: none;
  }
}

.p-sidebar {
  position: fixed;
  padding: 0.5em 1em;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.p-sidebar-left {
  top: 0;
  left: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.p-sidebar-right {
  top: 0;
  right: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.p-sidebar-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.p-sidebar-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.p-sidebar-full {
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: transform 0s;
  transition: transform 0s;
}

.p-sidebar-left.p-sidebar-active,
.p-sidebar-right.p-sidebar-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
  width: 20em;
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
  width: 40em;
}

.p-sidebar-left.p-sidebar-lg,
.p-sidebar-right.p-sidebar-lg {
  width: 60em;
}

.p-sidebar-top.p-sidebar-active,
.p-sidebar-bottom.p-sidebar-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.p-sidebar-top.p-sidebar-sm,
.p-sidebar-bottom.p-sidebar-sm {
  height: 10em;
}

.p-sidebar-top.p-sidebar-md,
.p-sidebar-bottom.p-sidebar-md {
  height: 20em;
}

.p-sidebar-top.p-sidebar-lg,
.p-sidebar-bottom.p-sidebar-lg {
  height: 30em;
}

.p-sidebar-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

.p-sidebar-close {
  float: right;
}

@media screen and (max-width: 64em) {
  .p-sidebar-left {

    &.p-sidebar-lg,
    &.p-sidebar-md {
      width: 20em;
    }
  }

  .p-sidebar-right {

    &.p-sidebar-lg,
    &.p-sidebar-md {
      width: 20em;
    }
  }
}

.p-slidemenu {
  width: 12.5em;
  padding: 0.25em;

  &.p-slidemenu-dynamic {
    position: absolute;
  }

  .p-menu-separator {
    border-width: 1px 0 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-slidemenu-rootlist {
    position: absolute;
    top: 0;
  }

  .p-submenu-list {
    display: none;
    position: absolute;
    top: 0;
    width: 12.5em;
    padding: 0.25em;
  }

  .p-menuitem-link {
    padding: 0.25em;
    display: block;
    position: relative;
    text-decoration: none;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }

  .p-menuitem {
    position: relative;
    margin: 0.125em 0;
  }

  .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -0.5em;
    right: 0;
    top: 50%;
  }

  .p-slidemenu-wrapper {
    position: relative;
  }

  .p-slidemenu-content {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
}

.p-slidemenu-backward {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.25em;
  cursor: pointer;
  display: none;

  .p-slidemenu-backward-icon,
  span {
    vertical-align: middle;
  }
}

.p-slidemenu {
  .p-menuitem-active {
    position: static;

    >.p-submenu>.p-submenu-list {
      display: block;
    }
  }

  ul:not(.p-active-submenu)>.p-menuitem:not(.p-menuitem-active) {
    display: none;
  }

  .p-active-submenu>.p-menuitem-active {
    >.p-submenu>.p-submenu-list {
      display: none;
    }

    ~.p-menuitem {
      display: block;
    }
  }
}

.p-slider {
  position: relative;
  text-align: left;

  .p-slider-handle {
    position: absolute;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 1;

    &.p-slider-handle-active {
      z-index: 2;
    }
  }

  .p-slider-range {
    position: absolute;
    font-size: 0.7em;
    display: block;
    border: 0;
    background-position: 0 0;
  }
}

.p-slider-horizontal {
  height: 0.8em;

  .p-slider-handle {
    top: -0.25em;
    margin-left: -0.6em;
  }

  .p-slider-range {
    top: 0;
    height: 100%;
  }

  .p-slider-range-min {
    left: 0;
  }

  .p-slider-range-max {
    right: 0;
  }
}

.p-slider-vertical {
  width: 0.8em;
  height: 100px;

  .p-slider-handle {
    left: -0.25em;
    margin-left: 0;
    margin-bottom: -0.6em;
  }

  .p-slider-range {
    left: 0;
    width: 100%;
  }

  .p-slider-range-min {
    bottom: 0;
  }

  .p-slider-range-max {
    top: 0;
  }
}

.p-slider-animate .p-slider-handle {
  transition: left 0.3s;
}

.p-spinner {
  display: inline-block;
  overflow: visible;
  padding: 0;
  position: relative;
  vertical-align: middle;
}

.p-spinner-input {
  vertical-align: middle;
  padding-right: 1.5em;
}

.p-spinner-button {
  cursor: default;
  display: block;
  height: 50%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  vertical-align: middle;
  width: 1.5em;
}

.p-spinner .p-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}

.p-spinner-up {
  top: 0;
}

.p-spinner-down {
  bottom: 0;
}

/* Fluid */

.p-fluid .p-spinner {
  width: 100%;

  .p-spinner-input {
    padding-right: 2em;
    width: 100%;
  }

  .p-spinner-button {
    width: 1.5em;

    .p-spinner-button-icon {
      left: 0.7em;
    }
  }
}

.p-splitbutton {
  position: relative;
  display: inline-block;
  zoom: 1;

  .p-button.p-splitbutton-menubutton {
    width: 2em;
    vertical-align: top;
  }

  &.p-state-disabled button {
    cursor: default;
  }
}

.p-fluid .p-splitbutton {
  width: 100%;

  .p-button {
    &:first-child {
      width: calc(100% - 2em);
    }

    &.p-splitbutton-menubutton {
      width: 2em;
    }
  }
}

/* Responsive */
@media (max-width: 40em) {
  .p-steps .p-steps-item {
    .p-menuitem-link {
      padding: 0.5em;
    }

    .p-steps-title {
      display: none;
    }
  }
}

/** TabMenu **/

.p-tabview {
  padding: 0.25em;

  .p-tabview-nav {
    margin: 0;
    background: #ffffff;
    border-bottom: 2px solid #dee2e6;

    li {
      list-style: none;
      float: left;
      position: relative;
      margin: 0 0.125em -2px 0;
      padding: 0;
      white-space: nowrap;

      a {
        float: left;
        padding: 1em;
        text-decoration: none;
        border: solid #dee2e6;
        border-width: 0 0 2px 0;

        .tab-icon {
          margin-left: 5px;
        }
      }

      &.p-tabview-selected a,
      &.p-highlight a,
      &.p-state-disabled a,
      &.p-state-processing a {
        // background: #ffffff;
        border-color: $clr-pri;
        // color: #3B82F6
      }

      a {
        cursor: pointer;
      }
    }
  }

  &.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a {
    cursor: pointer;
  }

  .p-tabview-panel {
    border-width: 0;
    padding: 1em;
    background: none;
  }

  .p-tabview-nav li {
    display: block;

    .p-tabview-left-icon {
      margin-right: 0.25em;
      vertical-align: middle;
    }

    .p-tabview-right-icon {
      margin-left: 0.25em;
      vertical-align: middle;
    }
  }

  .p-tabview-title {
    vertical-align: middle;
  }

  .p-tabview-nav li .p-tabview-close {
    margin: 0.5em 0.3em 0 0;
    cursor: pointer;
  }

  &.p-tabview-top>.p-tabview-nav {
    li {
      border-bottom: 0;
      top: 1px;
    }

    padding: 0.2em 0.2em 0;
  }

  &.p-tabview-bottom>.p-tabview-nav {
    padding: 0 0.2em 0.2em;

    li {
      border-top: 0;
    }
  }
}

/* per orientation settings */
/* top and bottom */

/* left and right*/

.p-tabview-left::after,
.p-tabview-right::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.p-tabview-left> {
  .p-tabview-nav {
    float: left;
    width: 25%;
    height: 300px;
    background-image: none;
    padding-top: 1px;
  }

  .p-tabview-panels {
    float: right;
    width: 75%;
  }
}

.p-tabview {

  &.p-tabview-left>.p-tabview-nav li,
  &.p-tabview-right>.p-tabview-nav li {
    display: block;
    float: right;
    white-space: normal;
    width: 99%;
  }

  &.p-tabview-left>.p-tabview-nav li {
    margin: 0 0 1px 0;
    border-right: 0 none;
  }

  &.p-tabview-right> {
    .p-tabview-nav {
      float: right;
      width: 25%;
      height: 300px;
      background-image: none;
      padding-top: 1px;
    }

    .p-tabview-panels {
      float: left;
      width: 75%;
    }

    .p-tabview-nav li {
      margin: 0 0 1px 0;
      border-left: 0 none;
    }
  }
}

/* RTL */

.p-rtl .p-tabview .p-tabview-nav li {
  float: right;
}

.p-terminal {
  height: 18em;
  overflow: auto;
  padding: 0.25em;
}

.p-terminal-input {
  border: 0 none;
  background-color: transparent;
  color: inherit;
  padding: 0;
  margin: 0 0 0 0.125em;
  width: 75%;
  outline: none;
  vertical-align: baseline;
}

.p-terminal-command {
  margin-left: 0.125em;
  -moz-margin-start: 0.125em;
}

.p-terminal-input::-ms-clear {
  display: none;
}

.p-tieredmenu {
  width: 12.5em;
  padding: 0.25em;

  &.p-tieredmenu-dynamic {
    position: absolute;
  }

  .p-menu-separator {
    border-width: 1px 0 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-submenu-list {
    display: none;
    position: absolute;
    width: 12.5em;
    padding: 0.25em;
  }

  .p-menuitem-link {
    padding: 0.25em;
    display: block;
    position: relative;
    text-decoration: none;
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }

  .p-menuitem-text {
    vertical-align: middle;
  }

  .p-menuitem {
    position: relative;
    margin: 0.125em 0;
  }

  .p-menuitem-link .p-submenu-icon {
    position: absolute;
    margin-top: -0.5em;
    right: 0;
    top: 50%;
  }

  .p-menuitem-active>.p-submenu>.p-submenu-list {
    display: block;
  }
}

.p-toolbar {
  padding: 0.25em 0.5em;
}

.p-toolbar-group-left {
  float: left;
}

.p-toolbar-group-right {
  float: right;
}

/** Loading **/

/* Sections */

.ui-treetable-caption,
.ui-treetable-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.ui-treetable-caption {
  border-bottom: 0 none;
}

.ui-treetable-summary {
  border-top: 0 none;
}

/* Paginator */

.ui-treetable {
  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }
}

/* Scrollable */

.ui-treetable-scrollable-wrapper {
  position: relative;
}

.ui-treetable-scrollable-header,
.ui-treetable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.ui-treetable-scrollable-body {
  overflow: auto;
  position: relative;

  >table>.ui-treetable-tbody>tr:first-child>td {
    border-top: 0 none;
  }
}

.ui-treetable-virtual-table {
  position: absolute;
}

/* Frozen Columns */

.ui-treetable-frozen-view {
  .ui-treetable-scrollable-body {
    overflow: hidden;
  }

  >.ui-treetable-scrollable-body>table>.ui-treetable-tbody>tr>td:last-child {
    border-right: 0 none;
  }
}

.ui-treetable-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */

.ui-treetable-resizable {
  >.ui-treetable-wrapper {
    overflow-x: auto;
  }

  .ui-treetable-thead>tr>th,
  .ui-treetable-tfoot>tr>td,
  .ui-treetable-tbody>tr>td {
    overflow: hidden;
  }

  .p-resizable-column {
    background-clip: padding-box;
    position: relative;
  }
}

.ui-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.ui-treetable {
  .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5em;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }

  .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
}

/* Reorder */

.ui-treetable-reorder-indicator-up,
.ui-treetable-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */

.ui-treetable-responsive .ui-treetable-tbody>tr>td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .ui-treetable-responsive {

    .ui-treetable-thead>tr>th,
    .ui-treetable-tfoot>tr>td {
      display: none !important;
    }

    .ui-treetable-tbody>tr>td {
      text-align: left;
      display: block;
      border: 0 none;
      width: 100% !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left;

      .p-column-title {
        padding: 0.4em;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4em;
        font-weight: bold;
      }
    }
  }
}

/* Loader */

.ui-treetable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  opacity: 0.1;
  z-index: 1;
}

.ui-treetable-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.ui-treetable .ui-treetable-loading-icon {
  font-size: 2em;
}

.p-virtualscroller {
  .p-virtualscroller-header {
    text-align: center;
    padding: 0.5em 0.75em;
    border-bottom: 0 none;
  }

  .p-virtualscroller-footer {
    text-align: center;
    padding: 0.25em 0.625em;
    border-top: 0px none;
  }

  .p-virtualscroller-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}



.scoring-component .p-datatable-tbody>tr>td {
  border-width: 1px;
}


.scoring-component .p-datatable-thead>tr>th,
.scoring-component .p-datatable-thead>tr>td {
  border-width: 1px;
}


.scoring-component-header {
  td {
    padding: 1.25em;
    border: 1px solid #ebebeb;
    font-weight: 500;
    color: #a9b4bd;
    background-color: #ffffff;
    text-transform: uppercase;
  }
}