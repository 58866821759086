$arrow-clr: #d1d1d1;

.arrow-wrap {
  width: 100%;
margin: 0;
  @include susy-breakpoint($md, $susy, '.no-mq') {
  
    display: none;
  }

  .arrow__left, .arrow__right {
    cursor: all-scroll;
    position: absolute;
    z-index: 16;
    width: 36px;
    height: 63px;
    line-height: 63px;
    font-size: 36px;
    @include transition();
    //display: inline-block;
    text-align: center;
   // background:$black;
    background-color: rgba(227, 237, 240, 0.8);
    @include border-radius(10px);
   // left: 3%;


    .icon {
      color: $arrow-clr;

    }

    &:hover{

        background: $clr-pri;
       // @include opacity(1);

      .icon{
       color:$white;
      }

    }

  }

  .arrow__right {
    right:2%;
  }

}