$classic: #b9c0c2 !default;
$trendy: #008cc2 !default;
$modern: #b20022 !default;
$decent: #016ca9 !default;
$bold: #9c0127 !default;
$tme: #282830 !default;
$lexus: #1a1a1a !default;

// Theme CLASSIC //

.classic-container {
  background: #f0f3f6;
  float: left;
  width: 100%;
}

.classic-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.classic-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.classic-header {
  float: left;
  width: 100%;
  padding: 15px 0 0 0;

  .classic-wrapper {
    border-radius: 5px;
    padding: 0;
    background: white;
    text-align: right;
    position: relative;
    overflow: hidden;
    box-shadow: 0 11px 22px 0 rgb(0 0 0 / 15%);

    img {
      float: right;
    }
  }

  .logo-bar {
    background: #d5d5d5;
    position: absolute;
    width: 40px;
    top: 50%;
    left: 40px;
    opacity: 0.5;
    height: 6px;
    margin: -3px 0 0;
  }
}

.classic-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  margin-bottom: 7px;
  border-bottom: 1px solid #cecfd1;
  box-shadow: 0 20px 20px -20px #cecfd1;

  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }
}

.classic-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    border-radius: 3px;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $classic;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.classic-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    border-radius: 3px;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.classic-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    margin: 0;
    flex: unset;
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cdced1;
    color: $gray;
    margin: 0;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: inline-block;
    line-height: 30px;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $classic;
      background: $classic;
      color: white;
    }
  }
}

.classic-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 70px;
    font-size: 10px;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    border: 1px solid #ccc;
    box-shadow: inset 0 0 3px 3px #f1f1f1;
    background: #fff;

  }
}

.classic-rating-legend {
  margin: 10px 0 0;
  width: 100%;
  text-align: center;
  float: left;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    position: absolute;
    background-color: #cecfd1;
    top: 7px;
    clear: both;
    z-index: 0;
    left: 45px;
    right: 45px;
  }

  .legend__label {
    color: #5e6162;
    font-size: 10px;
    position: relative;
    background: #f0f3f6;

    &.label--left {
      float: left;
      padding-right: 5px;
    }

    &.label--right {
      float: right;
      padding-left: 5px;
    }
  }
}

.classic-rating-scale {
  z-index: 0;
  max-width: 100%;
  display: block;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border-radius: 25px;
      border: 1px solid #cdced1;
      transition: all 0.3s ease-in-out;

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $classic;
        border-color: $classic;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.classic-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.classic-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.classic-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $classic;
        }
      }
    }
  }
}

.classic-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 20%;
  }

  .rating__label {
    float: left;
    width: 100%;
    cursor: pointer;
    position: relative;

    .active__icon {
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 10px;
      height: 20px;
      text-align: center;
      width: 20px;
      line-height: 20px;
      color: white;
      border: 2px solid white;
      background: $classic;
      border-radius: 50%;
      display: none;
    }

    img {
      width: 100%;
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      padding: 5px;
      background: $white;
      margin: 0;
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      margin: 0;
      position: relative;
    }

    &.active {
      .active__icon {
        display: inline-block;
      }

      img {
        border-color: $classic;
      }

      p {
        .radio-image__no {
          background: $classic;
          color: $white;
          border-color: $classic;
        }
      }
    }
  }
}

.classic-footer {
  text-align: center;
  margin: 10px 0;

  .preview-button {
    display: inline-block;
    background: $classic;
    color: white;
    font-size: 10px;
    padding: 8px 20px;
    border-radius: 25px;
    text-transform: uppercase;
  }
}

.classic-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 1px solid $classic;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $classic;

        i {
          display: inline-block;
        }
      }
    }
  }
}

// Theme TRENDY //

.trendy-container {
  background: #f4f5f9;
  float: left;
  width: 100%;
  padding: 15px 0;
}

.trendy-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.trendy-content {
  float: left;
  width: 100%;
}

.trendy-content-inner {
  float: left;
  width: 100%;
  background: white;
  box-shadow: 0 0 28.35px 6.65px hsl(0deg 0% 57% / 10%);
}

.trendy-header {
  float: left;
  width: 100%;
  padding: 0 0 10px 0;

  .logo-bar {
    background: #909c9c;
    width: 40px;
    opacity: 0.5;
    height: 6px;
  }
}

.trendy-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  margin-bottom: 7px;
  // border-bottom: 1px solid #cecfd1;
  // box-shadow: 0 20px 20px -20px #cecfd1;

  .question__copy {
    float: left;
    width: 35%;
  }

  .question__answer {
    float: right;
    width: 60%;
  }

  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }
}

.trendy-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    border-radius: 3px;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $trendy;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.trendy-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    border-radius: 3px;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.trendy-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    margin: 0 1px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgb(157 175 185 / 25%);
    border: 1px solid #e1e1e1;
    color: $gray;
    margin: 0;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $trendy;
      background: $trendy;
      color: white;
    }
  }
}

.trendy-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;

  .legend__label {
    color: #5e6162;
    font-size: 10px;

    &.label--left {
      float: left;
    }

    &.label--right {
      float: right;
    }
  }
}

.trendy-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 60px;
    font-size: 10px;
    border-radius: 3px;
    padding: 10px;
    resize: none;
    border: 1px solid #cdced1;
    box-shadow: 0 3px 16px 0 rgb(157 175 185 / 25%);
    background: #fff;

    &:focus {
      box-shadow: 0 3px 16px 0 rgb(157 175 185 / 25%);
    }
  }
}

.trendy-rating-scale {
  z-index: 0;
  max-width: 100%;
  display: block;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border: 1px solid #cdced1;
      transition: all 0.3s ease-in-out;
      background: #fff;
      box-shadow: 0 3px 16px 0 rgb(157 175 185 / 25%);

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $trendy;
        border-color: $trendy;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.trendy-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.trendy-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.trendy-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $trendy;
        }
      }
    }
  }
}

.trendy-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 25%;
  }

  .rating__label {
    float: left;
    width: 100%;
    cursor: pointer;
    position: relative;

    .active__icon {
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 10px;
      height: 20px;
      text-align: center;
      width: 20px;
      line-height: 20px;
      color: white;
      border: 2px solid white;
      background: $trendy;
      border-radius: 50%;
      display: none;
    }

    img {
      width: 100%;
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      padding: 5px;
      background: $white;
      margin: 0;
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      margin: 0;
      position: relative;
    }

    &.active {
      .active__icon {
        display: inline-block;
      }

      img {
        border-color: $trendy;
      }

      p {
        .radio-image__no {
          background: $trendy;
          color: $white;
          border-color: $trendy;
        }
      }
    }
  }
}

.trendy-footer {
  text-align: right;
  margin: 10px 0;
  clear: both;
  float: left;
  width: 100%;

  .preview-button {
    display: inline-block;
    background: $trendy;
    color: white;
    font-size: 10px;
    border-radius: 20px;
    text-transform: uppercase;
    box-shadow: 0 8px 16px 0 rgb(94 94 94 / 25%);
    padding: 7px 40px 6px 25px;
    position: relative;

    &:before {
      content: "\f178";
      font-family: "Font Awesome 5 Pro";
      font-size: 12px;
      position: relative;
      font-weight: 500;
      position: absolute;
      top: 7px;
      right: 15px;
    }
  }
}

.trendy-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 1px solid $trendy;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $trendy;

        i {
          display: inline-block;
        }
      }
    }
  }
}

// Theme Modern //

.modern-container {
  background: #e6e6e6;
  float: left;
  width: 100%;
}

.modern-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.modern-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.modern-header {
  float: left;
  width: 100%;
  padding: 15px 0 0 0;

  .logo-bar {
    background: #909c9c;
    width: 40px;
    opacity: 0.5;
    height: 6px;
  }
}

.modern-question {
  position: relative;
  padding: 10px 0;
  border-radius: 3px;
  float: left;
  width: 100%;

  .question__copy {
    background: #efefef;
    padding: 10px;
    margin-bottom: 15px;
  }

  p {
    padding: 0;
    text-transform: uppercase;
    font-size: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -18px;
    top: 63px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $modern;
  }

  &:after {
    content: "";
    position: absolute;
    left: -16px;
    top: 80px;
    bottom: -50px;
    width: 1px;
    background-color: $modern;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.modern-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $modern;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    padding: 6px 10px 6px;
  }
}

.modern-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.modern-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    width: 100%;
    height: 27px;
    line-height: 26px;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    border: 2px solid #cccbcb;
    border-radius: 20px;
    background: #fff;
    color: $gray;
    margin: 0;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $modern;
      background: $modern;
      color: white;
    }
  }
}

.modern-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;

  .legend__label {
    color: #5e6162;
    font-size: 10px;

    &.label--left {
      float: left;
    }

    &.label--right {
      float: right;
    }
  }
}

.modern-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 70px;
    font-size: 10px;
    border-radius: 3px;
    padding: 10px;
    resize: none;
    border: 1px solid $modern;
    // box-shadow: inset 0 0 3px 3px #f1f1f1;
    background: transparent;

    &:focus {
      border: 1px solid $modern;
      background: transparent;
    }
  }
}

.modern-rating-scale {
  z-index: 0;
  max-width: 100%;
  display: block;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      background: #fff;
      border-radius: 25px;
      border: 1px solid #9c9c9c;
      transition: all 0.3s ease-in-out;

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $modern;
        border-color: $modern;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.modern-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.modern-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.modern-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $modern;
        }
      }
    }
  }
}

.modern-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 27%;
  }

  .rating__label {
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 5px 5px 15px;
    border: 1px solid #9c9c9c;
    border-radius: 5px;
    color: #202020;

    img {
      margin-top: 20px;
      max-width: 46px;
    }

    .active__icon {
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 9px;
      height: 16px;
      text-align: center;
      width: 16px;
      line-height: 16px;
      color: white;
      border: 1px solid #9c9c9c;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      text-transform: capitalize;
      margin: 0;
      position: relative;
    }

    &.active {
      background: white;

      .active__icon {
        background: $modern;

        i {
          display: inline-block;
        }
      }
    }
  }
}

.modern-footer {
  text-align: right;
  margin-bottom: 10px;

  .preview-button {
    display: inline-block;
    background: $modern;
    color: white;
    border-radius: 25px;
    font-size: 10px;
    padding: 8px 20px;
    text-transform: uppercase;
  }
}

.modern-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 1px solid $modern;
      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $modern;

        i {
          display: inline-block;
        }
      }
    }
  }
}

// Theme Decent //

.decent-container {
  background: #f3f3f3;
  float: left;
  width: 100%;
}

.decent-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.decent-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.decent-header {
  float: left;
  width: 100%;
  padding: 8px 0;
  background: $decent;

  .logo-bar {
    background: white;
    width: 40px;
    opacity: 0.5;
    height: 6px;
  }
}

.decent-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  background: #fff;
  margin-bottom: 7px;
  box-shadow: 0px 5px 15px #0000000f;

  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }
}

.decent-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $decent;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.decent-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.decent-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    color: $gray;
    margin: 0;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $decent;
      background: $decent;
      color: white;
    }
  }
}

.decent-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;

  .legend__label {
    color: #5e6162;
    font-size: 10px;

    &.label--left {
      float: left;
    }

    &.label--right {
      float: right;
    }
  }
}

.decent-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 70px;
    font-size: 10px;
    border-radius: 0;
    padding: 10px;
    resize: none;
    border: 1px solid #ccc;
    background: #fff;

    &:focus {
      border: 1px solid #ccc;
      box-shadow: none;
    }
  }
}

.decent-rating-scale {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border: 1px solid #cdced1;
      transition: all 0.3s ease-in-out;

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $decent;
        border-color: $decent;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.decent-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.decent-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.decent-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $decent;
        }
      }
    }
  }
}

.decent-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 26%;
  }

  .rating__label {
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 5px 5px 10px;
    border: 1px solid #dadada;
    border-radius: 3px;
    color: #202020;

    img {
      margin-top: 20px;
      max-width: 46px;
    }

    .active__icon {
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 9px;
      height: 16px;
      text-align: center;
      width: 16px;
      line-height: 16px;
      color: white;
      border: 1px solid #dadada;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      text-transform: capitalize;
      margin: 0;
      position: relative;
    }

    &.active {
      background: white;

      .active__icon {
        background: $decent;

        i {
          display: inline-block;
        }
      }
    }
  }
}

.decent-footer {
  text-align: right;
  margin-bottom: 10px;

  .preview-button {
    display: inline-block;
    background: $decent;
    color: white;
    font-size: 10px;
    padding: 8px 10px;
    text-transform: uppercase;
  }
}

.decent-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 2px solid #e4e4e4;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $decent;
        border-color: $decent;

        i {
          display: inline-block;
        }
      }
    }
  }
}

// Theme Bold //

.bold-container {
  background: #fff;
  float: left;
  width: 100%;
}

.bold-banner {
  margin-top: 8px;
  float: left;
  width: 100%;
}

.bold-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.bold-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.bold-header {
  float: left;
  width: 100%;
  padding: 8px 0 0;
  background: #909c9c;

  .logo-bar {
    background: white;
    width: 40px;
    opacity: 0.5;
    height: 6px;
  }
}

.bold-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  background: #f5f5f5;
  margin-bottom: 7px;

  //box-shadow: 0px 5px 15px #0000000f;
  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }
}

.bold-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $bold;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.bold-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.bold-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgb(157 175 185 / 25%);
    border: 1px solid #e1e1e1;
    background: white;
    color: $gray;
    margin: 0;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $bold;
      background: $bold;
      color: white;
    }
  }
}

.bold-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;

  .legend__label {
    color: #5e6162;
    font-size: 10px;

    &.label--left {
      float: left;
    }

    &.label--right {
      float: right;
    }
  }
}

.bold-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 70px;
    font-size: 10px;
    border-radius: 0;
    padding: 10px;
    resize: none;
    border: 1px solid #ccc;
    background: #fff;

    &:focus {
      border: 1px solid #ccc;
      box-shadow: none;
    }
  }
}

.bold-rating-scale {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border: 1px solid #cdced1;
      transition: all 0.3s ease-in-out;
      background: #fff;
      box-shadow: 0 3px 16px 0 rgb(157 175 185 / 25%);

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $bold;
        border-color: $bold;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.bold-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.bold-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.bold-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $bold;
        }
      }
    }
  }
}

.bold-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 26%;
  }

  .rating__label {
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 5px 5px 10px;
    border: 1px solid #dadada;
    border-radius: 3px;
    color: #202020;

    img {
      margin-top: 20px;
      max-width: 46px;
    }

    .active__icon {
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 9px;
      height: 16px;
      text-align: center;
      width: 16px;
      line-height: 16px;
      color: white;
      border: 1px solid #dadada;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      text-transform: capitalize;
      margin: 0;
      position: relative;
    }

    &.active {

      //background: white;
      .active__icon {
        background: $bold;

        i {
          display: inline-block;
        }
      }
    }
  }
}

.bold-footer {
  text-align: center;
  margin-bottom: 10px;

  .preview-button {
    display: inline-block;
    background: $bold;
    color: white;
    font-size: 10px;
    padding: 8px 12px;
    text-transform: uppercase;
  }
}

.bold-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 2px solid #cdced1;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $bold;
        border-color: $bold;

        i {
          display: inline-block;
        }
      }
    }
  }
}


// Theme tme //

.tme-container {
  background: #f6f6f6;
  float: left;
  width: 100%;
}

.tme-banner {
  background: $tme;
  clear: both;
  float: left;
  width: 100%;

}

.tme-caption {
  position: absolute;
  max-width: 130px;

  h2 {
    color: white;
    line-height: 24px;

  }

  .logo-bar {
    background: white;
    width: 40px;
    opacity: .5;
    height: 6px;
    margin-bottom: 20px;
  }

}

.tme-wrapper {
  margin: 0 auto;
  max-width: 480px;
  clear: both;
  position: relative;
}

.tme-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.tme-header {
  float: left;
  width: 100%;
  padding: 8px 0;
  position: relative;
  background: $tme;

  .tme-top-bar {
    content: "";
    position: absolute;
    top: 0;
    width: 35%;
    right: 0;
    height: 5px;
    background: #e50000;
  }


}

.tme-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  background: #fff;
  margin-bottom: 7px;
  box-shadow: 0px 5px 15px #0000000f;

  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }
}

.tme-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $tme;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.tme-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #cdced1;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.tme-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    border: 1px solid #e5e5e5;
    border-left: 0;


    &:first-child {
      border-left: 1px solid #e5e5e5;
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    width: 100%;
    height: 25px;
    line-height: 25px;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    // border: 1px solid #e5e5e5;
    // border-radius: 5px;
    color: $gray;
    margin: 0;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $tme;
      background: $tme;
      color: white;
    }
  }
}

.tme-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;

  .legend__label {
    color: #5e6162;
    font-size: 10px;

    &.label--left {
      float: left;
    }

    &.label--right {
      float: right;
    }
  }
}

.tme-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 70px;
    font-size: 10px;
    border-radius: 3PX;
    padding: 10px;
    resize: none;
    border: 1px solid #ccc;
    background: #fff;

    &:focus {
      border: 1px solid #ccc;
      box-shadow: none;
    }
  }
}

.tme-rating-scale {
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border: 1px solid #cdced1;
      transition: all 0.3s ease-in-out;
      border-radius: 3px;

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        border-radius: 3PX;
        font-weight: 300;
        color: $tme;
        top: 6px;
        left: 12px;
      }

      &.active {
        color: $tme;
        border-color: $tme;
        // color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.tme-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #cdced1;
    font-size: 14px;
  }
}

.tme-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.tme-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #cdced1;

      i {
        margin: 0 2px;

        &.active {
          color: $tme;
        }
      }
    }
  }
}

.tme-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 15%;
  }

  .rating__label {
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 2px;
    border: 1px solid #dadada;
    border-radius: 3px;
    color: #202020;
    margin-bottom: 2px;

    img {
      // margin-top: 20px;
      // max-width: 46px;
    }

    .active__icon {
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 9px;
      height: 16px;
      text-align: center;
      width: 16px;
      line-height: 16px;
      color: white;
      border: 1px solid #dadada;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      text-transform: capitalize;
      margin: 0;
      position: relative;
    }

    &.active {
      background: white;

      .active__icon {
        background: $tme;

        i {
          display: inline-block;
        }
      }
    }
  }
}

.tme-footer {
  text-align: left;
  margin-bottom: 10px;

  .preview-button {
    display: inline-block;
    background: $tme;
    color: white;
    font-size: 10px;
    padding: 8px 10px;
    text-transform: uppercase;
  }
}

.tme-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 2px solid #e4e4e4;
      border-radius: 5px;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $tme;
        border-color: $tme;

        i {
          display: inline-block;
        }
      }
    }
  }
}


.tme-banner-img {
  float: right;
}

.tme-bar-bottom {
  float: left;
  width: 50%;
  height: 10px;
  background: #e50000;
}



// lexus theme

.lexus-container {
  background: #fff;
  float: left;
  width: 100%;
}

.lexus-wrapper {
  margin: 0 auto;
  max-width: 480px;
}

.lexus-content {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.lexus-content-inner {
  float: left;
  width: 100%;
  background: #f2f2f2;
}

.lexus-header {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 10px;

  .lexus-wrapper {
    padding: 0;
    background: white;
    text-align: right;
    position: relative;
    top: 30px;
    left: 0;

    // img {
    //   float: right;
    // }
  }

  .logo-bar {
    background: #787878;
    position: absolute;
    width: 40px;
    top: 50%;
    left: 40px;
    opacity: 0.7;
    height: 6px;
    margin: -3px 0 0;
  }
}

.lexus-question {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  margin-bottom: 7px;
  border-bottom: 1px solid #fff;

  &.no--border {
    border: 0;
  }

  .question__copy {
    float: left;
    width: 32%;
  }

  .question__answer {
    float: right;
    width: 62%;
  }

  p {
    padding: 0;
    font-size: 10px;
    margin-bottom: 7px;
  }

  &.full--width {
    .question__copy {
      width: 100%;
      margin-bottom: 10px;
    }

    .question__answer {
      float: left;
      width: 100%;
    }
  }
}

.lexus-calendar {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-datepicker-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #74788d;
    border-radius: 3px;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  .btn-calendar {
    position: absolute;
    background: $lexus;
    right: 0;
    top: 0;
    color: #fff;
    @include border-radius(3px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px 6px;
  }
}

.lexus-dropdown {
  position: relative;
  overflow: hidden;
  float: left;
  width: 200px;

  .field-dropdown-box {
    float: left;
    width: 100%;
    font-size: 10px;
    border: 1px solid #74788d;
    border-radius: 3px;
    background: white;
    padding: 6px 10px;
    height: 26px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 7px;
  }
}

.lexus-rating-list {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  li {
    margin: 0;
    flex: unset;
  }

  .rating__label {
    position: relative;
    font-size: 10px;
    top: 0;
    max-width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px solid #74788d;
    color: $gray;
    margin: 0;
    height: 26px;
    width: 26px;
    display: inline-block;
    line-height: 26px;
    background: white;

    &.active {
      animation: flash 250ms 2 ease;
      border-color: $lexus;
      background: $lexus;
      color: white;
    }
  }
}

.lexus-comment-box {
  .field-comment-box {
    width: 100%;
    display: block;
    height: 30px;
    font-size: 10px;
    padding: 10px;
    resize: none;
    border: 1px solid #ccc;
    background: #fff;

  }
}

.lexus-rating-legend {
  margin: 0 0 10px;
  width: 100%;
  text-align: center;
  float: left;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    position: absolute;
    background-color: #cecfd1;
    top: 7px;
    clear: both;
    z-index: 0;
    left: 45px;
    right: 45px;
  }

  .legend__label {
    color: #5e6162;
    font-size: 10px;
    position: relative;
    background: #f0f3f6;

    &.label--left {
      float: left;
      padding-right: 5px;
    }

    &.label--right {
      float: right;
      padding-left: 5px;
    }
  }
}

.lexus-rating-scale {
  z-index: 0;
  max-width: 100%;
  display: block;

  li {
    display: inline-block;
    padding: 0;
    margin: 0 10px 0 0;

    .scale__label {
      position: relative;
      font-size: 10px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      padding: 6px 12px;
      vertical-align: middle;
      font-weight: 300;
      border-radius: 25px;
      border: 1px solid #74788d;
      transition: all 0.3s ease-in-out;

      &:before {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        content: "\f058";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px;
        font-weight: 300;
        color: white;
        top: 6px;
        left: 12px;
      }

      &.active {
        background: $lexus;
        border-color: $lexus;
        color: white;
        padding-left: 30px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.lexus-smiley {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 4px;
    font-weight: 300;
    color: #74788d;
    font-size: 14px;
  }
}

.lexus-smiley-legend {
  text-align: center;
  font-size: 10px;
}

.lexus-starrating {
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px;
    font-weight: 300;
    font-size: 10px;

    .stars {
      font-size: 14px;
      color: #74788d;

      i {
        margin: 0 2px;

        &.active {
          color: $lexus;
        }
      }
    }
  }
}

.lexus-image-list {
  position: relative;
  width: 100%;

  li {
    float: left;
    padding: 0 15px 0 0;
    width: 20%;
  }

  .rating__label {
    float: left;
    width: 100%;
    cursor: pointer;
    position: relative;

    .active__icon {
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 10px;
      height: 20px;
      text-align: center;
      width: 20px;
      line-height: 20px;
      color: white;
      border: 2px solid white;
      background: $lexus;
      border-radius: 50%;
      display: none;
    }

    img {
      width: 100%;
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      padding: 5px;
      background: $white;
      margin: 0;
    }

    p {
      font-size: 10px;
      color: #666666;
      padding: 0 2px 2px;
      margin: 0;
      position: relative;
    }

    &.active {
      .active__icon {
        display: inline-block;
      }

      img {
        border-color: $lexus;
      }

      p {
        .radio-image__no {
          background: $lexus;
          color: $white;
          border-color: $lexus;
        }
      }
    }
  }
}

.lexus-footer {
  text-align: right;
  margin: 10px;

  .preview-button {
    display: inline-block;
    background: $lexus;
    color: white;
    font-size: 10px;
    padding: 8px 20px;
    text-transform: uppercase;
  }
}

.lexus-checkbox-list {
  display: flex;
  justify-content: space-around;

  li {
    text-align: center;

    p {
      font-size: 10px;
      margin-bottom: 5px;
    }

    .active__icon {
      display: inline-block;
      font-size: 9px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      color: white;
      border: 1px solid $lexus;

      border-radius: 50%;

      i {
        display: none;
      }
    }

    &.active {
      .active__icon {
        background: $lexus;

        i {
          display: inline-block;
        }
      }
    }
  }
}