.table {
  > table {
    width: 100%;
    border-collapse: collapse;
    > tr {
      > td {
        padding: 6px 16px;
        &.actions {
          .btn {
            float: left;
            margin: 4px;
            vertical-align: middle;
          }
        }
      }
    }
    > thead {
      background: $white;
      font-size: 13px;
      font-weight: bold;
      color: $clr-pri;
      text-transform: uppercase;
      border-bottom: 2px solid $clr-pri;
      :lang(fr) {
        text-transform: none;
      }
    }
    > tbody {
      background: #f4f5f9;
      border-bottom: 1px solid #d7d7d7;
      > tr {
        &:first-child {
          > td {
            padding-top: 15px;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
.table-striped {
  .question-bank-import & {
    height: 160px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    color: #34495e;
    border-top: 1px solid #ebebeb;
    tr {
      border-bottom: 1px solid #ebebeb;
      td {
        padding: 10px 40px;
        vertical-align: middle;
        .question-bank-import & {
          padding: 10px;
          &:first-child {
            padding-left: 20px;
            max-width: 40px;
          }
        }
        &.actions {
          .btn {
            float: left;
            margin: 4px;
            vertical-align: middle;
          }
        }
      }
    }
    thead {
      background: $white;
      color: #a9b4bd;
      border-bottom: 1px solid #ebebeb;
      i {
        vertical-align: middle;
      }
    }
    tbody {
      background: $white;
      tr {
        &:nth-child(even) {
          background: #f4f5f9;
        }
      }
    }
  }
}
.table-print {
  h3 {
    font-size: 18px;
    color: #000;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    color: #34495e;
    tr {
      td {
        border: 1px solid #ebebeb;
        padding: 10px;
      }
    }
    thead {
      background: $white;
      color: #a9b4bd;
      tr {
        td {
          border-bottom: 2px solid #ebebeb;
        }
      }
      i {
        vertical-align: middle;
      }
    }
    tbody {
      background: $white;
      tr {
        &:nth-child(even) {
          background: #f4f5f9;
        }
      }
    }
  }
}
.administration--table {
  width: 100%;
  overflow-x: auto;
  padding: 0 0 3px 0;
  table {
    border-top: none;
    tr {
      border-bottom: none;
      border-top: 1px solid #ebebeb;
      td {
        padding: 10px;
        white-space: nowrap;
        &:first-child {
          padding-left: 40px;
        }
      }
    }
  }
  a {
    color: $clr-pri;
    &:hover {
      color: $clr-sec;
    }
  }
  .actions {
    a {
      color: #cdd4d8;
      margin: 0 15px;
      cursor: pointer;
      &:hover {
        color: $clr-pri;
      }
    }
    i {
      font-size: 22px;
    }
  }
}

.add-manual-contact--table {
  padding: 0 0 35px 0;
  table {
    tbody {
      tr {
        td {
          padding: 10px;
        }
        &:first-child {
          td {
            padding: 15px 10px 10px;
          }
        }
        &:last-child {
          td {
            padding-bottom: 25px;
          }
        }
      }
    }
  }
  input[type="text"] {
    width: 100%;
  }
  a {
    color: $clr-pri;
    &:hover {
      color: $clr-sec;
    }
  }
  .content-header {
    padding: 15px 0;
    margin: 0;
    .actions {
      a {
        margin: 0 5px;
      }
    }
  }
}
.ui-dialog--create-filter {
  .modal__content {
    //padding: 20px 15px 30px 20px !important;
  }
  .table {
    > table {
      > thead {
        td {
          padding: 10px;
        }
      }
      > tbody {
        > tr {
          //border-bottom: 1px solid #d7d7d7;
          > td {
            padding: 10px;
            //width: 28%;
            //&:last-child {
            //  width: 16%;
            //}
            &.actions {
              width: 12%;
            }
            .action {
              width:55px;
              a {
                margin: 0 5px;
              }
              i {
                font-size: 16px;
              }
            }
          }
          &:first-child {
            > td {
              //padding: 15px 10px 10px;
            }
          }
        }
      }
    }
    input[type="text"] {
      //width: 100%;
    }
    a {
      //color: $clr-pri;
      &:hover {
        //color: $clr-sec;
      }
    }
  }
  .content-header {
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid #d7d7d7;
    h4 {
      float: left;
      font-size: 12px;
      line-height:34px;
      padding: 0 10px 0 0;
    }

  }
}
.card-table {
  font-size: 13px;
  color: #34495e;
  padding: 0 0 10px 0;
  @include clearfix();
  table {
    width: 100%;
    th {
      color: #a9b4bd;
      font-weight: 600;
      text-align: left;
    }
    td {
      border-top: 1px solid #ebebeb;
      .percentage {
        color: #9d9d9d;
      }
    }
    td,
    th {
      padding: 5px 20px;
    }
  }
  a {
    color: $clr-pri;
    &:hover {
      color: $clr-sec;
    }
  }
}
.table-report {
 clear: both;
  background: $white;
  //box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);
  border-radius: 3px;
  font-size: 12px;
  margin: 0 -1px 20px;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      th,
      td {
        padding: 10px;
        border: 1px solid #e4e9ed;
      }
    }
    thead {
      background: $white;
      color: $clr-pri;
      text-transform: uppercase;
      border-bottom: 2px solid $clr-pri;
      :lang(fr) {
        text-transform: none;
      }
    }
  }
  .gray {
    background: #f8f9fa;
    font-weight: bold;
    color: #5b6c7c;
  }
  .text-gray {
    text-transform: none;
    color: #5b6c7c;
    font-weight: 600;
  }
}

.distribution-table {
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      th,
      td {
        width: calc(100%/3);
        padding: 8px;
        border-top: 1px solid #ebebeb;
        vertical-align: top;
      }
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }
    }
    thead {
      text-align: left;
      background: $white;
      font-size: 14px;
      font-weight: 600;
      color: #5b6c7c;
      border-bottom: 2px solid $clr-pri;
    }
  }
}

.validation-table {
  font-size: 14px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      td {
        padding: 10px 20px;
        vertical-align: top;
        text-align: center;
        &:first-child {
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          padding-left: 5px;
        }
        &.summary-heading {
          padding-bottom: 5px;
          border-bottom: 1px solid #ebebeb;
        }
      }
    }
  }
}

.table-user-management {
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    color: #666666;
    table-layout: fixed;
    tr {
      border-bottom: 1px solid #ebebeb;
      td, th {
        width: 25%;
        padding: 10px;
        vertical-align: middle;
        text-align: left;
        font-weight: normal;
      }
      td {
        border: 1px solid #ebebeb;
        font-weight: 600;
      }
    }
    thead {
      background: $white;
      border-bottom: 1px solid #e1e9ed;
    }
    tbody {
      background: $white;
      font-weight: 500;
      tr {
        &:nth-child(odd) {
          background: #f4f5f9;
        }
      }
    }
  }
}

$column-border: #cccccc;
$row-gray: #f4f5f9;
$row-border: #ebebeb;

.table-download {
  width: 100%;
  max-width: 100%;
  clear: both;
  height: auto;

  tr {
    background: $white;
  }
  &.table--striped {
    tr {
      &:nth-child(even) {
        background: #fcfcfc;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    &.table--striped {
      tr {
        &:nth-child(even) {
          background: #f4f5f9;
        }
      }
    }
  }
  th {
    padding: 15px;
    text-align: left;
    position: relative;
    color: #8996a0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
  td {
    padding: 15px;
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    color: #34495e;
    font-family: $font-pri;

    &.actions {
      // font-size: 16px;
      a {
        i {
          margin-left: 5px;
        }
      }
    }
  }
  .thead,
  thead {
    tr {
      border-bottom: 1px solid $row-border;
      background: #f9f9f9;
      &:first-child {
        th {
          border-top: 1px solid $row-border;
          text-transform: uppercase;
        }
      }
    }
  }
  //tbody {
  tr { 
    border-bottom: 1px solid $row-border;

    &.thead {
      border-bottom: 1px solid $row-border;
      // background: none;
      &:first-child {
        // border-bottom: 1px solid $white;
        th {
          border-top: 1px solid $row-border;
          text-transform: uppercase;
        }
      }
    }
  }
}
