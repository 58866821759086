.p-carousel,.p-carousel-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
}

.p-carousel-content {
  overflow: auto;
}

.p-carousel-next,.p-carousel-prev {
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: 14px;
}
.p-carousel-next,.p-carousel-prev {
  padding: 0 15px;
}

.p-carousel-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-carousel-items-content {
  overflow: hidden;
  width: 100%;
}

.p-carousel-indicators,.p-carousel-items-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-carousel-indicators {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-carousel-indicator>button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-carousel .p-carousel-indicators {
  padding: 15px;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin: 0 6px;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #ced1d7;
  width: 8px;
  height: 8px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: $clr-pri;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: $clr-pri;
  color: #495057;
}

.p-carousel-vertical .p-carousel-container {
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-carousel-vertical .p-carousel-items-container {
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.p-items-hidden .p-carousel-item {
  visibility: hidden;
}

.p-items-hidden .p-carousel-item.p-carousel-item-active {
  visibility: visible;
}

.p-carousel-item {
  text-align: center;
  a {
    display: inline-block;
    color: #212229;
    padding: 10px;
    span {
      color: #969dab;
    }

    &.active {
      background: $clr-pri;
      color: $white;
      border-radius: 3px;
      span{
        color: $white;
      }
    }
  }
}


// .ui-carousel {
//   position: relative;
//   padding: 0.063em;
//   font-size: 12px;
//   .ui-carousel-viewport-wrapper {
//     padding: 0 40px;
//     position: relative;
//     float: left;
//     clear: both;
//     width: 100%;
//   }
//   .ui-carousel-viewport {
//     .ui-carousel-items {
//       list-style: none outside none;
//       margin: 0;
//       padding: 0;
//       position: relative;
//       width: 32000px;
//       /*  width: 100%;
//         text-align: center;*/
//       left: 0;
//       .ui-carousel-item {
//         margin:5px;
//         padding: 0;
//         float: left;
//         box-sizing: border-box;
//       }
//     }
//     overflow: hidden;
//     position: relative;
//     border: 0;
//   }
//   .ui-carousel-footer {
//     margin: 1px 1px 0px 1px;
//     padding: 0.5em;
//     overflow: hidden;
//   }
//   .ui-carousel-header {
//     margin: 0 1px;
//     overflow: hidden;
//     padding: 0.625em;
//     .ui-carousel-header-title {
//       display: inline-block;
//       overflow: hidden;
//     }
//   }
//   .ui-carousel-dropdown,
//   .ui-carousel-mobiledropdown {
//     float: right;
//     margin: 0px 0.625em;
//     background-image: none;
//   }
//   .ui-carousel-dropdown option,
//   .ui-carousel-mobiledropdown option {
//     background-image: none;
//     border: 0 none;
//     box-shadow: none;
//     -moz-box-shadow: none;
//     -webkit-box-shadow: none;
//   }
//   .ui-carousel-button {
//     float: right;
//     //margin: 0.125em;
//   }
//   .ui-carousel-page-link {
//     margin: 0 6px;
//     outline: none;
//     text-decoration: none;
//     cursor: pointer;
//     font-size: 8px;
//     color: #ced1d7;

//     &.active{
//       color:$clr-pri;
//     }
//   }
//   .ui-carousel-button {
//     cursor: pointer;
//   }
//   .ui-carousel-page-links {
//     float: left;
//     width: 100%;
//     text-align: center;
//     padding:15px;
//   }
//   .ui-carousel-mobiledropdown {
//     display: none;
//   }
// }

// .ui-carousel {
//   padding: 0;
//   clear: both;

//   .ui-carousel-header {
//     //  background-color: $panelHeaderBgColor;
//     color: $panelHeaderTextColor;
//     padding: $panelHeaderPadding;
//     //   border: $panelHeaderBorder;
//     margin: 0;
//     font-weight: $panelHeaderFontWeight;

//     .ui-carousel-header-title {
//       padding: 0;
//     }
//   }

//   .ui-carousel-viewport {
//     border: $panelContentBorder;
//     background-color: $panelContentBgColor;
//     color: $panelContentTextColor;
//     padding: 0;
//     border-left: 0;
//     clear: both;
//     border-right: 0;
//     border-color: #ebebeb;

//     &.carousel--child{
//       border-top: 0;
//     }

//     .ui-carousel-items {
//       .ui-carousel-item {
//         text-align: center;
//         a {
//           display: inline-block;
//           color: #212229;
//           padding: 10px;
//           span {
//             color: #969dab;
//           }

//           &.active {
//             background: $clr-pri;
//             color: $white;
//             border-radius: 3px;
//             span{
//               color: $white;
//             }
//           }
//         }
//       }
//     }
//   }

//   .ui-carousel-footer {
//     background-color: $panelHeaderBgColor;
//     color: $panelHeaderTextColor;
//     padding: $panelHeaderPadding;
//     border: $panelHeaderBorder;
//     margin: 0;
//   }

//   .ui-carousel-button {
//     color: $panelHeaderIconColor;
//     @include transition(color $transitionDuration);
//     position: absolute;
//     top:15px;
//     z-index: 5;

//     &.ui-carousel-next-button {
//       font-size: 20px;
//       right: 15px;

//       //  @include icon-override("\e92a");
//     }

//     &.ui-carousel-prev-button {
//       font-size: 20px;
//       left: 15px;

//       // @include icon-override("\e928");
//     }

//     &:not(.ui-state-disabled):hover {
//       color: $panelHeaderIconHoverColor;
//     }
//   }

//   .ui-carousel-page-links {
//     // margin: 0.125em $inlineSpacing;

//     .ui-carousel-page-link {
//      // color: $panelHeaderIconColor;
//       @include transition(color $transitionDuration);

//       &:hover {
//         color:$clr-pri;
//       }
//     }
//   }
// }

// // .ui-carousel {
// //   position: relative;
// //   padding: 0.063em;
// //   font-size: 12px;
// //   .ui-carousel-viewport-wrapper {
// //     padding: 0 40px;
// //     position: relative;
// //     float: left;
// //     clear: both;
// //     width: 100%;
// //   }
// //   .ui-carousel-viewport {
// //     .ui-carousel-items {
// //       list-style: none outside none;
// //       margin: 0;
// //       padding: 0;
// //       position: relative;
// //       width: 32000px;
// //       /*  width: 100%;
// //         text-align: center;*/
// //       left: 0;
// //       .ui-carousel-item {
// //         margin:5px;
// //         padding: 0;
// //         float: left;
// //         box-sizing: border-box;
// //       }
// //     }
// //     overflow: hidden;
// //     position: relative;
// //     border: 0;
// //   }
// //   .ui-carousel-footer {
// //     margin: 1px 1px 0px 1px;
// //     padding: 0.5em;
// //     overflow: hidden;
// //   }
// //   .ui-carousel-header {
// //     margin: 0 1px;
// //     overflow: hidden;
// //     padding: 0.625em;
// //     .ui-carousel-header-title {
// //       display: inline-block;
// //       overflow: hidden;
// //     }
// //   }
// //   .ui-carousel-dropdown,
// //   .ui-carousel-mobiledropdown {
// //     float: right;
// //     margin: 0px 0.625em;
// //     background-image: none;
// //   }
// //   .ui-carousel-dropdown option,
// //   .ui-carousel-mobiledropdown option {
// //     background-image: none;
// //     border: 0 none;
// //     box-shadow: none;
// //     -moz-box-shadow: none;
// //     -webkit-box-shadow: none;
// //   }
// //   .ui-carousel-button {
// //     float: right;
// //     //margin: 0.125em;
// //   }
// //   .ui-carousel-page-link {
// //     margin: 0 6px;
// //     outline: none;
// //     text-decoration: none;
// //     cursor: pointer;
// //     font-size: 8px;
// //     color: #ced1d7;

// //     &.active{
// //       color:$clr-pri;
// //     }
// //   }
// //   .ui-carousel-button {
// //     cursor: pointer;
// //   }
// //   .ui-carousel-page-links {
// //     float: left;
// //     width: 100%;
// //     text-align: center;
// //     padding:15px;
// //   }
// //   .ui-carousel-mobiledropdown {
// //     display: none;
// //   }
// // }

// // .ui-carousel {
// //   padding: 0;
// //   clear: both;

// //   .ui-carousel-header {
// //     //  background-color: $panelHeaderBgColor;
// //     color: $panelHeaderTextColor;
// //     padding: $panelHeaderPadding;
// //     //   border: $panelHeaderBorder;
// //     margin: 0;
// //     font-weight: $panelHeaderFontWeight;

// //     .ui-carousel-header-title {
// //       padding: 0;
// //     }
// //   }

// //   .ui-carousel-viewport {
// //     border: $panelContentBorder;
// //     background-color: $panelContentBgColor;
// //     color: $panelContentTextColor;
// //     padding: 0;
// //     border-left: 0;
// //     clear: both;
// //     border-right: 0;
// //     border-color: #ebebeb;

// //     &.carousel--child{
// //       border-top: 0;
// //     }

// //     .ui-carousel-items {
// //       .ui-carousel-item {
// //         text-align: center;
// //         a {
// //           display: inline-block;
// //           color: #212229;
// //           padding: 10px;
// //           span {
// //             color: #969dab;
// //           }

// //           &.active {
// //             background: $clr-pri;
// //             color: $white;
// //             border-radius: 3px;
// //             span{
// //               color: $white;
// //             }
// //           }
// //         }
// //       }
// //     }
// //   }

// //   .ui-carousel-footer {
// //     background-color: $panelHeaderBgColor;
// //     color: $panelHeaderTextColor;
// //     padding: $panelHeaderPadding;
// //     border: $panelHeaderBorder;
// //     margin: 0;
// //   }

// //   .ui-carousel-button {
// //     color: $panelHeaderIconColor;
// //     @include transition(color $transitionDuration);
// //     position: absolute;
// //     top:15px;
// //     z-index: 5;

// //     &.ui-carousel-next-button {
// //       font-size: 20px;
// //       right: 15px;

// //       //  @include icon-override("\e92a");
// //     }

// //     &.ui-carousel-prev-button {
// //       font-size: 20px;
// //       left: 15px;

// //       // @include icon-override("\e928");
// //     }

// //     &:not(.ui-state-disabled):hover {
// //       color: $panelHeaderIconHoverColor;
// //     }
// //   }

// //   .ui-carousel-page-links {
// //     // margin: 0.125em $inlineSpacing;

// //     .ui-carousel-page-link {
// //      // color: $panelHeaderIconColor;
// //       @include transition(color $transitionDuration);

// //       &:hover {
// //         color:$clr-pri;
// //       }
// //     }
// //   }
// // }
