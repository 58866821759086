.ui-tree {
  background-color: $panelContentBgColor;
  color: $panelContentTextColor;
  padding: 0;
  border-radius: 0;
  font-size: 12px;

  .ui-tree-container {
    padding: 25px 5px;
    margin: 0;
    .org-level-access & {
      padding: 10px 0 0 0;
    }

    > ui-treenode {
      &:first-child {
        > li {
          border-top: none;
        }
      }
    }

    .ui-treenode {
      border-top: 1px solid #ebebeb;

      .ui-treenode-content {
        padding: $treeNodePadding;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;

        .ui-tree-toggler {
          vertical-align: middle;
          display: inline-block;
          float: none;
          margin: 0 $treeNodeContentSpacing 0 0;
          color: $dataIconColor;
          font-size: 14px;
          .p-tree-toggler-icon {
            font-size: 12px;
          }
        }

        .ui-treenode-icon {
          vertical-align: middle;
          display: inline-block;
          margin: 0 $treeNodeContentSpacing 0 0;
          color: $dataIconColor;
          font-size: 16px;
          &.fa-plus {
            font-size: 12px;
          }
        }

        .ui-treenode-label {
          margin: 0;
          vertical-align: middle;
          display: inline-block;
          padding: $treeNodeLabelPadding;

          &.ui-state-highlight {
            background-color: $highlightBgColor;
            color: $highlightColorText;
          }
        }

        .ui-chkbox {
          margin: 0 $inlineSpacing 0 0;

          .ui-chkbox-icon {
            margin: 0 0 0 0;
          }
        }

        &.ui-treenode-selectable {
          .ui-treenode-label:not(.ui-state-highlight):hover {
            background-color: $inputListItemHoverBgColor;
            color: $inputListItemHoverTextColor;
          }
        }

        > span {
          line-height: inherit;
        }

        &.ui-treenode-dragover {
          background-color: $inputListItemHoverBgColor;
          color: $inputListItemHoverTextColor;
        }
        &:not(.ui-treenode-selectable) {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .ui-treenode-droppoint {
      &.ui-treenode-droppoint-active {
        background-color: $highlightBgColor;
      }
    }
  }

  &.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0;

    .ui-treenode {
      .ui-treenode-content {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;

        &.ui-state-highlight {
          background-color: $highlightBgColor;
          color: $highlightColorText;
        }

        .ui-chkbox {
          .ui-icon {
            color: $primaryColor;
          }
        }

        .ui-treenode-label:not(.ui-state-highlight):hover {
          background-color: inherit;
          color: inherit;
        }

        &.ui-treenode-selectable:not(.ui-state-highlight):hover {
          background-color: $inputListItemHoverBgColor;
          color: $inputListItemHoverTextColor;
        }
      }
    }
  }
  .input-search {
    width: 100%;
    .p-tree-filter-icon {
      display: none;
    }
  }
}
.p-tree {
  background-color: $panelContentBgColor;
  color: $panelContentTextColor;
  padding: 0;
  border-radius: 0;
  font-size: 12px;

  .p-tree-container {
    padding: 25px 5px;
    margin: 0;
    .org-level-access & {
      padding: 10px 0 0 0;
    }

    .p-treenode {
      border-top: 1px solid #ebebeb;

      .p-treenode-content {
        padding: $treeNodePadding;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;

        .p-tree-toggler {
          vertical-align: middle;
          display: inline-block;
          float: none;
          margin: 0 $treeNodeContentSpacing 0 0;
          color: $dataIconColor;
          font-size: 14px;
          .p-tree-toggler-icon {
            font-size: 12px;
          }
        }

        .p-treenode-icon {
          vertical-align: middle;
          display: inline-block;
          margin: 0 $treeNodeContentSpacing 0 0;
          color: $dataIconColor;
          font-size: 16px;
          &.fa-plus {
            font-size: 12px;
          }
        }

        .p-treenode-label {
          margin: 0;
          vertical-align: middle;
          display: inline-block;
          padding: $treeNodeLabelPadding;

          &.p-state-highlight {
            background-color: $highlightBgColor;
            color: $highlightColorText;
          }
        }

        .p-checkbox {
          margin: 0 $inlineSpacing 0 0;

          .p-checkbox-icon {
            margin: 0 0 0 0;
          }
        }

        &.p-treenode-selectable {
          .p-treenode-label:not(.p-state-highlight):hover {
            background-color: $inputListItemHoverBgColor;
            color: $inputListItemHoverTextColor;
          }
        }

        > span {
          line-height: inherit;
        }

        &.p-treenode-dragover {
          background-color: $inputListItemHoverBgColor;
          color: $inputListItemHoverTextColor;
        }
        &:not(.p-treenode-selectable) {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .p-treenode-droppoint {
      &.p-treenode-droppoint-active {
        background-color: $highlightBgColor;
      }
    }
  }

  &.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0;

    .p-treenode {
      .p-treenode-content {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;

        &.p-state-highlight {
          background-color: $highlightBgColor;
          color: $highlightColorText;
        }

        .p-checkbox {
          .p-icon {
            color: $primaryColor;
          }
        }

        .p-treenode-label:not(.p-state-highlight):hover {
          background-color: inherit;
          color: inherit;
        }

        &.p-treenode-selectable:not(.p-state-highlight):hover {
          background-color: $inputListItemHoverBgColor;
          color: $inputListItemHoverTextColor;
        }
      }
    }
  }
  .input-search {
    width: 100%;
    .p-tree-filter-icon {
      display: none;
    }
  }
}
//.ui-tree {
//  width: 18em;
//  .ui-treenode-selectable.ui-treenode-content {
//    cursor: pointer;
//  }
//  .ui-tree-container {
//    height: 100%;
//    margin: 0;
//    overflow: auto;
//    padding: 0.25em;
//    white-space: nowrap;
//  }
//}

.ui-tree-empty-message, .p-tree-empty-message {
  padding: 0.25em;
}

.ui-tree, .p-tree {
  .ui-treenode-children, .p-treenode-children {
    margin: 0;
    padding: 0 0 0 1em;
  }
  .ui-treenode, .p-treenode {
    background-attachment: scroll;
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat-y;
    list-style: none outside none;
    margin: 0;
    padding: 0.125em 0 0 0;
  }
  .ui-treenode-droppoint, .p-treenode-droppoint {
    height: 4px;
    list-style-type: none;
  }
  .ui-treenode-droppoint-active, .p-treenode-droppoint-active {
    border: 0 none;
  }
  .ui-tree-toggler, .p-tree-toggler {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }
  .ui-treenode-icon, .p-treenode-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .ui-treenode-label, .p-treenode-label {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
    &.ui-state-hover,
    &.ui-state-highlight {
      font-weight: normal;
      border: 0 none;
    }
  }
  .ui-treenode.ui-treenode-leaf > .ui-treenode-content > .ui-tree-toggler,
  .p-treenode.p-treenode-leaf > .p-treenode-content > .p-tree-toggler {
    visibility: hidden;
  }
  .ui-chkbox-box, .p-checkbox-box {
    cursor: pointer;
  }
  .ui-chkbox, .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    .ui-checkbox-icon, .p-checkbox-icon {
      margin-left: 1px;
    }
  }
}

/** Fluid **/

.ui-fluid .ui-tree, .p-fluid .p-tree {
  width: 100%;
}

/** Horizontal Tree **/

.ui-tree-horizontal, .p-tree-horizontal {
  width: auto;
  padding: 0.5em 0;
  overflow: auto;
}

.ui-tree.ui-tree-horizontal, .p-tree.p-tree-horizontal {
  table,
  tr,
  td {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }
  .ui-tree-toggler, .p-tree-toggler {
    vertical-align: middle;
    margin: 0;
  }
}

.ui-tree-horizontal .ui-treenode-content, .p-tree-horizontal .p-treenode-content {
  font-weight: normal;
  padding: 0.4em 1em 0.4em 0.2em;
}

.ui-tree.ui-tree-horizontal .ui-tree-node-label, .p-tree.p-tree-horizontal .p-tree-node-label {
  margin: 0;
}

.ui-tree-horizontal .ui-treenode-parent .ui-treenode-content, .p-tree-horizontal .p-treenode-parent .p-treenode-content {
  font-weight: normal;
  white-space: nowrap;
}

.ui-tree, .p-tree {
  &.ui-tree-horizontal, &.p-tree-horizontal {
    .ui-treenode, .p-treenode {
      background: url("/assets/styles/portal/source/styles/vendor/primeng/images/line.gif")
      repeat-x scroll center center transparent;
      padding: 0.25em 2.5em;
      &.ui-treenode-leaf,
      &.ui-treenode-collapsed,
      &.p-treenode-leaf,
      &.p-treenode-collapsed {
        padding-right: 0;
      }
    }
    .ui-treenode-children, .p-treenode-children {
      padding: 0;
      margin: 0;
    }
    .ui-treenode-connector, .p-treenode-connector {
      width: 1px;
    }
    .ui-treenode-connector-table, .p-treenode-connector-table {
      height: 100%;
      width: 1px;
    }
    .ui-treenode-connector-line, .p-treenode-connector-line {
       background: url("/assets/styles/portal/source/styles/vendor/primeng/images/line.gif")
      repeat-y scroll 0 0 transparent;
      width: 1px;
    }
    table {
      height: 0;
    }
    .ui-chkbox, .p-checkbox {
      vertical-align: bottom;
      margin-right: 0.25em;
    }
  }
  &.ui-tree-loading, &.p-tree-loading {
    position: relative;
    min-height: 4em;
  }
  .ui-tree-loading-mask, .p-tree-loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
  }
  .ui-tree-loading-content, .p-tree-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
    .ui-tree-loading-icon, .p-tree-loading-icon {
      font-size: 2em;
    }
  }
}

//Category Tree Redefine

.ui-tree-category, .p-tree-category {
  .ui-tree, .p-tree {

    .ui-tree-container, .p-tree-container {
      > r-treenode > .ui-treenode:first-child, > r-treenode > .p-treenode:first-child {
        border: none;
      }

      .ui-treenode, .p-treenode {

        .ui-treenode-content, .p-treenode-content {
          cursor: default;
          position: relative;

          .ui-treenode-label, .p-treenode-label {
            padding: 0;
            input[type=text] {
              border: 1px solid $clr-pri;
              font: inherit;
              color: inherit;
              padding: 2px 5px;
            }
          }
          .ui-treenode-actions, .p-treenode-actions {
            position: absolute;
            right: 0;
            top: 3px;
            > span {
              color: $clr-pri;
            }
            .handler {
              display: inline-block;
              width: 24px;
              height: 24px;
              z-index: 1;
              color: $clr-pri;
              position: relative;
              border-radius: 50%;
              text-align: center;
              line-height:24px;
              i {
                font-size:12px;
              }
              &:hover {
                background: #f1f6fa;
              }
            }
          }
          .ui-treenode-label-inner, .p-treenode-label-inner {
            cursor: pointer;
            padding: $treeNodeLabelPadding;
            display: block;
          }

          &.ui-treenode-selectable, &.p-treenode-selectable {
            .ui-treenode-label:not(.ui-state-highlight):hover, .p-treenode-label:not(.p-state-highlight):hover {
              background-color: transparent;
              color: $panelContentTextColor;
            }
          }
          &.ui-treenode-selectable, &.p-treenode-selectable {
            .ui-treenode-label:not(.ui-state-highlight), .p-treenode-label:not(.p-state-highlight) {
              .ui-treenode-label-inner:hover, .p-treenode-label-inner:hover {
                background-color: $inputListItemHoverBgColor;
                color: $inputListItemHoverTextColor;
              }
            }
          }

          > span {
            line-height: inherit;
          }

        }
      }
    }
  }
}
