.p-dropdown {
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 3px;

  .dropdown-wrapper & {
    margin-right: 10px;
    min-width: 125px;
  }

  .alphabetic-filters & {
    border: none;
  }

  .p-dropdown-clear-icon {
    position: absolute;
    right: 2em;
    top: 50%;
    font-size: 1em;
    height: 1em;
    margin-top: -0.5em;
  }

  .p-dropdown-trigger {
    border-right: none;
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0.25em;

    .p-dropdown-trigger-icon {
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      position: absolute;
      font-size: 12px;
    }

    .alphabetic-filters & {
      border-left: 1px solid #e1e1e1
    }
  }

  .p-dropdown-label {
    display: block;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    line-height: normal;
    padding-right: 2.5em;
    border-radius: 3px;

    .alphabetic-filters & {
      padding-left: 1em;
      padding-right: 3.5em;
    }

    .dashboard-filters & {
      max-width: 185px;
      min-width: 130px;
      text-overflow: ellipsis;
    }

    .form-field & {
      padding: 8px 2.5em 8px 10px;
    }

    /* .language-switcher & {
      padding-left: 0.5em;
      color: #b3b3b3;
      font-size: 13px;
    }*/
  }

  /* .language-switcher & {
    border: none;
  }*/
}

.p-dropdown-item-empty,
.p-dropdown-label-empty {
  text-indent: -9999px;
  overflow: hidden;
}

.p-dropdown {
  &.ui-state-disabled {

    .p-dropdown-trigger,
    .p-dropdown-label {
      cursor: default;
    }
  }

  label.p-dropdown-label {
    cursor: pointer;
  }

  input.p-dropdown-label {
    cursor: default;
  }

  .p-dropdown-panel {
    min-width: 100%;

    .language-switcher & {
      min-width: 170px;
    }
  }
}

.p-dropdown-panel {
  // position: absolute;
  height: auto;
  // width: 100%;

  .p-dropdown-items-wrapper {
    overflow: auto;
  }

  .p-dropdown-item {
    font-weight: normal;
    font-size: 12px;
    border: 0 none;
    cursor: pointer;
    margin: 1px 0;
    padding: 0.125em 0.25em;
    text-align: left;
  }

  .p-dropdown-empty-message {
    padding: 0.5em;
  }

  .p-dropdown-item-group {
    font-weight: bold;
    cursor: default;
  }

  .p-dropdown-list {
    padding: 0.4em;
    border: 0 none;
  }

  .p-dropdown-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
  }

  .p-dropdown-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
    width: 100%;

    .p-dropdown-filter-icon {
      position: absolute;
      top: 0.8em;
      right: 1em;
      font-size: 14px;
    }
  }

  &.maxwidth-defined {
    .p-dropdown-items-wrapper {
      max-width: 506px;
    }
  }
}

/** Dropdown **/

.ui-fluid .p-dropdown {
  width: 100%;
}

.p-dropdown {
  background: $inputBgColor;
  border: $inputBorder;
  @include transition($inputTransition);

  &:not(.ui-state-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.ui-state-disabled).ui-state-focus {
    @include focused-input();

    .language-switcher & {
      box-shadow: none;
    }
  }

  .p-dropdown-label {
    //padding-right: 2em;
    padding-left: 0.75em;
  }

  .p-dropdown-trigger {
    background-color: $inputBgColor;
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    color: $inputDropdownIconColor;
  }

  .p-dropdown-clear-icon {
    color: $inputDropdownIconColor;
  }
}

.p-dropdown-panel {
  padding: 0;
  z-index: map-get($zindex, dropdown);
  border: $inputOverlayBorder;
  background-color: $inputListBgColor;
  @include shadow($inputOverlayShadow);

  .p-dropdown-filter-container {
    padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background-color: $inputListHeaderBgColor;
    margin: $inputListHeaderMargin;

    .p-dropdown-filter {
      width: 100%;
      padding-right: 2em;
    }

    .p-dropdown-filter-icon {
      top: 50%;
      margin-top: -0.5em;
      right: $inputListHeaderPaddingRight + 0.5em;
      color: $inputListHeaderSearchIconColor;
    }
  }

  .p-dropdown-items {
    padding: $inputListPadding;

    .p-dropdown-item,
    .p-dropdown-item-group {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background-color: $inputListItemBgColor;
      @include border-radius(0);

      &.ui-state-highlight,
      &.p-highlight {
        color: $inputListItemHighlightTextColor;
        background-color: $inputListItemHighlightBgColor;
      }

      &:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        color: $inputListItemHoverTextColor;
        background-color: $inputListItemHoverBgColor;
      }
    }
  }
}

.submit-continue {
  display: inline-block;
  position: relative;
  margin-right: 10px;

  .btn {
    i {
      margin-left: 10px;
    }
  }

  .custom-dropdown {
    position: absolute;
    z-index: 10;
    left: -95px;
    top: 39px;
    width: 200px;
    background: white;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);

    ul {
      li {
        float: left;
        width: 100%;
        font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid #ebebeb;

        .ui-inputswitch {
          float: right;
          font-size: 8px;
        }
      }
    }

  }
}