.ps__rail-y {
    display: none !important;
}



.url-content{
    padding:5px 5px 5px 12px;

    .url-link{
        padding: 0 10px;
    }

    i{
        font-size: 16px;
    }
}