.settings-block {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);
  .actions-bar {
    padding: 20px 5px;
    i {
      font-size: 18px;
    }
    a {
      color: #d1dce2;
      margin: 0 9px;
      &:hover,
      &.active {
        color: $clr-pri;
      }
    }
  }
  .setting-field {
    width: 100%;
    padding: 12px;
    border-top: 1px solid #ebebeb;
    @include clearfix();

    &.child--field {
      padding-left: 20px;
    }
    label {
      float: left;
      font-size: 12px;
      color: #34495e;
      padding: 2px 0 0 0;
    }
    .number-length {
      float: right;
      width: 80px;
    }
    .toggle {
      float: right;
      padding: 0 0 0 10px;
    }
    .half-column {
      float: left;
      width: 50%;
    }
    .btn.dropdown-toggle {
      font-size: 12px;
      padding: 7px 20px 7px 10px;
    }
    .dropdown-menu {
      font-size: 14px;
      padding: 0px;
      ul {
        li {
          .question-no {
            display: inline-block;
            width: 30px;
            color: $clr-pri;
            font-weight: bold;
          }
        }
      }
    }
    .question {
      font-size: 12px;
      color: #34495e;
      padding: 0 0 0 30px;
      position: relative;
      span {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        font-weight: bold;
        color: $clr-pri;
      }
    }
    .two-digits-select {
      float: right;
      margin: -3px 0 0 0;
      .btn.dropdown-toggle {
        font-size: 12px;
        line-height: 19px;
        padding: 0 5px;
        color: #34495e;
        .caret {
          right: 4px;
          margin-top: -6px;
        }
      }
      .dropdown-menu li > a {
        padding: 5px;
      }
    }
    .plus-minus-action {
      float: right;
    }
    .anchor-label {
      padding: 7px 0 0 0;
    }
    .anchor-input {
      float: right;
      width:105px;
    // text-align: right;
      input {
        width: 105px;
        text-align: center;
        padding: 5px;
        font-size: 11px;
        margin-right: 5px;
      }
      i {
        font-size: 16px;
        line-height: 30px;
        float: right;
      }
      a {
        color: #cdd4d8;
        &:hover {
          color: $clr-pri;
        }
      }
      &.comment-length {
        width: 80px;
        input {
          width: 100%;
          text-align: left;
        }
      }
      .p-dropdown .p-dropdown-label{
        padding: 5px;
      }
    }
    .ui-inputswitch {
      font-size: 8px;
    }
  }

  .upload-field {
    padding: 12px;
    border-top: 1px solid #ebebeb;
    font-size: 12px;
    input {
      display: none;
    }
    .field__label {
      font-size: 14px;
      font-weight: normal;
      padding: 0 0 9px 0;
      display: block;
      text-transform: none;
      color: #34495e;
    }
    .field__controls {
      display: inline-block;
      vertical-align: middle;
      label {
        display: inline-block;
      }
      .btn {
        cursor: pointer;
        margin-right: 5px;
        vertical-align: middle;
        //   border-radius: 20px;
      }
      .file-name {
        display: block;
        vertical-align: middle;
        padding: 9px 0 0 0;
      }
    }
    .field__instructions {
      color: #34495e;
      padding: 0 0 9px 0;
      font-size: 11px;
    }
  }
}

.anchor-label-fields {
  float: left;
  width: 100%;
  margin-top: 5px;
  input {
    width: 85%;
    padding: 5px;
    font-size: 11px;
    margin-right: 5px;
  }
  i {
    font-size: 16px;
    line-height: 30px;
    float: right;
  }
  a {
    color: #cdd4d8;
    &:hover {
      color: $clr-pri;
    }
  }
}
