.survey-completion {
  .completion__item {
    position: relative;
    margin-bottom: 5px;
    display: flex;
    float: left;
    width: 100%;

    .knob {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .progressbar {
      margin-left: 10px;
      position: relative;
      width: 100%;

      .info {
        position: absolute;
        z-index: 1;
        left: 20px;
        top: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
          color: white;
          margin-left: 15px;
        }

        .count {
          color: white;
          font-size: 15px;
        }
      }

      h2 {
        color: #212229;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
      }

      p {
        color: #494c57;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .completion__percentage {
        color: #96a2b6;
        font-size: 11px;
      }

      .ui-progressbar,
      .p-progressbar {
        height: 60px;
        margin-bottom: 5px;
        background: #ddd;
      }
    }
  }
}