.tabs {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #ebebeb;

  li {
    display: inline-block;
    padding: 0 25px !important;
    margin: 0 !important;
    margin-bottom: -3px !important;
    &:first-child {
      padding-left: 0;
    }
    a {
      display: block;
      padding: 2px 0 10px;
      color: #212229;
      cursor: pointer;
      font-weight: 400;
      position: relative;
      &:after {
        display: none;
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 3px;
        background: $clr-pri;
      }

      &:hover,
      &.active {
        color: $clr-pri;
      }
      &.active {
        &:after {
          display: block;
        }
      }
    }
  }
  &:not(.tabs--timeline) {
    ul {
      li {
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.tabs--timeline {
  ul {
    display: block;
    padding: 5px 20px 0 !important;
    text-align: center;
    border-bottom: none !important;
    li {
      display: inline-block !important;
      float: none !important;
      margin-bottom: -6px !important;
      // border-bottom: none !important;
      a {
        padding: 10px !important;
      }
      &.ui-state-active, &.p-highlight {
        a {
          color: $clr-pri !important;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}
