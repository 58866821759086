/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url("#{$path_images}/flags.png") no-repeat;
  margin: 0 2px -1px 0;
  .p-dropdown & {
    margin: -2px 5px 0 0;
    vertical-align: middle;
  }
  .mat-option-text & {
    vertical-align: middle;
    margin: -3px 5px -1px 0;
  }
  &.flag-WS {
    background-position: -112px -165px;
  }
  &.flag-KR {
    background-position: -144px -77px;
  }
  &.flag-TV {
    background-position: -80px -154px;
  }
  &.flag-PE {
    background-position: -208px -110px;
  }
  &.flag-KP {
    background-position: -128px -77px;
  }
  &.flag-BJ {
    background-position: -128px -11px;
  }
  &.flag-MC {
    background-position: -160px -88px;
  }
  &.flag-CD {
    background-position: -80px -22px;
  }
  &.flag-JM {
    background-position: -240px -66px;
  }
  &.flag-RW {
    background-position: -240px -121px;
  }
  &.flag-TG {
    background-position: -160px -143px;
  }
  &.flag-MZ {
    background-position: -224px -99px;
  }
  &.flag-IC {
    background-position: -48px -66px;
  }
  &.flag-SN {
    background-position: -208px -132px;
  }
  &.flag-AN {
    background-position: -112px 0;
  }
  &.flag-SH {
    background-position: -112px -132px;
  }
  &.flag-SG {
    background-position: -96px -132px;
  }
  &.flag-TO {
    background-position: -32px -154px;
  }
  &.flag-FR {
    background-position: -144px -44px;
  }
  &.flag-TZ {
    background-position: -112px -154px;
  }
  &.flag-NR {
    background-position: -128px -110px;
  }
  &.flag-NZ {
    background-position: -160px -110px;
  }
  &.flag-HM {
    background-position: -224px -55px;
  }
  &.flag-CG {
    background-position: -112px -22px;
  }
  &.flag-ST {
    background-position: -32px -143px;
  }
  &.flag-CL {
    background-position: -176px -22px;
  }
  &.flag-IQ {
    background-position: -160px -66px;
  }
  &.flag-TJ {
    background-position: -208px -143px;
  }
  &.flag-RU {
    background-position: -224px -121px;
  }
  &.flag-MD {
    background-position: -176px -88px;
  }
  &.flag-CW {
    background-position: -32px -33px;
  }
  &.flag-GT {
    background-position: -144px -55px;
  }
  &.flag-CM {
    background-position: -192px -22px;
  }
  &.flag-PN {
    background-position: -64px -121px;
  }
  &.flag-VI {
    background-position: -32px -165px;
  }
  &.flag-IR {
    background-position: -176px -66px;
  }
  &.flag-SE {
    background-position: -80px -132px;
  }
  &.flag-LT {
    background-position: -80px -88px;
  }
  &.flag-GW {
    background-position: -176px -55px;
  }
  &.flag-DM {
    background-position: -128px -33px;
  }
  &.flag-BD {
    background-position: -32px -11px;
  }
  &.flag-KUrdistan {
    background-position: -160px -77px;
  }
  &.flag-AE {
    background-position: -16px 0;
  }
  &.flag-ZM {
    background-position: -208px -165px;
  }
  &.flag-BN {
    background-position: -160px -11px;
  }
  &.flag-LB {
    background-position: -240px -77px;
  }
  &.flag-ZW {
    background-position: -224px -165px;
  }
  &.flag-LU {
    background-position: -96px -88px;
  }
  &.flag-EE {
    background-position: -192px -33px;
  }
  &.flag-AD {
    background-position: 0 0;
  }
  &.flag-GF {
    background-position: -224px -44px;
  }
  &.flag-EG {
    background-position: -208px -33px;
  }
  &.flag-LY {
    background-position: -128px -88px;
  }
  &.flag-UG {
    background-position: -144px -154px;
  }
  &.flag-CF {
    background-position: -96px -22px;
  }
  &.flag-AG {
    background-position: -48px 0;
  }
  &.flag-NL {
    background-position: -80px -110px;
  }
  &.flag-GQ {
    background-position: -96px -55px;
  }
  &.flag-SK {
    background-position: -160px -132px;
  }
  &.flag-IO {
    background-position: -144px -66px;
  }
  &.flag-AR {
    background-position: -144px 0;
  }
  &.flag-VC {
    background-position: -240px -154px;
  }
  &.flag-NE {
    background-position: -16px -110px;
  }
  &.flag-PA {
    background-position: -192px -110px;
  }
  &.flag-CA {
    background-position: -48px -22px;
  }
  &.flag-DK {
    background-position: -112px -33px;
  }
  &.flag-SOmaliland {
    background-position: -240px -132px;
  }
  &.flag-CO {
    background-position: -224px -22px;
  }
  &.flag-GB {
    background-position: -176px -44px;
  }
  &.flag-AF {
    background-position: -32px 0;
  }
  &.flag-NI {
    background-position: -64px -110px;
  }
  &.flag-ZA {
    background-position: -176px -165px;
  }
  &.flag-JP {
    background-position: -16px -77px;
  }
  &.flag-MV {
    background-position: -160px -99px;
  }
  &.flag-GD {
    background-position: -192px -44px;
  }
  &.flag-BT {
    background-position: -224px -11px;
  }
  &.flag-DE {
    background-position: -80px -33px;
  }
  &.flag-SCotland {
    background-position: -48px -132px;
  }
  &.flag-KH {
    background-position: -64px -77px;
  }
  &.flag-XK {
    background-position: -128px -165px;
  }
  &.flag-GH {
    background-position: 0 -55px;
  }
  &.flag-LV {
    background-position: -112px -88px;
  }
  &.flag-QA {
    background-position: -160px -121px;
  }
  &.flag-MP {
    background-position: -64px -99px;
  }
  &.flag-SS {
    background-position: -16px -143px;
  }
  &.flag-GA {
    background-position: -160px -44px;
  }
  &.flag-ER {
    background-position: 0 -44px;
  }
  &.flag-MO {
    background-position: -48px -99px;
  }
  &.flag-VE {
    background-position: 0 -165px;
  }
  &.flag-UA {
    background-position: -128px -154px;
  }
  &.flag-LS {
    background-position: -64px -88px;
  }
  &.flag-PG {
    background-position: -240px -110px;
  }
  &.flag-WAles {
    background-position: -80px -165px;
  }
  &.flag-RE {
    background-position: -176px -121px;
  }
  &.flag-GU {
    background-position: -160px -55px;
  }
  &.flag-GS {
    background-position: -128px -55px;
  }
  &.flag-MA {
    background-position: -144px -88px;
  }
  &.flag-BS {
    background-position: -208px -11px;
  }
  &.flag-ES {
    background-position: -16px -44px;
  }
  &.flag-PW {
    background-position: -128px -121px;
  }
  &.flag-BW {
    background-position: 0 -22px;
  }
  &.flag-ZAnzibar {
    background-position: -192px -165px;
  }
  &.flag-LI {
    background-position: -16px -88px;
  }
  &.flag-MM {
    background-position: -16px -99px;
  }
  &.flag-KE {
    background-position: -32px -77px;
  }
  &.flag-SA {
    background-position: 0 -132px;
  }
  &.flag-FK {
    background-position: -96px -44px;
  }
  &.flag-BB {
    background-position: -16px -11px;
  }
  &.flag-SL {
    background-position: -176px -132px;
  }
  &.flag-WF {
    background-position: -96px -165px;
  }
  &.flag-KI {
    background-position: -80px -77px;
  }
  &.flag-HT {
    background-position: -16px -66px;
  }
  &.flag-CN {
    background-position: -208px -22px;
  }
  &.flag-UZ {
    background-position: -208px -154px;
  }
  &.flag-ME {
    background-position: -192px -88px;
  }
  &.flag-LK {
    background-position: -32px -88px;
  }
  &.flag-LC {
    background-position: 0 -88px;
  }
  &.flag-GY {
    background-position: -192px -55px;
  }
  &.flag-LR {
    background-position: -48px -88px;
  }
  &.flag-FJ {
    background-position: -80px -44px;
  }
  &.flag-SB {
    background-position: -16px -132px;
  }
  &.flag-PF {
    background-position: -224px -110px;
  }
  &.flag-YT {
    background-position: -160px -165px;
  }
  &.flag-TR {
    background-position: -48px -154px;
  }
  &.flag-VG {
    background-position: -16px -165px;
  }
  &.flag-AU {
    background-position: -192px 0;
  }
  &.flag-PR {
    background-position: -80px -121px;
  }
  &.flag-PY {
    background-position: -144px -121px;
  }
  &.flag-GL {
    background-position: -32px -55px;
  }
  &.flag-TM {
    background-position: 0 -154px;
  }
  &.flag-SV {
    background-position: -48px -143px;
  }
  &.flag-FO {
    background-position: -128px -44px;
  }
  &.flag-ET {
    background-position: -32px -44px;
  }
  &.flag-MW {
    background-position: -176px -99px;
  }
  &.flag-IE {
    background-position: -80px -66px;
  }
  &.flag-DO {
    background-position: -144px -33px;
  }
  &.flag-MQ {
    background-position: -80px -99px;
  }
  &.flag-AS {
    background-position: -160px 0;
  }
  &.flag-CK {
    background-position: -160px -22px;
  }
  &.flag-GN {
    background-position: -64px -55px;
  }
  &.flag-NG {
    background-position: -48px -110px;
  }
  &.flag-GE {
    background-position: -208px -44px;
  }
  &.flag-VU {
    background-position: -64px -165px;
  }
  &.flag-KN {
    background-position: -112px -77px;
  }
  &.flag-PS {
    background-position: -96px -121px;
  }
  &.flag-FI {
    background-position: -64px -44px;
  }
  &.flag-RO {
    background-position: -192px -121px;
  }
  &.flag-MX {
    background-position: -192px -99px;
  }
  &.flag-VN {
    background-position: -48px -165px;
  }
  &.flag-SZ {
    background-position: -96px -143px;
  }
  &.flag-KY {
    background-position: -192px -77px;
  }
  &.flag-BG {
    background-position: -80px -11px;
  }
  &.flag-NF {
    background-position: -32px -110px;
  }
  &.flag-PM {
    background-position: -48px -121px;
  }
  &.flag-TH {
    background-position: -176px -143px;
  }
  &.flag-TF {
    background-position: -144px -143px;
  }
  &.flag-IL {
    background-position: -96px -66px;
  }
  &.flag-BR {
    background-position: -192px -11px;
  }
  &.flag-TK {
    background-position: -224px -143px;
  }
  &.flag-EU {
    background-position: -48px -44px;
  }
  &.flag-AT {
    background-position: -176px 0;
  }
  &.flag-UY {
    background-position: -192px -154px;
  }
  &.flag-IS {
    background-position: -192px -66px;
  }
  &.flag-AI {
    background-position: -64px 0;
  }
  &.flag-GP {
    background-position: -80px -55px;
  }
  &.flag-AO {
    background-position: -128px 0;
  }
  &.flag-NU {
    background-position: -144px -110px;
  }
  &.flag-SO {
    background-position: -224px -132px;
  }
  &.flag-NC {
    background-position: 0 -110px;
  }
  &.flag-PK {
    background-position: -16px -121px;
  }
  &.flag-IT {
    background-position: -208px -66px;
  }
  &.flag-LA {
    background-position: -224px -77px;
  }
  &.flag-AW {
    background-position: -208px 0;
  }
  &.flag-AM {
    background-position: -96px 0;
  }
  &.flag-BO {
    background-position: -176px -11px;
  }
  &.flag-BI {
    background-position: -112px -11px;
  }
  &.flag-SX {
    background-position: -64px -143px;
  }
  &.flag-EH {
    background-position: -224px -33px;
  }
  &.flag-YE {
    background-position: -144px -165px;
  }
  &.flag-KW {
    background-position: -176px -77px;
  }
  &.flag-BY {
    background-position: -16px -22px;
  }
  &.flag-VA {
    background-position: -224px -154px;
  }
  &.flag-CY {
    background-position: -48px -33px;
  }
  &.flag-MY {
    background-position: -208px -99px;
  }
  &.flag-JO {
    background-position: 0 -77px;
  }
  &.flag-HK {
    background-position: -208px -55px;
  }
  &.flag-BA {
    background-position: 0 -11px;
  }
  &.flag-BE {
    background-position: -48px -11px;
  }
  &.flag-HN {
    background-position: -240px -55px;
  }
  &.flag-MN {
    background-position: -32px -99px;
  }
  &.flag-GG {
    background-position: -240px -44px;
  }
  &.flag-TD {
    background-position: -128px -143px;
  }
  &.flag-UM {
    background-position: -160px -154px;
  }
  &.flag-DZ {
    background-position: -160px -33px;
  }
  &.flag-BM {
    background-position: -144px -11px;
  }
  &.flag-HU {
    background-position: -32px -66px;
  }
  &.flag-FM {
    background-position: -112px -44px;
  }
  &.flag-SJ {
    background-position: -144px -132px;
  }
  &.flag-PT {
    background-position: -112px -121px;
  }
  &.flag-HR {
    background-position: 0 -66px;
  }
  &.flag-CH {
    background-position: -128px -22px;
  }
  &.flag-CI {
    background-position: -144px -22px;
  }
  &.flag-KZ {
    background-position: -208px -77px;
  }
  &.flag-AZ {
    background-position: -240px 0;
  }
  &.flag-KG {
    background-position: -48px -77px;
  }
  &.flag-ML {
    background-position: 0 -99px;
  }
  &.flag-BZ {
    background-position: -32px -22px;
  }
  &.flag-IM {
    background-position: -112px -66px;
  }
  &.flag-PH {
    background-position: 0 -121px;
  }
  &.flag-BV {
    background-position: -240px -11px;
  }
  &.flag-GI {
    background-position: -16px -55px;
  }
  &.flag-MR {
    background-position: -96px -99px;
  }
  &.flag-RS {
    background-position: -208px -121px;
  }
  &.flag-IN {
    background-position: -128px -66px;
  }
  &.flag-SD {
    background-position: -64px -132px;
  }
  &.flag-CU {
    background-position: 0 -33px;
  }
  &.flag-AX {
    background-position: -224px 0;
  }
  &.flag-TT {
    background-position: -64px -154px;
  }
  &.flag-MH {
    background-position: -224px -88px;
  }
  &.flag-GM {
    background-position: -48px -55px;
  }
  &.flag-CAtalonia {
    background-position: -64px -22px;
  }
  &.flag-MS {
    background-position: -112px -99px;
  }
  &.flag-MU {
    background-position: -144px -99px;
  }
  &.flag-TW {
    background-position: -96px -154px;
  }
  &.flag-TL {
    background-position: -240px -143px;
  }
  &.flag-SY {
    background-position: -80px -143px;
  }
  &.flag-NA {
    background-position: -240px -99px;
  }
  &.flag-KM {
    background-position: -96px -77px;
  }
  &.flag-MT {
    background-position: -128px -99px;
  }
  &.flag-CV {
    background-position: -16px -33px;
  }
  &.flag-SM {
    background-position: -192px -132px;
  }
  &.flag-OM {
    background-position: -176px -110px;
  }
  &.flag-ID {
    background-position: -64px -66px;
  }
  &.flag-TN {
    background-position: -16px -154px;
  }
  &.flag-MK {
    background-position: -240px -88px;
  }
  &.flag-PL {
    background-position: -32px -121px;
  }
  &.flag-BF {
    background-position: -64px -11px;
  }
  &.flag-AL {
    background-position: -80px 0;
  }
  &.flag-ENgland {
    background-position: -240px -33px;
  }
  &.flag-CZ {
    background-position: -64px -33px;
  }
  &.flag-NP {
    background-position: -112px -110px;
  }
  &.flag-CR {
    background-position: -240px -22px;
  }
  &.flag-SI {
    background-position: -128px -132px;
  }
  &.flag-NO {
    background-position: -96px -110px;
  }
  &.flag-JE {
    background-position: -224px -66px;
  }
  &.flag-MG {
    background-position: -208px -88px;
  }
  &.flag-US {
    background-position: -176px -154px;
  }
  &.flag-TIbet {
    background-position: -192px -143px;
  }
  &.flag-EC {
    background-position: -176px -33px;
  }
  &.flag-DJ {
    background-position: -96px -33px;
  }
  &.flag-TC {
    background-position: -112px -143px;
  }
  &.flag-SR {
    background-position: 0 -143px;
  }
  &.flag-GR {
    background-position: -112px -55px;
  }
  &.flag-SC {
    background-position: -32px -132px;
  }
  &.flag-BH {
    background-position: -96px -11px;
  }
}
