.response-profile-details {
  @include clearfix();
  padding: 0 0 20px 0;
  .col-left {
    float: left;
    width: 22.5%;
    padding: 0 15px 0 0;
  }
  .col-right {
    float: left;
    width: 77.5%;
  }
  .user-info {
    background: $white;
    border: 1px solid #e0e8ec;
    border-radius: 3px;
    padding: 20px;
    header {
      text-align: center;
      border-bottom: 1px solid #e1e1e1;
      padding: 5px 0 20px;
      .profile-photo {
        width: 100%;
        height: 100px;
        i {
          font-size: 120px;
          line-height: 100px;
          height: 100px;
          color: #cdd4d8;
        }
        img {
          border-radius: 50%;
          max-width: 100px;
          vertical-align: top;
        }
      }
      h3 {
        font-size: 18px;
        color: #4d565f;
        padding: 15px 0 0 0;
      }
      p {
        font-size: 14px;
        color: #9ea8af;
        padding: 5px 0 0 0;
      }
    }
    .user-details {
      padding: 0 0 15px 0;
      h3 {
        font-size: 14px;
        font-weight: 600;
        color: #4d565f;
        padding: 15px 0;
      }
      .item {
        padding: 10px 0;
      }
      h5 {
        font-size: 12px;
        font-weight: 400;
        color: #a8b9c2;
      }
      p {
        font-size: 14px;
        color: #4d565f;
      }
      a {
        color: $clr-pri;
        &:hover {
          color: #4d565f;
        }
      }
    }
  }
  .survey-summary {
    background: $white;
    border: 1px solid #e0e8ec;
    border-radius: 3px;
    padding: 35px;
    .summary-block {
      padding: 0 0 20px;
      border: 1px solid #e1e9ed;
      background: $white;
      font-size: 14px;
      line-height: 18px;
      color: #4d565f;
      border-radius: 3px;
      .row {
        border-top: 1px solid #e0e8ec;
        padding: 20px 25px;
        @include clearfix();
        &.header--row {
          border-top: none;
          background: #f4f5f9;
          + .row {
            border-bottom: 1px solid #e0e8ec;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          h2 {
            padding: 10px 25px 10px 60px;
            position: relative;
            span {
              position: absolute;
              left: 0;
              top: 0;
              width: 36px;
              height: 36px;
              font-size: 16px;
              line-height: 34px;
              font-weight: 600;
              color: $clr-pri;
              text-align: center;
              border: 1px solid $clr-pri;
              border-radius: 3px;
              background: $white;
            }
          }
        }
        .col {
          float: left;
          width: 50%;
        }
      }
      h2 {
        font-size: 16px;
        font-weight: normal;
        color: #34495e;
      }
      h3 {
        font-size: 14px;
        font-weight: 600;
        color: #a9b4bd;
      }
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: #4d565f;
      }
      h5 {
        font-size: 12px;
        font-weight: 600;
        color: #a9b4bd;
        padding: 10px 0;
      }
      .status {
        float: right;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #a9b4bd;
      }
      .summary__question {
        padding: 0 35px;
        position: relative;
        .question__no {
          position: absolute;
          left: 0;
          top: 0;
          color: $clr-pri;
        }
      }
      .summary__answer {
        padding: 25px 0 0 0;
        word-break: break-word;
        &:first-child {
          padding: 0;
        }
      }
      .second__level {
        padding: 0 60px;
        .row {
          padding-left: 0;
          padding-right: 0;
          .summary__question {
            padding-left: 0;
          }
        }
      }
    }
  }
}


.response-filters{
  clear: both;
  padding:8px 15px;
  border-top:1px solid #ebebeb;
  float: left;
  width: 100%;

  .filter-inputs{
   float: left;
  }
  .filter-results{
    float:right;
    line-height:34px;
    .filter__item{
      font-size: 12px;
      margin-left:20px;
      margin-right:0;
      color: #a9b4bd;
      span{
        color: #212229;
      }
    }
  }
  .filter__item{
    display: inline-block;
    margin-right:15px;
    label{
      font-size: 12px;
      margin-right: 10px;
      color: #212229;
    }
  }
}