.ui-menu {
  width: 185px;
  padding: 0.25em;
  font-size: 12px;
  z-index: map-get($zindex, dropdown);
  &.ui-menu-dynamic {
    position: absolute;
  }
  .ui-menu-separator {
    border-width: 1px 0 0 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .ui-submenu-header {
    padding: 0.25em 0.5em;
    margin: 0.125em 0;
  }
  .ui-menuitem {
    margin: 0.125em 0;
  }
  .ui-menuitem-link {
    padding: 0.6em 0.9em;
    display: block;
    text-decoration: none;
  }
  .ui-menuitem-icon {
    margin-right: 0.5em;
    font-size: 14px;
    font-weight: 300;
    vertical-align: middle;
  }
  .ui-menuitem-text {
    vertical-align: middle;
  }
}

.ui-menu {
  padding: $menuPadding;
  background-color: $menuBgColor;
  border: $menuBorder;

  .ui-menuitem {
    margin: $menuitemMargin;
  }

  &.ui-shadow {
    border: $overlayMenuBorder;
    @include shadow($overlayMenuShadow);
  }

  .ui-submenu-header {
    margin: $submenuHeaderMargin;
    padding: $menuitemPadding;
    color: $panelHeaderTextColor;
    background-color: $panelHeaderBgColor;
    font-weight: $panelHeaderFontWeight;
    border: 0 none;
  }

  .ui-menu-separator {
    border: $menuBorder;
    border-width: 1px 0 0 0;
  }
}
