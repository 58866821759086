.ui-treetable {
  position: relative;
  font-size: 12px;
  table {
    border-collapse: collapse;
    // width: 100%;
    table-layout: fixed;
  }
  .ui-treetable-thead > tr > th,
  .ui-treetable-tbody > tr > td,
  .ui-treetable-tfoot > tr > td {
    padding: 0.25em 0.5em;
  }
  .ui-treetable-toggler {
    cursor: pointer;
    display: inline-block;
    height: 1em;
    position: relative;
    top: 50%;
    margin-top: -0.5em;
    color: $primaryColor;
    i {
      color: $primaryColor;
      font-size: 12px;
      font-weight: 400;
      margin: 0 0.6em 0 0;
    }
  }
  .ui-sortable-column {
    cursor: pointer;
  }
  ui-treetablesorticon {
    vertical-align: middle;
  }
}

.ui-treetable-auto-layout > .ui-treetable-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

.ui-treetable .ui-treetable-chkbox {
  margin: 0 0.25em;
  vertical-align: middle;
}

.ui-treetable {
  .ui-treetable-caption,
  .ui-treetable-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .ui-treetable-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .ui-treetable-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .ui-treetable-thead tr {
    outline: none;
    text-align: left;
  }

  .ui-treetable-thead > tr > th {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    border-width: 1px 0;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    // background-color: $tableHeaderCellBgColor;
    //outline: none;
    font-size: 12px;
  }

  .ui-treetable-tbody > tr > td {
    padding: $tableBodyCellPadding;
  }

  .ui-treetable-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .ui-sortable-column {
    .ui-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.ui-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .ui-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.ui-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .ui-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .ui-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .ui-treetable-tbody {
    > tr {
      // background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        font-size: 12px;

        .ui-treetable-toggler {
          color: $dataIconColor;
          vertical-align: middle;
        }

        .ui-treetable-chkbox {
          vertical-align: middle;
          margin-right: $inlineSpacing;
        }

        .highcharts-container, .container-bar {
          overflow: visible !important;
        }

        .highcharts-container svg {
          overflow: visible !important;
        }
      }

      &.ui-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .ui-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }

      &.ui-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .ui-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }
    }
  }

  &.ui-treetable-hoverable-rows {
    .ui-treetable-tbody
      > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
      cursor: pointer;
      background-color: $tableBodyRowHoverBgColor;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .ui-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
}


.ui-treetable {
  position: relative;
  font-size: 12px;
  table {
    border-collapse: collapse;
    //width: 100%;
    table-layout: fixed;
  }
  .ui-treetable-thead > tr > th,
  .ui-treetable-tbody > tr > td,
  .ui-treetable-tfoot > tr > td {
    padding: 0.25em 0.5em;
  }
  .ui-treetable-toggler {
    cursor: pointer;
    display: inline-block;
    height: 1em;
    position: relative;
    top: 50%;
    margin-top: -0.5em;
    color: $primaryColor;
    i {
      color: $primaryColor;
      font-size: 12px;
      font-weight: 400;
      margin: 0 0.6em 0 0;
    }
  }
  .p-sortable-column {
    cursor: pointer;
  }
  ui-treetablesorticon {
    vertical-align: middle;
  }
}

.ui-treetable-auto-layout > .ui-treetable-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

.ui-treetable .ui-treetable-chkbox {
  margin: 0 0.25em;
  vertical-align: middle;
}

.ui-treetable {
  .ui-treetable-caption,
  .ui-treetable-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .ui-treetable-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .ui-treetable-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .ui-treetable-thead tr {
    outline: none;
    text-align: left;
  }

  .ui-treetable-thead > tr > th {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    border-width: 1px 0;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    // background-color: $tableHeaderCellBgColor;
    //outline: none;
    font-size: 12px;
  }

  .ui-treetable-tbody > tr > td {
    padding: $tableBodyCellPadding;
  }

  .ui-treetable-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.p-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .p-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.p-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .p-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .p-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .ui-treetable-tbody {
    > tr {
      // background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        font-size: 12px;

        .ui-treetable-toggler {
          color: $dataIconColor;
          vertical-align: middle;
        }

        .ui-treetable-chkbox {
          vertical-align: middle;
          margin-right: $inlineSpacing;
        }

        .highcharts-container, .container-bar {
          overflow: visible !important;
        }

        .highcharts-container svg {
          overflow: visible !important;
        }
      }

      &.p-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .ui-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }

      &.p-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .ui-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }
    }
  }

  &.ui-treetable-hoverable-rows {
    .ui-treetable-tbody
      > tr:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
      cursor: pointer;
      background-color: $tableBodyRowHoverBgColor;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .p-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
}


.p-treetable {
  position: relative;
  font-size: 12px;
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  .p-treetable-thead > tr > th,
  .p-treetable-tbody > tr > td,
  .p-treetable-tfoot > tr > td {
    padding: 0.25em 0.5em;
  }
  .p-treetable-toggler {
    cursor: pointer;
    display: inline-block;
    height: 1em;
    position: relative;
    top: 50%;
    margin-top: -0.5em;
    color: $primaryColor;
    i {
      color: $primaryColor;
      font-size: 12px;
      font-weight: 400;
      margin: 0 0.6em 0 0;
    }
  }
  .p-sortable-column {
    cursor: pointer;
  }
  p-treetablesorticon {
    vertical-align: middle;
  }
}

.p-treetable-auto-layout > .p-treetable-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

.p-treetable .p-treetable-chkbox {
  margin: 0 0.25em;
  vertical-align: middle;
}

.p-treetable {
  .p-treetable-caption,
  .p-treetable-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .p-treetable-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .p-treetable-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .p-treetable-thead tr {
    outline: none;
    text-align: left;
  }

  .p-treetable-thead > tr > th {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    border-width: 1px 0;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    // background-color: $tableHeaderCellBgColor;
    //outline: none;
    font-size: 12px;
  }

  .p-treetable-tbody > tr > td {
    padding: $tableBodyCellPadding;
  }

  .p-treetable-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.p-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .p-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.p-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .p-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .p-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .p-treetable-tbody {
    > tr {
      // background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        font-size: 12px;

        .p-treetable-toggler {
          color: $dataIconColor;
          vertical-align: middle;
        }

        .p-treetable-chkbox {
          vertical-align: middle;
          margin-right: $inlineSpacing;
        }

        .highcharts-container, .container-bar {
          overflow: visible !important;
        }

        .highcharts-container svg {
          overflow: visible !important;
        }
      }

      &.p-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .p-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }

      &.p-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .p-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }
    }
  }

  &.p-treetable-hoverable-rows {
    .p-treetable-tbody
      > tr:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
      cursor: pointer;
      background-color: $tableBodyRowHoverBgColor;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .p-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
}


.p-treetable {
  position: relative;
  font-size: 12px;
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  .p-treetable-thead > tr > th,
  .p-treetable-tbody > tr > td,
  .p-treetable-tfoot > tr > td {
    padding: 0.25em 0.5em;
  }
  .p-treetable-toggler {
    cursor: pointer;
    display: inline-block;
    height: 1em;
    position: relative;
    top: 50%;
    margin-top: -0.5em;
    color: $primaryColor;
    i {
      color: $primaryColor;
      font-size: 12px;
      font-weight: 400;
      margin: 0 0.6em 0 0;
    }
  }
  .p-sortable-column {
    cursor: pointer;
  }
  p-treetablesorticon {
    vertical-align: middle;
  }
}

.p-treetable-auto-layout > .p-treetable-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

.p-treetable .p-treetable-chkbox {
  margin: 0 0.25em;
  vertical-align: middle;
}

.p-treetable {
  .p-treetable-caption,
  .p-treetable-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .p-treetable-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .p-treetable-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .p-treetable-thead tr {
    outline: none;
    text-align: left;
  }

  .p-treetable-thead > tr > th {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    border-width: 1px 0;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    // background-color: $tableHeaderCellBgColor;
    //outline: none;
    font-size: 12px;
  }

  .p-treetable-tbody > tr > td {
    padding: $tableBodyCellPadding;
  }

  .p-treetable-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.p-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .p-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.p-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .p-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .p-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .p-treetable-tbody {
    > tr {
      // background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        font-size: 12px;

        .p-treetable-toggler {
          color: $dataIconColor;
          vertical-align: middle;
        }

        .p-treetable-chkbox {
          vertical-align: middle;
          margin-right: $inlineSpacing;
        }

        .highcharts-container, .container-bar {
          overflow: visible !important;
        }

        .highcharts-container svg {
          overflow: visible !important;
        }
      }

      &.p-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .p-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }

      &.p-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        > td {
          background-color: inherit;
          border: $tableBodyCellBorder;

          .p-treetable-toggler {
            color: $highlightColorText;
          }
        }
      }
    }
  }

  &.p-treetable-hoverable-rows {
    .p-treetable-tbody
      > tr:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
      cursor: pointer;
      background-color: $tableBodyRowHoverBgColor;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .p-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
}
