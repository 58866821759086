$path-images: "^assets/styles/portal/build/assets/images";
.error-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .error {
    // because error class apply in input that's why i comment this link.
    height: 100%;
    width: 100%;
    // background: url("#{$path-images}/mbc-icon-light.png") center 25% no-repeat #fff;
    background: #fff;
    position: relative;
    background-size: 30% auto;
    @media only screen and (max-width: 1024px) {
      background-size: 60% auto;
      background-position: center 32.5%;
    }

    .logo__error{
      padding:80px 0 50px;
      text-align: center;
      img{
        width:160px;
      }
    }

    .error__inner {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      text-align: center;
      z-index: 2;
      //background: rgba(253, 237, 235, 0.75);
      background: #F0F0F0;

      .btn{
        margin: 0 5px;
        border-radius: 20px;
      }

      i {
        margin-top: -43px;
        font-size: 60px;
        background: #fff;
        position: relative;
        border-radius: 100%;
        padding: 10px;
       // border: 3px solid #fdedeb;
        color: #C81720;
        // img {
        //   float: left;
        //   max-width: 60px;
        //   max-height: 60px;
        // }
      }
      h1 {
        font-size: 50px;
        margin-top: 20px;
        color: #000000;
      }
      p {
        font-size: 18px;
        margin: 15px 0;
        color: #000;

      }
    }

    .error__footer{
      .btn{
        margin: 0 5px;
        border-radius: 20px;
      }
    }

    &.error--success {
      // background: -webkit-linear-gradient(bottom, #e8f1eb 60%, white 40%);
      i {
        //  color: $clr-green;
        color: #eaeceb;
        border-color: #e8f1eb;
      }

      .error__inner {
        background: #e8f1eb;
        p {
          margin: 20px 0;
        }
      }
    }
  }
}

.timeout {
  display: table;
  height: 100%;
  background: $white;
  width: 100%;
  font-size: 1rem;

  .timeout__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      color: $clr-pri;
      :lang(fr) {
        text-transform: none;
      }
    }

    p {
      font-size: emify(18, 10);
      margin: 30px 0;
    }

    .btn--timeout {
      display: inline-block;
    }
  }
}

.logout-page {
  display: table;
  height: 100%;
  background: $white;
  width: 100%;

  .logout__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    p {
      font-size: 24px;
      margin: 0;
      color: $clr-pri;
      font-weight: 600;
    }
  }
}




.error-btn  {
  display:inline-block;
  padding:15px 30px;
  border-radius: 25px;
  background: rgb(247,147,30);
  background: linear-gradient(90deg, rgba(247,147,30,1) 5%, rgba(241,90,36,1) 60%);
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  font-weight: 500;
  &:hover{    
      background: rgb(241,90,36);
      color: white;
      background: linear-gradient(90deg, rgba(241,90,36,1) 10%, rgba(247,147,30,1) 70%);
  
  }
}

