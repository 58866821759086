.modal-dropdown {
  z-index: map-get($zindex, modalDropdown) !important;
}
.ui-dialog {
  position: fixed;
  padding: 0;
  background: $white;
  z-index: map-get($zindex, overlay);
  backface-visibility: hidden;
  .ui-dialog-titlebar {
    text-align: left;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px 20px 15px;
    position: relative;
    h2 {
      float: left;
      font-size: 14px;
      font-weight: 500;
      span {
        color: #4d565f;
        font-size: 16px;
        margin-right: 8px;
      }
    }
    .actions {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      padding: 0 25px 0 0;
      :lang(fr) {
        text-transform: none;
      }
      a {
        display: inline-block;
        color: #4d565f;
        vertical-align: middle;
        padding: 25px 5px 16px 27px;
        border-bottom: 2px solid $white;
        &:hover,
        &.active {
          border-bottom-color: $clr-pri;
        }
      }
      //i {
      //  font-size: 24px;
      //  line-height: 24px;
      //  color: $clr-pri;
      //  margin: -2px 6px 0 0;
      //  vertical-align: middle;
      //}
      .alert {
        display: inline-block;
        padding: 0 3px;
        position: relative;
        line-height: 14px;
        i {
          position: absolute;
          left: 5px;
          top: 50%;
          width: 20px;
          height: 20px;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          border-radius: 10px;
          background: #e5e5e5;
          padding: 3px;
          margin: -8px 0 0 0;
          color: $white;
        }
        &.complete {
          i {
            background: #35b968;
          }
          strong {
            color: #35b968;
          }
        }
        &.updated {
          i {
            background: #4ca1fe;
            transform: rotate(180deg);
          }
          strong {
            color: #4ca1fe;
          }
        }
        &.deleted {
          i {
            background: #ce4a73;
          }
          strong {
            color: #ce4a73;
          }
        }
        &.canceled {
          i {
            background: #ec638d;
          }
          strong {
            color: #ec638d;
          }
        }
        &.no-icon {
          a {
            padding-left: 5px;
          }
        }
        //span {
        //  position: absolute;
        //  right: 0px;
        //  top: 0px;
        //  padding: 3px 0 0 0;
        //  color: #a7b0b9;
        //  font-size: 11px;
        //  line-height: 11px;
        //  i {
        //    font-size: 11px;
        //    line-height: 11px;
        //    margin: 0 3px 0 0;
        //    position: relative;
        //    top: 1px;
        //  }
        //}
      }
    }
    .ui-dialog-titlebar-actions {
      float: right;
    }
  }
  .ui-dialog-content {
    position: relative;
    border: 0;
    padding: 20px;
    zoom: 1;
    max-height: 400px;
    overflow: auto;
  }

  .ui-dialog-footer {
    background: #f4f5f9;
    border-top: 1px solid #d7d7d7;
    padding: 15px 20px;
    border-radius: 0 0 5px 5px;
    text-align: right;

    .btn {
      margin: 0 5px;
    }
  }

  .ui-dialog-titlebar-icon {
    text-decoration: none;
    float: right;
    padding: 0.125em;
    cursor: pointer;
    border: 1px solid transparent;
    position: absolute;
    right: 15px;
    top: 19px;
    span {
      display: block;
      margin: 0;
    }
  }

  .ui-resizable-se {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px;
  }
  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    min-width: 90% !important;
    max-width: 90% !important;
  }
}

.ui-dialog--big {
  min-width: 1000px;
  max-width: 1000px;
}

.ui-dialog--small {
  min-width: 550px;
  max-width: 550px;
}

.ui-dialog--medium {
  min-width: 750px;
  max-width: 750px;
}

.ui-dynamicdialog {
  top: 50%;
  left: 50%;
}

.ui-dialog-resizable .ui-dialog-content {
  overflow: auto;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-dialog-mask {
  position: fixed;
  width: 100%;
  z-index: map-get($zindex, overlay);
  height: 100%;
}

.ui-dialog-maximized {
  -webkit-transition: left 0.3s, top 0.3s, width 0.3s, height 0.3s;
  transition: left 0.3s, top 0.3s, width 0.3s, height 0.3s;
}

/* ConfirmDialog */

.ui-confirmdialog {
  width: 30em;
  top: 50%;
  left: 50%;
  &.ui-dialog .ui-dialog-content {
    padding: 3em 2em;
  }
  .ui-dialog-content {
    .ui-confirmdialog-icon {
      font-size: 1.5em;
      vertical-align: middle;
      margin-right: 0.5em;
    }
    .ui-confirmdialog-message {
      vertical-align: middle;
    }
  }
}

.ui-dialog-footer .ui-button {
  margin-right: 0.25em;
}

/* Fluid */

.ui-fluid .ui-dialog-footer .ui-button {
  width: auto;
}

/* RTL */

.ui-rtl .ui-dialog {
  .ui-dialog-titlebar-close {
    float: left;
  }
  .ui-dialog-buttonpane button {
    text-align: right;
  }
}

@media screen and (max-width: 40em) {
  .ui-confirmdialog {
    width: 90%;
  }
}

.ui-dialog--create-data-source {
  .ui-dialog-content {
    position: relative;
    height: 450px;
    max-height: 450px;
    .modal-form {
      padding: 30px 0;
      .form__error {
        padding: 15px;
        font-size: 14px;
        text-align: center;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #34495e;
      padding: 0 0 5px 0;
      &.icon-heading {
        color: $clr-pri;
        font-size: 14px;
        line-height: 18px;
        i {
          font-size: 16px;
          line-height: 18px;
          vertical-align: middle;
          margin: -3px 10px 0 0;
        }
      }
    }
    a {
      color: $clr-pri;
      &:hover {
        color: $clr-sec;
      }
    }
  }
  .p-steps ul {
    padding: 0 0 40px;
  }
  .form-steps {
    padding: 10px 20px;
    background: $white;
    //border-top: 1px dotted #c6c7ca;
    position: relative;
  }
}

.ui-dialog--account-settings {
  background: #f4f5f9;
  .ui-dialog-titlebar {
    background: $white;
  }
  .ui-dialog-content {
    padding: 0;
  }
  .modal__sidebar {
    float: left;
    width: 30%;
    padding: 15px;

    font-size: 14px;
    line-height: 20px;
    text-align: left;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      width: 100%;
    }
    ul.left-nav {
      li {
        margin: 0 0 8px 0;
        @include susy-breakpoint(1024px, $susy, ".no-mq") {
          width: 50%;
          display: inline-block;
          padding: 0 5px;
        }
        @include susy-breakpoint(480px, $susy, ".no-mq") {
          width: 100%;
          padding: 0;
        }
        a {
          font-size: 12px;
          position: relative;
          display: block;
          color: #34495e;
          background: $white;
          border-radius: 3px;
          vertical-align: middle;
          padding: 8px 25px 8px 38px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);
          i {
            position: absolute;
            left: 10px;
            top: 50%;
            margin: -8px 0 0 0;
            font-size: 16px;
            line-height: 16px;
            width: 20px;
            text-align: center;
            height: 16px;
            vertical-align: middle;
            color: #cbcbcb;
          }
          .close {
            height: 16px;
            right: 10px;
            margin: -8px 0 0;
            position: absolute;
            top: 50%;
            width: 16px;
            cursor: pointer;
            i {
              left: 0;
            }
          }
          &:hover,
          &.active {
            background: $clr-pri;
            color: $white;
            i {
              color: $white;
            }
          }
        }
      }
    }
  }
  .modal__content {
    min-height: 400px;
    //  max-height: 400px;
    float: left;
    width: 70%;
    padding: 0;
    background: $white;
    border-left: 1px solid #d7d7d7;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      width: 100%;
    }
    .input-search{
    width: 100%;
    }
    .modal-form {
      padding: 20px 20px 40px;
      //min-height: 400px;
      //max-height: 400px;
      //overflow-y: auto;
      .row {
        .col-left {
          width: 25%;
          @include susy-breakpoint(1024px, $susy, ".no-mq") {
            width: 40%;
          }
        }
        .col-right {
          width: 70%;
          @include susy-breakpoint(1024px, $susy, ".no-mq") {
            width: 60%;
          }
          &.align-right {
            padding-left: 25%;
            @include susy-breakpoint(1024px, $susy, ".no-mq") {
              padding-left: 40%;
            }
          }
        }
        input,
        textarea,
        .upload-field,
        .info {
          width: 100%;
        }
        .upload-photo {
          width: 100%;
          text-align: center;
          .profile-photo {
            width: 100%;
            height: 100px;
            i {
              font-size: 120px;
              line-height: 100px;
              height: 100px;
              color: #cdd4d8;
              @include susy-breakpoint(1024px, $susy, ".no-mq") {
                font-size: 100px;
              }
            }
            img {
              border-radius: 50%;
              max-width: 100px;
              vertical-align: top;
              border: 1px solid #cdd4d8;
            }
          }
          .btn {
            width: 40px;
            height: 40px;
            padding: 0;
            text-align: center;
            border-radius: 100%;
            margin: -25px 0 0 0;
            i {
              position: relative;
              left: 0;
              top: 0;
              font-size: 20px;
              line-height: 38px;
              text-align: center;
            }
          }
        }
        a {
          color: $clr-pri;
          &:hover {
            color: $clr-sec;
          }
        }
      }
    }
  }
  .ui-dialog-footer {
    clear: both;
    background: $white;
    .btn {
      padding: 7px 25px 6px;
    }
  }
}

.ui-dialog--notifications {
  .ui-dialog-titlebar {
    @include clearfix();
    h2 {
      float: left;
      padding: 5px 0;
    }
  }
  .ui-dialog-content {
    clear: both;
    .modal-form {
      padding: 10px 0;
    }
  }
}

.ui-dialog--createsurvey {
  .ui-dialog-titlebar {
    @include clearfix();
    h2 {
      // float: left;

    }

    .toggle-options {
      float: right;
      margin-right: 20px;
    }
  }
  .ui-dialog-content {
    padding: 20px;
  }

}

.ui-dialog--questionbank {
  .ui-dialog-titlebar {
    @include clearfix();
    h2 {
      float: left;
      padding: 5px 0;
    }
  }
  .ui-dialog-content {
    clear: both;
  }
}

.ui-dialog--error {
  .modal__background {
    background: #f4f5f9;
    @include opacity(1);
    &:after {
      content: "";
      position: fixed;
      right: -2%;
      bottom: -20%;
      width: 100%;
      height: 100%;
      background: url("#{$path_images}/icon-hourglass.svg") no-repeat right top;
      filter: grayscale(100%);
      opacity: 0.05;
    }
  }
}

.ui-dialog--404 {
  .modal__background {
    &:after {
      height: 90%;
      background-image: url("#{$path_images}/icon-404.svg");
    }
  }
}

.ui-dialog--403 {
  .modal__background {
    &:after {
      background-image: url("#{$path_images}/icon-lock.svg");
    }
  }
}

.ui-dialog--loading {
  .modal__background {
    &:after {
      display: none;
    }
  }
}

.ui-dialog--fixheight {
  .ui-dialog-content {
    min-height: 400px;
    max-height: 400px;
  }
}

.ui-dialog--fixheight-medium {
  .ui-dialog-content {
    min-height: 230px;
    max-height: 230px;
  }
}

.ui-dialog--image-preview {
  .ui-dialog-content {
    padding: 20px;
    text-align: center;
  }
  .ui-dialog-footer {
    text-align: center;
    padding: 0 20px;
  }
}

.image-preview-meta {
  .meta-info {
    color: #969dab;
    p {
      display: inline-block;
      width: 33%;
      padding: 10px 20px;
      border-left: 1px solid #e3e4e5;
      &:first-child {
        border-left: none;
      }
      span {
        display: block;
        padding: 3px 0;
        color: #4d565f;
        font-weight: 600;
        text-transform: uppercase;
        :lang(fr) {
          text-transform: none;
        }
      }
    }
  }
  .btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.navigator {
  margin: 0 10px;
}

.navigator-list {
  border: 1px solid #e3e4e6;
  border-radius: 3px;
  @include clearfix();
  line-height: 20px;

  li {
    padding: 10px 15px;
    height: 40px;
    float: left;
    border-left: 1px solid #e3e4e6;

    &.icon--item {
      background: #f4f5f9;
      color: $clr-pri;

      i {
        font-size: 20px;
      }
    }

    &:first-child {
      border: 0;
    }

    &:last-child {
      float: right;
    }

  }
}
.ui-dialog-fixed-header-table {
  border: 1px solid #dde5eb;
  border-radius: 5px;
  margin: 20px 0;
  @include clearfix;
  .content-header {
    background: #f4f5f9;
    margin: 0;

    padding: 10px 10px 10px 20px;
    border-radius: 3px 3px 0 0;

    .actions {
      padding-top: 0;
    }
    h4 {
      font-size: 12px;
    }
  }

  .table-striped {
    max-height: 270px;
    table {
      tr {
        td {
          padding: 10px;
        }
      }
    }
  }
}

.ui-dialog--fullwidthcontent {
  .ui-dialog-content {
    padding: 0;
  }
}

.dialog-confirmation-content {
  p {
    padding: 5px 0 10px;
    &.alert-bar {
      border: 1px solid #dc3545;
      padding: 10px;
      margin: 0 0 10px 0;
    }
  }
  ul {
    padding: 5px 0 10px 10px;
    list-style-position: outside;
    margin: 0 0 0 10px;
    li {
      list-style-type: disc;
      display: list-item;
      padding: 0 0 5px 0;
    }
  }
}

.ui-dailog--categoryconfiguration {
  .ui-dialog-content {
    @include susy-breakpoint($md, $susy, '.no-mq') {
      max-height: 400px !important;
      .rg-drop-column {
        max-height: none !important;
        min-height: auto !important;
      }
    }
  }
  .col {
    float: left;
    width: 48%;
    padding: 10px;
    @include susy-breakpoint($md, $susy, '.no-mq') {
      width: 100%;
    }
  }
  .col-seperator {
    width: 4%;
    float: left;
    min-height: 400px;
    max-height: 400px;
    text-align: center;
    i {
      margin-top: 250px;
      font-size: 30px;
      color: #ccc;
      cursor: pointer;
    }
    @include susy-breakpoint($md, $susy, '.no-mq') {
      width: 100%;
      min-height: auto;
      max-height: none;
      i {
        margin-top: 0;
      }
    }
  }
}


.p-dialog {
  border-radius: 2px;
  border: 0 none;
}
.p-dialog .p-dialog-header {
  text-align: left;
  background: #fff;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px 20px 15px;
    position: relative;
}
.p-dialog .p-dialog-header .p-dialog-title {
  float: left;
    font-size: 14px;
    font-weight: 500;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #605e5c;
  border: 0 none;
  background: transparent;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #605e5c;
  border-color: transparent;
  background: #f3f2f1;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #605e5c;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #fff;
  padding: 20px;
}
.p-dialog .p-dialog-footer {
  background: #f4f5f9;
  border-top: 1px solid #d7d7d7;
  padding: 15px 20px;
  text-align: right;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(77, 86, 95, 0.7);
}