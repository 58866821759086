// Form styles label, inputs, etc

$field-clr: #d6dade;

.form {
  .form-col-left {
    @include span(8);
  }

  .form-col-right {
    @include span(4);
  }

  fieldset {
    padding: 10px;
    border: 0;
    margin: 0;
  }

  .field {
    margin-bottom: 20px;

    .field__label {
      font-size: 12px;
      font-weight: 500;
      display: block;
      margin-bottom: 5px;
    }

    .file-field {
      border: 1px solid #d0d7dd;
      padding: 4px 5px 0;
      border-radius: 5px;
      width: 66%;

      .file-name {
        position: relative;
        top: -14px;
        font-size: 12px;
      }

      .btn {
        margin-right: 10px;
      }

      .file-field {
        position: absolute;
        left: -9999px;
      }
    }

    .select__field {
      position: relative;
    }

    @include placeholder {
      font-size: 12px;
      color: #989898;
    }
  }

  .field_group {
    @include clearfix();
    margin-left: 0 - gutter();
    margin-right: 0 - gutter();

    .field {
      @include span(4);

      @include susy-breakpoint($sm, $susy, ".no-mq") {
        @include span(6);
      }

      @include susy-breakpoint($xs, $susy, ".no-mq") {
        @include span(12);
      }

      &.fields--auto {
        width: auto !important;
      }

      &.fields--one {
        @include span(5);
      }

      &.fields--two {
        @include span(6);
      }

      &.fields--three {
        @include span(3);

        @include susy-breakpoint($sm, $susy, ".no-mq") {
          @include span(12);
        }
      }

      &.fields--eight {
        @include span(8);
      }

      &.fields--full {
        @include span(12);
      }

      .field__controls {
        width: 100%;

        input[type="text"] {
          width: 100%;
        }
      }

      &.field--small {
        .field__controls {
          input[type="text"] {
            width: 50%;
          }

          .field__label {
            display: inline-block;
          }
        }
      }

      &.field--medium {
        width: 33%;
      }
    }
  }
}

//form styles

.form-container {
  @include clearfix();

  &.form--padding {
    padding: 20px;
  }

  &.form--padding10 {
    padding: 10px;
  }

  &.form--grey {
    background: #f9fafc;
  }

  .input-search {
    width: 100%;
  }

  .form-error {
    margin-top: 3px;
    color: $clr-red;
    display: block;
  }

  .form-info-error {
    margin-top: 3px;
    color: $clr-red;
    display: block;
    font-size: 12px;
  }
}

.form-column {
  margin-bottom: gutter();
  margin-top: gutter();
}

.form--modal {
  .form-column {
    padding-left: gutter() * 1.5;
    padding-right: gutter() * 1.5;
  }
}

.one.form-column,
.one.form-column {
  @include span(1);
}

.two.form-column {
  @include span(2);
}

.three.form-column {
  @include span(3);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.four.form-column {
  @include span(4);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.five.form-column {
  @include span(5);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.six.form-column {
  @include span(6);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.seven.form-column {
  @include span(7);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.eight.form-column {
  @include span(8);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.nine.form-column {
  @include span(9);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.ten.form-column {
  @include span(10);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.eleven.form-column {
  @include span(11);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.twelve.form-column {
  @include span(12);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.one-third.form-column {
  @include span(4);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.two-thirds.form-column {
  @include span(8);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.one-half.form-column {
  @include span(6);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.no-label.form-column {
  padding-top: 19px;
  line-height: 32px;
}

.select-reason.form-column {
  .form-label {
    padding: 4px 0;
  }
}

.no--label.form-column {
  padding-top: 19px;
}

.form-label {
  font-size: 12px;
  //font-weight: 500;
  padding: 0 0 5px 0;
}

.form-field {
  position: relative;

  &.toggle--field {
    margin-top: 5px;
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 8px 10px;
    @include border-radius(3px);
    border: 1px solid #d0d7dd;
    font-family: $font-pri;
    font-size: 12px;
    color: #666;

    &:focus {
      -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
    }

    &:disabled {
      background: #f1f1f1;
      opacity: 0.5;
    }

    &.field--error {

      input[type="text"],
      input[type="url"],
      input[type="email"],
      input[type="password"] {
        border-color: $clr-red;
      }
    }
  }

  &.field--error {

    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="password"] {
      border-color: $clr-red;
    }
  }

  textarea {
    width: 100%;
    padding: 10px;
    font-family: $font-pri;
    @include border-radius(3px);
    border: 1px solid #d0d7dd;
    font-size: 12px;
    height: 102px;

    &:focus {
      -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
    }
  }

  &.field--search {
    &:before {
      // font-family: "Font Awesome\ 5 Pro";
      font-family: "Font Awesome 5 Pro";
      content: "\f002";
      font-weight: 400;
      position: absolute;
      right: 14px;
      top: 11px;
    }
  }

  &.field--radio-group {
    font-size: 13px;

    .ui-radiobutton-label {
      margin: 0 10px 0 5px;
    }
  }
}

.calendar-field {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f073";
    position: absolute;
    top: 8px;
    right: 11px;
    pointer-events: none;
    font-size: 16px;
    color: #ced1d7;
    z-index: 10;
  }
}

.calendar-field-plain {
  position: relative;
  width: 100%;
  padding: 8px 10px;
  @include border-radius(3px);
  border: 1px solid #d0d7dd;
  font-family: $font-pri;
  font-size: 12px;
  color: #666;
  background: #f1f1f1;
  opacity: 0.5;
  min-height: 31px;

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f073";
    position: absolute;
    top: 8px;
    right: 11px;
    pointer-events: none;
    font-size: 16px;
    color: #ced1d7;
    z-index: 10;
  }
}

.field-attachment {
  label {
    text-align: left;
    margin-right: 0;
    font-size: 12px;
    color: #4c5e70;
  }

  .btn {
    margin-right: 10px;
  }

  .file-field {
    position: absolute;
    left: -9999px;
  }

  &.attachement--treereport {
    display: inline-block;
  }
}

.upload-files-list {
  font-size: 12px;

  ul {
    padding: 2px 0;

    li {
      padding: 2px 0;
      word-break: break-word;

      .delete-file {
        margin: 0 0 0 5px;
        color: #fe2e47;

        a {
          color: #fe2e47;
        }
      }
    }
  }
}

.upload-files-list {
  font-size: 12px;

  ul {
    padding: 2px 0;

    li {
      padding: 2px 0;
      word-break: break-word;

      .delete-file {
        margin: 0 0 0 5px;
        color: #fe2e47;

        a {
          color: #fe2e47;
        }
      }
    }
  }
}

.file__field {
  .file__name {
    position: relative;
    top: -10px;
    font-size: 12px;
  }

  .btn {
    margin-right: 10px;
  }

  .file__input {
    position: absolute;
    left: -9999px;
  }
}

.radio-list {
  width: 100%;
  margin-top: 10px;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }
  }

  input[type="radio"] {
    display: none;

    +label {
      position: relative;
      font-size: 12px;
      display: inline-block;
      padding-left: 25px;
      cursor: pointer;
      line-height: 23px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 15px;
        height: 15px;
        text-align: center;
        color: black;
        border-radius: 50%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      &:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
        border: 1px solid #b7b7b7;
      }

      &:hover:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
      }
    }

    &:checked+label:before {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em $clr-pri;
      border: 1px solid #b7b7b7;
    }
  }
}

.image-based-radio {
  .radio-container {
    float: left;
    display: table;
    width: 80px;
    height: 70px;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .radio-image {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 7px;
    vertical-align: middle;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid $white;

    &:hover {
      border-color: #d0d7dd;
    }
  }

  .radio-hidden:checked~.radio-image {
    border-color: $clr-pri;
  }

  .radio-hidden {
    position: absolute;
    visibility: hidden;
  }
}

.form-row {
  @include clearfix();
  clear: both;

  &.with--padding {
    padding: 10px 0 0;
  }

  .form-row {
    .form-column {
      margin: 0;
      // margin-bottom: 0;
      /*&:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }*/
    }
  }
}

.row-grey {
  background: #f1f1f1;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.form-title {
  padding: 0 0 5px 10px;
}

.form__footer {
  border-top: 1px solid #ebebeb;
  padding: 15px 30px;
  margin: 0 0 0;
  position: relative;
  // background: #f9fafc;
  text-align: right;
  border-radius: 0 0 3px 3px;

  &.text-left {
    text-align: left;
  }

  .btn {
    margin: 0 5px 0 0;
  }
}

.form__full {
  margin: 0 0 0;
  position: relative;

  &.form--gray {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    background: #f9fafc;

    +.form__footer {
      margin-top: 20px;
    }
  }
}

.from--select-kpi {}

.form-content {
  font-size: 13px;
  padding: 0 10px;

  .content-row {
    padding: 5px 0;

    ul {
      padding: 0 0 0 25px;
      list-style: disc inside none;

      li {
        display: list-item;
        padding: 0 0 5px 0;
      }
    }
  }
}

.inline-item {
  display: inline-block;
  padding: 0 15px 0 0;
}

.small-button-holder {
  .btn {
    padding: 4px 15px 3px;
    border-radius: 15px;
  }
}

.image-preview-setting {
  width: 34px;
  margin-right: 10px;
  border: 1px solid #c3c3c3;
  display: inline-block;
}

.default-text-list {
  max-width: 400px;

  li {
    font-size: 13px;
    padding: 7px 0 7px 15px;
    position: relative;
    border-bottom: 1px solid #ebebeb;

    &:before {
      content: "- ";
      position: absolute;
      left: 5px;
    }

    .title {
      color: #666;
    }
  }
}





.user-form {
  display: flex;
  justify-content: space-between;

  //gap: 20px;
  .user__col {

    flex: 1;

    &:last-child {
      border-left: 1px solid #ebebeb;
      margin-left: 10px;
      padding-left: 10px;
    }
  }

}