.ui-panelmenu {
  .ui-icon {
    position: static;
  }

  .ui-panelmenu-header {
    padding: 0;

    > a {
      border: $panelHeaderBorder;
      background-color: $panelHeaderBgColor;
      color: $panelHeaderTextColor;
      padding: $menuitemPadding;
      font-weight: $panelHeaderFontWeight;
      position: relative;
      font-size: $fontSize;
      border-radius: 3px;
      @include transition($panelHeaderTransition);

      .ui-panelmenu-icon {
        position: absolute;
        right: 15px;
        top: 14px;
        font-size: 14px;
        color: $panelHeaderIconColor;
      }
      .initials {
        display: inline-block;
        width: 28px;
        height: 26px;
        font-size: 12px;
        line-height: 18px;
        color: $clr-pri;
        text-align: center;
        padding: 4px;
        border: 1px solid $clr-pri;
        border-radius: 3px;
        margin: 0 10px 0 0;
      }
      .count {
        color: #969dab;
      }
    }

    &:not(.ui-state-active) {
      > a:hover {
        outline: 0 none;
        border: $panelHeaderHoverBorder;
        background-color: $panelHeaderHoverBgColor;
        color: $panelHeaderHoverTextColor;

        .ui-panelmenu-icon {
          color: $panelHeaderHoverIconColor;
        }
      }
    }

    &.ui-state-active {
      > a {
        border: $panelHeaderActiveBorder;
        background-color: $panelHeaderActiveBgColor;
        color: $panelHeaderActiveTextColor;
        border-radius: 3px 3px 0 0;

        .ui-panelmenu-icon {
          color: $panelHeaderActiveIconColor;
        }
        .initials {
          color: #ffffff;
          border-color: #ffffff;
        }
        .count {
          color: #ffffff;
        }

        &:hover {
          outline: 0 none;
          border: $panelHeaderActiveHoverBorder;
          background-color: $panelHeaderActiveHoverBgColor;
          color: $panelHeaderActiveHoverTextColor;

          .ui-panelmenu-icon {
            color: $panelHeaderActiveHoverIconColor;
          }
        }
      }
    }
  }

  .ui-panelmenu-panel {
    margin-top: 15px;
    box-shadow: 0 0 4.05px 0.95px rgba(146,146,146,.25);
    border-radius: 3px;

    &:first-child {
      margin-top: 0;
    }
  }

  .ui-panelmenu-content {
    padding: $menuPadding;
    background-color: $menuBgColor;
    //border: $menuBorder;
    margin-top: 0;
    position: static;
    border-top: 0 none;
    border-radius: 0 0 3px 3px;

    .ui-menuitem {
      margin: $menuitemMargin;

      .ui-menuitem-link {
        padding: $menuitemPadding;
        color: $menuitemTextColor;
        .ui-menuitem-icon,
        .ui-panelmenu-icon {
          margin: 0 5px 0 0;
        }

        &:hover {
          //background-color: $menuitemHoverBgColor;

          .ui-menuitem-icon,
          .ui-panelmenu-icon {
            color: $menuitemHoverIconColor;
          }

          .ui-menuitem-text {
            color: $menuitemHoverTextColor;
          }
        }
      }
    }
  }
}

.ui-panelmenu {
  width: auto;
  .ui-menu-separator {
    border-width: 1px 0 0 0;
  }
  .ui-panelmenu-content-wrapper {
    overflow: hidden;
  }
  .ui-panelmenu-header {
    margin: -1px 0 0 0;
    zoom: 1;
  }
  .ui-panelmenu-header-link {
    padding: 0.5em;
    display: block;
    text-decoration: none;
  }
  .ui-panelmenu-icon,
  .ui-menuitem-text {
    vertical-align: middle;
    &.active-menu {
      color: $clr-pri !important;
      font-weight: 500;
    }
  }
  .ui-menuitem-icon {
    margin-right: 0.25em;
    vertical-align: middle;
  }
  .ui-panelmenu-content {
    border-top: 0;
  }
  .ui-submenu-list {
    font-size: 12px;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 1.5em;
  }
  .ui-panelmenu-content > .ui-panelmenu-root-submenu > .ui-submenu-list {
    margin-left: 0;
  }
  .ui-menuitem {
    overflow: hidden;
    margin: 0.125em 0;
  }
  .ui-menuitem-link {
    padding: 0.25em;
    display: block;
    text-decoration: none;
  }
}