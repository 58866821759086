.fivestar-question-block {
  h4 {
    font-size: 12px;
    font-weight: 500;
    color: #4d565f;
    text-transform: uppercase;

    :lang(fr) {
      text-transform: none;
    }
  }
}

.fivestar-question-copy {
  font-size: 18px;
  line-height: 130%;
  outline: none;
  margin: 0 0 10px 0;
  color: #4d565f;

  p {
    position: relative;
    padding: 0 0 7px 45px;
    word-wrap: break-word;

    &:after {
      content: '';
      position: absolute;
      left: 40px;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #4ca1fe;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      color: $clr-pri;
    }

    em {
      font-style: normal;
      color: #ec638d;
    }
  }
}

.fivestar-grid {
  @include nested(10) {
    .column {
      @include span(2);
      padding: 0 5px;

      &:first-child {
        @include span(6);
        padding: 0 5px 0 0;
      }
    }
  }

  h4 {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #bdc2c6;
    padding: 10px 0;

    :lang(fr) {
      text-transform: none;
    }
  }

  .negative-block {
    h4 {
      color: #f10075;
    }
  }

  .neutral-block {}

  .positive-block {
    h4 {
      color: #00cccc;
    }
  }

  .star-field-row {
    margin: 0 -10px;
  }

  .star-field {
    @include span(12);
    color: #4d565f;
    font-size: 18px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 0;
      height: 2px;
      background: #d1dce2;
    }

    .number {
      font-size: 12px;
      color: #ada9b2;
    }

    .star-icon {
      font-size: 24px;
      color: #ffdb19;
      position: relative;
      top: 2px;
      padding: 0 10px 0 0;
    }
  }

  .negative-block {
    .star-field {
      @include span(4);
    }
  }
}

.star-block {
  @include span(12);
  min-height: 200px;
  background: #f4f5f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

  .negative-block & {
    background: #f4e9f2;
  }

  .neutral-block & {}

  .positive-block & {
    background: #e8f3f7;
  }
}

.drop-container {
  clear: both;
  font-size: 11px;
  color: #818999;
  padding: 10px 0;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .drop-label {
    padding: 10px 0;
  }

  .drop-area {
    position: relative;
    min-height: 110px;
    text-align: center;
    font-size: 12px;
    color: #c6c4ca;
    background: rgba(255, 255, 255, 0.25);
    border: 2px dashed #fff;

    i {
      font-size: 20px;
      padding: 5px 0;
    }

    p {
      padding: 5px 0;
    }

    .drop-message {
      width: 100%;
      @include center();
    }
  }
}

.sentiments-instructions {
  clear: both;
  color: #717f8e;
  font-size: 12px;
  line-height: 16px;
  padding: 15px 0;

  p {
    padding: 5px 0;
  }
}

.sentiments-list {
  padding: 10px 0;

  .sentiment-icon {
    display: inline-block;
    text-align: center;
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    a {
      display: block;
      margin: 0 auto;
      width: 56px;
      height: 56px;
      text-align: center;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      cursor: grab;

      i {
        font-size: 26px;
        line-height: 54px;
        color: #4ca1fe;
      }

      &:hover {
        border: 1px dashed #4ca1fe;
      }
    }

    span {
      display: inline-block;
      font-size: 10px;
      color: #34495e;
      padding: 5px 0;
    }

    &.add-more {
      a {
        background: #4ca1fe;
        cursor: pointer;

        i {
          color: #fff;
        }
      }
    }
  }
}

.fivestar-settings-block {
  clear: both;
  font-size: 12px;
  color: #34495e;
  padding: 0 0 15px 0;
}

.settings-row-group {
  margin: 10px 0 0 0;

  .settings-row {
    &:nth-child(even) {
      background: #eeeff4;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-top: 1px solid #ebebeb;
    }

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.settings-row {
  background: #f4f5f9;
  border: 1px solid #ebebeb;
  border-width: 0 1px 1px 1px;
  padding: 15px 5px;

  .column {
    @include span(4);

    .field-label {
      padding: 0 0 5px 0;
    }

    .p-inputtext {
      min-width: 250px;
      margin: 0 10px 0 0;
    }
  }

  @include clearfix;
}

.toggle-switch {
  label {
    padding: 0 0 0 10px;
    vertical-align: middle;
  }
}

.themes-list {
  padding: 10px 0;

  .theme-icon {
    display: inline-block;
    text-align: center;
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    a {
      display: block;
      margin: 0 auto;
      width: 91px;
      height: 85px;
      text-align: center;
      border: 2px solid #e1e1e1;
      border-radius: 3px;

      &:hover {
        border-color: #b8b8b8;
      }
    }
  }
}

.fontawesome-list {
  font-size: 24px;
  color: #495057;

  ul {
    li {
      display: inline-block;
      margin: 5px;
      width: 60px;
      max-width: 60px;
      text-align: center;
      vertical-align: top;

      i {
        font-size: 36px;
      }

      span {
        display: block;
        font-size: 10px;
      }
    }
  }
}

.fivestar-preview-image {
  text-align: center;
}

.fivestar-widget {
  float: left;
  width: 100%;
  margin-top: 30px;

  .widget__graph {
    margin: 0;
  }

  .col__left {
    float: left;
    width: 50%;
    padding: 0 10px;
    text-align: center;
  }

  .col__right {
    float: right;
    width: 50%;
    padding: 0 10px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;

    &.ta-positive {
      color: #3bb001;
    }

    &.ta-negative {
      color: #dc3545;
    }
  }

  h3 {
    font-weight: bold;

    &.grey {
      color: grey;
    }
  }

  h6 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding: 0;

    &.black {
      padding-right: 6px;
      color: black;
    }
  }

  h5 {
    font-size: 10px;
    font-weight: bold;
    margin: 0;
    padding: 0;

    &.ta-negative {
      color: #dc3545;
    }
  }
}

.numberofsurveys {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  font-size: 16px;
}

.surveycount {
  cursor: pointer;
  color: #dc3545;
  font-size: 15px;
  font-weight: bold;
}

.custom-handler-more {
  width: 26px;
  height: 26px;
  z-index: 1;
  color: #96a2b6;
  position: relative;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
}


.star-table {

  td {
    height: 48px !important;

    &:first-child {
      a {
        display: inline-block;
      }
    }
  }
}