// buttons.scss
// contains buttons' styles
.btn {
    position: relative;
    display: inline-block;
    color: $white;
    @include transition();
    border-radius: 3px;
    padding: 9px 15px;
    border: 1px solid $clr-pri;
    font-size: 12px;
    background: $clr-pri;
    outline: none;
    cursor: pointer;
    &.btn--small-icon{
        padding:10px 6px;
        margin-right: 1px;
        &.radius--ntlbl{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    &.btn--outline {
        background: none;
        color: $clr-pri;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        &:hover {
            background: $clr-pri;
            color: #fff;
        }
    }
    //i {
    //  font-size: 13px;
    //  line-height: 13px;
    //  position: absolute;
    //  left: 5px;
    //  top: 6px;
    //}
    &:hover {
        background-color: darken($clr-pri, 8%);
        color: $white;
    }
    &.btn-icon-only {
        margin: 0 2px;
        font-size: 16px;
        padding: 5px 8px;
        color: #98999c;
        background-color: #e6e6e6;
        border: none;
        &.active,
        &:hover {
            background: $clr-pri;
            color: $white;
        }
    }
    &.btn--cancel {
        background: #969dab;
        border-color: #969dab;
        &:hover {
            background-color: darken(#969dab, 8%);
        }
    }
    &.btn--danger {
        background: $clr-red;
        border-color: $clr-red;
        &:hover {
            background-color: darken($clr-red, 8%);
        }
    }
    &.btn--full {
        width: 100%;
        text-align: center;
    }
    &.btn--icon {
        padding-left: 9px;
        padding-right: 9px;
        i {
            font-size: 14px;
            line-height: 14px;
            color: $clr-pri;
        }
    }
    &.btn--icon-right {
        padding-right: 40px;
        padding-left: 20px;
        i {
            left: auto;
            right: 10px;
        }
    }
    &.btn--secondary {
        color: #34495e;
        background: $white;
        border-color: #e1e1e1;
        &:hover {
            color: #34495e !important;
            background: $white;
            border-color: $clr-pri;
        }
        &.ui-state-active {
            background: $clr-pri;
            color: $white !important;
            border-color: $clr-pri;
        }
    }
    &.btn--withicon {
        color: #a0a6ac;
        background: $white;
        border-color: #e1e1e1;
        padding: 8px 11px;
        vertical-align: middle;
        i {
            font-size: 16px;
        }
        &:hover {
            //color: #34495e !important;
            color: $clr-pri;
            background: $white;
            border-color: $clr-pri;
        }
    }
    &.btn--filter {
        margin: 8px 10px 0;
        margin-bottom: 5px;
    }
    &.btn--flat {
        color: #34495e;
        background: none;
        border-color: transparent;
        box-shadow: none;
        &.active {
            color: $clr-pri !important;
            background: $white;
            border-color: $clr-pri;
            box-shadow: 0px 8px 16px 0px rgba(157, 175, 185, 0.25);
        }
        &:hover {
            color: $clr-pri !important;
        }
    }
    &.btn--big {
        border-radius: 20px;
        padding: 7px 25px 6px;
        font-size: 14px;
        line-height: 21px;
    }
    &.btn--small {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    &.btn--medium {
        padding-top: 7px;
        padding-bottom: 7px;
    }
    &.send--reply {
        // margin-top: 10px;
    }
    &.btn--disabled,
    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
        background: #d7d7d7 !important;
        border-color: transparent !important;
        color: $clr-copy !important;
    }
    &.dropdown-toggle {
        border: 1px solid #e1e1e1 !important;
        border-radius: 3px;
        padding: 5px 25px 5px 15px;
        background: $white;
        font-size: 12px;
        color: #34495e;
        white-space: nowrap;
        width: 100%;
        position: relative;
        font-weight: normal !important;
        text-transform: none;
        box-shadow: none;
        &:hover,
        &:active {
            color: #161f28 !important;
            background-color: $white;
        }
        &:active {
            -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
            -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
            box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
        }
        .caret {
            &:before {
                font-size: 21px;
                margin: 2px -5px 0 0;
            }
        }
        &.error {
            border-color: #fe596b !important;
        }
    }
    &.dropdown-toggle.btn-language {
        border: 1px solid $clr-pri !important;
        border-radius: 20px;
        padding: 3px 40px 4px 32px;
        background: $white;
        font-size: 14px;
        color: $clr-pri;
        white-space: nowrap;
        width: 100%;
        position: relative;
        i {
            top: 7px;
            left: 10px;
        }
        &:hover,
        &:active {
            color: $clr-pri !important;
            background-color: $white;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
            -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
            box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
        }
    }
    &.dropdown-toggle.btn-survey-language {
        padding: 5px 25px 5px 40px;
        i {
            top: 8px;
            left: 10px;
        }
        &:hover,
        &:active {
            color: $clr-pri !important;
            background-color: $white;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
            -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
            box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.15);
        }
    }
    &.dropdown-toggle.btn-number {
        padding: 3px 20px 3px 10px;
        height: 38px;
        .caret {
            margin-top: -1px !important;
        }
    }
    &.dropdown-toggle.btn-search-filter {
        padding: 0 30px 0 15px;
        height: 32px;
        border-radius: 0 3px 3px 0;
        border: 1px solid $clr-pri !important;
        background: $clr-pri;
        color: $white !important;
        .caret {
            margin-top: -9px !important;
            -webkit-text-stroke: 0px !important;
            top: 50% !important;
            &:before {
                font-size: 16px;
                color: $white;
                margin: 0;
            }
        }
    }
}

.full-btn {
    padding: 0 0 10px;
}

.actions {
    a {
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
            .tooltiptext {
                display: none;
            }
            &:hover {
                color: #cdd4d8 !important;
                border-color: #cdd4d8;
            }
        }
    }
}


.btn-rounded{
    position: relative;
width:35px;
display: inline-block;
height:35px;
line-height:35px; 
background: white;
text-align: center;
border-radius: 100%;
border: 1px solid $clr-pri;
img{
    max-height:20px;
    @include center();
}
&:hover {
  border-color: darken($clr-pri, 20%);  
}
}


.screener-link{
    font-size: 12px;
    margin-left: 10px;
    position: relative;
    top: -1px;
}