.filter {
    float: left;
    clear: both;
    width: 100%;
    @include border-radius(3px);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    background: $white;

    @include susy-breakpoint($md, $susy, ".no-mq") {
        padding: 0 0 8px;
        margin: 0 0 10px 0;
    }

    >ul {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @include susy-breakpoint(1024px, $susy, '.no-mq') {
            flex-direction: column;
        }
    }

    &.filter--disabled {
        @include opacity(0.5);
    }

    .filter__item {
        border-left: 1px solid #ebebeb;

        @include susy-breakpoint(1024px, $susy, ".no-mq") {
            border-left: none;
            border-top: 1px solid #ebebeb;
            flex: 1 1 auto !important;
        }

        &:first-child {
            border-left: none;
        }

        &.filter--btns {
            padding: 11px 11px 0 10px;
            border-left: none;
        }

        &.flex4 {
            flex: 4;
        }

        &.flex3 {
            flex: 3;
        }

        &.flex2 {
            flex: 2;
        }

        &.flexbreak {
            flex-basis: 100%;
            height: 0;
            border-top: 1px solid #ebebeb;

            @include susy-breakpoint(1024px, $susy, '.no-mq') {
                border-top: none;
            }
        }

        &.flexbtn {
            order: 20;
        }

        .filter__label {
            background: #f4f5f7;
            padding: 8px 10px;
            font-size: 13px;
            color: #666666;
            display: block;
            font-weight: 500;
            border-bottom: 1px solid #ebebeb;
            display: none;
        }

        .btn {
            @include susy-breakpoint($md, $susy, ".no-mq") {
                display: block;
                text-align: center;
            }
        }
    }
}

.filter-dropdown {
    position: absolute;
    background: $white;
    width: 100%;
    box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.21);
    top: 51px;
    text-align: left;
    left: 0;
    min-height: 200px;
    z-index: map-get($zindex, dropdown);
    opacity: 0;
    visibility: hidden;
    display: none;
    transform: translateY(30px);
    @include transition();
    @include clearfix();

    .tree-list_item & {
        box-shadow: none;
        float: left;
        width: 100%;
        position: relative;
        left: auto;
        top: auto;
    }

    .dropdown__footer {
        float: left;
        width: 100%;
        text-align: right;
        clear: both;
        background: #f4f5f9;
        padding: 10px;
        border-top: 1px solid #ebebeb;
        border-radius: 0 0 3px 3px;

        @include susy-breakpoint(1024px, $susy, ".no-mq") {
            text-align: left;
        }
    }

    &.active {
        opacity: 1;
        visibility: visible;
        display: block;
        transform: translateY(0);
    }

    @include susy-breakpoint($md, $susy, ".no-mq") {
        width: 100%;
        max-width: 100%;
        top: 44px;
    }

    .loader-overlay {
        position: absolute;
    }

    &.dropdown--allmodels {
        width: 720px;
        padding: 0;
        min-height: 200px;
        left: -30px;

        @include susy-breakpoint($md, $susy, ".no-mq") {
            width: 100%;
        }
    }

    &.dropdown--calendar {
        width: 570px;
        right: 0;
        left: auto;
        padding: 0;

        @include susy-breakpoint(1024px, $susy, '.no-mq') {
            width: 100%;

            .date-filters {
                width: 100%;
                border-right: none;
                padding: 10px 0;
                text-align: center;

                li {
                    border-radius: 3px;
                    padding: 10px;
                    display: inline-block;
                }
            }

            .date__calendar {
                width: 100%;
                padding: 10px 20px;
            }
        }
    }

    &.calendar--custom {
        width: 550px;

        .date-filters {
            width: 27%;

            @include susy-breakpoint($sm, $susy, ".no-mq") {
                width: 100%;
            }
        }

        .date__calendar {
            float: left;
            width: 73%;

            @include susy-breakpoint($sm, $susy, ".no-mq") {
                width: 100%;
            }
        }
    }

    &.dropdown--list {
        min-height: auto;
    }

    &.dropdown--surveyscore {
        width: 420px;
        padding: 10px;
        min-height: auto;
        left: auto;
        right: 0;

        .survey-score-heading {
            font-size: 14px;
            color: #666666;
            text-transform: uppercase;
            text-align: center;
            margin: 0 0 10px 0;

            :lang(fr) {
                text-transform: none;
            }

            span {
                font-weight: bold;
            }
        }

        .survey-score-ranges {
            font-size: 12px;
            color: #b3b3b3;

            li {
                cursor: pointer;
                border-top: 1px solid #ebebeb;
                padding: 5px 10px;

                &:first-child {
                    border-top: none;
                }

                .survey-score-range-value {
                    float: right;
                    font-weight: bold;
                }

                &.active {
                    background: #f3f6f9;
                    color: #666;
                    font-weight: bold;
                }
            }
        }
    }

    &.dropdown--surveyresponses {
        width: 480px;
    }

    .filter-search {
        input {
            padding: 8px;
            border: 1px solid #ebebeb;
        }
    }
}

.filter-field {
    float: left;
    position: relative;
    padding: 6px 10px;
    width: 100%;

    .tree-list_item & {
        padding: 1px 0 0 0;
    }

    .level-selected {
        background: #f3f6f9;

        a {
            color: #666666;
        }
    }

    @include susy-breakpoint($md, $susy, ".no-mq") {
        width: 100%;
        padding: 0;
    }

    .filter__trigger {
        padding: 12px 20px 12px 10px;
        cursor: pointer;
        float: left;
        width: 100%;

        >i {
            position: absolute;
            left: 15px;
            top: 50%;
            margin-top: -8px;
            font-size: 16px;
            color: #969dab;
            @include opacity(0.5);

            @include susy-breakpoint($md, $susy, ".no-mq") {
                left: 10px;
            }

            &.fa-search {
                margin-top: -7px;

                .tree-list_item & {
                    margin-top: 0;
                    top: 15px;
                }
            }
        }
    }

    &.filter--noicon {
        width: 100%;
        border-radius: 3px 0 0 3px;

        .filter__trigger {
            padding-left: 10px;

            .tree-list_item & {
                padding-left: 40px;
                padding-right: 30px;
            }
        }

        input {
            background: transparent;
            border: 0;
            width: 85%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 14px;
            padding: 0;
            padding-left: 20px;
            font-family: inherit;
            font-weight: 400;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: #969dab;
            }

            &:-ms-input-placeholder {}
        }

        &.active {
            input {}
        }

        .icon-search {
            position: relative;
            top: 1px;
        }
    }

    &.filter--withicon {
        width: 100%;
        border-radius: 3px 0 0 3px;

        .filter__trigger {
            padding-left: 30px;

            .tree-list_item & {
                padding-left: 40px;
                padding-right: 30px;
            }
        }

        input {
            background: transparent;
            border: 0;
            width: 85%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 14px;
            padding: 0;
            font-family: inherit;
            font-weight: 400;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: #969dab;
            }

            &:-ms-input-placeholder {}
        }

        &.active {
            input {}
        }

        .icon-search {
            position: relative;
            top: 1px;
        }
    }

    &.filter--calendar {
        width: 100%;

        .filter__trigger {
            padding-left: 30px;
        }

        .icon-calendar {}
    }

    .field__label {
        float: left;
        width: 100%;
        color: #969dab;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;

        span {
            //display: inline-block;
        }

        .to {
            opacity: 0.5;
            margin: 0 8px;
        }

        &.text__ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10%;
            white-space: nowrap;
        }
    }

    .field__icons {
        position: absolute;
        right: 17px;
        top: 17px;

        .tree-list_item & {
            right: 10px;
            top: 14px;
        }

        @include susy-breakpoint(1024px, $susy, ".no-mq") {
            top: 13px;
        }

        >i {
            font-size: 18px;
            line-height: 19px;
            margin-left: 5px;
            cursor: pointer;
            color: #b3b3b3;

            &.fa-angle-down {
                font-size: 14px;
                right: 5px;
                line-height: 14px;
                position: relative;
            }
        }

        .icons__active {
            width: 23px;
            height: 23px;
            font-size: 12px;
            display: none;
            border-radius: 50%;
            text-align: center;
            padding: 0;
            line-height: 23px;
            color: #fff;
            position: relative;
            top: -4px;
            background: #fe6e6e;
            margin: 0 5px 0 0;

            &.icon--ok {
                background: #5ee2a0;
            }

            .icon {
                margin: 0;
            }
        }
    }

    &.active {
        background: #f3f6f9;

        .fa-angle-down {
            display: none;
        }

        .icons__active {
            display: inline-block;
        }
    }

    &.last--visited {
        background: #feeed8;
    }
}

//filter overlay
.filter-overlay {
    background-color: #415a68;
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0.4;
    top: 0;
    z-index: map-get($zindex, filter);
}

//filter tree
.tree-breadcrumbs {
    border-bottom: 1px solid #ebebeb;
    padding: 12px;

    .breadcrumbs_list {
        li {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            color: $clr-pri;

            &:first-child {
                &:before {
                    display: none;
                }
            }

            &:before {
                content: "\f105";
                font-family: "Font Awesome 5 Pro";
                font-size: 12px;
                position: relative;
                font-weight: 500;
                display: inline-block;
                margin: 0 10px;
            }

            &.active {
                a {
                    color: #666;
                }
            }

            a {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.tree-list {
    width: 100%;
    float: left;
    overflow-x: auto;

    @include susy-breakpoint($md, $susy, ".no-mq") {
        width: 100%;
    }
}

.tree-list_item {
    display: table-cell;
    width: 240px;
    min-width: 240px;
    vertical-align: top;
    @include transition();
    border-right: 1px solid #ebebeb;

    @include susy-breakpoint($md, $susy, ".no-mq") {
        width: 100%;
        display: none;
    }

    .tree-list_straight & {
        width: auto;
        min-width: auto;
        border-right: none;
        display: block;
    }

    ul {
        li {
            display: block;

            a {
                position: relative;

                &:before {
                    content: "\f105";
                    font-family: "Font Awesome 5 Pro";
                    position: absolute;
                    right: 10px;
                    color: #b8b8b8;
                    font-size: 15px;
                    top: 50%;
                    margin: -7px 0 0 0;
                }

                &:hover {
                    color: #666666;

                    &:before {
                        color: #666666;
                    }
                }
            }

            &.active {
                a {
                    color: #666666;
                    background: #f3f6f9;

                    &:before {
                        color: #666666;
                    }
                }
            }

            &.no--child {
                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

//
.date-calendar {
    float: right;
    width: 70%;

    @include susy-breakpoint($sm, $susy, ".no-mq") {
        width: 100%;
    }
}

.date-filters {
    float: left;
    width: 30%;
    border-right: 1px solid #e5e5e5;
    padding: 20px 0;

    ul {
        display: block;
        width: 100%;
    }

    li {
        display: block;
        text-transform: uppercase;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        padding: 10px 15px;
        color: #b3b3b3;

        :lang(fr) {
            text-transform: none;
        }

        a {
            color: #b3b3b3;

            &:hover {
                color: #666666;
            }
        }

        &.active {
            background: #f3f6f9;

            a {
                color: #666666;
            }
        }
    }
}

.calendar-dropdown {
    border: 1px solid #d6dade;
    @include border-radius(5px);
    padding: 8px 10px;
    min-width: 240px;
    float: left;
    background: $white;
    position: relative;

    .filter__label {
        color: #969dab;

        span {
            opacity: 0.5;
        }
    }

    .icon-calendar {
        color: $clr-pri;
    }

    .filter__icons {
        position: absolute;
        right: 12px;
        top: 8px;

        .icon {
            cursor: pointer;
        }
    }
}

.date__calendar {
    float: right;
    padding: 20px;
    width: 70%;

    .ui-datepicker {
        border: 0;
    }

    .ui-calendar {
        display: block;
    }
}

.filter__list {
    float: left;
    width: 100%;

    .tree-list_item & {
        border-top: 1px solid #ebebeb;
    }

    .tree-list_straight & {
        border-top: none;
    }

    ul {
        li {
            display: block;

            a {
                display: block;
                padding: 12px 15px;
                font-size: 13px;
                border-bottom: 1px solid #ebebeb;
                position: relative;
                color: #b3b3b3;
                cursor: pointer;
                font-weight: 500;
                word-break: keep-all;
                word-break: break-word;

                &:hover {
                    color: #666666;
                    background: none;
                }
            }

            &.active {
                a {
                    color: #666666;
                    background: #f3f6f9;
                }
            }
        }
    }
}

.checkbox-top-label {
    display: block;
    font-size: 12px;
    padding: 0 0 5px 0;
    color: $clr-pri;
}

.filter__list-heading {
    font-size: 13px;
    font-weight: bold;
    padding: 12px 15px;
    color: #888;
}

.filter__list-topbar {
    font-size: 13px;
    font-weight: 500;
    padding: 12px 15px;
    color: #888;

    .select-deselect {
        float: right;
    }

    a {
        color: #b3b3b3;

        &:hover {
            color: #666;
        }
    }
}

.tooltip-multiOrg {
    background: white;
    position: absolute;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    top: 32px;
    padding: 1em;
    z-index: 1004;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
    text-align: left;
}