//page-dynamic topic search

.ds__filters {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  align-items: baseline;
  .filter-item {
    padding: 15px 20px;
    flex: 1 1 0;
    border-left: 1px solid #ebebeb;

    &:first-child {
      border-left: 0;
    }

    .item__label {
      font-size: 12px;
      color: #969dab;
      margin-right:50px;
    }

  }
}

.dynamic-topic-search {
  @include container();

}

.ds-drag-column {
  @include span(3 no-gutters);
  border-right: 1px solid #ebebeb;
  min-height:400px;
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12 no-gutters);
    min-height: auto;
  }
  .drag__list {
    .list__item {
      a {
        display: block;
        font-size: 12px;
        padding: 12px 15px;
        text-transform: capitalize;
        color: #34495e;
        border-bottom: 1px solid #ebebeb;
        text-decoration: none;
        position: relative;
        background: #f5f6f7;
        :lang(fr) {
          text-transform: none;
        }
        &:hover {
          cursor: move;
        }

        .action {
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          content: "\f105";
          color: #bdc2c6;
          font-size: 14px;
          right: 20px;
          cursor: pointer;
          @include susy-breakpoint(1024px, $susy, '.no-mq') {
            &:before {
              content: "\f107";
            }
          }
        }
      }

      &.active {
        > a {
          background: #edeef1;
          // color: $white;

          &:before {
            content: "\f107";
          }
        }
      }
      &.disabled {
        a {
          opacity: 0.5;
        }
      }
      ul {
        li {
          a {
            background: $white;
            padding: 9px 15px;

            &:before {
              &:hover {
                cursor: pointer;
              }
            }
            &:hover {
              background: $clr-pri;
              color: $white;
            }
            &.ui-state-disabled {
              &:hover {
                color: $black;
                opacity: 1;
              }
            }
            &.active {
              span {
                display: none;
              }
            }
          }

          &:first-child {
            a {
              border-radius: 0;
            }
          }
          &.selected {
            a {
              &:before {
                content: "\f00c";
                color: $clr-pri;
                font-size: 12px;
                right: 17px;
              }
              &:hover {
                background: none;
                cursor: default;
                color: inherit;
                &:before {
                }
              }
            }
          }
        }
      }
    }
  }
}

.ds-drop-column {
  @include span(9);
  position: relative;
  min-height:400px;
  padding: 25px;
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
    padding: 10px;
    min-height: auto;
  }
  .btn--danger {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -41px;
    text-align: center;
  }

  .drop-list {
    .list__item {
      float: left;
      margin: 0 10px 0 0;
      position: relative;
      font-size: 12px;
      @include transition();
      @include susy-breakpoint(1024px, $susy, '.no-mq') {
        margin-bottom: 10px;
      }
      a {
        color: #34495e;
        border: 1px solid #ebebeb;
        padding: 15px;
        border-radius: 3px;
        display: block;
        cursor: move;
        &.input-holder {
          padding: 14px 15px;
          input {
            min-width: 70px;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            border: none;
            text-align: center;
          }
        }
      }

      i {
        position: absolute;
        top: -7px;
        right: -7px;
        font-size: 10px;
        cursor: pointer;
        //opacity: 0;
        z-index: 1;
        background: #ef207d;
        @include transition();
        text-align: center;
        width: 18px;
        color: $white;
        height: 18px;
        line-height: 19px;
        border-radius: 100%;
      }
      &:hover {
        a {
          border-color: #b7b7b7;
        }
        i {
          //opacity: 1;
          //transform: scale(1.1);
        }
      }
    }
  }

  .drag-content {
    border: 2px dashed #ebebeb;
    padding: 20px;
    text-align: center;
    border-radius: 3px;
    clear: both;
    float: left;
    width: 100%;
    max-height: 500px;
    min-height: 90px;
    overflow-y: auto;
    &.cdk-drop-list-receiving, &.cdk-drop-list-dragging {
      border-color: darken(#ebebeb, 20%);
    }

    .drag__msg {
      padding: 30px;
      i {
        display: inline-block;
        font-size: 25px;
        color: #d5d7dd;
      }
    }

    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
body {
  > .list__item--drop {
    &.cdk-drag-preview {
      float: left;
      margin: 0 10px 0 0;
      position: relative;
      font-size: 12px;
      padding: 0;
      border-radius: 3px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      a {
        color: #34495e;
        border: 1px solid #b7b7b7;
        padding: 15px;
        border-radius: 3px;
        display: block;
        &.input-holder {
          padding: 14px 15px;
          input {
            min-width: 70px;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            border: none;
            text-align: center;
          }
        }
      }

      i {
        display: none;
      }
    }
  }
}
.ds-drop-column {
  .drag-content {
    > .list__item--drag {
      float: left;
      margin: 0 10px 0 0;
      position: relative;
      font-size: 12px;
      padding: 0;
      border-radius: 3px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      a {
        color: #34495e;
        border: 1px solid #b7b7b7;
        padding: 15px;
        border-radius: 3px;
        display: block;
        &.input-holder {
          padding: 14px 15px;
          input {
            min-width: 70px;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            border: none;
            text-align: center;
          }
        }
      }

      i {
        display: none;
      }
    }
  }
}

body {
  > .list__item--drag {
    &.cdk-drag-preview {
      padding: 0;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
      a {
        display: block;
        font-size: 12px;
        padding: 12px 15px;
        text-transform: capitalize;
        color: #34495e;
        border-bottom: 1px solid #ebebeb;
        text-decoration: none;
        position: relative;
        background: #f5f6f7;
        :lang(fr) {
          text-transform: none;
        }
        &:before, .action {
          display: none;
        }
      }
    }
  }
}


.tags-list{
  font-size: 13px;
  //margin-top:3px;
  li{
    display: inline-block;
    padding: 5px 8px;
    margin: 3px 5px 3px 0;
    border-radius:3px;
    background: $white;
    position: relative;
    border:1px solid #d6dade;

    span{
      color:#969dab;
      margin-right:3px;
      display: inline-block;
    }

    &.with-info {
      padding-right:35px;
    }

    .tooltip{
      position: absolute;
      top: 0;
      border-left:1px solid #d6dade;
      right: 0;
      padding: 5px 7px;
      color:#666;
      margin-right: 0;
      background: #e9ecef;
      bottom: 0;
    }
  }
}

.draggable-control {
  float: left;
  width: 100%;
  padding-top: 10px;

  .btn {
    margin-right: 5px;
  }
}

.search-result {
  float: left;
  width: 100%;
  min-height: 150px;
  position: relative;
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    min-height: auto;
  }
  h2 {
    font-size: 14px;
    color: #212229;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
    margin: 20px 0;
  }
  ul {
    max-height: 450px;
    overflow-y: auto;

    li {
      position: relative;
      margin: 0 10px 10px 0;
      float: left;
    
      a {
        cursor: default;
        display: block;
        font-size: 14px;
        padding: 0 0 0 15px;
        line-height: 34px;
        color: #000;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background: #fff;
        text-align: left;

        &:hover {
          //   cursor: copy;
        }
      }

      &.positive {
        a {
          background: #d5e8d4;
          border-color: #82b366;
        }

      }

      &.negative {
        a {
          background: #f8cecc;
          border-color: #b85450;
        }

      }

      &.neutral {
        a {
          background: #fff2cc;
          border-color: #d6b656;
        }

      }

    }
  }
  //.nodata {
  //  position: relative;
  //  left: auto;
  //  top: auto;
  //  font-size: 1rem;
  //  width: 100%;
  //  margin: 0px;
  //}
}

.pills-container {
  margin-left: 15px;
  font-size: 0;
  float: right;
  height: 36px;
  cursor: pointer;
}

.pills {
  padding: 0 5px;
  text-align: center;
  min-width: 30px;
  display: inline-block;

  font-size: 12px;

  &.unassigned {
    background: #f5f5f5;
  }

  &.positive {
    background: #d5e8d4;
    border-color: #82b366;
  }

  &.negative {
    background: #f8cecc;
    border-color: #b85450;

  }

  &.neutral {
    background: #fff2cc;
    border-color: #d6b656;

  }
}