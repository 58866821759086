.campaign-config {
  font-size: 13px;
  .row {
    clear: both;
    padding: 10px 0;
    @include clearfix();
  }
  .col-full {
    float: left;
    width: 100%;
    padding: 0 10px;
  }
  .col-half {
    float: left;
    width: 50%;
    padding: 0 10px;
    .col-full {
      padding: 5px 0;
    }
  }
  .col-oneForth {
    float: left;
    width: 25%;
    padding: 0 10px;
  }
  .col-oneThird {
    float: left;
    width: 33.33%;
    padding: 0 10px;
  }
  .col-twoThird {
    float: left;
    width: 66.66%;
    padding: 0 10px;
  }
  .item__content {
    .col {
      float: left;
      width: 25%;
      padding: 0 10px;
      vertical-align: middle;

      .col {
        width: 50%;
      }
      .date-field {
        input[type="date"],
        input[type="text"] {
          padding-top: 6px;
          padding-bottom: 6px;
          width: 100%;
        }
        span {
          padding: 0 8px 0 6px;
        }
      }
      .full-field {
        width: 100%;
        input {
          width: 100%;
        }
      }
      .custom-checkbox {
        padding: 5px 0 0 0;
      }
    }
    .input__container {
      padding: 0 5px;
      input[type="text"] {
        max-width: 80px;
      }
    }
    &.survey--flow {
      .row {
        padding: 20px 0;
        border-top: 1px solid #ebebeb;
        &:first-child {
          border-top: none;
        }
      }
      .date-field,
      .time-field {
        float: none;
        input[type="text"],
        input[type="date"] {
          max-width: 110px;
        }
      }
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 20px 0;
  }
  h3 {
    font-size: 14px;
    //font-weight: 600;
    &.blue {
      color: $clr-pri;
    }
    &.green {
      color: #0f9d46;
    }
    &.red {
      color: #fe596b;
    }
  }
  p {
    padding: 10px 0;
  }
  .field__label {
    display: block;
    font-weight: 600;
    padding: 0 0 5px 0;
  }
  .field__info {
    display: block;
    color: #b1b9c1;
    padding: 3px 0 0 0;
  }
  .actions {
    padding-top: 6px;
    a {
      color: $clr-pri;
      margin: 0 5px;
      &:hover {
        color: #4d565f;
      }
    }
    i {
      font-size: 16px;
      line-height: 100%;
      vertical-align: text-top;
    }
  }
  .toggle-options {
    padding:0;
    .toggle {
      display: inline-block;
      padding:3px 0;
      position: relative;
      //top: 3px;
    }
  }
}

.campaign-widget {
  padding: 10px 0 20px 40px;
  line-height: 1.5em;
  font-size: 13px;
  @include clearfix();
  .col-left {
    float: left;
    width: 40%;
  }
  .col-right {
    float: left;
    width: 55%;
  }
  .row {
    padding: 3px 0;
  }
  strong {
    font-weight: 600;
  }
  .multiple {
    display: inline-block;
    input[type="text"] {
      margin: 0 0 0 10px;
      width: 45px;
      padding: 7px 10px;
    }
  }
  .date-field {
    float: none;
    display: inline-block;
    margin: 0 5px;
  }
  .col {
    float: left;
    padding: 0 20px 0 0;
    input[type="text"] {
      margin: 5px 0;
    }
  }
  &.campaign-protection {
    .row {
      padding: 5px 0;
    }
  }
}

.campaign-config-old {
  margin: 0 30px;
  font-size: 13px;
  .campaign__item {
    clear: both;
    padding: 30px 0;
    border-top: 1px solid #ebebeb;
    @include clearfix();
    &:first-child {
      border-top: none;
    }
  }
  .row {
    clear: both;
    padding: 10px 0;
    @include clearfix();
  }
  .col-full {
    float: left;
    width: 100%;
    padding: 0 10px;
  }
  .col-left {
    float: left;
    width: 20%;
    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
      color: #4d565f;
      text-align: center;
      padding: 10px 0 20px 0;
      i {
        font-size: 40px;
        color: $clr-pri;
        margin: 0 0 10px 0;
      }
    }
  }
  .col-right {
    float: right;
    width: 80%;
    .col {
      float: left;
      width: 43.5%;
      max-width: 400px;
      padding: 0 10px;
      vertical-align: middle;
      > input[type="text"] {
        width: 100%;
      }
      .date-field {
        input[type="date"] {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        span {
          padding: 0 8px 0 6px;
        }
      }
      .full-field {
        width: 100%;
        input {
          width: 100%;
        }
      }
      .custom-checkbox {
        padding: 5px 0 0 0;
      }
    }
    .input__container {
      padding: 0 10px;
      input[type="text"] {
        max-width: 80px;
      }
    }
    &.sub--section {
      .row {
        border-top: 1px solid #ebebeb;
        padding: 20px 0 30px;
        .col-full {
          padding: 5px 0;
        }
        &:first-child {
          border-top: none;
        }
      }
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 20px 0;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    &.blue {
      color: $clr-pri;
    }
    &.green {
      color: #0f9d46;
    }
    &.red {
      color: #fe596b;
    }
  }
  p {
    padding: 10px 0;
  }
  .field__label {
    display: block;
    font-weight: 600;
    padding: 0 0 5px 0;
  }
  .field__info {
    display: block;
    color: #b1b9c1;
    padding: 3px 0 0 0;
  }
}
