@mixin vertical-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.tf-input {
  font-family: inherit;
  font-size: 14px;
  color: #292929;
  padding: 7px 5px;
  border-radius:0;
  background: white;
  border: 0;
  border-bottom: 1px solid #d0d7dd;
  width: 100%;
  max-width: 420px;

  &:focus {
    border-color: #a4a7aa;
  }
}

.tf-error-btn {
  min-width: 85px;
  padding: 12px 25px;
  background: $clr-pri;
  font-size: 15px;
  border-radius: 25px;
  background: rgb(247, 147, 30);
  background: linear-gradient(90deg, rgba(247, 147, 30, 1) 5%, rgba(241, 90, 36, 1) 60%);
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  transition: 0.3s;

  &:hover {
    background: rgb(241, 90, 36);
    color: white;
    background: linear-gradient(90deg, rgba(241, 90, 36, 1) 10%, rgba(247, 147, 30, 1) 70%);
  }



  &.red {
    background: #dc3545;
    background: transparent linear-gradient(90deg, #F66969 0%, #F12424 100%) 0% 0% no-repeat padding-box;

    &:hover {
      background: transparent linear-gradient(90deg, #F12424 0%, #F66969 100%) 0% 0% no-repeat padding-box;

    }
  }
}

.tf-black-error-btn {
  min-width: 85px;
  padding: 12px 25px;
  background: #292929;
  color: #fff;
  font-size: 15px;
  border-radius: 25px;
  line-height: 1.2;
  transition: 0.3s;
  background: rgb(247, 147, 30);
  background: transparent linear-gradient(90deg, #A2A2A2 0%, #292929 100%) 0% 0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(90deg, #292929 0%, #A2A2A2 100%) 0% 0% no-repeat padding-box;

  }
}

.twofactor-wrapper {
  padding: 25px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  background: white;
  //align-items: center;
  flex-wrap: wrap;
  font-size: 12px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 15px;
  }

  .column__figure {
    width: 50%;
    background: #f5f5f5;
    text-align: center;
    height: calc(100vh - 50px);
    padding: 50px 0 100px;
    position: relative;
    border: 15px solid #F0F0F0;



    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }


    h2 {
      font-size: 30px;
      //position: absolute;
      line-height: 36px;
      color: #d3d4d6;
    }

    p {
      font-size: 18px;
      position: absolute;
      line-height: 30px;
      left: 50%;
      bottom: 30px;
      margin-left: -97px;
      color: #d3d4d6;
    }

    .figure__icon {
      @include vertical-align();

      @media only screen and (max-width: 1024px) {
        position: static;
        transform: translate(0);
      }
    }
  }


  .column__content {
    width: 50%;
    padding: 20px 20px 0 50px;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding: 30px 0 0;
    }

  }


  .tf-logo {
    max-width: 120px;
  }


  h3 {
    color: #999999;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin: 20px 0 15px 0;

  }

  h2 {
    font-size: 18px;
    margin: 0 0 30px 0;
  }

  p {
    margin: 0 0 15px 0;
  }

  ol {
    padding: 0;
  }

  li {
    margin: 0 0 15px 0;
  }
}

.options-list {
  li {
    padding-left: 30px;
    position: relative;

    &:after {
      content: attr(data-listnumber);
      position: absolute;
      left: 0;
      top: 0;
      font-size: 21px;
    }
  }
}

.black-highlight {
  background: #333;
  color: #fff;
  padding: 3px 5px;
  border-radius: 4px;
}

.tf-red-highlight {
  background: #fbe6e8;
  color: #af0818;
  padding: 3px 5px;
  border-radius: 4px;
  margin: 0 2px;
}

.orange-highlight {
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 10px;
  background: #feeee8;
  border-radius: 5px;
}

.yellow-highlight {
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 10px 20px 10px 90px;
  background: #FFFFDB;
  border-radius: 15px;
  position: relative;

  p {
    margin: 5px 0;
  }

  .icon {
    position: absolute;
    left: 18px;
    color: #C81720;
    top: 15px;
  }
}

.qrcode-holder {
  margin: 0 0 15px 0;
}


.tf-recovery-code-holder {
  margin: 0 0 20px 0;

  p {
    margin: 0 0 10px 0;
  }
}



.tf-form-field{
  margin-bottom: 10px;

  .tf-label{
    text-transform: uppercase;
  }

}