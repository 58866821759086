.widget--trendingtopic {
  .view-more {
    float: right;
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    text-decoration: underline;
    position: relative;
    top: 2px;
  }

  .tags {
    float: left;
    width: 100%;
    margin-top: 15px;
  }

}

.tags {
  .tags__item {
    display: inline-block;
    font-size: 22px;
    margin-right: 7px;
    margin-bottom: 5px;
    .tags__count {
      font-size: 16px;
      color: #a5adb5;
    }


    &:hover{

    }

  }

  &.tags--smaller {
    .tags__item {
      font-size:15px;
      .tags__count {
        font-size: 12px;
      }

    }
  }
}

.tags-section {
  float: left;
  width: 100%;
  padding: 15px 0;
  .section-title {
    float: left;
    color: #4c5e70;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    :lang(fr) {
      text-transform: none;
    }
  }
  border-bottom: 1px solid #d6dbdf;

  &:last-child {
    border: 0;
  }
}

.search-field--trending-topics {
  clear: both;
  width: 100%;
  position: relative;
  z-index: 1;

  input {
    border: 1px solid #d6dade;
    box-shadow: none;
    color: #969dab;
    min-width: 250px;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    border-radius: 3px;
    padding: 8px 40px 8px 10px;
    &:focus {
      -webkit-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(119, 119, 119, 0.25);
    }
  }
  .btn--search {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 4px 10px;
    width: 34px;
    text-align: center;
  }
  .btn--close {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 4px 10px;
    width: 34px;
    text-align: center;
  }
}


