.main-content {
  float: left;
  clear: both;
  width: 100%;
  position: relative;
  margin: 70px 0 0 0;
  padding: 0 0 0 65px;
  @include transition(margin, 0.3s, ease-in-out);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    padding-left: 0;
    margin-top: 55px;
  }
}

/*.row {
  @include clearfix();
  margin-left: 0 - gutter();
  margin-right: 0 - gutter();
}*/

//page
[class*="page-"],
[class*=" page-"] {
  .page_header {
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;

    .admin--body & {
      margin: 0 0 15px 0;
    }

    &.no--margin {
      margin: 0 0 5px 0;
    }

    .page_header_item {
      //flex: 1 50%;
      flex: 1 1 auto;

      @include susy-breakpoint(1024px, $susy, ".no-mq") {
        flex: 1 100%;
      }
    }

    .page-breadcrumbs {
      flex: 1 100%;
    }

    .header-actions {
      text-align: right;

      @include susy-breakpoint(1024px, $susy, ".no-mq") {
        text-align: left;
      }
    }

    .popover {
      top: 35px;
      margin: 0;
    }
  }

  .page__title {
    font-size: 21px;
    line-height: 34px;
    font-weight: 500;
    color: #4d565f;
    float: left;

    @include susy-breakpoint(480px, $susy, ".no-mq") {
      line-height: 24px;
      font-size: 20px;
      margin: 5px 0 0;
    }
  }

  .main-breadcrumbs {}

  .page_inner {
    padding: 8px 10px 10px;
    float: left;
    width: 100%;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      padding: 5px 0 0;
    }
  }
}

.header-actions {
  font-size: 0;

  .breadcrumbs {
    line-height: 34px;

    @include susy-breakpoint(480px, $susy, ".no-mq") {
      line-height: normal;
      padding-top: 5px;
    }
  }

  .action__item {
    display: inline-block;
    margin: 0 0 0 10px;
    vertical-align: top;

    &.item--nospace {
      margin: 0;
    }
  }
}

.layout-icons {
  a {
    margin: 0 0 0 5px;

    //  color: #afbbc1;
    &:hover,
    &.active {
      color: $clr-pri;
    }
  }
}

.survey_core {
  color: #34495e;
  text-transform: uppercase;
  border-radius: 3px;
  background: #f6f7f8;
  padding: 3px;
  font-size: 10px;
  margin-left: 10px;
  border: 1px solid #a9b4bd;
  word-break: break-word;

  :lang(fr) {
    text-transform: none;
  }
}

.count-icon {
  padding: 0 0 0 4px;

  i {
    font-size: 16px;
    color: $clr-pri;
    vertical-align: middle;
  }
}

// temp style
appc-layout-xl {
  display: block;
  clear: both;
}

.googlemap-print {
  display: none;
}

.selected-filter {
  width: 100%;
  padding: 8px 10px 0px 10px;
  display: inline-block;
  position: relative;
}



.selected-filter-dropdown {
  position: absolute;
  background: $white;
  width: 400px;
  box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.21);
  top: 53px;
  text-align: left;
  right: 0;
  min-height: 200px;
  z-index: map-get($zindex, dropdown);
  opacity: 0;
  visibility: hidden;
  display: none;
  transform: translateY(30px);
  @include transition();
  @include clearfix();
  padding: 10px;

  h2 {
    color: #6f6f6f;
    font-size: 11px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    display: block;
    transform: translateY(0);
    z-index: 100;
  }

  @include susy-breakpoint($md, $susy, ".no-mq") {
    width: 100%;
    max-width: 100%;
    top: 44px;
  }


  .selected-filter-list {
    // display: flex;
    width: 100%;

    li {
      display: flex;
      justify-content: space-around;
      font-size: 12px;
    }

    .list-label {
      color: #949ba9;
      padding: 5px;
      border-bottom: 1px solid #f5f7f9;
      width: 20%;
    }

    .list-detail {
      border-bottom: 1px solid #f5f7f9;
      padding: 5px;
      width: 80%;
    }
  }


}


quill-editor {
  display: block !important;
}


.chip-container-inline {
  display: flex;
  gap: 5px;
  // align-items: center;
  // justify-content: center;

  &.list--view {
    align-items: center;
    justify-content: center;
  }
}


.page-action-review {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;

  i {
    margin-right: 5px;
  }

  .view-public {
    //font-size: 14px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}


.action-container{
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap: 10px;

  .testimonial__header{
    margin: 0;
  }
}


.top-section{
  display: flex;
  justify-content:space-between;
  align-items: center;
  gap: 10px;

  .comments-sentiment{
    padding: 0;
  }
}

.content__top{
  display: flex;
  justify-content:space-between;
 // align-items: center;
  .testimonial__header{
    margin: 0;
  }
}