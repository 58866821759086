.dashboard {
  margin: 0 0 10px 0;
  h2 {
    font-size: 24px;
    font-weight: 300;
  }
  .contact-list-block .administration-block {
    border: none;
    margin: 0;
  }
  &.reports--dashboard {
    .card {
      .card-header {
        border-bottom: none;
      }
    }
  }
}
.card {
  min-height: 50px;
  border-radius: 3px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background: $white;
  position: relative;
  .card-header {
    padding: 15px 25px 15px 20px;
    border-bottom: 2px solid #f5f5f5;
    h3 {
      font-size: 15px;
    }
  }
  .card-chart {
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .card-table {
    .col {
      padding: 0;
      &:first-child {
        border-right: 1px solid #ebebeb;
      }
    }
  }
  &.card--drop {
    opacity: 0.5;
    box-shadow: none;
    background: transparent;
    border: 2px dashed #34495e;
    .drop-wrapper {
      display: table;
      width: 100%;
      height: 100%;
      .drop-content {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 15px;
        color: #34495e;
        font-size: 12px;
        h4 {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    &.active {
      opacity: 0.8;
    }
  }
  &.card--plain {
    box-shadow: none;
    background: #cee3ef;
  }
  &.card--successful {
    background: #e1efd5;
    .notification-card {
      .icon {
        background: #88be58;
      }
    }
  }
  &.card--failed {
    background: #fbe0e8;
    .notification-card {
      .icon {
        background: #ec638d;
      }
    }
  }
}
.language-card {
  padding: 20px 20px 25px 95px;
  position: relative;
  .icon {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    background: $clr-pri;
    color: $white;
    box-shadow: 0px 10px 15px 0px rgba(115, 115, 115, 0.3);
    i {
      font-size: 20px;
      line-height: 60px;
    }
  }
  .details {
    color: #bdc2c6;
    font-size: 13px;
    h3 {
      color: #4d565f;
      font-size: 24px;
      font-weight: 600;
    }
  }
  &.dark-purple {
    .icon {
      background: #724a9f;
    }
  }
  &.orange {
    .icon {
      background: #ff994f;
    }
  }
  &.pink {
    .icon {
      background: #ec638d;
    }
  }
  &.tan {
    .icon {
      background: #73c1b3;
    }
  }
  &.lightpurple {
    .icon {
      background: #bc5dfe;
    }
  }
}
.dashboard-filters {
  @include clearfix();
  position: relative;
  z-index: 4;
  .widget & {
    z-index: auto;
  }
  h4 {
    float: left;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #34495e;
    padding: 15px;
    i {
      font-size:16px;
      line-height:16px;
      color: #cdd4d8;
      position: relative;
      top:2px;
      margin: 0 5px 0 0;
    }
  }
  .actions {
    float: left;
    clear: both;
    width: 100%;
    padding: 15px 15px 5px;

    background: #edeef1;
    .action-block {
      display: inline-block;
      padding: 0 5px 0 0;
      label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        padding: 0 0 3px 5px;
      }
    }
    input {
      float: left;
      margin: 0 10px 0 0;
    }
    .btn-group {
      float: left;
      margin: 0 10px 0 0;
      .btn {
        float: none;
        margin: 0;
        .caret {
          &:before {
            margin-right: -9px;
          }
        }
      }
    }
    .btn {
      margin-right: 10px;
      position: static;
      margin-bottom: 10px;
    }
    .dropdown-menu {
      font-size: 12px !important;
      max-width: 100%;
      overflow-x: hidden;
    }
    .col {
      float: left;
      label {
        display: block;
      }
    }
  }
}

ul.widgets-list {
  clear: both;
  li {
    position: relative;
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 125%;
    font-weight: 300;
    background: $white;
    border-radius: 3px;
    box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);
    span {
      position: absolute;
      left: 7px;
      top: 50%;
      font-size: 12px;
      margin: -15px 0 0 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      color: $clr-pri;
      border: 1px solid $clr-pri;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      font-weight: bold;
      letter-spacing: 1px;
    }
    .item {
      display: block;
      padding: 13px 60px 14px 47px;
      border-radius: 5px;
      color: #34495e;
      position: relative;
      cursor: pointer;
      .actions {
        position: absolute;
        right: 10px;
        top: 50%;
        margin: -8px 0 0 0;
        i {
          color: #ebebeb;
          font-size:14px;
          line-height:14px;
          padding: 0 5px;
        }
      }
      .image-tooltip-wrapper {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: 15;
        left: 100%;
        top: 50%;
        margin: -80px 0 0 0;
        cursor: default;
        transition: all 0.2s ease-in-out;
        .image-tooltip {
          position: fixed;
          width: 170px;
          height: 160px;
          padding: 0 0 0 10px;
          border-radius: 5px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: -6px;
            margin-top: -6px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent $white transparent transparent;
          }
          img {
            vertical-align: middle;
           // max-height: 60px;
            background: $white;
            border-radius: 5px;
            box-shadow: 0px 0px 23.49px 5.51px rgba(146, 146, 146, 0.3);
          }
          &:after {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }
        }
      }
      &:hover,
      &.selected {
        color: $white;
        background: $clr-pri;
        span {
          color: $clr-pri;
          background: $white;
        }
        .actions {
          i {
            color: $white;
          }
        }
      }
      &:hover {
        .image-tooltip-wrapper {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &:first-child {
      .item {
        .image-tooltip-wrapper {
          top: 100%;
          margin: -55px 0 0 0;
          .image-tooltip {
            &:before {
              top: 19%;
            }
          }
        }
      }
    }
    &:last-child {
      .item {
        .image-tooltip-wrapper {
          top: 100%;
          margin: -150px 0 0 0;
          .image-tooltip {
            &:before {
              top: 79%;
            }
          }
        }
      }
    }
  }
}
.sub-nav-wrapper {
  .search-field {
    width: 100%;
    margin: 0 0 10px 0;
    &:before {
      top: 10px;
    }
    input {
      width: 100%;
      padding-top: 9px;
      padding-bottom: 10px;
    }
  }
}
.pagination-holder {
  border-top: 1px solid #e4e9ed;
  padding: 10px 20px 5px;
}



.table-info{
  float: left;
  width: 100%;
  margin-top:10px;
  p{
    margin:15px 0;
    line-height: 18px;
  }
}

.drop-area{
  float:left;
  width: 100%;
  height: 100%;
}