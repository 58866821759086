.ui-table {
  clear: both;
  font-size: 12px;
  .small-td-padding & {
    padding-top: 0.75em;
  }
  .ui-table-caption,
  .ui-table-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .ui-table-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .ui-table-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .ui-table-thead > tr > th, .ui-table-thead > tr > td {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    background-color: $tableHeaderCellBgColor;
    text-transform: uppercase;
    text-align: left;
    border-width: 1px 0;
    :lang(fr) {
      text-transform: none;
    }
  }

  .ui-table-tbody > tr > td {
    padding: $tableBodyCellPadding;
    .small-td-padding & {
      padding: 0.75em;
      word-break: break-word;
    }
  }

  .ui-table-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .ui-sortable-column {
    .ui-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.ui-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .ui-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.ui-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .ui-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .ui-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .ui-table-tbody {
    > tr {
      background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;
      &.gray-bar {
        background-color: #f4f5f9 !important;
      }

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        &.nw {
          white-space: normal;
        }
        .source-icon {
          vertical-align: bottom;
          max-width: 12px;
          margin-right: 4px;
        
        }
      }

      &.ui-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        a {
          color: $highlightColorText;
        }
      }

      &.ui-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;
      }

      &.ui-table-dragpoint-bottom > td {
        @include shadow(inset 0 -2px 0 0 $highlightBgColor);
      }
    }

    > tr:nth-child(odd) {
      background-color: $tableBodyRowEvenBgColor;

      &.ui-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        a {
          color: $highlightColorText;
        }
      }

      &.ui-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;
      }
    }
  }
  .ui-table-bordered & {
    text-align: center;
    .ui-table-thead > tr > th, .ui-table-thead > tr > td {
      border-width: 1px;
      text-align: center;
    }
    .ui-table-tbody {
      > tr {
        > td {
          border-width: 1px;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
  .ui-table-plain-bg & {
    .ui-table-tbody {
      > tr:nth-child(odd) {
        background-color: $white;
      }
    }
  }
  .ui-table-dealer-competitors & {
    .ui-table-thead > tr > th, .ui-table-tbody > tr > td {
      &:first-child {
        border-right: 0 !important;
      }
      &.product-title {
        border-left: 0 !important;
      }
    }
  }
  &.ui-table-hoverable-rows {
    .ui-table-tbody
      > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
      cursor: pointer;
     // background-color: $tableBodyRowHoverBgColor;
      background: #ddeafa;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .ui-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
  .actions {
    margin: -6px 0 0 0;
    min-width: auto;
    max-width: auto;
    cursor: pointer;
    i {
      font-size: 14px;
      line-height: 30px;
      &.icon-big {
        font-size: 18px;
      }
    }
    a {
      display: inline-block;
      margin: 0 10px 0 0;
      color: #cdd4d8;
      width: 32px;
      height: 32px;
      border: 1px solid #cdd4d8;
      border-radius: 5px;
      text-align: center;
      &:hover {
        color: $clr-pri;
        border-color: $clr-pri;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: auto;
        .tooltiptext {
          display: none;
        }
        &:hover {
          color: #cdd4d8;
          border-color: #cdd4d8;
        }
      }
    }
  }
  &.nostripes {
    .ui-table-tbody {
      > tr, > tr:nth-child(odd) {
        background-color: $tableBodyRowEvenBgColor;
      }
      > tr:not(.bar) {
        background-color: $tableBodyRowBgColor;
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .ui-table {
    &.ui-table-responsive {
      .ui-paginator-top {
        border-bottom: $paginatorBorder;
      }

      .ui-paginator-bottom {
        border-top: $paginatorBorder;
      }

      .ui-table-tbody > tr > td {
        border: 0 none;
      }
    }
  }
}

.ui-table {
  position: relative;
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  //.ui-table-thead > tr > th, .ui-table-tbody > tr > td, .ui-table-tfoot > tr > td {
  //  padding: .25em .5em;
  //}
  .ui-sortable-column {
    cursor: pointer;
  }
  p-sorticon {
    vertical-align: middle;
  }
}

.ui-table-auto-layout > .ui-table-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

/* Sections */

.ui-table-caption,
.ui-table-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.ui-table-caption {
  border-bottom: 0 none;
}

.ui-table-summary {
  border-top: 0 none;
}

/* Paginator */

.ui-table {
  .ui-paginator-top {
    border-bottom: 0 none;
  }
  .ui-paginator-bottom {
    border-top: 0 none;
  }
}

/* Scrollable */

.ui-table-scrollable-wrapper {
  position: relative;
}

.ui-table-scrollable-header,
.ui-table-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.ui-table-scrollable-body {
  overflow: auto;
  position: relative;
}

.ui-table-virtual-table {
  position: absolute;
}

/* Frozen Columns */

.ui-table-frozen-view {
  .ui-table-scrollable-body {
    overflow: hidden;
  }
  > .ui-table-scrollable-body > table > .ui-table-tbody > tr > td:last-child {
    border-right: 0 none;
  }
}

.ui-table-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */

.ui-table-resizable {
  > .ui-table-wrapper {
    overflow-x: auto;
  }
  .ui-table-thead > tr > th,
  .ui-table-tfoot > tr > td,
  .ui-table-tbody > tr > td {
    overflow: hidden;
  }
  .ui-resizable-column {
    background-clip: padding-box;
    position: relative;
  }
}

.ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
  display: none;
}

.ui-table {
  .ui-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5em;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }
  .ui-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
  .ui-table-tbody > tr > td.ui-editing-cell {
    padding: 0;
    p-celleditor > * {
      width: 100%;
    }
  }
}

/* Edit */

/* Reorder */

.ui-table-reorder-indicator-up,
.ui-table-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */

.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .ui-table-responsive {
    .ui-table-thead > tr > th,
    .ui-table-tfoot > tr > td {
      display: none !important;
    }
    .ui-table-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none;
      width: 100% !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left;
      .ui-column-title {
        padding: 0.4em;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4em;
        font-weight: bold;
      }
    }
  }
}

/* Loader */

.ui-table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  opacity: 0.1;
  z-index: 1;
}

.ui-table-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.ui-table .ui-table-loading-icon {
  font-size: 2em;
}

.accordion-table {
  .ui-table-tbody,
  .ui-treetable-tbody {
    > tr {
      background-color: $white;
      &.parent{
        background: #f4f5f9;
      }
      &.bar {
        > td:first-child {
          position: relative;
          &:before {
            content: "";
            width: 4px;
            height: 100%;
            background: $clr-pri;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        &.expanded {
          background: #ffffff;
          > td {
            &:first-child {
              &:before {
                background: #c2e3f7;
              }
            }
          }
        }
        &.child {
          td {
            &:first-child {
              &:before {
                background: #c2e3f7;
              }
            }
          }
        }
        .accordion {
          a {
            color: $tableBodyRowTextColor;
            i {
              color: $primaryColor;
              font-size: 14px;
              margin: 0 1em 0 0;
            }
          }
        }
      }
    }
  }
}

.comment-column__text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  margin:0 auto;
  white-space: nowrap;
  
}




.p-datatable {
  clear: both;
  font-size: 12px;
  .small-td-padding & {
    padding-top: 0.75em;
  }
  .p-datatable-caption,
  .p-datatable-summary {
    background-color: $panelHeaderBgColor;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    text-align: center;
  }

  .p-datatable-caption {
    border-bottom: 0 none;
    font-weight: $tableCaptionFontWeight;
  }

  .p-datatable-summary {
    border-top: 0 none;
    font-weight: $tableSummaryFontWeight;
  }

  .p-datatable-thead > tr > th, .p-datatable-thead > tr > td {
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    font-weight: $tableHeaderCellFontWeight;
    color: $tableHeaderCellTextColor;
    background-color: $tableHeaderCellBgColor;
    text-transform: uppercase;
    text-align: left;
    border-width: 1px 0;
    :lang(fr) {
      text-transform: none;
    }
  }

  .p-datatable-tbody > tr > td {
    padding: $tableBodyCellPadding;
    .small-td-padding & {
      padding: 0.75em;
      word-break: break-word;
    }
  }

  .p-datatable-tfoot > tr > td {
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background-color: $tableFooterCellBgColor;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $tableHeaderCellIconColor;
    }

    &:not(.p-state-highlight):hover {
      background-color: $tableHeaderCellHoverBgColor;
      color: $tableHeaderCellHoverTextColor;

      .p-sortable-column-icon {
        color: $tableHeaderCellHoverIconColor;
      }
    }

    &.p-state-highlight {
      background-color: $highlightBgColor;
      color: $highlightColorText;

      .p-sortable-column-icon {
        color: $highlightColorText;
      }
    }
  }

  .p-editable-column {
    input {
      font-size: $fontSize;
      font-family: $fontFamily;

      &:focus {
        outline: 1px solid $inputFocusBorderColor;
        outline-offset: 2px;
      }
    }
  }

  .p-datatable-tbody {
    > tr {
      background-color: $tableBodyRowBgColor;
      color: $tableBodyRowTextColor;
      outline: none;
      &.gray-bar {
        background-color: #f4f5f9 !important;
      }

      > td {
        background-color: inherit;
        border: $tableBodyCellBorder;
        border-width: 1px 0;
        &.nw {
          white-space: normal;
        }
        .source-icon {
          vertical-align: bottom;
          max-width: 12px;
          margin-right: 4px;
         
        }
      }

      &.p-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        a {
          color: $highlightColorText;
        }
      }

      &.p-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;
      }

      &.p-datatable-dragpoint-bottom > td {
        @include shadow(inset 0 -2px 0 0 $highlightBgColor);
      }
    }

    > tr:nth-child(odd) {
      background-color: $tableBodyRowEvenBgColor;

      &.p-state-highlight {
        background-color: $highlightBgColor;
        color: $highlightColorText;

        a {
          color: $highlightColorText;
        }
      }

      &.p-contextmenu-selected {
        background-color: $highlightBgColor;
        color: $highlightColorText;
      }
    }
  }
  .p-datatable-bordered & {
    text-align: center;
    .p-datatable-thead > tr > th, .p-datatable-thead > tr > td {
      border-width: 1px;
      text-align: center;
    }
    .p-datatable-tbody {
      > tr {
        > td {
          border-width: 1px;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
  .p-datatable-plain-bg & {
    .p-datatable-tbody {
      > tr:nth-child(odd) {
        background-color: $white;
      }
    }
  }
  .p-datatable-dealer-competitors & {
    .p-datatable-thead > tr > th, .p-datatable-tbody > tr > td {
      &:first-child {
        border-right: 0 !important;
      }
      &.product-title {
        border-left: 0 !important;
      }
    }
  }
  &.p-datatable-hoverable-rows {
    .p-datatable-tbody
      > tr.p-selectable-row:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
      cursor: pointer;
     // background-color: $tableBodyRowHoverBgColor;
      background: #ddeafa;
      color: $tableBodyRowHoverTextColor;
    }
  }

  .p-column-resizer-helper {
    background-color: $tableResizerHelperBgColor;
  }
  .actions {
    margin: -6px 0 0 0;
    min-width: auto;
    max-width: auto;
    cursor: pointer;
    i {
      font-size: 14px;
      line-height: 30px;
      &.icon-big {
        font-size: 18px;
      }
    }
    a {
      display: inline-block;
      margin: 0 10px 0 0;
      color: #cdd4d8;
      width: 32px;
      height: 32px;
      border: 1px solid #cdd4d8;
      border-radius: 5px;
      text-align: center;
      &:hover {
        color: $clr-pri;
        border-color: $clr-pri;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: auto;
        .tooltiptext {
          display: none;
        }
        &:hover {
          color: #cdd4d8;
          border-color: #cdd4d8;
        }
      }
    }
  }
  &.nostripes {
    .p-datatable-tbody {
      > tr, > tr:nth-child(odd) {
        background-color: $tableBodyRowEvenBgColor;
      }
      > tr:not(.bar) {
        background-color: $tableBodyRowBgColor;
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .p-datatable {
    &.p-datatable-responsive {
      .p-paginator-top {
        border-bottom: $paginatorBorder;
      }

      .p-paginator-bottom {
        border-top: $paginatorBorder;
      }

      .p-datatable-tbody > tr > td {
        border: 0 none;
      }
    }
  }
}

.p-datatable {
  position: relative;
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  //.p-datatable-thead > tr > th, .p-datatable-tbody > tr > td, .p-datatable-tfoot > tr > td {
  //  padding: .25em .5em;
  //}
  .p-sortable-column {
    cursor: pointer;
  }
  p-sorticon {
    vertical-align: middle;
  }
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: auto;
  > table {
    table-layout: auto;
  }
}

/* Sections */

.p-datatable-caption,
.p-datatable-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.p-datatable-caption {
  border-bottom: 0 none;
}

.p-datatable-summary {
  border-top: 0 none;
}

/* Paginator */

.p-datatable {
  .p-paginator-top {
    border-bottom: 0 none;
  }
  .p-paginator-bottom {
    border-top: 0 none;
  }
}

/* Scrollable */

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-datatable-scrollable-body {
  overflow: auto;
  position: relative;
}

.p-datatable-virtual-table {
  position: absolute;
}

/* Frozen Columns */

.p-datatable-frozen-view {
  .p-datatable-scrollable-body {
    overflow: hidden;
  }
  > .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td:last-child {
    border-right: 0 none;
  }
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */

.p-datatable-resizable {
  > .p-datatable-wrapper {
    overflow-x: auto;
  }
  .p-datatable-thead > tr > th,
  .p-datatable-tfoot > tr > td,
  .p-datatable-tbody > tr > td {
    overflow: hidden;
  }
  .p-resizable-column {
    background-clip: padding-box;
    position: relative;
  }
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable {
  .p-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5em;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }
  .p-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
  }
  .p-datatable-tbody > tr > td.p-editing-cell {
    padding: 0;
    p-celleditor > * {
      width: 100%;
    }
  }
}

/* Edit */

/* Reorder */

.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */

.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable-responsive {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }
    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none;
      width: 100% !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left;
      .p-column-title {
        padding: 0.4em;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4em;
        font-weight: bold;
      }
    }
  }
}

/* Loader */

.p-datatable-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(77,86,95,.15);
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2em;
}

.accordion-table {
  .p-datatable-tbody,
  .p-treetable-tbody {
    > tr {
      background-color: $white;
      &.parent{
        background: #f4f5f9;
      }
      &.bar {
        > td:first-child {
          position: relative;
          &:before {
            content: "";
            width: 4px;
            height: 100%;
            background: $clr-pri;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        &.expanded {
          background: #ffffff;
          > td {
            &:first-child {
              &:before {
                background: #c2e3f7;
              }
            }
          }
        }
        &.child {
          td {
            &:first-child {
              &:before {
                background: #c2e3f7;
              }
            }
          }
        }
        .accordion {
          a {
            color: $tableBodyRowTextColor;
            i {
              color: $primaryColor;
              font-size: 14px;
              margin: 0 1em 0 0;
            }
          }
        }
      }
    }
  }
}

.comment-column__text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
}