.container {
  @include container;
}

[class*="card-"] {
  &:not(.kpi__card) {
    margin-bottom: gutter();
    margin-top: gutter();
    @include susy-breakpoint($md, $susy, ".no-mq") {
      margin-bottom: gutter() - 5;
      margin-top: gutter() - 5;
    }
  }
}

.card-xsmall {
  @include span(3);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(6);
  }
}

.card-small {
  @include span(4);

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.card-medium {
  @include span(6);
  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.card-large {
  @include span(8);
  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
  }
}

.card-xl {
  @include span(12);
}
.card--nofloat {
  float: none;
  clear: both;
  margin: 0 0 20px 0;
}

.form-container {
 // @include container;
  @include clearfix();
  padding: 0 gutter() gutter() * 2;
  .p-accordion & {
    padding: 0;
  }
  .ui-dialog-content & {
    padding: 0;
    margin-right: 0 - gutter();
    margin-left: 0 - gutter();
  }

  .input-search{
    width: 100%;
  }
  .form-error{
    margin-top: 3px;
    color: $clr-red;
  }
}
.form-column {
  margin-bottom: gutter();
  margin-top: gutter();
  &.border-top-bottom {
    border: 1px solid #ebebeb;
    border-width: 1px 0;
    padding: 10px 0;
  }
  &:first-child {
    clear: both;
  }
}

.one.form-column,
.one.form-column                    { @include span(1); }
.two.form-column                    { @include span(2); }
.three.form-column                  { @include span(3); }
.four.form-column                   { @include span(4); }
.five.form-column                   { @include span(5); }
.six.form-column                    { @include span(6); }
.seven.form-column                  { @include span(7); }
.eight.form-column                  { @include span(8); }
.nine.form-column                   { @include span(9); }
.ten.form-column                    { @include span(10); }
.eleven.form-column                 { @include span(11); }
.twelve.form-column                 { @include span(12); }

.one-third.form-column              { @include span(4); }
.two-thirds.form-column             { @include span(8); }

.one-half.form-column               { @include span(6); }
.no-label.form-column               { padding-top: 19px; line-height: 34px; }

.form-label {
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 5px 0;
}
