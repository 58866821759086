$header-height: 70px;
$subnav-height: 41px;
$header-plus-subnav-height: $header-height + $subnav-height;
$leftnav-width: 65px;
$subnav-width: 280px;
.page-content {
  height: 100%;
  width: 100%;
  padding: 0 0 0 $leftnav-width;
  .sub-nav-wrapper {
    position: fixed;
    left: -345px;
    top: 0px;
    z-index: 5;
    width: $subnav-width;
    height: 100%;
    background: #edeef1;
    padding: $header-height 0 0 0;
    .sub-nav {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      overflow-y: auto;

      .input-search {
        display: block;
        margin-bottom: 10px;
      }
      h3 {
        padding: 15px 7px;
        font-size: 12px;
        font-weight: bold;
        color: #222d38;
        text-transform: uppercase;
        letter-spacing: 1px;
        :lang(fr) {
          text-transform: none;
        }
        i {
          font-size: 16px;
        }
        a {
          float: right;
          color: #8c8c8c;
          &:hover {
            color: #575757;
          }
        }
      }
      ul.questions-list {
        li {
          position: relative;
          margin: 0 0 15px 0;
          font-size: 13px;
          line-height: 125%;
          font-weight: 300;
          background: $white;
          border-radius: 5px;
          box-shadow: -2.517px 3.109px 6.48px 1.52px rgba(146, 146, 146, 0.11);
          span {
            position: absolute;
            left: 7px;
            top: 50%;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            line-height: 16px;
            padding: 4px 3px 3px 3px;
            margin: -13px 0 0 0;
            display: inline-block;
            width: 28px;
            height: 24px;
            color: $clr-pri;
            border: 1px solid $clr-pri;
            border-radius: 3px;
          }
          a,
          .question-text {
            display: block;
            padding: 13px 30px 14px 47px;
            border-radius: 3px;
            color: #34495e;
            position: relative;
            cursor: pointer;
            i {
              font-size: 14px;
              position: absolute;
              right: 14px;
              top: 14px;
              color: #d1dce2;
            }
            &:hover,
            &.active {
              color: $white;
              background: $clr-pri;
              span {
                color: $clr-pri;
                background: $white;
              }
              i {
                color: $white;
              }
              .icon-link {
                color: $white;
                i {
                  color: $white;
                }
              }
            }
            .icon-link {
              font-size: 16px;
              position: absolute;
              right: 10px;
              top: 14px;
              color: $clr-pri;
              padding: 0;
              i {
                right: 0;
                top: 0;
                color: $clr-pri;
              }
              &:hover,
              &.active {
                color: $white;
                i {
                  color: $white;
                }
              }
            }
          }
          &.ui-sortable-placeholder {
            border: 2px dashed #d1dce2;
            min-height: 34px;
            background: none;
            border-radius: 0;
            box-shadow: none;
          }
        }
        &.list--icon {
          li {
            span {
              border: none;
              i {
                font-size: 20px;
                line-height: 30px;
              }
            }
            a {
              &:hover {
                span {
                  background: none;
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
      ul.basic-list {
        li {
          position: relative;
          margin: 0 0 15px 0;
          font-size: 14px;
          line-height: 125%;
          font-weight: 300;
          background: $white;
          border-radius: 5px;
          box-shadow: -2.517px 3.109px 6.48px 1.52px rgba(146, 146, 146, 0.11);
          a {
            display: block;
            padding: 13px 40px 14px 10px;
            border-radius: 5px;
            color: #34495e;
            &:hover {
              color: $white;
              background: $clr-pri;
            }
          }
          &.active {
            a {
              position: relative;
              &:after {
                position: absolute;
                right: 16px;
                top: 50%;
                content: "check";
                font-family: "Material Icons";
                font-size: 16px;
                line-height: 16px;
                color: $clr-pri;
                margin: -8px 0 0 0;
              }
              &:hover {
                &:after {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    //Question Bank
    .questions-bank {
      margin: 0 0 15px 0;
      .question-block {
        background: $white;
        border-radius: 3px 3px 0 0;
        box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);
        h4 {
          position: relative;
          padding: 20px 15px 20px 60px;
          font-size: 14px;
          line-height: 125%;
          font-weight: 600;
          color: #34495e;
          span {
            position: absolute;
            left: 17px;
            top: 50%;
            font-size: 14px;
            margin: -17px 0 0 0;
            display: inline-block;
            width: 34px;
            height: 34px;
            color: $white;
            background: $clr-pri;
            text-align: center;
            line-height: 34px;
            border-radius: 3px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
        .question {
          padding: 0 0 10px 0;
          .category {
            position: relative;
            background: $white;
            color: #34495e;
            padding: 6px 10px 6px 17px;
            font-size: 12px;
            line-height: 125%;
            font-weight: normal;
            border-left: 2px solid $clr-pri;
            border-bottom: 1px solid $white;
            cursor: pointer;
            &:before {
              font-family: "Material Icons";
              content: "keyboard_arrow_down";
              font-size: 16px;
              line-height: 16px;
              position: absolute;
              right: 17px;
              top: 50%;
              margin: -8px 0 0 0;
              color: #bdc2c6;
            }
            + .question-option {
              border-top: none;
            }
            &.first--level {
              font-size: 14px;
              padding: 10px 10px 10px 17px;
              border-top: 1px solid #ebebeb;
              border-bottom: 1px solid #ebebeb;
            }
            &.second--level {
              background: #f4f5f9;
              border-left-color: #c1dcfb;
            }
            &.third--level {
              background: #ecedef;
              border-left-color: #dce5f1;
            }
            &.active {
              border-left-color: $clr-pri !important;
            }
            i {
              font-size: 16px;
              float: right;
              color: $clr-pri;
              margin: 0 30px 0 0;
            }
          }
          .question-option {
            color: #717f8e;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            border-top: 1px solid #e5e5e5;
            margin: 0 15px;
            padding: 8px 5px 8px 30px;
            position: relative;
            span {
              position: absolute;
              left: 6px;
              top: 8px;
              &:before {
                font-family: "Material Icons";
                content: "check";
                font-size: 16px;
                line-height: 16px;
                position: absolute;
                left: -20px;
                top: 50%;
                margin: -8px 0 0 0;
                color: $clr-pri;
                display: none;
              }
            }
            &.no-records {
              color: #34495e;
              border-top: none;
              padding: 14px 5px 14px 5px;
            }
            &.active {
              padding-left: 45px;
              span {
                left: 22px;
                &:before {
                  display: block;
                }
              }
            }
          }
        }

        .question-bank-list {
          .question-text {
            position: relative;
            background: #edeef1;
            color: #34495e;
            padding: 10px 10px 10px 17px;
            font-size: 12px;
            line-height: 125%;
            font-weight: normal;
            border-left: 2px solid #9cc7f8;
            border-bottom: 1px solid #dbdbdb;
            cursor: pointer;
            &:before {
              font-family: "Font Awesome 5 Pro" ;
              content: "\f107";
              font-size: 12px;
              line-height: 16px;
              position: absolute;
              right: 17px;
              top: 50%;
              margin: -8px 0 0 0;
              color: #bdc2c6;
            }
            i {
              font-size: 16px;
              float: right;
              color: $clr-pri;
              margin: -1px 30px -1px 0;
              position: relative;
            }
            &.no-records {
              color: #34495e;
              padding: 14px 10px 14px 17px;
              cursor: default;
              &:before {
                display: none;
              }
            }
          }
          .ui-sortable-placeholder {
            border: 2px dashed #d1dce2;
            min-height: 30px;
            background: none;
          }
          .first--level {
            .question-text {
            }
          }
          .second--level {
            //display: none;
            .question-text {
              background: #f5f6f7;
              border-left-color: #d3e5f8;
              padding: 6px 10px 6px 17px;
              border-bottom-color: #e3e4e5;
            }
          }
          .third--level {
            //display: none;
            .question-text {
              background: #fdfdfd;
              padding: 6px 10px 6px 17px;
              border-left-color: #e3effd;
            }
          }
          .fourth--level {
            //display: none;
            background: $white;
            border-bottom: 1px solid #dbdbdb;
            .question-text {
              background: $white;
              color: #717f8e;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              border-top: 1px solid #e5e5e5;
              border-bottom: none;
              margin: 0 15px;
              padding: 8px 5px 8px 30px;
              position: relative;
              border-left: none;
              cursor: move;
              span {
                position: absolute;
                left: 6px;
                top: 8px;
                &:before {
                  font-family: "Font Awesome 5 Pro" ;
                  content: "\f00c";
                  font-size: 12px;
                  line-height: 16px;
                  position: absolute;
                  left: -20px;
                  top: 50%;
                  margin: -8px 0 0 0;
                  color: $clr-pri;
                  display: none;
                }
              }
              &:before {
                display: none;
              }
              &.added {
                padding-left: 45px;
                opacity: 0.35;
                cursor: not-allowed;
                span {
                  left: 22px;
                  &:before {
                    display: block;
                  }
                }
              }
            }
            li {
              &:first-child {
                .question-text {
                  border-top: none;
                }
              }
            }
          }
        }
      }
      .apps-container {
      }
      .app {
      }
    }
  }
  .content {
    margin-top: $header-height;
    padding: 20px;
    @include clearfix();
    .ui-sortable-placeholder {
      border: 2px dashed #d1dce2;
      min-height: 195px;
      margin-bottom: 20px;
      background: $white;
    }
  }
  &.subnav--active {
    .sub-nav-wrapper {
      left: $leftnav-width;
    }
    .content {
      margin-left: $subnav-width;
    }
  }
  &.header-subnav--active {
    .sub-nav-wrapper {
      padding-top: $header-plus-subnav-height;
    }
    .content {
      margin-top: $header-plus-subnav-height;
    }
  }
}

.header-sub-nav + .page-content {
  .sub-nav-wrapper {
    padding-top: $header-plus-subnav-height;
  }
  .content {
    margin-top: $header-plus-subnav-height;
  }
}

.category-collapsible {
  //display: none;
}

.widget {
  border-radius: 5px;
  background: $white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: -1.259px 1.554px 6.48px 1.52px rgba(146, 146, 146, 0.11);
  @include clearfix();
  &.no--pad {
    padding: 0px;
  }
}

.content-header {
  margin-bottom: 20px;
  @include clearfix();
  h2 {
    float: left;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    i {
      font-size: 25px;
      line-height: 30px;
      color: $clr-pri;
      position: relative;
      top: 3px;
    }
  }
  h3 {
    float: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #4d565f;
    i {
      font-size: 24px;
      line-height: 24px;
      color: #cbcbcb;
      position: relative;
      top: 4px;
      margin: 0 10px 0 0;
    }
  }
  h4 {
    float: left;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #34495e;
    padding: 7px 0;
    i {
      font-size: 18px;
      line-height: 18px;
      color: #cdd4d8;
      position: relative;
      top: 4px;
      margin: 0 10px 0 0;
    }
  }
  .details {
    float: left;
    font-size: 12px;
    line-height: 16px;
    padding: 7px 0;
    a {
      color: $clr-pri;
      &:hover {
        color: $clr-sec;
      }
    }
  }
  .actions {
    float: right;
    padding: 4px 0 0 10px;
    > .btn {
      float: right;
      margin: 0 0 0 10px;
    }
    .search-survey {
      display: inline-block;
      margin: 0 0 0 10px;
      position: relative;
      input {
        width: 277px;
        height: 30px;
        padding: 0 30px 0 10px;
        background: $white;
        border: 1px solid #e1e1e1;
        font-family: inherit;
        font-size: 12px;
        color: #34495e;
      }
      &:after {
        position: absolute;
        right: 10px;
        top: 0;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f002";
        font-size: 14px;
        line-height: 30px;
        color: #8c8c8c;
      }
    }
    .filter-by {
      float: left;
    }
    > label {
      float: left;
      padding: 0 20px 0 0;
    }
    .toggle {
      float: left;
      padding: 5px 0 0 0;
    }
    .insert-shortcode {
      float: left;
      label {
        display: block;
        font-size: 12px;
        padding: 3px 0;
      }
    }
    &.action-icons {
      padding: 8px 0 0 0;
      i {
        font-size: 20px;
        line-height: 20px;
        vertical-align: middle;
      }
      a {
        color: #d1dce2;
        margin: 0 5px;
        cursor: pointer;
        &:hover,
        &.active {
          color: $clr-pri;
        }
      }
    }
  }
  &.shortcode {
    h2 {
      padding-top: 20px;
    }
  }
  &.journey-header {
    margin-bottom: 5px;
    .actions {
      float: right;
      i {
        font-size: 20px;
      }
      a {
        margin: 0 0 0 10px;
        color: #cdd4d8;
        &:hover {
          color: $clr-pri;
        }
      }
    }
  }
}

.question-draggable {
  cursor: move;
  outline: none;
}

.question-droppable {
  background: $white;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px dashed #d1dce2;
  width: 100%;
  min-height: 195px;
  display: table;
  vertical-align: middle;
  text-align: center;
  .details {
    display: table-cell;
    vertical-align: middle;
    font-size: 15px;
    color: #4c5e70;
    opacity: 0.5;
    p {
      padding: 5px 0;
      i {
        font-size: 36px;
        opacity: 0.5;
      }
    }
  }
  &.active {
    border: 2px solid #a9b2b7;
    .details {
      opacity: 0.8;
      p {
        i {
          opacity: 0.8;
        }
      }
    }
  }
}

.add-pages {
  .btn {
    float: left;
  }
}

.survey-pagination {
  float: left;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #34495e;
  ul {
    li {
      display: inline-block;
      margin: 0 4px 0 0;
      position: relative;
      > a {
        color: #34495e;
        display: inline-block;
        background: $white;
        padding: 6px 15px;
        border-radius: 3px;
        box-shadow: 0px 3px 16px 0px rgba(157, 175, 185, 0.25);
        @include transition();
        i {
          font-size: 21px;
          line-height: 18px;
          height: 18px;
          vertical-align: middle;
          text-align: center;
          margin: 0 -7px;
          color: $clr-pri;
        }
        &:hover {
          color: $white;
          background: $clr-pri;
          i {
            color: $white;
          }
        }
        &.active {
          cursor: default;
        }
      }
      .delete {
        position: absolute;
        right: -4px;
        top: -7px;
        a {
          display: block;
          width: 16px;
          height: 16px;
          background: $white;
          border-radius: 50%;
          position: relative;
          i {
            font-size: 14px;
            line-height: 14px;
            color: #ec638d;
            vertical-align: middle;
            position: absolute;
            left: 1px;
            top: 1px;
          }
          &:hover {
            i {
              color: #ad4967;
            }
          }
        }
      }
      &.active {
        > a {
          color: $white;
          background: $clr-pri;
          i {
            color: $white;
          }
        }
      }
      &.disabled {
        > a {
          color: #dddddd;
          background-color: $white;
          cursor: not-allowed;
          i {
            color: #ddd;
          }
        }
        .delete {
          opacity: 0.5;
          a {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.editable-page-content {
  font-size: 14px;
  line-height: 200%;
  margin: 0 0 15px;
  p {
    padding: 10px 0 15px;
  }
}

.question-draggable {
  z-index: 900;
}

body > li.app {
  background: $white;
  color: #717f8e;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 5px 8px 16px;
  height: auto !important;
  cursor: move;
  //border: 2px dashed #d1dce2;
}

body > .app {
  &.question-block-main {
    cursor: move;
    border: 2px dashed red;
    box-shadow: none !important;
    .question-header {
      .actions {
        display: none;
      }
    }
  }
}

body > li.app {
  &.question-types {
    position: relative;
    margin: 0 0 15px 0;
    font-size: 14px;
    line-height: 125%;
    font-weight: 300;
    background: $clr-pri;
    border-radius: 5px;
    box-shadow: -2.517px 3.109px 6.48px 1.52px rgba(146, 146, 146, 0.11);

    color: $white;
    padding: 0px;
    border: none;

    span {
      position: absolute;
      left: 7px;
      top: 50%;
      font-size: 12px;
      margin: -15px 0 0 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      color: $clr-pri;
      border: 1px solid $clr-pri;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      font-weight: bold;
      letter-spacing: 1px;
    }
    a {
      display: block;
      padding: 13px 7px 14px 47px;
      border-radius: 5px;
      color: $white;
      background: $clr-pri;
      span {
        color: $clr-pri;
        background: $white;
      }
    }
  }
}

.main-loader {
  position: fixed;
  z-index: 1100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: none;
  .loader-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    //&:after {
    //  content: "";
    //  position: absolute;
    //  left: 43px;
    //  top: 41px;
    //  width: 18px;
    //  height: 18px;
    //  background: url("#{$path_images}/r-loader.svg") no-repeat;
    //}
  }
  &.active {
    display: block;
  }
}

.main-loader {
  position: fixed;
  z-index: 1100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: none;
  .loader-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    //&:after {
    //  content: "";
    //  position: absolute;
    //  left: 43px;
    //  top: 41px;
    //  width: 18px;
    //  height: 18px;
    //  background: url("#{$path_images}/r-loader.svg") no-repeat;
    //}
  }
  &.active {
    display: block;
  }
}

.top-loader {
  position: fixed;
  z-index: 1100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  .loader-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    //&:after {
    //  content: "";
    //  position: absolute;
    //  left: 43px;
    //  top: 41px;
    //  width: 18px;
    //  height: 18px;
    //  background: url("#{$path_images}/r-loader.svg") no-repeat;
    //}
  }
  &.active {
    display: block;
  }
}

.error__content {
  text-align: center;
  font-size: 14px;
  .error-icon {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background: url("#{$path_images}/icon-hourglass.svg") no-repeat center center;
    background-size: auto 100%;
    .modal--404 & {
      background-image: url("#{$path_images}/icon-404.svg");
    }
    .modal--403 & {
      background-image: url("#{$path_images}/icon-lock.svg");
    }
    .modal--loading & {
      background-image: url("#{$path_images}/loader.svg");
      width: 120px;
      height: 120px;
    }
  }
  h2 {
    font-size: 42px;
    padding: 5px 0;
  }
  h3 {
    font-size: 18px;
    padding: 5px 0;
  }
  p {
    padding: 8px 0;
  }
}

.img-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  .image-info {
    background: rgba(0, 0, 0, 0.25);
  }
}

// journey editor

.journey-upload {

  .journey__header {
    margin-bottom: 20px;
    float: left;
    width: 100%;

    h2 {
      font-size: 18px;
      float: left;
      color: #4d565f;
    }

    .actions {
      float: right;
      font-size: 12px;

      a {
        color: #4d565f;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $clr-pri;
        }
      }
    }

  }

  .journey__content {
    position: relative;
    float: left;
    width: 100%;
    @include clearfix();
    .image-block {
      padding: 0;
      text-align: center;
      .image{
        margin: 0 auto;
      }
    }
    p {
      margin-bottom: 10px;
      font-size: 12px;
      color: #4c5e70;
    }
  }

}

.ui-block-overlay {
  position: fixed;
  left: $leftnav-width;
  right: 0;
  top: $header-plus-subnav-height;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  z-index: 50;
  h4 {
    color: #393939;
    background: #ddb6b9;
    padding: 10px;
    margin: 2px 0 0 0;
    text-align: center;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
}
