$c_green: #8ec549;
$c_red: #eb2151;
$c_amber: #ef662f;


//.charts {
//    padding: 120px 0;
//    width: 100%;
//  /*  margin: 0 auto;*/
//    position: relative;
//
//    &:after {
//        content: "";
//        display: table;
//        clear: both;
//    }
//
//
//    .cej_dd {
//        position: absolute;
//        top: 30px;
//        left: 125px;
//        width: 360px;
//
//            label {
//                float: left;
//                padding: 10px;
//            }
//
//            .purchase {
//                width: 184px;
//                float: left;
//            }
//
//            .region {
//                width: 170px;
//                float: right;
//            }
//
//            &.right {
//               right: 105px;
//                  left: inherit;
//
//            }
//        }
//}

.charts {
  display: table;
  width: 100%;
  position: relative;

  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    display: block;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .cej_chart_container {
    display: table-cell;
    width: 50%;
    padding: 0 20px 30px;

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      display: block;
      width: 100%;
      text-align: center;
      padding: 0 0 30px;
    }
  }

  .cej_header {
    width: 100%;
    text-align: center;
    padding: 0 0 30px 0;

    @include susy-breakpoint(768px, $susy, '.no-mq') {
      padding: 0 0 15px 0;
    }
  }

  .cej_dd_container {
    display: inline-block;
    padding: 0 5px;

    >label {
      float: left;
      font-size: 14px;
      line-height: 34px;
      padding-right: 5px;
    }

    .select-dropdown {
      text-align: left;
      display: inline-block;
      margin: 0 5px;
    }

    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      display: block;

      >label {
        float: none;
      }

      .select-dropdown {
        display: block;
        text-align: center;
      }
    }
  }


  .cej_chart {
    width: 546px;
    height: 315px;
    border: 4px solid #fff;
    border-radius: 180px;
    display: block;
    position: relative;
    margin: 40px auto;
    padding: 55px;
    box-shadow: -1.975px 0.313px 27.26px 1.74px rgba(0, 0, 0, 0.12);

    @include susy-breakpoint(768px, $susy, '.no-mq') {
      position: relative;
      transform: scale(0.5);
      left: 50%;
      transform-origin: left center;
      margin: -70px auto;
      margin-left: -136px;
    }

    .start-btn {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #ebebeb;
      border: 1px solid #d6d9dc;
      top: -26px;
      right: 217px;
      font-weight: 600;
      z-index: 3;
      vertical-align: middle;
      text-align: center;
      padding: 15px 0;
      font-size: 14px;
    }

    .cej_chart-inner {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 100px;
      border: 4px dotted #e7e7e7;
      padding: 50px;
      position: relative;
      z-index: 1;

      .arrow-1 {
        position: absolute;
        top: -12px;
        left: 109px;
        font-size: 22px;
      }

      .arrow-2 {
        position: absolute;
        top: -12px;
        right: 132px;
        font-size: 22px;

        i {
          margin: 0 2px;
        }
      }

      .arrow-3 {
        position: absolute;
        bottom: 5px;
        right: 30px;
        font-size: 22px;
        -webkit-transform: rotate(-31deg);
        -moz-transform: rotate(-31deg);
        -o-transform: rotate(-31deg);
        -ms-transform: rotate(-31deg);
        transform: rotate(-31deg);

      }

      .arrow-4 {
        position: absolute;
        bottom: -12px;
        right: 189px;
        font-size: 22px;

        i {
          margin: 0 2px;
        }
      }

      .arrow-5 {
        position: absolute;
        bottom: -2px;
        left: 44px;
        font-size: 22px;
        -webkit-transform: rotate(33deg);
        -moz-transform: rotate(33deg);
        -o-transform: rotate(33deg);
        -ms-transform: rotate(33deg);
        transform: rotate(33deg);

        i {
          margin: 0 2px;
        }

      }


      .exp-container {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 100px;
        padding: 25px;
        box-shadow: inset 3px 2px 22px -2px rgba(0, 0, 0, 0.23);

        .exp-box {
          width: 100%;
          height: 100%;
          background-color: #a3afba;
          border-radius: 100px;
          display: table;

          h3 {
            font-size: 20px;
            display: table-cell;
            vertical-align: middle;
            padding: 0 0 0 20px;
            font-weight: 500;
            color: #fff;

            span {
              font-weight: 600;
            }
          }
        }
      }

    }

    .item {
      height: 110px;
      width: 110px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      border: 4px solid #ddd;
      z-index: 2;

      a {
        font-size: 10px;
        padding: 8px;
        margin-top: 15px;
        line-height: 1;
        display: block;
        text-align: center;
        color: white;
        font-weight: 600;

        &:focus {
          outline: 0;
          text-decoration: none;
        }

        span {
          margin-top: 6px;
          display: block;
          font-size: 28px;

          i {
            font-size: 12px;
            position: relative;
            top: -12px;
          }
        }

      }



      &:nth-child(7) {
        top: 0px;
        left: 199px;
      }

      &:nth-child(6) {
        top: 10px;
        left: 47px;
      }

      &:nth-child(5) {
        top: 136px;
        left: 2px;
      }

      &:nth-child(4) {
        top: 203px;
        left: 136px;
      }

      &:nth-child(3) {
        top: 200px;
        right: 120px;
      }

      &:nth-child(2) {
        top: 113px;
        right: -4px;
      }

      &:nth-child(1) {
        top: 3px;
        right: 66px;
      }

      &.positive {
        border: 4px solid $c_green;
        background-color: $c_green;

        span {
          color: white;
        }
      }

      &.negative {
        border: 4px solid $c_red;
        background-color: $c_red;

        span {
          color: white;
        }
      }

      &.neutral {
        border: 4px solid $c_amber;
        background-color: $c_amber;

        span {
          color: white;
        }
      }

      &.normal {
        border: 4px solid #ebebeb;
        background-color: #ebebeb;
        color: #646464;

        a {
          color: #646464;
        }

        span {
          color: $c_red;
        }
      }

    }



    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    &.five {
      .item {
        &:nth-child(6) {
          display: none;
        }

        &:nth-child(7) {
          display: none;
        }

        &:nth-child(1) {
          top: -4px;
          left: 85px;
        }

        &:nth-child(2) {
          top: -3px;
          right: 84px;
        }

        &:nth-child(3) {
          top: 156px;
          right: 13px;
        }

        &:nth-child(4) {
          top: 203px;
          left: 226px;
        }

        &:nth-child(5) {
          top: 150px;
          left: 12px;
        }
      }

      .start-btn {
        right: 329px;
      }

      .arrow-2 {
        position: absolute;
        top: -15px;
        right: 191px;
        font-size: 22px;
      }

      .arrow-5 {
        position: absolute;
        bottom: -16px;
        left: 100px;
        font-size: 22px;
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
      }

      .arrow-4 {
        position: absolute;
        bottom: -16px;
        right: 88px;
        font-size: 22px;
      }
    }


  }
}