.ngxp__container {
  width:240px;
  background: #FFF;
  padding:20px;
  border-radius: 3px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.ngxp__container.ngxp__animation {
  -webkit-animation: ngxp-fadeIn 150ms ease-out;
  -moz-animation: ngxp-fadeIn 150ms ease-out;
  -o-animation: ngxp-fadeIn 150ms ease-out;
  animation: ngxp-fadeIn 150ms ease-out;
}

.ngxp__container > .ngxp__arrow {
  border-color: white;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin:10px;
  -webkit-filter: drop-shadow(1px -1px 1px rgba(0,0,0,.2));
  filter: drop-shadow(1px -1px 1px rgba(0,0,0,.2));
}

.ngxp__container[x-placement^="top"],
.ngxp__container[x-placement^="bottom"],
.ngxp__container[x-placement^="right"],
.ngxp__container[x-placement^="left"] {
  display: block;
}

.ngxp__container[x-placement^="top"] {
  margin-bottom:10px;
}

.ngxp__container[x-placement^="top"] > .ngxp__arrow {
  border-width: 10px 10px 0 10px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  bottom: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.ngxp__container[x-placement^="top"] > .ngxp__arrow.__force-arrow {
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}

.ngxp__container[x-placement^="bottom"] {
  margin-top: 10px;
}

.ngxp__container[x-placement^="bottom"] > .ngxp__arrow {
  border-width: 0 10px 10px 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  top: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.ngxp__container[x-placement^="bottom"] > .ngxp__arrow.__force-arrow {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}

.ngxp__container[x-placement^="right"] {
  margin-left: 10px;
}

.ngxp__container[x-placement^="right"] > .ngxp__arrow {
  border-width: 10px 10px 10px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  left: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}

.ngxp__container[x-placement^="right"] > .ngxp__arrow.__force-arrow {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}

.ngxp__container[x-placement^="left"] {
  margin-right:10px;
}

.ngxp__container[x-placement^="left"] > .ngxp__arrow {
  border-width: 10px 0 10px 10px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  right: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}

.ngxp__container[x-placement^="left"] > .ngxp__arrow.__force-arrow {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}

@-webkit-keyframes ngxp-fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes ngxp-fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

/*ngxp-popper template classes*/

.ngxp-title {
  font-size: 18px;
  color: #34495e;

}

.ngxp-content {
  font-size: 12px;
  color: #969dab;
  line-height: 18px;
  font-weight: normal;
  margin: 15px 0;
}

.tour__pagination {
  float: left;
  a{
    display: inline-block;
    color: #34495e;
    font-size:20px;
    vertical-align: middle;
    &.disabled{
      opacity: 0.3;
    }
  }
}

.step__count {
  color: #34495e;
  font-size: 14px;
  margin: 0 10px;
  display: inline-block;
}

.ngxp-btn {
  border: none;
  background: none;


  &.btn-end {
    float: right;
    font-size: 16px;
    color: #34495e;
  }
}


.circle-ripple {
  background-color: #fff;
  font-size:3px;
  display: none;
  position:relative;
  right: -20px;
  top: -2px;
  width:5px;
  height:5px;
  border-radius: 50%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($clr-pri, 0.3),
    0 0 0 1em rgba($clr-pri, 0.3),
    0 0 0 3em rgba($clr-pri, 0.3),
    0 0 0 5em rgba($clr-pri, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba($clr-pri, 0.3),
    0 0 0 3em rgba($clr-pri, 0.3),
    0 0 0 5em rgba($clr-pri, 0.3),
    0 0 0 8em rgba($clr-pri, 0);
  }
}