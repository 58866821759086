// gilroy

@font-face {
  font-family: "GiloryLight";
  src: url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.otf"); /* IE9 Compat Modes */
  src: url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.otf") format("opentype"),
    /* Open Type Font */ url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.svg") format("svg"),
    /* Legacy iOS */ url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("^assets/styles/portal/build/assets/fonts/gilroy/Gilroy-Light.woff") format("woff"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal; 
}