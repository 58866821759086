$user-color: #4c5e70;

.header {
  width: 100%;
  height: 70px;
  background-color: $white;
  position: fixed;
  z-index: map-get($zindex, header);
  top: 0;
  left: 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    height: 55px;
  }
}

.logo {
  float: left;
  height: 100%;
  text-align: center;
  min-width: 130px;
  max-width: 200px;
  display: table;
  max-height: 100%;
  padding: 0 20px;
  outline: none;
  position: relative;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    padding: 0;
    margin-left: 45px;
    max-width: 150px;
  }

  @include susy-breakpoint(480px, $susy, ".no-mq") {
    margin-left: 40px;
  }

  // &.TCI-logo {
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  // }
  // &.tme-logo {
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  // }
  // &.apple-logo {
  //   padding: 20px;
  // }
  // &.ThemeTemplates-logo {
  //   padding: 15px 20px;
  // }
  // &:before {
  //   content: "";
  //   display: inline-block;
  //   height: 100%;
  //   vertical-align: middle;
  // }
  a {
    outline: none;
    //@include center();
    outline: none;
    display: table-cell;
    height: 60px;
    vertical-align: middle;
  }

  img {
    //  vertical-align: middle;
    //  max-height: 50px;
    // width:auto;
    // max-width: 190px;
    //  outline: none;
    // max-height: 100%;
    // max-width: 96%;
    // vertical-align: middle;
    border: 0;
    max-height: 60px;
    outline: none;

  }
}

.nav {
  .nav__link {
    float: left;
    font-size: 12px;
    font-weight: 400;
    border-left: 1px solid #ebebeb;
    text-transform: capitalize;
    text-align: center;
    position: relative;
    @include transition();
    max-width: 100px;

    :lang(fr) {
      text-transform: none;
    }

    &:last-child {
      border-right: 1px solid #ebebeb;
    }

    a {
      display: block;
      padding: 5px 15px;
      color: #969dab;
      //max-width:110px;
      height: 70px;
      cursor: pointer;
      border-top: 3px solid $white;

      &:hover {
        color: $clr-pri;

        //border-color: $white;
        .icon {
          color: $clr-pri;
        }
      }
    }

    .fal {
      display: block;
      font-size: 16px;
      margin: 5px 0 5px;
    }
  }

  .active {
    a {
      border-color: $clr-pri;
      color: $clr-pri;
    }
  }

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    display: block;
    position: absolute;
    background: #fff;
    top: 56px;
    width: 100%;
    left: 0;
    padding: 0;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);

    .nav__link {
      display: block;
      height: auto;
      float: none;
      font-size: 14px;
      max-width: 100%;
      width: 100%;
      font-weight: 400;
      text-align: left;
      border: 0;
      border-bottom: 1px solid #ebebeb;

      &:last-child {
        // border: 0;
      }

      .fal {
        display: inline-block;
        margin: 0 10px 0 0;
        position: relative;
        font-size: 16px;
        top: 0;
      }

      a {
        height: auto;
        border: 0;
        padding: 15px;

        &:before {
          height: 100%;
          top: 0;
          left: 0;
          width: 3px;
        }
      }

      &.active {
        a {}
      }
    }
  }
}

.navbar-right {
  float: right;
  padding: 22px 25px 0 0;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    padding-right: 15px;
    padding-top: 10px;
  }

  @include susy-breakpoint(480px, $susy, ".no-mq") {
    padding-right: 5px;
  }

  .user-info {
    float: right;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    color: #808080;

    span {
      float: left;
      line-height: 18px;
      padding: 9px 13px 9px 0;
    }

    img {
      float: left;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      border: 1px solid #d2d2d2;
    }


    i {
      font-size: 30px;
      color: #969dab;
      @include opacity(0.5);
      margin-top: 3px;

      @include susy-breakpoint(480px, $susy, ".no-mq") {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .user-dropdown-link {
      float: left;
      position: relative;
      display: block;
      padding: 0 25px 0 0;
      color: #808080;

      &:after {
        content: "\f107";
        font-size: 20px;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 300;
        position: absolute;
        right: 6px;
        @include opacity(0.5);
        top: 50%;
        margin-top: -10px;
      }

      &:hover {
        color: #222222;

        i {
          border-color: #222222;
        }
      }
    }
  }

  .user-features {
    float: right;
    padding: 0 15px;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      padding: 0;
    }

    .feature-node {
      float: left;
      margin: 0 5px;
      position: relative;
      min-width: 36px;

      @include susy-breakpoint(1024px, $susy, ".no-mq") {
        margin: 0 5px 0 0;

        &.notification-node {
          margin: 0 0 0 5px;
        }
      }
    }

    .handler {
      display: block;
      padding: 9px;
      color: #d5d7dd;
      position: relative;
      text-align: center;

      i {
        font-size: 16px;
      }

      &:after {
        content: "";
        width: 36px;
        height: 36px;
        background: #f1f6fa;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -1;
        margin: -18px 0 0 -18px;
        display: none;
      }

      &:hover {
        &:after {
          display: block;
        }
      }

      &.active {
        &:after {
          display: block;
        }
      }
    }

    .has-notification {}

    a {}
  }

  .menu-node {
    float: right;
    display: none;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      display: block;
      margin: 9px 0 0 0;
    }
  }

  .language-switcher {
    float: right;
    padding: 2px 0 0 0;
  }
}

//notification circle ripple animation
.notification-node {
  .noti-dot {
    position: relative;
    top: 7px;
    right: -1px;
  }

  .pulse {
    width: 2px;
    height: 2px;
    border: 3px solid $clr-pri;
    border-radius: 30px;
    background-color: $clr-pri;
    z-index: 10;
    position: absolute;
    right: 8px;
  }

  .dot {
    border: 4px solid $clr-pri;
    background: transparent;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    -webkit-animation: pulse 3s ease-out;
    animation: pulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -12px;
    right: -4px;
    z-index: 1;
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.user-mode {
  background: #f9f9f9;
  line-height: 18px;
  font-size: 12px;
  color: #34495e;
  padding: 15px 10px;
  border-bottom: 1px solid #ebebeb;

  .ui-inputswitch {
    float: right;
  }
}

.user {
  border-bottom: 1px solid #ebebeb;
  position: relative;
  padding: 10px 10px 10px 52px;

  p {
    line-height: 15px;

    strong {
      display: block;
      font-weight: 500;
    }
  }

  .user__inital {
    border: 1px solid $clr-pri;
    border-radius: 100%;
    font-size: 18px;
    width: 35px;
    position: absolute;
    left: 10px;
    color: $clr-pri;
    top: 7px;
    text-align: center;
    line-height: 35px;
    height: 35px;
  }
}

.notification {
  i {}

  a {
    &:hover {}

    &.active {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $clr-pri;
      }
    }

    .count {
      position: absolute;
      left: 20px;
      top: -5px;
      background: $clr-pri;
      font-size: 10px;
      line-height: 100%;
      color: $white;
      padding: 3px 5px;
      border-radius: 3px;
      border: 2px solid $white;
      display: none;
    }

    &.show--count {
      .count {
        display: block;
      }
    }
  }

  .notification-popup {
    position: absolute;
    right: -1px;
    top: 100%;
    width: 280px;
    margin: 6px 0 0 0;
    font-size: 12px;
    color: #34495e;
    background: $white;
    border: 1px solid $clr-pri;
    border-radius: 5px;
    box-shadow: 0px 0px 6.48px 1.52px rgba(146, 146, 146, 0.32);
    transform: translateY(45px);
    @include transition();
    visibility: hidden;
    @include opacity(0);

    &:before {
      content: "";
      position: absolute;
      right: 12px;
      top: -5px;
      width: 9px;
      height: 9px;
      background: $clr-pri;
      border: 1px solid $clr-pri;
      border-width: 1px 0 0 1px;
      transform: rotate(45deg);
    }

    .top-bar {
      background: $clr-pri;
      color: $white;
      border-radius: 3px 3px 0 0;
      padding: 6px 15px 4px;

      span {
        display: inline-block;
        width: 49%;
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }

      a {
        color: $white;
      }
    }

    ul {
      padding: 5px 13px;

      li {
        border-top: 1px solid #e5e5e5;
        padding: 6px 2px;

        a {
          color: $clr-pri;

          &:hover {
            color: $clr-sec;
          }
        }

        &:first-child {
          border-top: none;
        }
      }
    }

    &.active {
      transform: translateY(0);
      @include opacity(1);
      visibility: visible;
    }

    .alert {
      padding: 3px 0 3px 28px;
      position: relative;
      line-height: 14px;
      word-wrap: break-word;

      >i {
        position: absolute;
        left: 0;
        top: 4px;
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        padding: 0;
        //  color: $white;
      }

      &.complete {
        >i {
          color: #35b968;
        }
      }

      &.updated {
        >i {
          color: #4ca1fe;
          transform: rotate(180deg);
        }
      }

      &.deleted {
        >i {
          color: #ce4a73;
        }
      }

      &.canceled {
        >i {
          color: #ec638d;
        }
      }

      span {
        display: block;
        padding: 3px 0 0 0;
        color: #a7b0b9;
        font-size: 11px;
        line-height: 11px;

        i {
          font-size: 11px;
          line-height: 11px;
          margin: 0 3px 0 0;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.header-sub-nav {
  float: left;
  clear: both;
  width: 100%;
  position: fixed;
  left: 0;
  top: $header-height;
  z-index: 10;
  // color: #34495e;
  //font-size: 12px;
  // line-height: 16px;
  //background: #edeef1;
  background: #e9ecef;
  border-bottom: 1px solid #e8e9ec;
  padding: 0 0 0 $leftnav-width;

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin: 14px 30px -3px;
    font-size: 12px;
  }

  ul {
    list-style-type: none;

    li {}
  }

  .header-sub-nav__actions {



    .survey-languages {
      display: inline-block;
      margin: 0 5px;

      .p-dropdown-panel {
        right: 0;
      }

      .p-dropdown .p-dropdown-label {
        padding-top: 5px;
        padding-bottom: 5px;
      }


    }

    // float: right;
    padding: 6px 0 8px 0;
    position: absolute;
    top: 0;
    width: 50%;
    right: 20px;
    text-align: right;

    .dropdown__nav {
      display: inline-block;
      width: 200px;
      text-align: left;

      .p-inputtext {
        padding: 5px;
      }
    }

    .btn {
      margin: 0 5px;
    }
  }
}



// App Drawer Popup
.appdrawer-popup {
  position: absolute;
  right: -1px;
  top: 100%;
  width: 282px;
  min-height: 100px;
  max-height: 305px;
  margin: 6px 0 0 0;
  font-size: 12px;
  color: #34495e;
  background: $white;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow: 0px 0px 6.48px 1.52px rgba(146, 146, 146, 0.32);
  transform: translateY(45px);
  @include transition();
  visibility: hidden;
  @include opacity(0);

  &.active {
    transform: translateY(0);
    @include opacity(1);
    visibility: visible;
  }

  .search__trigger {
    cursor: pointer;
    float: left;
    position: relative;
    width: 100%;
    padding: 10px 30px 10px 40px;
    background: #f9f9f9;
    border: 1px solid #ebebeb;
    border-width: 1px 0;

    >i {
      position: absolute;
      left: 15px;
      top: 10px;
      font-size: 15px;
      color: #969dab;
      @include opacity(0.5);
    }

    input {
      background: transparent;
      border: 0;
      width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 14px;
      padding: 0;
      font-family: inherit;
      font-weight: 400;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: #969dab;
      }

      &:-ms-input-placeholder {}
    }

    .field__icons {
      position: absolute;
      right: 10px;
      top: 10px;

      >i {
        font-size: 16px;
        cursor: pointer
      }
    }
  }
}

// App Grid
.apps-grid-wrapper {
  float: left;
  width: 100%;
  padding: 10px;

  .app-items-holder {
    max-height: 210px;
    overflow-y: auto;
  }

  .app-item {
    float: left;
    text-align: center;
    max-width: 80px;
    margin: 0 3px;

    &:nth-child(3n+1) {
      clear: both;
    }

    .app-link {
      display: block;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: #f9f9f9;
      }
    }

    .img-holder {
      display: table;

      .img-align {
        padding: 15px;
        width: 80px;
        height: 80px;
        display: table-cell;
        vertical-align: middle;
      }

      img {
        float: left;
        width: 100%;
        max-height: 100%;
      }
    }

    .appname-holder {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #34495e;
      padding: 0 0 10px;
    }
  }
}

// App Listing
.app-listing-wrapper {}

// App Breadcrumbs
.app-breadcrumbs {
  padding: 14px 12px 12px 12px;

  .breadcrumbs_list {
    li {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      color: #969dab;

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        content: "/";
        font-size: 12px;
        position: relative;
        font-weight: 500;
        display: inline-block;
        margin: 0 4px;
      }

      a {
        color: $clr-pri;
      }
    }
  }
}

// Apps List
.apps__list {
  float: left;
  width: 100%;

  ul {
    max-height: 220px;
    overflow-y: auto;

    li {
      display: block;

      a {
        display: block;
        padding: 12px 15px;
        font-size: 13px;
        border-bottom: 1px solid #ebebeb;
        position: relative;
        color: #b3b3b3;
        cursor: pointer;
        font-weight: 500;

        &:hover {
          color: #666666;
          background: none;
        }
      }

      .app-url {
        display: block;
        color: $clr-pri;
        font-weight: 400;
      }

      &.active {
        a {
          color: #666666;
          background: #f3f6f9;
        }
      }

      &.has-child {
        a {
          position: relative;

          &:before {
            content: "\f105";
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            right: 10px;
            color: #b8b8b8;
            font-size: 15px;
            top: 50%;
            margin: -7px 0 0 0;
          }

          &:hover {
            color: #666666;

            &:before {
              color: #666666;
            }
          }
        }
      }
    }
  }
}