.distribution-wizard {
  padding: 0;
  clear:both;
  .wizard__content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;
  }
  .wizard__footer {
    padding:15px;
    clear: both;
    text-align: center;
    background: #f4f5f9;
    border-top: 1px solid #ebebeb;
    border-radius: 0 0 3px 3px;
    .btn {
      margin: 0 3px;
    }
  }
  .wizard-step {
    font-size: 13px;
    .btn.dropdown-toggle {
      font-size: 13px;
    }
    .row {
      clear: both;
      padding: 10px 0;
      @include clearfix();
      .col {
        float: left;
        width: calc(100% / 3);
        padding: 0 10px;
      }
      &.twoColumn {
        .col {
          width: calc(100% / 2) !important;
          padding-bottom: 10px;
        }
      }
      &.fourColumn {
        .col {
          width: calc(100% / 4) !important;
        }
      }
      &.fullColumn {
        .col {
          width: 100% !important;
        }
      }
      &.customColumn {
        .col {
          width: calc(100% / 5);
          &:first-child,
          &:first-child + .col {
            width: calc(100% / 3.333333333333333);
          }
        }
      }
    }
    &.step--01 {
      padding: 0 0 30px 0;
    }
    &.step--02 {
      .row {
        padding: 10px 35px;
      }
    }
    &.step--03 {
      .row {
        .col {
          width: calc(100% / 5);
          &:first-child,
          &:first-child + .col {
            width: calc(100% / 3.333333333333333);
          }
          &.firstTwoColumns {
            width: calc(100% / 1.25);
          }
        }
      }
    }
    &.step--04 {
      .row {
        border-top: 1px solid #ebebeb;
        padding: 20px 0;
        &:first-child {
          border-top: none;
        }
      }
      a {
        color: $clr-pri;
        &:hover {
          color: $clr-sec;
        }
      }
      p {
        i {
          color: $clr-pri;
          font-size: 12px;
          margin: 0 5px 0 0;
        }
      }
      .when {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px 10px;
        margin: 0 0 20px 0;
        border-bottom: 1px solid #ebebeb;
        :lang(fr) {
          text-transform: none;
        }
      }
    }
  }
  .form__label {
    font-size: 15px;
    font-weight: 600;
    color: #4d565f;
  }
  .form__value {
    font-size: 18px;
    font-weight: normal;
    color: $clr-pri;
    .flag {
      position: relative;
      top: -1px;
      left: -3px;
    }
  }
  .form__input {
    width: 100%;
  }
  .date-field,
  .time-field {
    width: 100%;
    input {
      width: 100% !important;
      padding: 5px 15px 6px 30px;
    }
  }
  .date-field {
    input {
      padding: 9px 45px 9px 10px !important;
    }
    .p-calendar.p-calendar-w-btn .p-datepicker-trigger {
      padding: 9px;
    }
  }
  .form__error {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }
  .survey-message {
    width: 100%;
    height: 200px;
  }
  .customer-prefered {
    display: block;
    padding-top: 7px;
  }
  .actions {
    padding-top:7px;
    display: block;
    a {
      color: $clr-pri;
      margin: 0 5px 0 0;
      &:hover {
        color: #4d565f;
      }
    }
    i {
      font-size:16px;
      line-height: 100%;
      vertical-align: text-top;
    }
  }
}
.validation-progress {
  text-align: center;
  color: #4d565f;
  .validation__label,
  .validation__count {
    font-weight: normal;
    font-size: 15px;
    padding: 10px 0;
  }
  .progress {
    border: none;
    backface-visibility: hidden;
  }
  &.error {
    color: #fe596b;
    .progress {
      border: 1px solid #fe596b;
    }
  }
  &.one-line {
    .validation__label,
    .validation__count,
    .progress {
      display: inline-block;
    }
    .validation__label {
      width: 30%;
    }
    .validation__count {
      width: 10%;
    }
    .progress {
      width: 50%;
      margin: 0 2%;
    }
  }
}
.validation-summary {
  .validation__item {
    padding: 0 0 15px 0;
    header {
      padding: 10px 5px;
      border-bottom: 1px solid #e1e1e1;
      color: #34495e;
      margin: 0 0 15px 0;
      @include clearfix();
      h3 {
        float: left;
        font-size: 15px;
        font-weight: bold;
      }
      p {
        float: right;
        font-size: 15px;
      }
    }
  }
}
