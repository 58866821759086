.administration-block {
  background: $white;
  border-radius: 3px;
  border: 1px solid #e0e8ec;
  margin: 0 0 15px 0;
  .content-header {
    margin-bottom: 0px;
    padding: 15px;
    .actions {
      padding: 0;
      .btn {
        float: none;
      }
      > .btn {
        float: left;
        margin: 0 0 0 10px;
      }
      .search-survey {
        float: left;
        margin: 0;
        input {
          width: 233px;
          height: 32px;
          border-radius: 3px 0 0 3px;
          padding: 0 40px 0 15px;
        }
        &:after {
          line-height: 32px;
        }
      }
      div.dropdown-menu {
        left: auto;
        right: 0;
        padding: 7px 10px;
        min-width: 120px;
        margin: 4px 0 0;
        font-size: 12px;
        border: 1px solid $clr-pri;
        border-radius: 5px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        li {
          border-top: 1px solid #e5e5e5;
          &:first-child {
            border-top: none;
          }
          a {
            padding: 6px 5px;
            font-size: 12px;
            &:hover {
              background: none;
              color: $clr-pri;
            }
          }
          &.selected {
            a {
              background: none;
              color: $clr-pri;
            }
          }
        }
      }
    }
  }
}
.contact-list-block {
  float: left;
  width: 100%;
  clear: both;
  border: 1px solid #ebebeb;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  > .content-header {
    padding:10px 15px;
    margin-bottom: 0;
    background: #edeef1;
    input {
      float: left;
      margin: 0 10px 0 0;
    }
    .btn-group {
      float: left;
      margin: 0 10px 0 0;
      .btn {
        float: none;
        margin: 0;
        .caret {
          &:before {
            margin-right: -9px;
          }
        }
      }
    }
    .btn {
      float: left;
    }
    .dropdown-menu {
      font-size: 12px !important;
    }
    .action-import-export {
      float: right;
      padding: 0 5px;
      .btn {
        margin: 0 5px;
      }
    }
    .action-tabs {
      float: left;
      .btn {
        margin: 1px 2px;
      }
    }
  }
  .administration-block {
    border-radius: 0;
    border-width: 1px 0 0 0;
  }
}

.role-container{
  margin-left: 148px;
  width: 63%;
  margin-top: 10px;
  border: 1px solid #efefef;

  .p-checkbox{
    margin-right:10px;
  }

  h6{
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    background: #f9f8f8;
    padding: 10px 15px;
    border-bottom: 1px solid #efefef;
    label{
      vertical-align: middle;
      //font-weight: 400;
    }
    .p-checkbox .p-checkbox-box{
     // border-color: $clr-pri;
    }
  }
}

.role-list{
  margin:0;
  padding: 15px;
  li{
    display: inline-block;
    margin-right:15px;
    text-transform: capitalize;
    :lang(fr) {
      text-transform: none;
    }

    label{
      font-size: 12px;
      vertical-align: middle;
      font-weight: 400;
    }
  }
}
