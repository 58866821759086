$widget-title: #666666;
$widget-bg: #fefefe;
$widget-border: #d6dbdf;

.widget {
  background: $white;
  position: relative;
  // min-height: 200px;
  width: 100%;
  padding: 10px;
  @include clearfix();
  margin-bottom: 0 !important;
  @include border-radius(3px);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

  .widget__header {
    clear: both;
    margin-bottom: 10px;
  }

  .widget__footer {
    clear: both;
    background: #f4f5f9;
    text-align: right;
    padding: 15px 10px;
    border-top: 1px solid #ebebeb;
    border-radius: 0 0 3px 3px;

    .btn {
      margin: 0 5px;
    }
  }

  .widget__graph {
    float: left;
    width: 100%;
  }

  .widget__title {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #212229;
    display: inline-block;

    :lang(fr) {
      text-transform: none;
    }

    a {
      color: #212229;

      &:hover {
        color: $clr-pri;
      }
    }
  }

  .title__tag {
    border: 1px solid $clr-pri;
    text-transform: none;
    color: $clr-pri;
    font-weight: 500;
    font-size: 11px;
    padding: 2px 4px 1px;
    margin: 0 5px 5px 0;

    :lang(fr) {
      text-transform: none;
    }
  }

  &.header-options {
    .title__tag {
      background: $clr-pri;
      border: 1px solid $clr-pri;
      color: $white;
    }
  }

  &.fullpage--widget {
    padding: 0;

    .widget__header {
      padding: 10px;
      margin: 0;
    }

    .from--select-kpi {
      .widget__header {
        padding: 10px 10px 0 10px;
      }
    }
  }

  &.no-padding {
    .widget__header {
      padding: 0;
    }
  }

  &.active {
    box-shadow: 0 0 16.2px 1.8px hsla(0, 0%, 57%, 0.5);
  }

  &.widget--customer-profile,
  &.widget--customer-life-cycle {
    min-height: 450px;
  }
}

.center-block {
  text-align: center;
}

.widget--menu {
  width: 110px;
}

.width--auto {
  width: fit-content;
}

.widget__tool {
  position: absolute;
  right: 15px;
  top: 15px;

  &.voc-region {
    top: 7px;
  }

  // float: right;
  // padding: 0 15px;

  .widget-node {
    float: left;
    margin: 0 2px;
    position: relative;

    &.voc-region {
      margin-top: -6px;
    }

    &.toggle {
      top: 2px;
    }

    .p-dropdown {
      top: -4px;
    }
  }

  .handler {
    display: block;
    width: 26px;
    height: 26px;
    z-index: 1;
    color: #96a2b6;
    position: relative;
    border-radius: 50%;
    text-align: center;
    line-height: 26px;

    // background: #f1f6fa;
    i {
      font-size: 16px;
    }

    &:hover {
      background: #f1f6fa;
    }
  }

  .text-handler {
    display: block;
    z-index: 1;
    color: #96a2b6;
    position: relative;
    font-size: 14px;
    margin: 7px 0 0 0;

    // background: #f1f6fa;
    i {
      margin-left: 5px;
      font-size: 16px;
    }

    &:hover {
      color: $clr-pri;
    }
  }
}

.dropdown-wrapper {
  padding: 10px 0;
  float: left;
  width: 100%;
}

// chart vertical align

.widget--chart-valign {
  .valign__content {
    display: table;
    float: left;
    width: 100%;
    position: relative;
    height: calc(100% - 58px);
    vertical-align: middle;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      display: block;
      height: auto;
    }

    .valign__graph {
      float: none;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      padding: 0;

      @include susy-breakpoint(1024px, $susy, ".no-mq") {
        display: block;
        height: auto;
      }
    }
  }
}

.question-footer {
  float: left;
  width: 100%;

  p {
    float: left;
    font-size: 12px;
    padding: 5px 0 0 0;
    color: #b1b9c1;
  }
}

.contextual-menu {
  float: right;
  margin-top: 5px;

  .btn {
    margin-left: 3px;
    padding: 4px 2px;
    font-size: 14px;
    text-align: center;
    width: 30px;
  }
}

.widget-note-new {
  float: left;
  width: 100%;
  text-align: center;
  color: #055160;
  background-color: #cff4fc;
  border: 1px solid #b6effb;
  padding: 10px;
}


.language-handler {
  display: block;
  width: 26px;
  height: 26px;
  z-index: 1;
  color: #96a2b6;
  position: relative;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;

  // background: #f1f6fa;
  i {
    font-size: 20px;
  }

  &:hover {
    background: #f1f6fa;
  }
}