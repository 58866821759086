//loader overlay
.loader-overlay {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: fixed;
  @include opacity(0.9);
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zindex, overlay);
}

.loader {
  width: 100%;
  text-align: center;
  position: absolute;
  transition: all 0.2s ease;
  height: 44px;
  top: 50%;
  margin: -22px 0 0 0;
}

.loader-overlay-w {
  background-color: $white;
  height: 100%;
  width: 100%;
  position:absolute;
  @include opacity(0);
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zindex, widgetoverlay);
  &.active {
    @include opacity(0.9);
    visibility: visible;
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
  }
}
