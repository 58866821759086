//NoDataFound
.nodata {
  text-align: center;
  width: 100%;
  height: 46px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -23px 0 0 -50%;
  .nodata__icon {
    display: inline-block;
    margin-bottom: 10px;

    &:before {
      font-size: 25px;
      vertical-align: middle;
      color: $clr-red;
    }
  }
  .nodata__msg {
    font-size: 12px;
  }
}
.nodata-wrapper {
  clear: both;
//  position: absolute;
  height: 150px;
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -75px;

}