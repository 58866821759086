// Form styles label, inputs, etc
.form {
  position: relative;

  fieldset {
    border: 0;
    margin: 0;
  }
}

input,
textarea,
select,
button {
  outline: none;
}

//input:focus,
//textarea:focus,
//select:focus {
//  -webkit-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
//  -moz-box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
//  box-shadow: 0px 0px 5px 0px rgba(119, 119, 119, 0.25);
//}
//input[type="text"],
//input[type="password"],
//input[type="date"],
//input[type="number"],
//input[type="tel"],
//input[type="email"],
//input[type="search"] {
//  border: 1px solid #e1e1e1;
//  padding: 5px 15px 6px;
//  background: $white;
//  font-size: 12px;
//  font-family: inherit;
//  color: #34495e;
//  border-radius: 3px;
//  &.error {
//    border-color: #fe596e;
//  }
//}
//textarea {
//  width: 100%;
//  border: 1px solid #e1e1e1;
//  background: $white;
//  font-family: inherit;
//  font-size: 12px;
//  line-height: 125%;
//  color: #34495e;
//  padding: 8px 18px;
//  min-height: 72px;
//  resize: none;
//  border-radius: 3px;
//}
//input[type="text"].two-digits {
//  width: 42px;
//  padding: 7px 10px;
//}
//
//input[type="text"],
//input[type="password"],
//textarea {
//  &.error {
//    border-color: #fe596b !important;
//  }
//}
.edit-field {
  position: relative;

  &:before {
    content: "\f040";
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    color: $clr-pri;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  input {
    padding-right: 30px;
  }
}

.field-message {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
}

.search-field,
.date-field,
.time-field {
  float: left;
  position: relative;

  &:before {
    content: "\f002";
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    color: #8c8c8c;
    position: absolute;
    left: 8px;
    // top: 6px;
  }

  input {
    padding-left: 30px;
    width: 170px;
  }
}

.date-field {
  &:before {
    content: "insert_invitation";
    color: #d1dce2;
  }

  input[type="text"] {
    width: 130px;
  }

  input[type="date"] {
    padding: 4px 15px 4px 30px;
  }
}

.search-field {
  &:before {
    top: 16px;

    .dashboard-filters &,
    .content-header &,
    .question-bank-filters & {
      top: 6px;
    }
  }

  &.inverse--icon {
    &:before {
      left: auto;
      right: 8px;
    }

    input {
      padding-right: 30px;
      padding-left: 15px;
    }
  }
}

.time-field {
  &:before {
    content: "access_time";
    color: #d1dce2;
  }

  input[type="text"] {
    width: 130px;
  }

  input[type="date"] {
    padding: 4px 15px 4px 30px;
  }
}

.custom-checkbox {
  display: inline-block;

  >label {
    font-size: 13px;
    vertical-align: middle;
    margin-left: 10px;
  }

  input[type="checkbox"]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;
    display: none;
  }

  input[type="checkbox"]:not(old)~label {
    display: inline-block;
    margin-left: 0;
    line-height: 1.5em;
    font-size: 13px;
    color: #546a79;
    cursor: pointer;
  }

  input[type="checkbox"]:not(old)~label>span:first-child {
    display: inline-block;
    border: 1px solid #d6dade;
    border-radius: 0;
    background: $white;
    vertical-align: bottom;
    line-height: 14px;
    position: relative;
    width: 14px;
    height: 14px;
    margin: 0 8px 0 0;
    top: -3px;
  }

  input[type="checkbox"]:not(old):checked~label>span:first-child:before {
    content: "done";
    display: block;
    font-family: "Material Icons";
    color: $clr-pri;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    -webkit-text-stroke: 1px $clr-pri;
  }

  i {
    color: #d1dce2;
    font-size: 20px;
    margin-right: 5px;
    vertical-align: bottom;
  }
}

//Toggle Checkbox
.toggle {
  input {
    position: absolute;
    left: -9999px;
  }

  .toggle__switch {
    position: relative;
    display: block;
    width: 27px;
    height: 15px;
    cursor: pointer;
    border-radius: 8px;
    transition: 350ms;
    background: #bdc2c6;

    &:after {
      position: absolute;
      content: "";
      width: 11px;
      height: 11px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      transition: 250ms ease-in-out;
      background: $white;
    }
  }

  input:checked+.toggle__switch {
    background: $clr-pri;

    &::after {
      left: 14px;
    }
  }
}

//Upload field
.upload-field {
  font-size: 12px;
  clear: both;

  input {
    display: none;
  }

  .field__label {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 30px;
    display: block;

    :lang(fr) {
      text-transform: none;
    }
  }

  .field__controls {
    display: inline-block;
    vertical-align: middle;

    &.control--image {
      float: left;
    }

    label {
      display: inline-block;
    }

    .btn {
      text-transform: capitalize;
      cursor: pointer;
      // padding: 8px 20px;
      vertical-align: middle;

      // border-radius: 20px;
      :lang(fr) {
        text-transform: none;
      }
    }

    .file-name {
      vertical-align: middle;
      padding: 7px 0 0 0;
      display: block;
    }
  }

  .field__instructions {
    color: #b1b9c1;
    padding: 2px 0 0 0;

    &.display-inline {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.form__error {
  background: #ffeded;
  color: #fe596b;
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid #f9e0e3;
  position: relative;

  .error-heading {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 0 10px 0;

    i {
      font-size: 24px;
      line-height: 20px;
      vertical-align: top;
      margin: 0 5px 0 0;
    }
  }

  .error-message {}

  .error-list {
    list-style-type: disc;

    li {
      padding: 2px 0;
      list-style-position: inside;
      display: list-item;
    }
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;

    a {
      color: #fe596b;
    }

    i {
      font-size: 14px;
    }
  }
}

.form__info {
  background: #f4f5f9;
  color: #4c5e70;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #c6c7ca;
  position: relative;

  ul {
    padding: 5px 0;

    li {
      padding: 3px 0 3px 30px;
      position: relative;

      &:before {
        content: "●";
        font-size: 10px;
        position: absolute;
        left: 10px;
        top: 1px;
        color: $clr-pri;
      }
    }
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;

    a {
      color: #fe596b;
    }

    i {
      font-size: 14px;
    }
  }
}

.form-steps {
  margin: 0 -5px;
  padding: 5px 0 0 0;

  ul {
    display: table;
    table-layout: fixed;
    width: 100%;

    li {
      display: table-cell;
      width: 100%;
      padding: 0 5px;

      .step__item {
        display: block;
        width: 100%;
        height: 4px;
        background: #dcdee7;
        border-radius: 4px;
      }

      &.active {
        .step__item {
          background: $clr-pri;
        }
      }
    }
  }
}

.validation {
  text-align: center;
  color: #fe596b;
  font-size: 15px;
  font-weight: 400;

  .validation__bar {
    max-width: 200px;
    margin: 0 auto;
    padding: 8px 0;
  }
}

.progress {
  position: relative;
  height: 8px;
  overflow: hidden;
  background-color: #e4e4e4;
  border-radius: 4px;
  border: 1px solid #fe596b;

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 8px;
    background-color: $clr-pri;
    @include transition();
  }

  &.progress-bar-loading {
    background-image: -webkit-linear-gradient(45deg,
        rgba(0, 0, 0, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(0, 0, 0, 0.15) 50%,
        rgba(0, 0, 0, 0.15) 75%,
        transparent 75%,
        transparent);
    background-image: -o-linear-gradient(45deg,
        rgba(0, 0, 0, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(0, 0, 0, 0.15) 50%,
        rgba(0, 0, 0, 0.15) 75%,
        transparent 75%,
        transparent);
    background-image: linear-gradient(45deg,
        rgba(0, 0, 0, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(0, 0, 0, 0.15) 50%,
        rgba(0, 0, 0, 0.15) 75%,
        transparent 75%,
        transparent);
    background-size: 20px 20px;
    animation: progress-bar-stripes 1s linear infinite;
  }
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 20px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 20px 0;
  }

  to {
    background-position: 0 0;
  }
}

.modal-form {
  padding: 20px 0 25px;

  .row {
    padding: 6px 0;
    font-size: 12px;

    .col-left {
      width: 30%;
      display: inline-block;
      text-align: right;
      padding: 0 10px 0 0;
      vertical-align: top;
      line-height: 34px;
    }

    .col-right {
      display: inline-block;
      width: 64%;

      &.align-right {
        padding-left: 30%;
        width: 94%;
      }
    }

    .col-full {
      width: 100%;
      padding: 5px 0;
    }

    .checkbox--inline {
      display: inline-block;
      padding: 0 20px 0 0;
    }

    label {}

    .upload-label {
      line-height: 38px;
    }

    input,
    .date-field {
      width: 100%;

      &.password {
        max-width: 200px;
      }
    }

    textarea {
      width: 64%;
    }

    .required {
      font-weight: bold;
      color: $clr-pri;
    }

    .upload-field {
      display: inline-block;
      width: 64%;
    }

    h3 {
      font-size: 16px;
      font-weight: normal;
      padding: 0 10px 20px;
    }

    .info {
      vertical-align: top;
      line-height: 30px;
      color: #9ea8af;
      font-size: 14px;
      line-height: 20px;

      span {
        color: $clr-pri;
        padding: 0 20px;
      }

      h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        font-weight: normal;
        padding: 6px 0 0 0;
      }

      &.error {
        font-size: 12px;
        line-height: 16px;
        color: #f00;
      }
    }

    &.profile--row {
      padding: 2px 0;
      font-size: 14px;
      font-weight: normal;

      label {
        font-weight: 600;
      }
    }

    &.photo--row {
      padding-bottom: 25px;

      .info {
        margin: 20px;
      }

      .col-left {
        padding: 0;
      }

      .col-right {
        vertical-align: middle;
      }
    }
  }

  &.modal-info {
    padding-bottom: 10px !important;

    h4 {
      margin: 0 0 10px 0;
    }

    .row {
      padding: 10px 20px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      background: #f4f5f9;
      border-bottom: 1px solid #e1e1e1;

      >p {
        padding: 0 0 10px 0;
      }

      .col-left {
        width: 35%;
        line-height: inherit;
        text-align: left;

        i {
          font-size: 14px;
          width: 14px;
          color: $clr-pri;
          margin: 0 10px 0 0;
          text-align: center;
        }
      }

      .col-right {
        width: 59%;
      }

      label {}

      .info {
        vertical-align: middle;
        color: $clr-pri;
      }

      &.error {
        color: #fe596b !important;
        background: #ffeded;

        .col-left {
          i {
            color: #fe596b;
          }
        }

        .info {
          color: #fe596b;
        }
      }
    }
  }
}

.delete {
  position: absolute;
  right: 2px;
  top: 0px;

  .grid-column & {
    top: 5px;
  }

  a {
    display: block;
    width: 18px;
    height: 18px;
    background: $white;
    border-radius: 50%;

    i {
      font-size: 14px;
      line-height: 14px;
      color: #ec638d;
      vertical-align: middle;
    }

    &:hover {
      i {
        color: #ad4967;
      }
    }
  }
}

.custom-radio {
  display: inline-block;

  input[type="radio"] {
    display: none;

    +label {
      position: relative;
      display: inline-block;
      padding-left: 25px;
      cursor: pointer;
      vertical-align: middle;
      font-size: 13px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        width: 15px;
        height: 15px;
        text-align: center;
        color: black;
        border-radius: 50%;
        transition: all 0.3s ease;
      }

      &:before {
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 0.1em white, inset 0 0 0 1em white;
        border: 1px solid #d7dbdf;
      }

      &:hover:before {
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #c6c6c6;
      }

      span {
        display: block;
      }

      .thumb-container {
        max-width: 550px;
        height: 275px;
        overflow: hidden;
        border: 2px solid #e0e3ea;
        border-radius: 3px;
        margin: 10px 0 0 0;
        // img {
        //   width: 100%;
        //   transition: transform 0.5s ease-in-out;
        // }
      }

      &:hover {
        .thumb-container {
          box-shadow: 0 0 10px 0 rgba(120, 120, 120, 0.25);
          // img {
          //   transform: translate3d(0px, -1198px, 0px);
          //   transition: transform 10s ease-in-out;
          // }
        }
      }
    }

    &:checked+label:before {
      transition: all 0.3s ease;
      box-shadow: inset 0 0 0 0.1em white, inset 0 0 0 1em $clr-pri;
      border: 1px solid $clr-pri;
    }

    &:checked+label {
      .thumb-container {
        border-color: $clr-pri;
      }
    }
  }

  +input[type="text"] {
    clear: both;
    display: block;
    margin: 10px 0;
    max-width: 170px;
  }

  &.custom__label {
    width: 100%;

    input[type="radio"] {
      display: none;

      +label {
        display: block;

        &:before,
        &:after {
          top: 8px;
        }

        span {
          background: #f4f5f9;
          display: block;
          width: 100%;
          border: 1px solid #ebebeb;
          padding: 9px 10px;
          margin: 0 0 5px 0;

          strong {
            float: right;
            color: $clr-pri;
          }
        }
      }
    }
  }
}

.question-numbering {
  float: left;
  width: 100%;
  clear: both;
  margin: 0 0 5px 0;
  cursor: pointer;

  .numbering__label {
    background: #f4f5f9;
    display: inline-block;
    width: 90%;
    border: 1px solid #ebebeb;
    padding: 9px 10px;

    strong {
      float: right;
      color: $clr-pri;
    }
  }
}

.thumb-container {
  cursor: pointer;
  // max-width: 550px;
  height: 275px;
  overflow: hidden;
  border: 2px solid #e0e3ea;
  border-radius: 3px;
  margin: 10px 0 0 0;
  overflow-y: auto;

  // img {
  //   width: 100%;
  //   transition: transform 0.5s ease-in-out;
  // }
  &:hover {
    // .thumb-container {
    box-shadow: 0 0 10px 0 rgba(120, 120, 120, 0.25);
    // img {
    //   transform: translate3d(0px, -840px, 0px);
    //   transition: transform 5s ease-in-out;
    // }
    // }
  }
}

.form__action {
  text-align: right;

  .btn--submit {
    // min-width: 100px;
    float: left;
    text-align: left;
  }
}

.prefilled {
  .btn {
    margin: 0 8px 8px 0;
    padding: 5px 8px;
    background: $white;
    text-transform: none;
  }

  &.prefilled__qa {
    .btn {
      //  margin: 7px 7px 0 0;
    }
  }
}

.comment-classification {
  border-top: 2px dashed #ebebeb;
  float: left;
  width: 100%;

  .classification__table {
    // padding: 20px;
  }

  .classification__footer {
    float: left;
    width: 100%;
    padding: 20px;
  }

  .classification__comment {
    float: left;
    width: 100%;
    //border-bottom: 1px solid #ebebeb;
    padding: 20px 200px 20px 20px;
    position: relative;

    .remove__comment {
      position: absolute;
      right: 20px;
      top: 20px;
      text-align: right;
      color: $clr-red;
      cursor: pointer;
      font-size: 18px;
      // padding: 9px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &:hover {
        color: $clr-red;
        background: #f1f6fa;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #646464;
      line-height: 18px;
    }
  }
}

.result-panel {
  float: left;
  width: 100%;
  border: 1px solid #ebebeb;
  padding: 0;
  border-radius: 3px;

  h2 {
    font-size: 14px;
    color: #212229;
    border-bottom: 1px solid #ebebeb;
    font-weight: 500;
    padding: 7px 10px;
  }

  .panel__content {
    background: #f8f8f8;
    padding: 10px;
  }

  p {
    //  color: #989898;
    line-height: 18px;
  }
}

.part-speach {
  float: left;
  width: 100%;
  border: 1px solid #ebebeb;
  padding: 0;
  border-radius: 3px;

  h2 {
    font-size: 12px;
    color: #4d565f;
    border-bottom: 1px solid #ebebeb;
    font-weight: 500;
    padding: 10px;
  }

  .speach__content {
    background: #f8f8f8;
    padding: 10px;
  }

  p {
    color: #989898;
    line-height: 18px;
  }
}

.speach__part {
  display: inline-block;
  margin: 5px;
  text-align: center;

  p {
    border-top: 2px solid #ebebeb;
    padding-top: 4px;
    font-size: 13px;
    color: #4d565f;
  }

  span {
    display: inline-block;
    padding: 2px 10px;
    font-size: 11px;
    margin-bottom: 5px;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid $clr-pri;
    color: $clr-pri;
    background: lighten($clr-pri, 34%);
  }
}

.category-breadcrumbs {
  display: inline-block;

  @include susy-breakpoint(480px, $susy, ".no-mq") {
    padding-top: 5px;
  }

  li {
    display: inline;
    vertical-align: middle;
    white-space: normal;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    color: #969dab;

    &:before {
      color: #969dab;
      position: relative;
      //  top: 1px;
      font-size: 12px;
      margin: 0 10px;
      //  @include opacity(0.5);
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &.active {
      a {
        color: #969dab;
      }
    }
  }
}

.sentiment-tags {
  span {
    display: inline-block;
    padding: 2px 10px;
    font-size: 10px;
    position: relative;
    z-index: 1;
    line-height: 15px;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid $clr-pri;
    color: $clr-pri;
    background: lighten($clr-pri, 34%);

    &.negative {
      border: 1px solid $clr-red;
      color: $clr-red;
      background: lighten($clr-red, 40%);
    }

    &.positive {
      border: 1px solid $clr-green;
      color: $clr-green;
      background: lighten($clr-green, 60%);
    }

    &.unassigned {
      border: 1px solid #969dab;
      color: #969dab;
      background: lighten(#969dab, 60%);
    }

    &.neutral {
      border: 1px solid $clr-amber;
      color: $clr-amber;
      background: lighten($clr-amber, 38%);
    }
  }
}

.answer-container {
  float: left;
  width: 100%;
  border-top: 1px solid #ebebeb;

  .answer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 20px;

    h2 {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.comment-mic {
  cursor: pointer;
  @include transition();
  position: absolute;
  right: 20px;
  font-size: 18px;
  z-index: 1;
  border-radius: 100%;
  top: 30px;
  width: 30px;
  color: #bbbdbf;
  height: 30px;
  text-align: center;
  line-height: 30px;

  &:hover {
    color: $clr-pri;
  }

  .cat__list & {
    right: auto;
    left: 5px;
    top: 0;
  }

  .filter-search & {
    right: 40px;
    top: 1px;
  }

  .form-field & {
    top: 50%;
    margin-top: -15px;
    right: 10px;
  }

  .login & {
    right: 30px;
  }

  .search-field & {
    right: 40px;
    top: 1px;
  }

  .model-search & {
    right: 50px;
    top: 15px;
  }

  .template__subject & {
    top: 4px;
    right: 5px;
  }

  .filter__trigger & {
    right: 30px;
    top: 8px;
  }

  .filter--withicon.active & {
    right: 60px;
    top: 10px;
    background: #fff;
  }

  .translation__form & {
    right: 10px;
    top: 35px;
  }

  i {
    &:before {
      display: inline-block;
      font-family: "Font Awesome 5 Pro";
      content: "\f130";
      font-style: normal;
      position: relative;
      font-weight: 500;
    }
  }
}

.comment-mic.mic--active {
  color: $clr-pri;
  font-size: 11px;
  background: #e8e8e8;

  i {
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f04d";
      font-weight: 700;
    }
  }
}

.animation-outer {
  width: 20px;
  height: 20px;
  display: none;
  top: 5px;
  right: 5px;
  -webkit-transform: scale(0);
  border-radius: 100%;
  position: absolute;
  background-color: #e8e8e8;
  z-index: -1;
  transition: 1.5s all ease;
  -webkit-animation: woong 1.5s infinite;
  -moz-animation: woong 1.5s infinite;
  -o-animation: woong 1.5s infinite;
  animation: woong 1.5s infinite;

  .mic--active & {
    color: $clr-pri;
    display: block;
  }
}

@-o-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@-webkit-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@-moz-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }

  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}

voice-button {
  --button-diameter: 30px;
  --mic-color: #59b9ef;
  --text-color: #666666;
  --button-color: #fff;
  --circle-viz-color: #e8e8e8;
}

.comments-sentiments {
  float: left;
  width: 100%;
  margin: 20px 0 5px;

  // border-bottom: 1px solid #ebebeb;
  .sentiment__box {
    float: left;
    text-align: center;
    width: 150px;
    padding: 10px;
    margin-right: 10px;
    line-height: 15px;
    border-radius: 3px;
    border: 1px solid $clr-pri;
    color: #2e2e2e;
    background: lighten($clr-pri, 34%);

    h4 {
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 20px;
    }

    i {
      font-size: 27px;
      color: $clr-pri;
    }

    h5 {
      font-size: 12px;
      font-weight: normal;
      margin: 10px 0 0 0;
      text-transform: uppercase;
      color: $clr-pri;
    }

    &.negative,
    &.sad,
    &.angry {
      border: 1px solid $clr-red;
      background: lighten($clr-red, 44%);

      h5 {
        color: $clr-red;
      }

      i {
        color: $clr-red;
      }
    }

    &.positive,
    &.praise,
    &.happy {
      border: 1px solid $clr-green;
      background: lighten($clr-green, 64%);

      h5 {
        color: $clr-green;
      }

      i {
        color: $clr-green;
      }
    }

    &.unassigned,
    &.neutral {
      border: 1px solid #969dab;
      background: lighten(#969dab, 60%);

      h5 {
        color: #969dab;
      }

      i {
        color: #969dab;
      }
    }

    &.complain {
      border: 1px solid $clr-amber;
      background: lighten($clr-amber, 44%);

      h5 {
        color: $clr-amber;
      }

      i {
        color: $clr-amber;
      }
    }

    &.risk,
    &.acceptance {
      border: 1px solid #fd7e14;
      background: lighten(#fd7e14, 44%);

      h5 {
        color: #fd7e14;
      }

      i {
        color: #fd7e14;
      }
    }
  }
}






/// Theming STYLES ///

$arrow-size: 16px;

.overlay--hide--arrow {
  &::before {
    display: none;
  }
}

.theme-color-container {
 // background: red;
  // position: absolute;
  // top:35px;
  // right:0;
  // background: white;
  // width:275px;
  // border-radius:5px;
  // padding:15px 15px 8px;
  // text-align: center;
  // letter-spacing: 1px;
  // text-transform: uppercase;
  // -webkit-filter: drop-shadow(0 0 4px rgba(0,0,0,0.2));
  padding: 15px 15px 10px;
}

// .theme-color-container:after {
//   content: "";
//   width: $arrow-size;
//   height: $arrow-size;
//   display: block;
//   background-color: white;
//   position: absolute;
//   top: 1px;
//   margin-top:- calc($arrow-size / 2);
//   -webkit-transform: rotate(45deg);
// }
// .arrow--top:after{ right:5%; }


.color-palette {
  background: #fff;
  padding: 5px;
  position: relative;
  cursor: pointer;
  width: 45px;

  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d0d7dd;

  .colors__container {
    width: 20px;
    border: 1px solid #d0d7dd;
    height: 20px;
  }
}

.select-theme {
  display: flex;
  position: absolute;
  align-items: center;
  right: 2px;
  top: -10px;
  gap: 10px;
}


.select-theme-list {
  list-style: none;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  //  align-items: center;
  width: 100%;
  justify-content: flex-start;

  flex-flow: row wrap;

  li {
    padding: 0 2px 2px 2px;

    input {
      position: absolute;
      left: 0;
      top: -300%;
      opacity: 0;
      visibility: hidden;

      &:checked+label {
        border-color: #6CA1AD;
        opacity: 1;

        &:after {
          content: "";
          opacity: 1;
        }
      }
    }

    label {
      width: 30px;
      color: #fff;
      height: 30px;
      //  line-height:30px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      border: 0px solid #535151;
      cursor: pointer;
      text-align: center;
      // opacity: 0.5;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;

      &:after {
        font-size: 13px;
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -7px;
        top: 50%;
        margin-top: -7px;
        font-family: "Font Awesome 5 Pro";
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        -webkit-transition: opacity 0.3s ease-in-out;
        moz-transition: opacity 0.3s ease-in-out;
        o-transition: opacity 0.3s ease-in-out;
      }
    }
  }
}



.upload-image-link {
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $clr-pri;
  }
}

.upload-image-title{
  margin-bottom: 10px;
  font-size:14px;
}


.upload-image-container {
  display: flex;
  margin-bottom:20px;
  .image-block{
    padding: 0;
  }
  p{
    font-size:12px;
    margin-bottom: 10px;
  }

  .btn{
    padding:8px 10px;
  }

}