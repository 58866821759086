.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
