.sm-kpi {}

.kpi__card {
  @include span(3);
  border-right: 1px solid #e1e1e1;
  min-height: 165px;
  padding: 20px 15px 20px 10px;
  position: relative;

  @include susy-breakpoint($md, $susy, ".no-mq") {
    @include span(12);
    min-height: 50px;
    border-right: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e1e1;
  }

  widget-kpi-total-reviews & {
    border: 0;
  }

  .temp-loader {
    right: 10px !important;
    top: 10px !important;
  }

  .card__title {
    font-size: 13px;
    color: #383838;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .card__label {
    font-size: 12px;
    color: #969dab;
    margin-top: 5px;
  }

  .chart__detail {
    padding: 10px 0;
    position: relative;
  }

  .card__chart {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;

    .highcharts-container {
      overflow: visible !important;
    }

    .highcharts-tooltip>span {
      padding: 8px;
      font-size: 11px !important;
      position: relative;
      background-color: rgba(255, 255, 255, 1);
      background-color: white \9;
      /* < IE11 */
      //  border: 1px solid #a8a7a5;
      z-index: 9999 !important;
    }
  }

  .card__score {
    font-size: 26px;
    color: #383838;
    font-weight: normal;

    small {
      font-size: 11px;
      margin-left: 1px;
    }

  }

  .card__footer {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
    position: relative;
    padding-left: 20px;

    &:before {
      position: absolute;
      font-family: "Font Awesome 5 Pro";
      left: 0;
      content: '';
      font-size: 12px;
      font-weight: 700;
      //  top:0;

    }

    strong {
      font-weight: 600;
    }

    i {
      //cursor: pointer;
    }



  }

  &.card--negative {
    .card__footer {
      strong {
        color: $clr-red;
      }

      &:before {
        content: "\f0ab";
        color: $clr-red;
      }

    }
  }

  &.card--positive {
    .card__footer {
      strong {
        color: $clr-green;
      }

      &:before {
        content: "\f0aa";
        color: $clr-green;
      }
    }
  }

  &.card--neutral {
    .card__footer {

      strong,
      i {
        color: $clr-amber;
      }

      &:before {
        content: "\f056";
        color: $clr-amber;
      }
    }
  }
}

// NEW KPI-SM

.socialmedia-newkpi {
  background: $white;
  position: relative;
  min-height: 100px;
  width: 100%;
  padding: 0;
  @include clearfix();
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

  .sm__card {
    @include span(3);
    border-right: 1px solid #e1e1e1;
    min-height: 165px;
    padding: 13px;
    position: relative;

    h2 {
      font-size: 12px;
      color: #3a3c2f;
      margin-bottom: 15px;
      font-weight: 600;
    }

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      @include span(12 no-gutters);
      border-right: 0;
      min-height: 100px;
      padding: 20px;
      border-bottom: 1px solid #e1e1e1;
    }
  }

  &.kpi--voc{
    .sm__card {
    min-height: 195px;
    }
  }
}

.top-revirew-score {
  .revirew__item {
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .item__icon {
      width: 28px;
      text-align: center;
    }

    .item__description {
      flex: 1;
      margin-left: 20px;
    }

    .review__top {
      display: flex;
      font-size: 12px;
      justify-content: space-between;

      >span {
        font-weight: bold;
      }
    }

    .star-icon {
      color: $clr-yellow;

      i {
        margin: 0 0 0 5px;
      }
    }

    .progress-rating-label {
      color: $clr-pri;
    }

    .progress {
      float: left;
      width: 100%;
      margin: 5px 0 0;
      border: 0;
      border-radius: 0;

      .progress__bar {
        float: left;
        background: $clr-pri;
        animation: grower 3s linear;
        -moz-animation: grower 3s linear;
        -webkit-animation: grower 3s linear;
        -o-animation: grower 3s linear;
        height: 7px;
      }

    }
  }
}

.average-response {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 12px;
    margin-bottom: 5px;
    color: #3a3c2f;
  }

  h3 {
    font-size: 26px;
    font-weight: normal;

    small {
      font-size: 11px;
      margin: 1px;
      position: relative;
    }
  }

  .card__chart {
    float: left;
    width: 155px;
    clear: both;
  }
}

.review-summary {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;

  .summary__review {
    text-align: center;

    h3 {
      font-size: 32px;
      font-weight: normal;
      line-height: 32px;
    }

    .star-icon {
      font-size: 14px;
      color: #cfd9dd;

      i {
        margin: 0 2px;

        &.active {
          color: $clr-yellow;
        }
      }


    }

    p {
      font-size: 14px;
      margin-top: 6px;
      font-weight: 300;
      color: #3a3c2f;
    }
  }

  .summary__progress {
    flex: 1;
    margin-left: 25px;
  }

  .progress-container {
    //display: flex;
    //align-items: center;
    margin-top: 5px;
    width: 100%;
    display: table;

    .col {
      display: table-cell;
      font-size: 11px;
      color: #3a3c2f;

      &:first-child {
        width: 65px;
      }

      &:last-child {
        width: 30px;
        text-align: right;
      }
    }

    .progress {
      float: left;
      width: 100%;
      margin: 0;
      padding: 0 5px;
      min-width: 50px;
      border: 0;
      border-radius: 0;
      background: white;

      .progress__bar {
        float: left;
        background: $clr-pri;
        animation: grower 3s linear;
        -moz-animation: grower 3s linear;
        -webkit-animation: grower 3s linear;
        -o-animation: grower 3s linear;
        height: 7px;
      }

      &.progress--negative {
        .progress__bar {
          background: $clr-red;
        }
      }

      &.progress--positive {
        .progress__bar {
          background: $clr-green;
        }
      }

      &.progress--neutral {
        .progress__bar {
          background: $clr-yellow;
        }
      }
    }

    // .col-left {
    //  // width: 55px;
    //   font-size: 13px;
    //   color: #3a3c2f;
    // }

    // .col-right {
    //   flex: 1;
    //   margin-left: 10px;
    // }
  }
}

.top-review {
   display: flex;
   flex-flow: column;
   justify-content: center;
   min-height: 125px;
  // justify-content:space-between;
  .review__top {
    //flex: 1;
    // margin-left: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 30px;
      font-weight: normal;
    }

    .card__chart {
      flex: 1;
    }
  }

  .review__bottom {
    //flex: 1;
    // margin-left: 10px;
    display: flex;
    justify-content: space-between;
    margin-top:20px;
    
  }

  p {
    font-size: 12px;
    margin: 0 0 5px;
    color: #3a3c2f;
  }

  h3 {
    font-size: 32px;
    font-weight: 300;
  }
}

.dealership {
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid #d5d5d5;
  padding: 2px 2px 0 10px;

  &:last-child {
    border: 0;
  }

  &:first-child {
    padding-left: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 11px;
    color: #3a3c2f;
  }

  .badge {
    position: relative;
    width: 19px;
    display: inline-block;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    top: -1px;
    height: 19px;
    line-height: 19px;

    span {
      color: white;
      position: relative;
    }

    &:before {
      content: "\f335";
      left: 0;
      font-family: "Font Awesome 5 Pro";
      font-size: 18px;
      position: absolute;
      color: #b3b3b3;
      font-weight: 600;
    }

    &.badge--one {
      &:before {
        color: #eec82e;
      }

    }

    &.badge--three {
      &:before {
        color: #d29a57;
      }
    }
  }

}




/// sentiment summary

.sentiment-summary {
  display: flex;
  padding-top:0;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;

  .summary__review {
    text-align: center;
    margin-bottom:15px;

    h3 {
      font-size: 32px;
      font-weight: 300;
      line-height: 32px;
    }

    p {
      font-size:10px;
      margin-top:2px;
      font-weight: 400;
      color: #a9aba1;
    }
  }

  .summary__progress {
   width:95%;
  }

  .progress-container {
    display: flex;
    gap: 5px;
    padding: 5px 0;
    width: 100%;
    align-items: center;

    .col {
      font-size:12px;
      color: #000;
      font-weight: 500;

      &:last-child {
        flex: 1;
      }
    }

    .sentiment-progress {
      display: flex;

      align-items: center;
      .bar {
        background: $clr-pri;
        font-size: 11px;
        color: white;
        text-shadow:1px 1px #000;
        text-align: center;
       // font-weight: bold;
        height: 18px;
        line-height: 18px;

        &.bar--positive {
          background: $clr-green;
          background: #78ec6c;
        }

        &.bar--neutral {
          background: $clr-yellow;
          background: #00aeef;
        }

        &.bar--negative {
          background: $clr-red;
          background: #f18961;
        }
      }
    }
  }
}