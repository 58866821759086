//customized dashboard

.customized-dashboard {
  float: left;
  width: 100%;
  padding: 0;
  margin: 20px 0;
  border: 1px solid #ebebeb;

  .customize__column {
    @include span(6 no-gutters);

    &:last-child {
      border-left: 1px solid #ebebeb;
    }
  }

  .col__header {
    float: left;
    width: 100%;
    background: #f5f6f7;
    padding: 10px 15px;
    border-bottom: 1px solid #ebebeb;

    .item-selected {
      float: left;
      font-size: 12px;
    }

    .remove {
      float: right;
      font-size: 13px;
      font-weight: 500;
      color: #a9b4bd;
      cursor: pointer;
    }
  }

  .cdk-drag-placeholder {
    // background: #ccc;
    background: #fbfbfb;
    border: dashed 2px #999;
    min-height: 30px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.customize__list {
  background: $white;
  height: 160px;
  clear: both;
  outline: none;
  overflow-y: auto;

  li {
    border-bottom: 1px solid #ebebeb;
    font-size: 12px;
    padding: 10px 10px 10px 30px;
    position: relative;

    &:last-child {
      border: 0;
    }

    &:before {
      content: "\f338";
      position: absolute;
      left: 13px;
      top: 10px;
      font-family: "Font Awesome 5 Pro";
      color: #96a2b6;
      font-size: 12px;
    }

    &:hover,
    &:active {
      background: #fbfbfb;
      cursor: move;
    }
  }

  .add {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    width: 35px;
    height: 35px;
    color: $clr-pri;
    z-index: 1;
    font-size: 12px;

    i {
      top: 10px;
      position: relative;
    }

    &:hover {
      cursor: pointer;
    }
  }

}

.cdk-drag-preview {
  //  box-sizing: border-box;
  //border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  font-size: 12px;
  z-index: 1000000 !important;
  padding: 10px 10px 10px 30px;
  background: white;
  cursor: move;
  // border:dashed 2px #ebebeb;

  .add,
  .actions,
  .image-tooltip-wrapper {
    display: none;
  }

}

.summary__heading {
  position: relative;
}


.ui-dialog--customized-dashboard {
  background: #f4f5f9;

  .ui-dialog-titlebar {
    background: $white;
    padding: 20px 0 10px;

    h2 {
      padding: 0 20px;
    }
  }

  .ui-dialog-content {
    padding: 0;
  }

  .input-search {
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px 0;
    border-top: 1px solid #d5d5d5;
    background: #fff;

    &:before {
      left: 20px;
      margin-top: -2px;
    }

    .p-inputtext,
    .ui-inputtext {
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal__sidebar {
    float: left;
    width: 30%;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    position: fixed;



    h2 {
      text-transform: uppercase;
      margin-bottom: 5px;
      color: #222d38;

      :lang(fr) {
        text-transform: none;
      }
    }

    ul.left-nav {
      li {
        margin: 0 0 8px 0;

        a {
          font-size: 12px;
          position: relative;
          display: block;
          color: #34495e;
          background: $white;
          border-radius: 3px;
          vertical-align: middle;
          padding: 8px 25px 8px 38px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.2);

          i {
            position: absolute;
            left: 10px;
            top: 50%;
            margin: -8px 0 0 0;
            font-size: 16px;
            line-height: 16px;
            width: 20px;
            text-align: center;
            height: 16px;
            vertical-align: middle;
            color: #cbcbcb;
          }

          .close {
            height: 16px;
            right: 10px;
            margin: -8px 0 0;
            position: absolute;
            top: 50%;
            width: 16px;
            cursor: pointer;

            i {
              left: 0;
            }
          }

          &:hover,
          &.active {
            background: $clr-pri;
            color: $white;

            i {
              color: $white;
            }
          }
        }

        &.disabled {

          a {
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
              color: inherit;
              background: white;
            }

            i {
              color: inherit;
            }
          }

        }
      }
    }
  }

  .modal__content {
    min-height: 400px;
    //  max-height: 400px;
    float: left;
    width: 70%;
    padding: 0;
    margin-left: 30%;
    background: $white;
    border-left: 1px solid #d7d7d7;


    .modal-form {
      padding: 20px 20px 40px;

      //min-height: 400px;
      //max-height: 400px;
      //overflow-y: auto;
      .row {
        .col-left {
          width: 25%;
        }

        .col-right {
          width: 70%;

          &.align-right {
            padding-left: 25%;
          }
        }

        input,
        textarea,
        .upload-field,
        .info {
          width: 100%;
        }

        .upload-photo {
          width: 100%;
          text-align: center;

          .profile-photo {
            width: 100%;
            height: 100px;

            i {
              font-size: 120px;
              line-height: 100px;
              height: 100px;
              color: #cdd4d8;
            }

            img {
              border-radius: 50%;
              max-width: 100px;
              vertical-align: top;
              border: 1px solid #cdd4d8;
            }
          }

          .btn {
            width: 40px;
            height: 40px;
            padding: 0;
            text-align: center;
            border-radius: 100%;
            margin: -25px 0 0 0;

            i {
              position: relative;
              left: 0;
              top: 0;
              font-size: 20px;
              line-height: 38px;
              text-align: center;
            }
          }
        }

        a {
          color: $clr-pri;

          &:hover {
            color: $clr-sec;
          }
        }
      }
    }
  }

  .ui-dialog-footer {
    clear: both;
    background: $white;

    .btn {
      padding: 7px 25px 6px;
    }
  }
}

.customized-widgets__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.customized-widgets {
  padding: 20px;

  .widget__item {
    margin-bottom: 20px;
    position: relative;
    padding-right: 100px;
    float: left;
    width: 100%;

    .widget__image {
      border: 1px solid #d0d7dd;
      height: 98px;
      width: 125px;
      text-align: center;
      float: left;
      border-radius: 3px;
      margin-right: 15px;
    }

    h2 {
      color: #4d565f;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
    }

    p {
      color: #4d565f;
      font-size: 12px;
    }

    .btn {
      position: absolute;
      right: 0;
      top: 30px;
    }
  }
}

.reordering-icons {
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 20px;

  a {
    margin: 0 2px;

    &.remove {
      color: #ef207d;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
      color: #d7d7d7;
    }
  }
}