.ql-editor .mention {
  color: $clr-pri !important;
  text-decoration: none !important;
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #edeef1;
  padding: 3px 5px;
  margin-left: 5px;
}

.ql-mention-list-item {
  &.selected {
    background-color: #edeef1;
    text-decoration: none;
    border-radius: 0 !important;
    width: auto !important;
  }
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 10px;
  vertical-align: middle;
}

.ql-mention-list-container {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  position: absolute;
  visibility: visible;
  top: 30px;
  left: 105px;
  width: auto !important;
  overflow: auto;
  max-height: 150px;
  z-index: map-get($zindex, qlmention);
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-toolbar {
  border-bottom: 1px solid #d1d1d1 !important;
  background: #f8f8f8 !important;
  padding: 6px 8px 2px !important;
  white-space: normal !important;
  padding: 4px !important;
}

.ql-snow {
  &.ql-toolbar button,
  .ql-toolbar button,
  .ql-picker,
  .ql-picker-label {
    height: 22px !important;
  }
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 1px !important;
}
//survey designer mentions
.mention-list {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  margin: 31px 0 0 14px;
  position: relative;
  z-index: 4;
  .dropdown-menu {
    li {
      padding: 0 !important;
      border: none !important;
      &:before {
        content: '' !important;
        display: none;
      }
      a {
        display: block;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
        padding: 3px 10px;
        color: #666;
      }
      &.active {
        a {
          background-color: #edeef1;
        }
      }
    }
  }
}

