.main-sidebar {
  top: 70px;
  border-top: 1px solid #e1e4e7;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.5, 0.32, 0.01, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.32, 0.01, 1);
  left: -192px;
  z-index: map-get($zindex, sidebar);
  height: 100%;
  width: 257px;
  position: fixed;
  background: $white;

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    left: -262px;
    top: 55px;
  }

  ul {
    opacity: 0;
    @include transition();
    visibility: hidden;
    padding-top: 50px;

    li {
      a {
        display: block;
        padding: 15px 30px 15px 20px;
        border-bottom: 1px solid #ebebeb;
        position: relative;
        color: #494c57;
        cursor: pointer;
        font-size: 12px;
        @include transition();

        &:hover {
          color: $clr-pri;
        }

        &:after {
          content: "\f061";
          font-family: "Font Awesome 5 Pro";
          font-size: 10px;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -5px;
          //
        }
      }

      &.active {
        >a {
          color: $clr-pri;
        }
      }

      &.selected {
        >a {
          border-left: 4px solid $clr-pri;
          color: $clr-pri;

          &:after {
            content: "\f063";
          }
        }
      }

      ul {
        margin: 0;
        padding: 10px 0 10px 30px;
        background: #f5f6f7;

        li {
          a {
            border: 0;
            padding: 8px;
            color: #969dab;

            &:after {
              display: none;
            }
          }

        }
      }
    }
  }

  &.active {
    left: 0;

    ul {
      opacity: 1;
      visibility: visible;
    }
  }
}

.sidebar-header {
  position: relative;
  float: left;
  width: 100%;
}

.sidebar-overlay {
  position: fixed;
  z-index: map-get($zindex, sidebaroverlay);
  width: 100%;
  height: 100%;
  top: 70px;
  bottom: 0;
  left: 0;
}

// hamburger

$bar-width: 18px;
$bar-height: 2px;
$bar-spacing: 6px;

.menu-wrapper {
  position: absolute;
  top: 23px;
  right: 23px;
  bottom: 0;
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  z-index: 10;
  cursor: pointer;

  @include susy-breakpoint($md, $susy, ".no-mq") {
    display: block;
  }

  .header & {
    top: 0;
    left: 0;
    right: auto;
    height: 55px;
    width: 35px;
    padding: 21px 0 0 12px;
    display: none;

    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      display: block;
    }

    @include susy-breakpoint(480px, $susy, ".no-mq") {
      //left: 10px;
    }
  }
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(202, 206, 213, 1);
  transition: all 0ms 300ms;

  &.animate {
    background: rgba(202, 206, 213, 0);
  }
}

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(202, 206, 213, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(202, 206, 213, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}