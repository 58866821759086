.p-tooltip {
  .p-tooltip-text {
    background-color: $tooltipBgColor;
    color: $tooltipTextColor;
    padding: $tooltipPadding;
    @include shadow($overlayContainerShadow);
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: $tooltipBgColor;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: $tooltipBgColor;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $tooltipBgColor;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $tooltipBgColor;
    }
  }
}
.p-tooltip {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  max-width: 25em;
  font-size: 12px;
  &.p-tooltip-right,
  &.p-tooltip-left {
    padding: 0 0.25em;
  }
  &.p-tooltip-top,
  &.p-tooltip-bottom {
    padding: 0.25em 0;
  }
  &.p-tooltip-top {
    margin: -3px 0 0 0;
  }
  &.p-tooltip-bottom {
    margin: 3px 0 0 0;
  }
  &.p-tooltip-right {
    margin: 0 0 0 3px;
  }
  &.p-tooltip-left {
    margin: 0 0 0 -3px;
  }

  .p-tooltip-text {
    padding: 0.25em 0.75em;
    background-color: rgb(76, 76, 76);
    color: #ffffff;
    white-space: pre-line;
    border-radius: 3px;
  }
  &.tooltip--questionblock {
    max-width: 186px;
    .p-tooltip-text {
      background-color: #f3f6f8;
      color: #34495e;
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      @include shadow($overlayContainerShadow);
      p {
        padding: 0 0 15px 0;
      }
    }

    &.p-tooltip-right {
      margin: 0 0 0 10px;
      .p-tooltip-arrow {
        border-right-color: #f3f6f8;
        margin-top: -10px;
        margin-left: -7px;
        border-width: 10px 10px 10px 0;
      }
    }

    &.p-tooltip-left {
      margin: 0 0 0 -10px;
      .p-tooltip-arrow {
        border-left-color: #f3f6f8;
        margin-top: -10px;
        margin-right: -7px;
        border-width: 10px 0 10px 10px;
      }
    }

    &.p-tooltip-top {
      margin: -10px 0 0 0;
      padding: 10px 0;
      .p-tooltip-arrow {
        border-top-color: #f3f6f8;
        margin-left: -10px;
        margin-bottom: 0px;
        border-width: 10px 10px 0;
      }
    }

    &.p-tooltip-bottom {
      margin: 10px 0 0 0;
      .p-tooltip-arrow {
        border-bottom-color: #f3f6f8;
        margin-left: -10px;
        margin-top: -7px;
        border-width: 0 10px 10px;
      }
    }

  }
}

.p-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0.25em 0.25em 0;
  border-right-color: rgb(76, 76, 76);
}

.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0 0.25em 0.25em;
  border-left-color: rgb(76, 76, 76);
}

.p-tooltip.p-tooltip-top {
  padding: 0.25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0.25em 0.25em 0;
  border-top-color: rgb(76, 76, 76);
}

.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0 0.25em 0.25em;
  border-bottom-color: rgb(76, 76, 76);
}
