.question-block-main {
  //background: $white;
  //box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  //border-radius:3px;
  //padding: 20px 25px;
  &.widget {
    margin-bottom: 20px !important;
  }
  .question-header {
    padding: 4px 0;
    @include clearfix();
    .tag {
      float: left;
      border: 1px solid $clr-pri;
      text-transform: uppercase;
      color: $clr-pri;
      font-weight: 600;
      font-size: 11px;
      padding: 2px 4px 1px;
      :lang(fr) {
        text-transform: none;
      }
    }
    .actions {
      float: right;
      i {
        font-size: 18px;
      }
      a {
        margin: 0 0 0 10px;
        color: #cdd4d8;
        &:hover {
          color: $clr-pri;
        }
      }
    }
  }
  .question-copy {
    font-size: 14px;
    line-height: 130%;
    outline: none;
    //margin: 0 0 30px 0;
    margin: 0 0 10px 0;
    > p,
    .question-copy-text {
      position: relative;
      padding: 0 0 7px 45px;
      word-wrap: break-word;
      > span {
        position: absolute;
        left: 0;
        top: 0;
        color: $clr-pri;
      }
      &.instructions {
        font-size: 12px;
        padding: 5px 0 0 0;
        color: #b1b9c1;
      }
    }
    &[contenteditable="true"] {
      cursor: text;
      span {
        cursor: default;
      }
    }
    .action {
      position: relative !important;
      left: auto !important;
      top: auto !important;
      display: inline-block;
      padding: 0 0 0 5px;
      i {
        font-size: inherit;
        vertical-align: middle;
      }
      a {
        color: $clr-pri;
        &:hover {
          color: $clr-sec;
        }
      }
    }
  }
  .answer-options {
    color: #a6aaaf;
    outline: none;
    //padding: 0 0 5px 45px;
    padding: 5px 0 5px 45px;

    &.side--answer{
      padding: 5px 0 5px 5px;
    }
    font-size: 12px;
    @include clearfix();
    .action {
      display: inline-block;
      padding: 0 0 0 5px;
      i {
        font-size: inherit;
        vertical-align: middle;
      }
      a {
        color: $clr-pri;
        &:hover {
          color: $clr-sec;
        }
      }
    }
    ul.grid-column {
      float: left;
      width: 33.33%;
      vertical-align: top;
      //padding: 7px 0 10px 0;
      padding: 7px 0 20px 0;
      font-size: 12px;
      li {
        clear: both;
        padding: 8px 50px 7px 2px;
        position: relative;
        border-bottom: 1px solid #d4dfe4;
        white-space: normal;
        word-break: break-all;
        &:before {
          content: "- ";
          padding: 0 4px 0 5px;
        }
        &.list-question {
          position: relative;
          min-height: 29px;
          font-size: 14px;
          font-weight: 400;
          color: #4d565f;
          border-bottom: 2px solid $clr-pri;
          padding: 0 0 10px 0;
          margin: 0 0 15px 0;
          &:before {
            content: "";
            padding: 0;
          }
          div[contenteditable="true"] {
            cursor: text;
          }
        }
        &.options-choices {
          margin: 0 0 15px 0;
          border-bottom: none;
          padding: 8px 8px 7px 2px;
          display: table;
          width: 100%;
          &:before {
            content: "";
            padding: 0;
          }
          > div {
            display: table-cell;
            &:not(:first-child) {
              text-align: right;
            }
          }
          .choices {
            font-size: 12px;
            font-weight: 600;
            color: #4d565f;
            //margin-top: 0;
            margin-top: -10px;
            p {
              padding: 5px 0;
            }
            .options {
              padding: 5px 0;
              line-height: 20px;
              span {
                padding: 0 5px;
              }
              i {
                font-size: 14px;
                line-height: 20px;
                color: $clr-pri;
                vertical-align: top;
              }
              .disabled {
                pointer-events: none;
                cursor: not-allowed;
                i {
                  color: #d7d7d7;
                }
              }
            }
          }
          .toggle {
            margin: 0 0 5px 0;
            color: #4d565f;
            .ui-inputswitch {
              margin: 0 0 0 5px;
            }
          }
        }
        .action {
          position: absolute;
          top: 5px;
          right: 4px;
          i {
            font-size: 14px;
          }
          a {
            color: $clr-pri;
            &:hover {
              color: $clr-sec;
            }
            &.close {
              color: #ec638d;
            }
          }
          &.negative {
            color: $clr-negative;
          }
          &.positive {
            color: $clr-positive;
          }
          &.none {
            color: $clr-none;
          }
          &.action--image {
            right: 25px;
            top: 5;
            a {
              color: #d1dce2;
              i {
                font-size: 20px;
              }
            }
            //+ .delete {
            //  position: absolute;
            //  top: -2px;
            //  right: 2px;
            //}
          }
        }
        &[contenteditable="true"]:active,
        &[contenteditable="true"]:focus,
        &.active {
          border: 2px dashed #e9ecef;
          padding: 6px 48px 6px 0px;
          .action {
            &.action--image {
              top: 3px;
              right: 23px;
            }
          }
        }
        &[contenteditable="true"] {
          cursor: text;
        }
        &.image--option {
          padding-right: 50px;
        }
        &.options {
          margin-right: 5px;
        }
      }
      .ui-scrollpanel-wrapper-hidden {
        li {
          &.options {
            margin-right: 0;
          }
        }
      }
      &.double-column {
        width: 66.66%;
        li {
          float: left;
          width: calc(50% - 5px);
          clear: none;
          &.tooltip-container {
            margin-left: 10px;
            padding-left: 5px;
            &:before {
              display: none;
            }
            .tooltip-label {
              color: #666;
            }
          }
        }
      }
    }
    &.multiple--columns {
      overflow-x: auto;
      ul {
        padding: 7px 0 7px 20px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  &:hover {
    //box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.4);
  }
  &.active {
    //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
    &:hover {
      //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
    }
    .edit {
      display: none;
    }
  }
}
.header-options {
  //box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.25);
  //margin-bottom: 20px;
  //border-radius: 5px;
  &.widget {
    margin-bottom: 20px !important;
  }
  .question-block-main {
    //box-shadow: none;
    //border-radius: 5px 5px 0 0;
    //padding: 20px 25px;
    //margin-bottom: 0;
    position: relative;
    min-height: 80px;
    .question-copy {
      p {
        padding: 0;
        &.instructions {
          font-size: 12px;
          color: #b1b9c1;
        }
      }
    }
    &:hover {
      //box-shadow: none;
    }
    &.active {
      //box-shadow: none;
      &:hover {
        //box-shadow: none;
      }
    }
  }
  &:hover {
    //box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.4);
  }
  &.active {
    //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
    &:hover {
      //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
    }
  }
  .upload-section {
    background: #f4f5f9;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #ebebeb;
    @include clearfix();
    .col {
      float: left;
      width: 50%;
      padding: 20px 25px;
      &:first-child {
        border-right: 1px solid #ebebeb;
      }
    }
  }
  .tag {
    background: $clr-pri;
    border: 1px solid $clr-pri;
    color: $white;
  }
  .image-preview {
    width: 360px;
    position: absolute;
    right: 0;
    top: -10px;
  }
  &.header-options {
    .question-block-main {
      //border-radius: 5px;
      //padding: 0 300px 70px 25px;
      //position: relative;
      .question-copy {
        margin-bottom: 0;
        padding: 0 390px 0 0;
      }

      &:first-child {
        //padding: 20px 25px 0 25px;
        //border-radius: 5px 5px 0 0;
        + .question-block-main {
          //border-radius: 0 0 5px 5px;
        }
      }
    }
    &:hover {
      //box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.4);
    }
    &.active {
      //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
      &:hover {
        //box-shadow: 0px 0px 16.2px 1.8px rgba(146, 146, 146, 0.5);
      }
    }
  }
}
.journey-index {
  .widget {
    padding: 20px 35px;
    box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.25);
    .question-block-main {
      box-shadow: none;
      border-radius: 0;
      padding: 20px 5px;
      margin-bottom: 0;
      border-top: 1px solid #e4e6e7;
      .question-header {
        margin: 0 0 10px 0;
      }
      &:first-child {
        border-top: none;
      }
      &:hover {
        box-shadow: none;
      }
      &.active {
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }
      .question-copy {
        margin: 0 0 10px 0;
      }
    }
    .content-header + .question-block-main {
      border-top: none;
    }
    .content-header {
      h2 {
        color: $clr-pri;
        font-weight: normal;
      }
    }
    &.widget--emailtemplate {
      padding: 20px;
      .left-column {
        float: left;
        width: 90px;
      }
      .right-column {
        float: left;
        width: calc(100% - 90px);
        padding: 0 0 0 20px;
      }
      .info-icon {
        text-align: center;
        a {
          color: #cdd4d8;
          &:hover {
            color: $clr-pri;
          }
        }
      }
      .row {
        margin: 0 0 20px 0;
      }
      .form-label {
        font-size: 14px;
        margin: 0 0 3px 0;
        .actions {
          float: right;
        }
      }
      .form-field {
        input {
          max-width: 200px;
          &.invite-subject {
            max-width: 400px;
          }
        }
        &.invite-message {
          min-height: 250px;
          border: 1px solid #d0d7dd;
        }
      }
      .invite-icon {
        margin: 0 0 0 10px;
        a {
          color: #cdd4d8;
          &:hover {
            color: $clr-pri;
          }
        }
      }
    }
  }
}

.options-grid {
  clear: both;
  //margin: 0 0 30px;
  margin: 0;
  .content-header {
    margin: 0px;
    h3 {
      font-size: 12px;
      font-weight: bold;
      padding: 20px 0 0 0;
    }
    .actions {
      padding: 0;
      a {
        color: #d1dce2;
        margin: 0 0 0 5px;
        &:hover,
        &.active {
          color: $clr-pri;
        }
      }
    }
  }
}
.conditions-block {
  clear: both;
  //margin: 0 0 30px 45px;
  margin: 0 0 10px 45px;
  font-size: 13px;
  color: #34495e;
  .content-header {
    margin: 10px 0;
    h3 {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .conditions {
    padding: 5px 15px;
    background: #f4f5f9;
    border: 2px solid #ebebeb;
    border-radius: 3px;
    textarea {
      height: 100px;
      padding: 8px 12px;
    }
    > ul {
      display: table;
      @include clearfix();
      > li {
        display: table-cell;
        padding: 10px 30px 10px 0;
        vertical-align: middle;
        border-bottom: none !important;
        > label {
          display: inline-block;
          padding: 3px;
        }
        .actions {
          margin: 0 0 -4px 0;
          .btn {
            margin-right: 10px;
          }
        }
        .inline-label {
          padding: 0 10px 0 0;
          vertical-align: middle;
        }
        input[type="checkbox"]:not(old) ~ label {
          color: #34495e;
        }
        &:before {
          display: none !important;
        }
      }
    }
    &.add-to-bank {
      padding: 15px 20px 12px;
      > ul {
        width: 100%;
        @include clearfix();
        > li {
          float: left;
          width: calc(100%/2);
          max-width: 350px;
          padding-right: 20px;
          vertical-align: middle;
          .toggle-options {
            label {
              display: inline-block;
            }
            .toggle {
              display: inline-block;
              padding: 3px 5px;
            }
          }
          input[type="text"] {
            width: 100%;
          }
        }
      }
    }
    .form-container {
      padding: 0;
      .form-label {
        > span {
          margin: 0 10px 0 0;
        }
      }
      .ui-inputswitch {
        //margin: 8px 0 9px;
      }
      .number-length {
        width: 80px;
        &.full--width {
          width: 100%;
        }
      }
    }
  }
}

.logic-block {
  clear: both;
  margin: 0 0 15px 45px;
  font-size: 14px;
  color: #bfc1c4;
  @include clearfix();
  .content-header {
    margin: 5px 0;
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #4d565f;
    }
  }
  .logic {
    float: left;
    clear: left;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    margin: 0 0 15px 0;
    padding: 0 30px 0 0;
    width: 66.6%;
    position: relative;
    p {
      span {
        color: #4d565f;
      }
    }
    .logic-content {
      float: left;
      font-size: 12px;
      line-height: 20px;
      padding: 5px 10px;
      width: 100%;
      border-right: 1px solid #e1e1e1;
      border-top: 1px dotted #e1e1e1;
      &:first-child {
        border-top: none;
      }
    }
    .actions {
      position: absolute;
      right: 10px;
      top: 50%;
      margin: -15px 0 0 0;
      i {
        font-size: 14px;
        line-height: 30px;
      }
      a {
        display: block;
        color: $clr-pri;
        &:hover {
          color: $clr-sec;
        }
      }
    }
  }
}

.information-block {
  float: left;
  width: 100%;
  clear: both;
  margin: 0 0 15px 45px;
  font-size: 14px;
  color: #999;
  @include clearfix();

  .information__header {
    margin: 5px 0;
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #4d565f;
    }
  }
  .information__content {
    float: left;
    min-width: 55%;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }
}

.question-bank-filters {
  @include clearfix();
  position: relative;
  z-index: 4;
  border-radius: 3px;
  // box-shadow: 0px 0px 4.05px 0.95px rgba(146, 146, 146, 0.25);
  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background: #f4f5f9;
  margin: 15px 0 15px;
  border: 1px solid #dde5eb;
  h4 {
    float: left;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #34495e;
    padding: 18px 15px 10px 15px;
    i {
      font-size: 18px;
      line-height: 18px;
      color: #cdd4d8;
      position: relative;
      top: 4px;
      margin: 0 5px 0 0;
    }
  }
  .actions {
    float: right;
    padding: 12px 15px 10px;
    input {
      float: left;
      margin: 0 10px 0 0;
    }
    .btn-group {
      float: left;
      margin: 0 10px 0 0;
      .btn {
        float: none;
        margin: 0;
        .caret {
          &:before {
            margin-right: -9px;
          }
        }
      }
    }
    .btn {
      float: left;
    }
    .dropdown-menu {
      font-size: 12px !important;
      max-width: 100%;
      overflow-x: hidden;
    }
  }
}
.question-bank-import {
  border: 1px solid #dde5eb;
  border-radius: 5px;
  @include clearfix;
  .content-header {
    background: #f4f5f9;
    margin: 0;

    padding: 10px 10px 10px 20px;
    border-radius: 3px 3px 0 0;

    .actions {
      padding-top: 0;
    }
    h4 {
      font-size: 12px;
    }
  }
}
.add-to-bank-block {
  clear: both;
  margin: 15px 0;
  font-size: 12px;
  //padding: 10px 20px 10px;
  background: #f4f5f9;
  border: 1px dashed #afafaf;
  border-radius: 3px;
  textarea {
    height: 100px;
    padding: 8px 12px;
  }
  > ul {
    width: 100%;
    @include clearfix();
    > li {
      float: left;
      width: calc(100%/2);
      vertical-align: middle;
      .col {
        float: left;
        width: 75%;
        padding: 10px 20px;
        height: 80px;
        .toggle-options {
          margin-bottom: 5px;
        }

        &:first-child {
          width: 25%;
          background: $white;
          position: relative;

          .single-option {
            label {
              display: block;
              margin: 4px 0 5px;
            }
          }

          &:before {
            content: "";
            position: absolute;
            right: -20px;
            top: 50%;
            margin-top: -10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid transparent;

            border-left: 10px solid $white;
          }
        }
      }
      /*.toggle-options {
        padding: 0 0 3px 0;
        label {
          display: inline-block;
          padding: 3px;
        }
        .toggle {
          display: inline-block;
          padding: 3px 5px;
          position: relative;
          top: 3px;
        }
        &.single-option {
          text-align: center;
          label {
            display: block;
            font-weight: 600;
            padding: 10px 0 0 0;
          }
        }
      }
      input[type="text"] {
        width: 100%;
      }*/
      .actions {
        margin: 0 0 -4px 0;
        .btn {
          margin-right: 10px;
        }
      }
      .inline-label {
        padding: 0 10px 0 0;
        vertical-align: middle;
      }
      input[type="checkbox"]:not(old) ~ label {
        color: #34495e;
      }
    }
  }
}
.question-type-strip {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: $clr-pri;
  border: 1px solid $clr-pri;
  padding: 1px 6px;
  :lang(fr) {
    text-transform: none;
  }
}

.twocolumn-grid {
  clear: both;
  width: 100%;
  ul {
    width: 50% !important;
    margin: 0 !important;
    padding: 0 40px 30px 0 !important;
  }
}

.highlight-admin {
  clear: both;
  padding: 15px 5px 5px 45px;
  .choices {
    font-size: 12px;
    font-weight: 600;
    color: #4d565f;
    //margin-top: 0;
    margin-top: -10px;
    p {
      padding: 5px 0;
    }
    .options {
      padding: 5px 0;
      line-height: 20px;
      span {
        padding: 0 5px;
      }
      i {
        font-size: 14px;
        line-height: 20px;
        color: $clr-pri;
        vertical-align: top;
      }
      .disabled {
        pointer-events: none;
        cursor: not-allowed;
        i {
          color: #d7d7d7;
        }
      }
    }
  }

  .highlight-tags {
    padding: 10px 0 0 0;
  }

  .tag {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 0 10px 10px 0;
    padding: 6px 10px;
    font-size: 14px;
    color: #909090;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    position: relative;
    .delete {
      background: $white;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      padding: 2px;
      position: absolute;
      right: -6px;
      top: -6px;
      i {
        font-size: 12px;
        line-height: 12px;
        color: #ec638d;
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
    &.active {
      border-color: $clr-pri;
    }
  }

  .highlight-input {
    padding: 15px 0 0 0;
    input {
      width: 250px;
      padding: 10px;
      border: 1px solid #e1e1e1;
      font-family: inherit;
      font-size: 12px;
      &:focus {
        border-bottom-color: $clr-pri;
      }
    }
  }
}

.hotspot-wrapper {
  max-width: 780px;
  margin: 0 0 0 45px;
  clear: both;
}
.hotspot-features {
  clear: both;
  @include clearfix();
  padding: 0 0 10px 0;
  .hotspot-labels {
    float: left;
    .label {
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      color: #34495e;
      border-radius: 10px;
      padding: 2px 20px;
      margin: 0 12px 0 0;
      min-width: 85px;
      border: 2px solid #34495e;
      :lang(fr) {
        text-transform: none;
      }
      &.red {
        background: #cd2b17;
        border-color: #cd2b17;
        color: $white;
      }
      &.green {
        border-color: #88be58;
      }
    }
  }
  .marks {
    float: right;
    font-size: 12px;
    color: #909090;
    padding: 5px 0 0 0;
    a {
      color: #909090;
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      padding: 0 0 0 10px;
      i {
        color: #fe596b;
        margin: 0 3px 0 0;
      }
    }
  }
}
.hotspot-image {
  background: $white;
  padding: 10px;
  width: 100%;
  max-height: 370px;
  border: 1px solid #e1e1e1;
  text-align: center;
  overflow: auto;
}
.hotspot-image img {
  max-width: none;
}
.hotspot-buttons {
  text-align: center;
  padding: 15px 10px;
  .btn {
    margin: 0 5px;
    padding: 9px 25px;
    border-radius: 20px;
  }
}



.sidebyside-container{
float: left;
width: 100%;
margin-top: 20px;
}

.group-editor{
  padding: 5px;
  .ql-container {
    min-height:30px;
}
}