.ui-paginator {
  //background-color: $paginatorBgColor;
  border: $paginatorBorder;
  padding: $paginatorPadding;
  font-size: 12px;
  color: $tableBodyRowTextColor;


  .ui-paginator-first,
  .ui-paginator-prev,
  .ui-paginator-next,
  .ui-paginator-last {
    color: $paginatorIconColor;
    height: $paginatorElementHeight;
    min-width: $paginatorElementWidth;
    border: $paginatorElementBorder;
    line-height: $paginatorElementHeight;
    padding: $paginatorElementPadding;
    margin: $paginatorElementMargin;
    vertical-align: top;
    @include border-radius($paginatorElementBorderRadius);

    &:not(.ui-state-disabled):not(.ui-state-active):hover {
      background-color: $paginatorElementHoverBgColor;
      color: $paginatorElementHoverIconColor;
    }
  }

  .p-dropdown {
    //border: 0 none;

    .p-dropdown-trigger, .p-dropdown-label {
      color: $paginatorIconColor;
    }

    &:hover {
      .p-dropdown-trigger, .p-dropdown-label {
        color: $paginatorElementHoverIconColor;
      }
    }
  }

  .ui-paginator-first {
    &:before {
      position: relative;
      top: 1px;
    }
  }

  .ui-paginator-prev {
    &:before {
      position: relative;
    }
  }

  .ui-paginator-next {
    &:before {
      position: relative;
      top: 1px;
    }
  }

  .ui-paginator-last {
    &:before {
      position: relative;
      top: 1px;
    }
  }

  .ui-paginator-pages {
    vertical-align: top;
    display: inline-block;
    padding: 0;

    .ui-paginator-page {
      color: $tableBodyRowTextColor;
      height: $paginatorElementHeight;
      min-width: $paginatorElementWidth;
      border: $paginatorElementBorder;
      line-height: $paginatorElementHeight;
      padding: $paginatorElementPadding;
      margin: $paginatorElementMargin;
      vertical-align: top;
      @include border-radius($paginatorElementBorderRadius);
      outline: none;
      padding: 2px 0 0 0;


      &.ui-state-active {
        background-color:$white;
       // color: $highlightColorText;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.20);
        border-radius: 3px;
      }

      &:not(.ui-state-active):hover {
        background-color: $paginatorElementHoverBgColor;
        color: $paginatorElementHoverIconColor;
      }
    }
  }

  .p-dropdown {
    margin-left: $inlineSpacing;
    //height: $paginatorElementHeight;
    min-width: auto;
  }
}

.ui-paginator {
  margin: 0;
  text-align: center;
  padding: 1em;
  .ui-paginator-top {
    border-bottom: 0 none;
  }
  .ui-paginator-bottom {
    border-top: 0 none;
  }
  .ui-paginator-left-content {
    float: left;
    line-height: 32px;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      float: none;
      padding-bottom: 10px;
    }
  }
  .ui-paginator-right-content {
    float: right;
    @include susy-breakpoint(1024px, $susy, ".no-mq") {
      float: none;
      padding-top: 10px;
    }
    .per-page {
      margin: 0 5px 0 0;
    }
  }
  .ui-paginator-page, .ui-paginator-pages, .ui-paginator-next, .ui-paginator-last, .ui-paginator-first, .ui-paginator-prev, .ui-paginator-current {
    display: inline-block;
    //min-width: 1.5em;
    //height: 1.5em;
    //line-height: 1.5em;
    zoom: 1;
    margin-left: .063em;
    margin-right: .063em;
    text-decoration: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
    outline: none;
  }
  .ui-paginator-pages {
    width: auto;
    line-height: 1;
  }
  .ui-paginator-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
  }
  .ui-paginator-page, .ui-paginator-next, .ui-paginator-last, .ui-paginator-first, .ui-paginator-prev {
    cursor: pointer;
  }
  .ui-paginator-current, .ui-paginator-rpp-options {
    margin-left: 1em;
    margin-right: 1em;
    background-image: none;
  }
  .ui-paginator-jtp-select option, .ui-paginator-rpp-options option {
    background-image: none;
    border: 0 none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  a.ui-state-disabled {
    outline: 0 none;
  }
  .p-dropdown {
    min-width: 4em;
    margin-left: .375em;
  }
}

.ui-fluid .ui-paginator .p-dropdown {
  width: auto;
}
