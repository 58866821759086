.translation-module {
  padding: 5px 20px 10px 20px;
  font-size: 14px;
  .question-copy {
    padding: 15px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #d7d7d7;

    h2{
      //font-size: 18px;
      margin-bottom:5px;
      color: $clr-pri;
    }
    h3 {
      //font-size: 18px;
      font-weight: normal;
      position: relative;

    }
  }
  .row {
    margin: 0 0 10px 0;
    @include clearfix();
    .col {
      float: left;
      padding: 0 0 0 15px;
      &:first-child {
        padding-left: 0;
      }
    }
    &.two--column {
      .col {
        width: 50%;
        padding: 5px 0;
      }
    }
    &.four--column {
      .col {
        width: 25%;
      }
    }
    &.translation--row {
      textarea {
        height: 80px;
        padding: 10px;
      }
    }
  }
  h4 {
    //font-size: 18px;
    font-weight: 400;
    color: #5d6872;
    padding: 0 0 5px 0;
    span {
      color: $clr-pri;
    }
  }
  .input-search {
    width: 100%;
  }
  .selected-languages {
    font-size: 12px;
    line-height: 16px;
    color: #5d6872;
    ul {
      padding: 8px 0 0 0;
      li {
        float: left;
        position: relative;
        margin: 6px 16px 6px 0;
        > a {
          display: block;
          color: #5d6872;
          padding: 5px 24px 5px 12px;
          border: 1px solid $clr-pri;
          border-radius: 3px;
          box-shadow: -1.888px 2.331px 4.05px 0.95px rgba(146, 146, 146, 0.11);
          &.current {
            background: $clr-pri;
            color: $white;
          }
        }
        .delete {
          position: absolute;
          right: -9px;
          top: 50%;
          margin: -9px 0 0 0;
          a {
            display: block;
            width: 18px;
            height: 18px;
            text-align: center;
            line-height: 19px;
            background: $white;
            border-radius: 50%;
            i {
              font-size: 16px;
              line-height: 16px;
              color: #ec638d;
              vertical-align: middle;
            }
            &:hover {
              i {
                color: #ad4967;
              }
            }
          }
        }
      }
    }
  }
  .select-language-list {
    li {
      float: left;
      width: 25%;
      padding: 0 0 12px 12px;
      font-size: 12px;
      line-height: 125%;
      background: $white;
      .language {
        position: relative;
        > a {
          position: relative;
          display: block;
          padding: 13px 7px 12px 47px;
          border-radius: 5px;
          color: #34495e;
          border: 1px solid $clr-pri;
          box-shadow: -1.888px 2.331px 4.05px 0.95px rgba(146, 146, 146, 0.11);
          transition: none;
          span {
            position: absolute;
            left: 7px;
            top: 50%;
            font-size: 12px;
            line-height: 28px;
            margin: -14px 0 0 0;
            display: inline-block;
            width: 28px;
            height: 28px;
            color: $clr-pri;
            border: 1px solid $clr-pri;
            text-align: center;
            border-radius: 5px;
            font-weight: 500;
            letter-spacing: 1px;
          }
          &:hover,
          &.active {
            padding: 12px 6px 11px 46px;
            color: $clr-pri;
            border-width: 2px;
            span {
              color: $white;
              background: $clr-pri;
              border-color: $white;
            }
          }
        }
        .delete {
          position: absolute;
          right: -8px;
          top: -8px;
          a {
            display: block;
            width: 18px;
            height: 18px;
            background: $white;
            border-radius: 50%;
            i {
              font-size: 16px;
              line-height: 16px;
              color: #ec638d;
              vertical-align: middle;
            }
            &:hover {
              i {
                color: #ad4967;
              }
            }
          }
        }
        &.current {
          > a {
            color: $clr-pri;
            padding: 13px 6px 12px 46px;
            border-width: 1px;
            span {
              background: $clr-pri;
              color: $white;
            }
            &:hover,
            &.active {
              color: $clr-pri;
              background: $white;
              span {
                background: $clr-pri;
                color: $white;
                border-color: $clr-pri;
              }
            }
          }
        }
        &.added {
          > a {
            padding-right: 35px;
            background: $clr-pri;
            color: $white;
            span {
              background: $white;
              color: $clr-pri;
            }
            &:hover,
            &.active {
              color: $white;
              background: $clr-pri;
              padding: 13px 35px 12px 47px;
              border-width: 1px;
              span {
                color: $clr-pri;
                background: $white;
                border-color: $clr-pri;
              }
            }
          }
          &:after {
            content: "\f00c";
            position: absolute;
            right: 12px;
            top: 50%;
            margin: -7px 0 0 0;
            font-family: "Font Awesome 5 Pro";
            font-size: 14px;
            line-height: 14px;
            text-transform: lowercase;
            color: $white;
          }
        }
      }
      &:first-child,
      &:nth-child(4n + 1) {
        padding-left: 0;
      }
    }
  }
}
.translation-select {
  .dropdown-menu {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
}
.table {
  > table {
    width: 100%;
    border-collapse: collapse;
    > tr {
      > td {
        padding: 6px 16px;
        &.actions {
          .btn {
            float: left;
            margin: 4px;
            vertical-align: middle;
          }
        }
      }
    }
    > thead {
      background: $white;
      font-size: 13px;
      font-weight: bold;
      color: $clr-pri;
      text-transform: uppercase;
      border-bottom: 2px solid $clr-pri;
      :lang(fr) {
        text-transform: none;
      }
    }
    > tbody {
      background: #f4f5f9;
      border-bottom: 1px solid #d7d7d7;
      > tr {
        &:first-child {
          > td {
            padding-top: 15px;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
.language--table {
  font-size: 12px;
  table {
    tr {
      td {
        &.actions {
          .btn {
            float: left;
            margin: 4px;
            vertical-align: middle;
          }
        }
        &.action-icons {
          a {
            color: $clr-pri;
            &:hover {
              color: $clr-sec;
            }
          }
        }
      }
    }
  }
  p {
    font-weight: 600;
  }
}
.category-options {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #d7d7d7;
  padding: 13px 0 10px;
  vertical-align: middle;
  @include clearfix();
  :lang(fr) {
    text-transform: none;
  }
  h4 {
    float: left;
    font-size: 14px;
    cursor: pointer;
    i {
      font-size: 24px;
      vertical-align: middle;
      margin: -2px 0 0 0;
      color: $clr-pri;
      transform: rotate(-90deg);
    }
  }
  .actions {
    float: right;
    padding: 3px 0 0 0;
    a {
      color: $clr-pri;
      &:hover {
        color: $clr-sec;
      }
    }
  }
}
.options-block {
  display: none;
}
.language--table + .category-options + .options-block {
  display: block;
}
.category-options.active {
  @include clearfix();
  h4 {
    i {
      transform: rotate(0deg);
    }
  }
}
